import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { GetSlotsResult } from "../types/results/get-slots-result";
import { IGetSlots } from "../types/slot";

const getSlotsForEmailQuery = gql`
  query GetSlots($workspaceId: String, $emails: [String]) {
    getSlots(workspace_id: $workspaceId, emails: $emails) {
      id
      key
      creator_id
      ics
      ics_uid
      reminder_status
      schedule_date
      status
      type
      workshop_id
      workspace_id
      workshop {
        topic
      }
    }
  }
`;

export function getSlotsForEmails(
  client: AppApolloClient,
  variables: {
    workspaceId: string;
    emails: string[];
  }
) {
  return client
    .query<GetSlotsResult>({
      query: getSlotsForEmailQuery,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data.getSlots;
    });
}

const getSlotsForWorkshopIdQuery = gql`
  query GetSlots($workspaceId: String, $workshopId: String) {
    getSlots(workspace_id: $workspaceId, workshop_id: $workshopId) {
      id
      key
      creator_id
      ics
      ics_uid
      reminder_status
      schedule_date
      status
      type
      workshop_id
      workspace_id
      workshop {
        topic
      }
      invitations {
        profile {
          id
          name
          email
          workspace {
            workspace_id
          }
        }
        status
        email
      }
    }
  }
`;

export function getSlotsForWorkshopId(
  client: AppApolloClient,
  variables: {
    workspaceId: string;
    workshopId: string;
  }
) {
  return client
    .query<GetSlotsResult>({
      query: getSlotsForWorkshopIdQuery,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data.getSlots;
    });
}

const getSlotsQuery = gql`
  query GetSlots(
    $workspaceId: String
    $status: [SlotStatus]
    $sortDirection: SortDirection
  ) {
    getSlots(
      workspace_id: $workspaceId
      sortDirection: $sortDirection
      status: $status
    ) {
      id
      create_date
      creator_id
      ics
      ics_uid
      invitations {
        email
        emails_count
        profile {
          id
          name
          image
          headline
          email
          workspace {
            workspace_id
          }
        }
        slot_id
        status
      }
      key
      profile {
        create_date
        email
        headline
        id
        image
        login_date
        name
        update_date
      }
      reminder_status
      schedule_date
      status
      type
      sessions {
        id
        create_date
        update_date
        complete_date
        session_key
        state
        status
        slot_id
        creator_id
        workshop_id
        workspace_id
      }
      workshop {
        activities {
          id
        }
        about_text
        about_video
        author {
          id
          create_date
          email
          headline
          is_completed
          login_date
          name
          update_date
        }
        author_id
        create_date
        duration
        headline
        id
        status
        topic
        type
        typeInstance {
          create_date
          description
          id
          name
          update_date
          video
        }
        update_date
      }
      workshop_id
      workspace_id
    }
  }
`;

export function getSlots(client: AppApolloClient, variables: IGetSlots) {
  return client
    .query<GetSlotsResult>({
      query: getSlotsQuery,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data.getSlots;
    });
}
