import {
  PropsWithChildren,
  createContext,
  useEffect,
  useMemo,
  useRef,
} from "react";

import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { ClientOptions, createClient } from "graphql-ws";
import { SocketStates, socketMachine } from "../+xstate/machines/socket";

import {
  closed,
  connected,
  connecting,
  error,
  opened,
  retry,
  pending,
  setConnectionStrength,
} from "../+xstate/actions/socket";
import { useMachine } from "@xstate/react";
import { apolloSubscriptionUri, apolloUri } from "../constants/endpoints";
import { ConnectionStrength } from "../types/enums/connection-strength";
import { RetryLink } from "@apollo/client/link/retry";

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
});

export type AppApolloClient = ApolloClient<NormalizedCacheObject>;

type SocketMachineState = ReturnType<
  typeof useMachine<typeof socketMachine>
>["0"];

export const SERVER_TIME_UPDATE = "SERVER_TIME_UPDATE" as const;

const httpLink = createHttpLink({
  uri: apolloUri,
});

export const ApolloContext = createContext<{
  client: AppApolloClient;
  setToken(value: string | null): void;
  socket: { state: SocketMachineState };
  serverTimeEventTarget: EventTarget;
  socketEventTarget: EventTarget;
}>({} as any);

type Handlers = {
  shouldRetry: ClientOptions["shouldRetry"] | null;
  connectionParams: ClientOptions["connectionParams"] | null;
  on: Partial<ClientOptions["on"]> | null;
};

export enum SocketEvents {
  MESSAGE = 'message',
  OPENED = 'opened',
  CLOSED = 'closed',
  ERROR = 'error',
  CONNECTED = 'connected',
  CONNECTING = 'connecting',
  PING = 'ping',
  PONG = 'pong',
  PENDING = 'pending',
};

export const ApolloProvider = (props: PropsWithChildren) => {
  const [socketState, socketSend, socketActor] = useMachine(socketMachine);

  const token = useRef<string | null>();
  const handlers = useRef<Handlers>({
    shouldRetry: null,
    connectionParams: null,
    on: null,
  });

  const serverTimeEventTarget = useMemo(() => new EventTarget(), []);
  const socketEventTarget = useMemo(() => new EventTarget(), []);

  useEffect(() => {
    handlers.current.shouldRetry = function shouldRetry() {
      const shouldRetry = !socketState.matches(SocketStates.Idle);
      if (shouldRetry) socketSend(retry());
      return shouldRetry;
    };
  }, [socketSend, socketState]);

  useEffect(() => {
    handlers.current.connectionParams = function () {
      return {
        Authorization: token.current ? `Bearer ${token.current}` : "",
      };
    };
  }, []);

  useMemo(() => {
    let pingSentTime: number | undefined = undefined;
    let pingTimeout: number | null = null
    const highConnectionThreshold = 100;
    const normalConnectionThreshold = 300;
    let connectionStrength = ConnectionStrength.Normal;

    handlers.current.on = {
      message: (message) => {
        if (message.type === "pong" && "serverTime" in message) {
          serverTimeEventTarget.dispatchEvent(
            new CustomEvent(SERVER_TIME_UPDATE, { detail: message.serverTime })
          );
        }

        socketEventTarget.dispatchEvent(new CustomEvent(SocketEvents.MESSAGE, { detail: message }));
      },
      opened: () => {
        socketSend(opened());
        socketActor.send(
          setConnectionStrength({
            connectionStrength: ConnectionStrength.Normal,
          })
        );

        socketEventTarget.dispatchEvent(new CustomEvent(SocketEvents.OPENED));
      },
      closed: () => {
        socketSend(closed());
        socketActor.send(
          setConnectionStrength({
            connectionStrength: ConnectionStrength.Unknown,
          })
        );

        socketEventTarget.dispatchEvent(new CustomEvent(SocketEvents.CLOSED));
      },
      error: () => {
        socketSend(error());
        socketActor.send(
          setConnectionStrength({
            connectionStrength: ConnectionStrength.Unknown,
          })
        );

        socketEventTarget.dispatchEvent(new CustomEvent(SocketEvents.ERROR));
      },
      connected: () => {
        socketSend(connected());
        socketActor.send(
          setConnectionStrength({
            connectionStrength: ConnectionStrength.Normal,
          })
        );

        socketEventTarget.dispatchEvent(new CustomEvent(SocketEvents.CONNECTED));
      },
      connecting: () => {
        socketSend(connecting());
        socketActor.send(
          setConnectionStrength({
            connectionStrength: ConnectionStrength.Unknown,
          })
        );

        socketEventTarget.dispatchEvent(new CustomEvent(SocketEvents.CONNECTING));
      },
      ping: () => {
        pingSentTime = Date.now();

        if (pingTimeout) {
          clearTimeout(pingTimeout);
        }

        pingTimeout = setTimeout(() => {
          socketSend(pending());
          socketEventTarget.dispatchEvent(new CustomEvent(SocketEvents.PENDING));
        }, 10_000) as any as number;

        socketEventTarget.dispatchEvent(new CustomEvent(SocketEvents.PING));
      },
      pong: () => {
        const roundTripTime = Date.now() - pingSentTime!;
        if (roundTripTime <= highConnectionThreshold) {
          connectionStrength = ConnectionStrength.High;
        } else if (roundTripTime <= normalConnectionThreshold) {
          connectionStrength = ConnectionStrength.Normal;
        } else {
          connectionStrength = ConnectionStrength.Slow;
        }
        const snapshot = socketActor.getSnapshot();
        if (snapshot.context.connectionStrength !== connectionStrength) {
          socketActor.send(setConnectionStrength({ connectionStrength }));
        }

        socketEventTarget.dispatchEvent(new CustomEvent(SocketEvents.PONG));

        if (socketState.matches(SocketStates.Pending)) {
          socketSend(opened());
        }
      },
    };
  }, [serverTimeEventTarget, socketActor, socketEventTarget, socketSend, socketState]);

  const client = useMemo(() => {
    const wsLink = new GraphQLWsLink(
      createClient({
        url: apolloSubscriptionUri,
        shouldRetry: (errOrCloseEvent) => {
          if (!handlers.current.shouldRetry) return false;
          return handlers.current.shouldRetry(errOrCloseEvent);
        },
        connectionParams: () => {
          if (!handlers.current.connectionParams) return;
          if (typeof handlers.current.connectionParams === "function")
            return handlers.current.connectionParams();
          return handlers.current.connectionParams;
        },
        on: handlers.current.on || undefined,
        keepAlive: 1000,
        retryAttempts: Infinity,
      })
    );

    const authLink = setContext((_, { headers }) => {
      const result = (typeof handlers.current.connectionParams === "function"
        ? handlers.current.connectionParams()
        : handlers.current.connectionParams) || { Authorization: undefined };

      if (result instanceof Promise)
        return result.then((result) => {
          const { Authorization } = result || { Authorization: undefined };
          return {
            headers: {
              ...headers,
              Authorization,
            },
          };
        });

      const { Authorization } = result;

      return {
        headers: {
          ...headers,
          Authorization,
        },
      };
    });

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      authLink.concat(httpLink)
    );

    const client = new ApolloClient({
      link: retryLink.concat(splitLink),
      cache: new InMemoryCache(),
    });

    return client;
  }, []);

  const value = useMemo(() => {
    return {
      client,
      setToken(tokenValue: string | null) {
        token.current = tokenValue;
      },
      socket: { state: socketState },
      serverTimeEventTarget,
      socketEventTarget,
    };
  }, [client, serverTimeEventTarget, socketEventTarget, socketState]);

  return (
    <ApolloContext.Provider value={value}>
      {props.children}
    </ApolloContext.Provider>
  );
};
