import { PropsWithChildren, memo, useMemo } from "react";

import { SlotType } from "../../apollo-graphql/types/enums/slot-type";
import UserImage from "../Shared/UserImage/UserImage";

import cn from "classnames";
import styles from "./WorkshopDetails.module.css";

export interface WorkshopDetailProps {
  author: { id: string; name: string; headline: string };
  workshop: {
    topic: string;
    headline: string;
    goals: string[];
    duration: number;
  };
  invitedParticipantCount: number;
  slotType?: SlotType;
}

function WorkshopDetails(
  props: PropsWithChildren<WorkshopDetailProps & { showInvitedPeople: boolean }>
) {
  const { author, workshop, invitedParticipantCount, showInvitedPeople } =
    props;

  const authorContent = useMemo(() => {
    return (
      <>
        <div className="text small">Workshop by</div>
        <div className="author-container">
          <UserImage
            isPublic={true}
            profileId={author.id}
            alt="author-profile"
            fallbackFontAwesomeIconClass="fa fa-user"
            containerClass={styles.imgContainer}
          />
          <div className={styles.authorInfo}>
            <h2>{author.name}</h2>
            <div className="text">{author.headline}</div>
          </div>
        </div>
      </>
    );
  }, [author.headline, author.id, author.name]);

  return (
    <div className={styles.container}>
      <div className={styles.topSection}>
        <h1>{workshop.topic}</h1>
        <div className="text">{workshop.headline}</div>
      </div>
      <div className={styles.dataSections}>
        <div className={styles.dataSection}>
          <div className={styles.authorContainer}>{authorContent}</div>
          <div className={styles.dataSections}>
            <div className={styles.dataSection}>
              <div className={cn(styles.dataSectionTitle, "text")}>
                <i className="icon fa fa-clock" />
                Time to complete
              </div>
              <div className={styles.statisticContainer}>
                <h1 className="big">{workshop.duration}</h1>
                <span className="text small">minutes</span>
              </div>
            </div>
            {invitedParticipantCount > 0 && showInvitedPeople && (
              <div className={styles.dataSection}>
                <div className={cn(styles.dataSectionTitle, "text")}>
                  <i className="icon fa fa-users" />
                  People invited
                </div>
                <div className={styles.statisticContainer}>
                  <h1 className="big">{invitedParticipantCount}</h1>
                  <span className="text small">users</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.dataSection}>
          <h2>Objectives:</h2>
          <div className={styles.goalsContainer}>
            {workshop.goals.map((goal, index) => (
              <div key={index} className={styles.goal}>
                <i className="icon fa fa-circle-check" />
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: goal.replaceAll(/\\n|\\/gm, ""),
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(WorkshopDetails);
