export function generateWorkspaceProfileImageKey(
  profileId: string,
  workspace_id: string
) {
  return `workspace_${workspace_id}:profile_${profileId}:profile_image`;
}

export function generateProfileImageKey(profileId: string) {
  return `public:profile_${profileId}:profile_image`;
}
