import { createMachine, assign, spawnChild, sendTo } from "xstate";
import { Workshop } from "../../../apollo-graphql/types/workshop";
import { AppApolloClient } from "../../../contexts/Apollo";
import * as adminDashboardActions from "../../actions/dashboard/admin-dashboard";
import { getWorkshops } from "../../../apollo-graphql/queries/workshop";
import { IEditSlot, Slot } from "../../../apollo-graphql/types/slot";
import { getSlots } from "../../../apollo-graphql/queries/slot";
import { fetchMachineFactory } from "../fetch-factory";
import { SlotStatus } from "../../../apollo-graphql/types/enums";
import { SortDirection } from "../../../types/enums/sort-direction";
import { editSlot } from "../../../apollo-graphql/mutations/slot";
import { teamMembersMachine } from "../team-members";

export enum AdminDashboardState {
  Dashboard = "dashboard",
  Workshops = "workshops",
  Schedule = "schedule",
  TeamMembers = "teamMembers",
}

export interface AdminDashboardMachineContext {
  client: AppApolloClient;
  workshops: Workshop[] | null;
  slots: Slot[] | null;
  currentSlotsWorkspaceId: string | null;
  error: any | null;
}

type AdminDashboardActionCreators = typeof adminDashboardActions;
type AdminDashboardActionCreatorKeys = keyof AdminDashboardActionCreators;
type AdminDashboardActions = ReturnType<
  AdminDashboardActionCreators[AdminDashboardActionCreatorKeys]
>;

export const {
  machine: getWorkshopsMachine,
  success: getWorkshopsSuccess,
  failure: getWorkshopsFailure,
  done: getWorkshopsDone,
} = fetchMachineFactory({
  id: "getWorkshops",
  invokeFn: ({
    client,
    searchText,
  }: {
    searchText: string;
    client: AppApolloClient;
  }) => {
    return getWorkshops(client, { searchText });
  },
  triggerOnCreation: true,
});

export const {
  machine: getSlotsMachine,
  success: getSlotsSuccess,
  trigger: getSlotTrigger,
  failure: getSlotsFailure,
  done: getSlotsDone,
  reTrigger: reTriggerGetSlots,
} = fetchMachineFactory({
  id: "getSlots",
  invokeFn: ({
    workspaceId,
    client,
    slotStatuses,
    sortDirection,
  }: {
    workspaceId: string;
    client: AppApolloClient;
    slotStatuses: SlotStatus[];
    sortDirection: SortDirection;
  }) => {
    return getSlots(client, {
      workspaceId,
      status: slotStatuses,
      sortDirection,
    }).then((slots) => ({
      slots,
      workspaceId,
    }));
  },
  triggerOnCreation: true,
});

export const {
  machine: editSlotMachine,
  success: editSlotSuccess,
  trigger: editSlotTrigger,
  failure: editSlotFailure,
  done: editSlotDone,
} = fetchMachineFactory({
  id: "editSlot",
  invokeFn: ({
    client,
    ...others
  }: IEditSlot & { client: AppApolloClient }) => {
    return editSlot(client, others);
  },
  triggerOnCreation: false,
});

type AdminDashboardMachineTypes = {
  context: AdminDashboardMachineContext;
  events:
    | AdminDashboardActions
    | ReturnType<typeof getWorkshopsSuccess>
    | ReturnType<typeof getWorkshopsFailure>
    | ReturnType<typeof getWorkshopsDone>
    | ReturnType<typeof getSlotsSuccess>
    | ReturnType<typeof getSlotsFailure>
    | ReturnType<typeof getSlotsDone>
    | ReturnType<typeof editSlotSuccess>
    | ReturnType<typeof editSlotFailure>
    | ReturnType<typeof editSlotDone>;
};

export const adminDashboardMachine = createMachine(
  {
    types: {} as AdminDashboardMachineTypes,
    id: "admin-dashboard",
    initial: AdminDashboardState.Dashboard,
    context: ({ input }): AdminDashboardMachineContext => {
      const machineInput = input as
        | {
            client?: AppApolloClient;
          }
        | undefined;
      if (!machineInput?.client)
        throw new Error("Apollo client must be provided!");
      return {
        client: machineInput.client,
        workshops: null,
        slots: null,
        currentSlotsWorkspaceId: null,
        error: null,
      };
    },
    states: {
      [AdminDashboardState.Dashboard]: {},
      [AdminDashboardState.Workshops]: {
        entry: [
          assign({
            error: null,
            workshops: null,
          }),
          spawnChild(getWorkshopsMachine.id, {
            id: getWorkshopsMachine.id,
            syncSnapshot: true,
            input: ({ event, context }: any) => {
              event = event as ReturnType<
                typeof adminDashboardActions.goToWorkshops
              >;
              return {
                searchText: event.payload.searchText,
                client: context.client,
              };
            },
          }),
        ],
        exit: sendTo(getWorkshopsMachine.id, () => {
          return getWorkshopsDone();
        }),
        on: {
          [getWorkshopsSuccess.type]: {
            actions: assign({
              workshops: ({ event }) => {
                return event.payload.output;
              },
            }),
          },
          [getWorkshopsFailure.type]: {
            actions: assign({
              error: ({ event }: any) => {
                return event.payload.error;
              },
            }),
          },
        },
      },
      [AdminDashboardState.TeamMembers]: {
        entry: [
          assign({
            error: null,
          }),
          spawnChild(teamMembersMachine.id, {
            id: teamMembersMachine.id,
            syncSnapshot: true,
            input: ({
              event,
              context,
            }: {
              event: any;
              context: AdminDashboardMachineContext;
            }) => {
              event = event as ReturnType<
                typeof adminDashboardActions.goToTeamMembers
              >;
              const { currentPage, pageSize, query, workspaceId } =
                event.payload;
              const pagination = {
                offset: (currentPage - 1) * pageSize,
                limit: pageSize,
              };

              return {
                workspace_id: workspaceId,
                client: context.client,
                pagination,
                query,
              };
            },
          }),
        ],
      },
      [AdminDashboardState.Schedule]: {
        entry: [
          assign({
            error: null,
            currentSlotsWorkspaceId: null,
            slots: null,
          }),
          spawnChild(getSlotsMachine.id, {
            id: getSlotsMachine.id,
            syncSnapshot: true,
            input: ({ event, context }: any) => {
              const currentEvent = event as ReturnType<
                typeof adminDashboardActions.goToSchedule
              >;
              return {
                workspaceId:
                  currentEvent.payload.workspaceId || context.workspaceId,
                sortDirection: currentEvent.payload.sortDirection,
                slotStatuses: currentEvent.payload.slotStatuses || [
                  SlotStatus.SCHEDULED,
                  SlotStatus.ONGOING,
                ], //TODO: use one constant that defines the filters for the different routes (we already have this isnide the Schedule.tsx)
                client: context.client,
              };
            },
          }),
          spawnChild(editSlotMachine.id, {
            id: editSlotMachine.id,
            syncSnapshot: true,
            input: ({ event, context }: any) => {
              const currentEvent = event as ReturnType<
                typeof adminDashboardActions.editSlot
              >;
              return {
                id: currentEvent.payload.id,
                status: currentEvent.payload.status,
                client: context.client,
              };
            },
          }),
        ],
        exit: sendTo(getSlotsMachine.id, () => {
          return getSlotsDone();
        }),
        on: {
          [getSlotsSuccess.type]: {
            actions: assign({
              slots: ({ event }) => {
                return event.payload.output.slots;
              },
              currentSlotsWorkspaceId: ({ event }) => {
                return event.payload.output.workspaceId;
              },
            }),
          },
          [getSlotsFailure.type]: {
            actions: assign({
              error: ({ event }: any) => {
                return event.payload.error;
              },
            }),
          },
          [adminDashboardActions.editSlot.type]: {
            actions: [
              sendTo(editSlotMachine.id, ({ event, context }: any) => {
                event = event as ReturnType<
                  AdminDashboardActionCreators["editSlot"]
                >;
                const payload = { client: context.client, ...event.payload };
                return editSlotTrigger(payload);
              }),
            ],
          },
          [editSlotSuccess.type]: {
            actions: [
              sendTo(getSlotsMachine.id, () => {
                return reTriggerGetSlots();
              }),
            ],
          },
          [editSlotFailure.type]: {
            actions: assign({
              error: ({ event }: any) => {
                return event.payload.error;
              },
            }),
          },
        },
      },
    },
    on: {
      [adminDashboardActions.goToDashboard.type]: {
        target: `.${AdminDashboardState.Dashboard}`,
      },
      [adminDashboardActions.goToWorkshops.type]: {
        target: `.${AdminDashboardState.Workshops}`,
      },
      [adminDashboardActions.goToSchedule.type]: {
        target: `.${AdminDashboardState.Schedule}`,
      },
      [adminDashboardActions.goToTeamMembers.type]: {
        target: `.${AdminDashboardState.TeamMembers}`,
      },
    },
  },
  {
    actors: {
      [getWorkshopsMachine.id]: getWorkshopsMachine,
      [getSlotsMachine.id]: getSlotsMachine,
      [editSlotMachine.id]: editSlotMachine,
      [teamMembersMachine.id]: teamMembersMachine,
    },
  }
);
