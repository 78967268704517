import { PropsWithChildren, useRef, memo, useState, useEffect } from "react";
import Video from "../Shared/Video/Video";
import UserImage from "../Shared/UserImage/UserImage";

import cn from "classnames";
import styles from "./VideoWithBlur.module.css";

const VideoWithBlur = (
  props: PropsWithChildren<{
    width: number | string;
    height: number;
    participantId: string;
    profileId: string;
    workspaceId: string;
    noBorderRadius?: boolean;
    disableAudioAndVideo: boolean;
    isConnecting?: boolean;
    isVideoMuted?: boolean;
    attachHtmlVideoElementToTracks(
      participantId: string,
      videoElement: HTMLVideoElement,
      audioElement: HTMLAudioElement
    ): void;
  }>
) => {
  const {
    width,
    height,
    participantId,
    profileId,
    workspaceId,
    noBorderRadius,
    disableAudioAndVideo,
    isConnecting,
    isVideoMuted,
    attachHtmlVideoElementToTracks,
  } = props;

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (!videoRef.current) return;
    if (isVideoMuted || disableAudioAndVideo) {
      setIsVideoPlaying(false);
    }
  }, [disableAudioAndVideo, isVideoMuted]);

  useEffect(() => {
    if (!videoRef.current) return;

    const videoPlayingHandler = () =>
      setTimeout(() => {
        setIsVideoPlaying(true);
      }, 300);

    videoRef.current.addEventListener("play", videoPlayingHandler);

    return () => {
      videoRef.current?.removeEventListener("play", videoPlayingHandler);
    };
  }, []);

  return (
    <>
      <div
        className={cn(
          styles.videoContainer,
          isVideoMuted && !isVideoPlaying && "hidden"
        )}
      >
        <Video
          key={
            "video-" +
            participantId +
            `-${isConnecting ? "connecting" : "connected"}`
          }
          noBorderRadius={noBorderRadius}
          participantId={participantId}
          width={width}
          isMuted={disableAudioAndVideo}
          isConnecting={isConnecting}
          loaderTransitionInMilliseconds={100}
          height={height}
          ref={(ref) => {
            if (!ref) return;
            videoRef.current = ref;

            if (audioRef.current) {
              attachHtmlVideoElementToTracks(
                participantId,
                videoRef.current!,
                audioRef.current!
              );
            }
          }}
          audioRef={(ref) => {
            if (!ref) return;
            audioRef.current = ref;

            if (videoRef.current) {
              attachHtmlVideoElementToTracks(
                participantId,
                videoRef.current!,
                audioRef.current!
              );
            }
          }}
        />
      </div>

      <div
        className={cn(
          styles.imageContainer,
          !isVideoMuted && isVideoPlaying && "hidden"
        )}
      >
        <div className={styles.mutedVideoContainer}>
          <UserImage
            isPublic={false}
            profileId={profileId}
            profileWorkspaceId={workspaceId}
            alt="profile-image"
            fallbackFontAwesomeIconClass="icon fa fa-user"
            showLoader={!isVideoPlaying && !isVideoMuted}
          />
        </div>
      </div>
    </>
  );
};

export default memo(VideoWithBlur);
