import { PropsWithChildren, memo, useMemo } from "react";
import { ConnectionStrength } from "../../types/enums/connection-strength";

import styles from "./InternetConnectionStrength.module.css";

const strengthIconMap = {
  [ConnectionStrength.Unknown]: "/icons/bad-connection.svg",
  [ConnectionStrength.High]: "/icons/good-connection.svg",
  [ConnectionStrength.Normal]: "/icons/good-connection.svg",
  [ConnectionStrength.Slow]: "/icons/poor-connection.svg",
};

const connectionStatusMessage = {
  [ConnectionStrength.Unknown]: "No internet connection.",
  [ConnectionStrength.High]: "Your internet connection is good.",
  [ConnectionStrength.Normal]: "Your internet connection is unstable.",
  [ConnectionStrength.Slow]: "Your internet connection is poor.",
};

const InternetConnectionStrength = (
  props: PropsWithChildren<{ strength: ConnectionStrength }>
) => {
  const { strength } = props;

  const connectionStrengthMessage = useMemo(() => {
    return connectionStatusMessage[strength];
  }, [strength]);

  const imageSrc = useMemo(() => strengthIconMap[strength], [strength]);

  return (
    <div className={styles.container}>
      <img src={imageSrc} alt="internet connection strength" />
      <div className="connection-message-container">
        <span className="arrow"></span>
        <div className="message">
          <p>{connectionStrengthMessage}</p>
        </div>
      </div>
    </div>
  );
};

export default memo(InternetConnectionStrength);
