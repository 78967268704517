import {
  PropsWithChildren,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import AsideMenu from "./AsideMenu";
import Overview from "./Overview";
import WorkshopReview from "./WorkshopReview";
import NextSteps from "./NextSteps";
import Congratulations from "../Congratulations/Congratulations";
import {
  IActivityResult,
  SessionStateValue,
} from "../../../apollo-graphql/types/session-state";
import { Workshop } from "../../../apollo-graphql/types/workshop";
import { useGenerateResults } from "./generateResults";
import { Workspace } from "../../../apollo-graphql/types/workspace";

import styles from "./ViewResults.module.css";
import cn from "classnames";

const TOP_BANNER_SCROLL = 267;
const SECTION_GAP = 80;
const SMALL_WINDOW_BREAKPOINT = 630; // In the current section setup this is the min-height of the Overview section

export default memo(function ViewResults({
  sessionState,
  activityResult,
  profileId,
  workshop,
  nextWorkshop,
  isFetchingNextWorkshop,
  workspace,
  requestNextWorkshop,
  nextWorkshopRequested,
  workshopCoverageData,
}: PropsWithChildren<{
  sessionState: SessionStateValue;
  activityResult: IActivityResult[];
  profileId: string;
  workshop: Workshop;
  nextWorkshop: Workshop | null;
  isFetchingNextWorkshop: boolean;
  workspace: Workspace;
  requestNextWorkshop: (id: string) => void;
  nextWorkshopRequested: boolean;
  workshopCoverageData: {
    coverage: number;
    workspaceProfilesCount: number;
  } | null;
}>) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const {
    activities,
    activityQuestions,
    teamConsensusCount,
    wrongAnswerIds,
    correctAnswersCount,
    wrongAnswersCount,
  } = useGenerateResults(activityResult, profileId, workshop);

  const overviewRef = useRef<HTMLDivElement>(null);
  const workshopReviewRef = useRef<HTMLDivElement>(null);
  const nextStepsRef = useRef<HTMLDivElement>(null);

  const windowIsSmall = window.innerHeight < SMALL_WINDOW_BREAKPOINT;

  const sectionRefs = useMemo(
    () => [overviewRef, workshopReviewRef, nextStepsRef],
    []
  );

  const sections = useMemo(
    () => [
      <Overview
        key="overview"
        ref={sectionRefs[0]}
        activityResult={activityResult}
        profileId={profileId}
        workshop={workshop}
        workspace={workspace}
        correctAnswersCount={correctAnswersCount}
        wrongAnswersCount={wrongAnswersCount}
        teamConsensusCount={teamConsensusCount}
        activityQuestionsCount={activityQuestions.length}
        workshopCoverageData={workshopCoverageData}
      />,
      <WorkshopReview
        key="workshop-review"
        ref={sectionRefs[1]}
        sessionState={sessionState}
        workshop={workshop}
        activityResult={activityResult}
        profileId={profileId}
        activities={activities}
        wrongAnswerIds={wrongAnswerIds}
      />,
      <NextSteps
        key="next-steps"
        ref={sectionRefs[2]}
        workshop={workshop}
        nextWorkshop={nextWorkshop}
        isFetchingNextWorkshop={isFetchingNextWorkshop}
        activities={activities}
        requestNextWorkshop={requestNextWorkshop}
        nextWorkshopRequested={nextWorkshopRequested}
      />,
    ],
    [
      sectionRefs,
      activities,
      activityQuestions.length,
      activityResult,
      workspace,
      nextWorkshop,
      isFetchingNextWorkshop,
      correctAnswersCount,
      profileId,
      sessionState,
      teamConsensusCount,
      workshop,
      wrongAnswerIds,
      wrongAnswersCount,
      requestNextWorkshop,
      nextWorkshopRequested,
      workshopCoverageData,
    ]
  );

  const [currentSectionIdx, setCurrentSectionIdx] = useState(0);

  const [isAsideFixed, setIsAsideFixed] = useState(false);

  const autoScrollTarget = useRef<number | null>(null);
  const lastScrollTop = useRef<number>(0);

  const [scrollCoolDown, setScrollCoolDown] = useState(false);
  const scrollCoolDownRef = useRef(false);

  const onScrollHandler = useCallback(
    (event: any) => {
      const isAutoScrolling =
        !!autoScrollTarget.current || autoScrollTarget.current === 0;

      const scrollTop = event.target.scrollTop;
      const isScrollingDown =
        scrollTop > 0 && scrollTop >= lastScrollTop.current;
      lastScrollTop.current = scrollTop;

      const asideShouldBeFixed = scrollTop > TOP_BANNER_SCROLL;
      if (isAsideFixed !== asideShouldBeFixed) {
        setIsAsideFixed(asideShouldBeFixed);
      }

      const sectionHeights = sectionRefs.map(
        (ref) => ref.current?.offsetHeight || 0
      );
      const previousSectionHeights = sectionHeights
        .slice(0, currentSectionIdx)
        .reduce((acc, height) => acc + height, 0);
      const previousSectionGaps = currentSectionIdx * SECTION_GAP;
      const currentSectionHeight = sectionHeights[currentSectionIdx];

      const currentSectionScrollTop =
        scrollTop -
        previousSectionHeights -
        TOP_BANNER_SCROLL -
        previousSectionGaps;

      if (!isAutoScrolling && !scrollCoolDownRef.current) {
        if (
          currentSectionScrollTop > currentSectionHeight &&
          currentSectionIdx < sectionRefs.length - 1
        ) {
          setCurrentSectionIdx(currentSectionIdx + 1);
        }
        if (currentSectionScrollTop < 0 && currentSectionIdx > 0) {
          setCurrentSectionIdx(currentSectionIdx - 1);
        }

        if (isScrollingDown) {
          const heightDiff =
            window.innerHeight -
            (sectionRefs[currentSectionIdx].current?.clientHeight || 0);
          const nextSectionScrollTarget = windowIsSmall
            ? currentSectionHeight * 0.6
            : heightDiff < 0
            ? heightDiff * 2.5 * -1
            : 80;
          if (
            currentSectionScrollTop > nextSectionScrollTarget &&
            currentSectionIdx < sectionRefs.length - 1
          ) {
            const top =
              previousSectionGaps +
              previousSectionHeights +
              currentSectionHeight +
              TOP_BANNER_SCROLL +
              SECTION_GAP;
            setCurrentSectionIdx(currentSectionIdx + 1);
            autoScrollTarget.current = top;
            wrapperRef.current?.scroll({ behavior: "smooth", top });
          }
        } else {
          if (currentSectionScrollTop < 0 && currentSectionIdx) {
            const newIdx = currentSectionIdx - 1;

            const newPreviousSectionHeights = sectionHeights
              .slice(0, newIdx)
              .reduce((acc, height) => acc + height, 0);
            const newPreviousSectionGaps = newIdx * SECTION_GAP;

            const top =
              newIdx === 0
                ? 0
                : newPreviousSectionGaps +
                  newPreviousSectionHeights +
                  TOP_BANNER_SCROLL;
            setCurrentSectionIdx(newIdx);
            autoScrollTarget.current = top;
            wrapperRef.current?.scroll({ behavior: "smooth", top });
          }
        }
      }

      if (
        isAutoScrolling &&
        (isScrollingDown
          ? scrollTop >= autoScrollTarget.current!
          : scrollTop <= autoScrollTarget.current!)
      ) {
        autoScrollTarget.current = null;
        setScrollCoolDown(true);
        scrollCoolDownRef.current = true;
        setTimeout(() => {
          setScrollCoolDown(false);
          scrollCoolDownRef.current = false;
        }, 200);
      }
    },
    [currentSectionIdx, isAsideFixed, sectionRefs, windowIsSmall]
  );

  const onSelectMenuItemHandler = useCallback(
    (idx: number) => {
      setCurrentSectionIdx(idx);

      const sectionHeights = sectionRefs.map(
        (ref) => ref.current?.offsetHeight || 0
      );
      const previousSectionHeights = sectionHeights
        .slice(0, idx)
        .reduce((acc, height) => acc + height, 0);
      const previousSectionGaps = idx * SECTION_GAP;
      const top =
        idx === 0
          ? 0
          : previousSectionHeights + TOP_BANNER_SCROLL + previousSectionGaps;

      autoScrollTarget.current = top;
      wrapperRef.current?.scroll({ behavior: "smooth", top });
    },
    [sectionRefs]
  );

  return (
    <div
      className={cn(styles.resultsContainer, "main-container")}
      ref={wrapperRef}
      onScroll={onScrollHandler}
      style={{ overflow: scrollCoolDown ? "hidden" : "auto" }}
    >
      <Congratulations
        congratulationImageSrc="/images/congratulations_view_results.svg"
        showAnimation={currentSectionIdx === 0}
      />
      <div className={styles.resultsContent}>
        <AsideMenu
          selectedMenuItemIdx={currentSectionIdx}
          onSelectHandlerIdx={onSelectMenuItemHandler}
          isFixed={isAsideFixed}
        />
        <div className={styles.content}>
          {sections.map((section) => section)}
        </div>
      </div>
    </div>
  );
});
