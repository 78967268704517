export enum ActivityType {
  Question = "Question",
  Assignment = "Assignment",
  Theory = "Theory",
  Conceptualization = "Conceptualization",
  Benchmark = "Benchmark",
  Survey = "Survey",
  Action = "Action",
  Moment = "Moment",
}
