import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { IResetPasswordResponse } from "../types/results/reset-password-result";
import { IResetPassword } from "../types/reset-password";

const query = gql`
  query ResetPassword($email: String!) {
    resetPassword(email: $email) {
      success
    }
  }
`;

export function resetPassword(
  client: AppApolloClient,
  variables: IResetPassword
) {
  return client
    .query<IResetPasswordResponse>({
      query,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.resetPassword;
    });
}
