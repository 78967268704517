import {
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SessionStateValue } from "../../../apollo-graphql/types/session-state";

import { ActivityCommon } from "../../../types/activity-common";
import ActionFooter from "../ActionFooter";
import { activityTypeFooterTextFactoryMap } from "../../../utils/footer";
import { ActionFooterType } from "../../../types/action-footer";
import { FooterType } from "../../../types/enums/activity-footer";

import styles from "./Survey.module.css";
import cn from "classnames";

const surveyAnswers = new Array(11).fill(null).map((_, i) => `${i}`);

interface SurveyProps extends ActivityCommon {
  activityResultForCurrentProfile:
    | SessionStateValue["context"]["activityResult"][0]["value"][0]["value"][0]
    | null;
  setActivityValueHandler: (args: {
    activityId: string;
    value: string;
  }) => void;
}
export default memo(function Survey(props: PropsWithChildren<SurveyProps>) {
  const {
    activity,
    transition,
    isReady,
    currentActiveParticipantCount,
    notReadyProfilesCount,
    activityResultForCurrentProfile,
    setActivityReadyHandler,
    setActivityValueHandler,
  } = props;

  const completedItemValues = useMemo(() => {
    if (!activityResultForCurrentProfile) return [];
    let currentValues: string[];
    try {
      currentValues = JSON.parse(activityResultForCurrentProfile.value);
      return currentValues || [];
    } catch (e) {
      console.error("Error parsing survey value");
      return [];
    }
  }, [activityResultForCurrentProfile]);

  const sortedSurveyItems = useMemo(() => {
    return (
      [...(activity.items || [])].sort(
        (a, b) => a.sequence_number - b.sequence_number
      ) || []
    );
  }, [activity.items]);

  const [currentItemIndex, setCurrentIndex] = useState(
    Math.min(completedItemValues.length, sortedSurveyItems.length - 1)
  );
  const [currentItemValue, setCurrentItemValue] = useState<string | null>(null);

  useEffect(
    () => setCurrentItemValue(completedItemValues[currentItemIndex] || null),
    [completedItemValues, currentItemIndex]
  );

  const currentItem = useMemo(() => {
    return (
      sortedSurveyItems[currentItemIndex] ||
      sortedSurveyItems[sortedSurveyItems.length - 1]
    );
  }, [currentItemIndex, sortedSurveyItems]);

  const setCurrentItemValueHandler = useCallback(
    (value: string) => {
      completedItemValues[currentItemIndex] = value;
      const updatedCompletedValuesString = JSON.stringify(completedItemValues);
      setActivityValueHandler({
        activityId: activity.id,
        value: updatedCompletedValuesString,
      });
    },
    [
      activity.id,
      completedItemValues,
      currentItemIndex,
      setActivityValueHandler,
    ]
  );

  const continueClickHandler = useCallback(() => {
    if (!currentItemValue) return;
    setCurrentItemValueHandler(currentItemValue);
    if (currentItemIndex + 1 > sortedSurveyItems.length - 1) return;
    setCurrentIndex((curr) => curr + 1);
  }, [
    currentItemIndex,
    currentItemValue,
    setCurrentItemValueHandler,
    sortedSurveyItems.length,
  ]);

  const isFirstItem = currentItemIndex === 0;
  const isLastItem = currentItemIndex === sortedSurveyItems.length - 1;
  const hasCompletedAllItems =
    sortedSurveyItems.length <= completedItemValues.length; // depending on this we will allow the user to click continue in the footer

  const text = currentItem.text; // the first text;
  const question = currentItem.question; // the text above the scale
  const min_text = currentItem.min_text; // value that is on the left side under the scale
  const max_text = currentItem.max_text; // value that is on the right side under the scale
  // const details = currentItem.details; // we should have this displayed under the scale
  // const more = currentItem.more; // also there is a button "See more" and when clicked we should display this

  const actionFooterData: ActionFooterType = useMemo(() => {
    if (transition > 0) {
      return {
        text: (
          <>
            Everyone is ready. Continuing forward in{" "}
            <span className="accent">{transition}...</span>
          </>
        ),
        buttonText: "",
        disabledButton: false,
        type: FooterType.Ready,
        isLoading: true,
      };
    }
    if (!isReady) {
      const buttonText = hasCompletedAllItems ? "Continue" : "";
      const text = hasCompletedAllItems ? (
        activityTypeFooterTextFactoryMap[activity.type](
          currentActiveParticipantCount - notReadyProfilesCount
        )
      ) : (
        <>Answer all the survey questions in order to continue.</>
      );

      return {
        text,
        buttonText,
        disabledButton: false,
        type: FooterType.Notice,
        isLoading: false,
      };
    }
    return {
      text: (
        <>
          Waiting for{" "}
          <span className="accent">
            {notReadyProfilesCount} more player
            {notReadyProfilesCount > 1 && "s"}...
          </span>
        </>
      ),
      buttonText: "",
      disabledButton: true,
      type: FooterType.Waiting,
      isLoading: false,
    };
  }, [
    transition,
    isReady,
    notReadyProfilesCount,
    hasCompletedAllItems,
    activity.type,
    currentActiveParticipantCount,
  ]);

  return (
    <div key={activity.id} className="activity-container">
      <div className={cn(styles.container, "main-container")}>
        <div className={cn(styles.infoText, "text", "bold")}>{text}</div>
        <div className={cn(styles.question, "text")}>{question}</div>
        <div className={styles.answersContainer}>
          {surveyAnswers.map((answerValue) => (
            <div
              className={cn(
                styles.answer,
                currentItemValue === answerValue ? "selected" : "",
                "text",
                "bold",
                "extra"
              )}
              onClick={() => {
                setCurrentItemValue(answerValue);
              }}
            >
              {answerValue}
            </div>
          ))}
        </div>
        {min_text && max_text && (
          <div className={styles.answerGuide}>
            <span className="text small">{min_text}</span>
            <span className="text small">{max_text}</span>
          </div>
        )}

        <div className={styles.controlsContainer}>
          <button
            className={styles.control}
            disabled={isFirstItem}
            onClick={() => setCurrentIndex((curr) => curr - 1)}
          >
            ⃪
          </button>
          <button
            className={styles.control}
            disabled={
              isLastItem || currentItemIndex > completedItemValues.length - 1
            }
            onClick={() => setCurrentIndex((curr) => curr + 1)}
          >
            ͢
          </button>
        </div>

        {/* {details && (
        <div className={styles.details}>
          <div
            className={cn(styles.detailsText, 'text')}
            dangerouslySetInnerHTML={{ __html: details }}
          />
          <button className={styles.seeMoreButton}>See more</button>
        </div>
      )} */}

        <button
          className={cn(styles.continueHandler, "btn")}
          disabled={
            !currentItemValue ||
            completedItemValues[currentItemIndex] === currentItemValue
          }
          onClick={continueClickHandler}
        >
          Continue
        </button>
      </div>
      <ActionFooter
        buttonText={actionFooterData.buttonText}
        type={actionFooterData.type}
        disabledButton={actionFooterData.disabledButton}
        buttonClickHandler={() =>
          setActivityReadyHandler({ activityId: activity.id })
        }
        isLoading={actionFooterData.isLoading}
      >
        {actionFooterData.text}
      </ActionFooter>
    </div>
  );
});
