import { PropsWithChildren, memo } from "react";

import cn from "classnames";
import styles from "./TabSwitch.module.css";

interface Tab {
  id: string;
  label: string;
}

const TabSwitch = (
  props: PropsWithChildren<{
    tabs: Tab[];
    activeTabId: string;
    setActiveTab: (tabId: string) => void;
  }>
) => {
  const { tabs, activeTabId, setActiveTab } = props;
  return (
    <div className={styles.container}>
      {tabs.map(({ id, label }) => (
        <button
          type="button"
          key={id}
          className={cn(styles.tab, id === activeTabId ? 'active' : '', 'text', 'small', 'bold')}
          onClick={() => setActiveTab(id)}
        >
          {label}
        </button>
      ))}
    </div>
  );
}

export default memo(TabSwitch);