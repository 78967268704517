export class StatsHelper {
  private values: number[];

  constructor() {
    this.values = [];
  }

  addValue(value: number): void {
    this.values.push(value);
  }

  getValues(): number[] {
    return this.values;
  }

  getMin(): number {
    return Math.min(...this.values);
  }

  getMax(): number {
    return Math.max(...this.values);
  }

  getValueAtPercentile(percentile: number): number {
    if (percentile < 0 || percentile > 100)
      throw new Error(`Invalid argument supplied for getValueAtPercentile() "${percentile}". Percentile must be between 0 and 100.`);

    const sortedValues = this.values.slice().sort((a, b) => a - b);
    const index = Math.max(
      Math.ceil((percentile / 100) * sortedValues.length) - 1,
      0
    );

    return sortedValues[index];
  }
}

export function determineTeamStagesProbabilities(ratingsPerParticipant: (number | null)[][], maxStatementScore = 10) {
  const ratingsPerStatement: number[][] = Array.from({ length: 12 }, () => []);

  // Reorganize ratings to be per statement
  for (let i = 0; i < ratingsPerParticipant.length; i++) {
    const participantRatings = ratingsPerParticipant[i];
    for (let j = 0; j < participantRatings.length; j++) {
      if (participantRatings[j] !== null) {
        ratingsPerStatement[j].push(participantRatings[j]!);
      }
    }
  }

  // Calculate a combined rating for each statement
  const combinedRatingPerStatement: (number | null)[] = [];
  for (let i = 0; i < ratingsPerStatement.length; i++) {
    const ratingStats = new StatsHelper();
    const statementRatings = ratingsPerStatement[i];
    for (let j = 0; j < statementRatings.length; j++) {
      ratingStats.addValue(statementRatings[j]);
    }
    let combinedRating: number | null = null;
    if (ratingStats.getValues().length > 0) {
      combinedRating = (ratingStats.getMin() + ratingStats.getValueAtPercentile(50)) / 2;
    }
    combinedRatingPerStatement.push(combinedRating);
  }

  const scores: { [key: string]: number } = {};
  // Calculate score for Forming
  const formingStats = new StatsHelper();
  combinedRatingPerStatement.slice(0, 3).forEach(value => {
    if (value !== null) {
      formingStats.addValue(value);
    }
  });
  let scoreForForming: number | null = null;
  if (formingStats.getValues().length > 0) {
    scoreForForming = formingStats.getMin();
    scores["Forming"] = maxStatementScore - scoreForForming;
  }

  // Calculate score for Storming
  const stormingStats = new StatsHelper();
  combinedRatingPerStatement.slice(3, 6).forEach(value => {
    if (value !== null) {
      stormingStats.addValue(value);
    }
  });

  let scoresForStorming: number | null = null;
  if (stormingStats.getValues().length > 0) {
    scoresForStorming = stormingStats.getMin();
    scores["Storming"] = maxStatementScore - scoresForStorming;
  }

  // Calculate score for Norming
  const normingStats = new StatsHelper();
  combinedRatingPerStatement.slice(6, 9).forEach(value => {
    if (value !== null) {
      normingStats.addValue(value);
    }
  });

  let scoresForNorming: number | null = null;
  if (normingStats.getValues().length > 0) {
    scoresForNorming = normingStats.getMin();
    scores["Norming"] = maxStatementScore - scoresForNorming;
  }

  // Calculate score for Performing
  const performing = new StatsHelper();
  combinedRatingPerStatement.slice(9, 12).forEach(value => {
    if (value !== null) {
      performing.addValue(value);
    }
  });

  let scoresForPerforming: number | null = null;
  if (performing.getValues().length > 0) {
    scoresForPerforming = performing.getMin();
    scores["Performing"] = maxStatementScore - scoresForPerforming;
  }

  // Calculate probabilities using the Softmax function
  let sumOfExp = 0;
  Object.values(scores).forEach(score => {
    sumOfExp += Math.exp(score);
  });

  const probabilitiesMap: Record<string, number> = {};
  Object.keys(scores).forEach(stage => {
    const score = scores[stage];
    probabilitiesMap[stage] = Math.exp(score) / sumOfExp;
  });

  const probabilities: (number | null)[] = [];
  probabilities.push(probabilitiesMap["Forming"] !== undefined ? 100 * probabilitiesMap["Forming"] : null);
  probabilities.push(probabilitiesMap["Storming"] !== undefined ? 100 * probabilitiesMap["Storming"] : null);
  probabilities.push(probabilitiesMap["Norming"] !== undefined ? 100 * probabilitiesMap["Norming"] : null);
  probabilities.push(probabilitiesMap["Performing"] !== undefined ? 100 * probabilitiesMap["Performing"] : null);

  return probabilities;
}

export function calculateRelevanceScores(ratingsPerParticipant: (number | null)[][], stageProbabilities: (number | null)[]): number[] {
  const ratingsPerStatement: number[][] = Array.from({ length: 12 }, () => []);

  // Reorganize ratings to be per statement
  for (let i = 0; i < ratingsPerParticipant.length; i++) {
    const participantRatings = ratingsPerParticipant[i];
    for (let j = 0; j < participantRatings.length; j++) {
      if (participantRatings[j] !== null) {
        if (!ratingsPerStatement[j]) {
          ratingsPerStatement[j] = [];
        }
        ratingsPerStatement[j].push(participantRatings[j]!);
      }
    }
  }

  // Calculate a combined rating for each statement
  const combinedRatingPerStatement: number[] = [];
  const minMaxRatingPerStatement: number[] = [];
  const minRatingPerStatement: number[] = [];

  for (let i = 0; i < ratingsPerStatement.length; i++) {
    const ratingStats = new StatsHelper();
    const statementRatings = ratingsPerStatement[i];
    for (let j = 0; j < statementRatings.length; j++) {
      if (statementRatings[j] !== null && statementRatings[j] !== undefined) {
        ratingStats.addValue(statementRatings[j]);
      }
    }
    let combinedRating: number | null = null;
    let minMaxRating: number | null = null;
    let minRating: number | null = null;
    const values = ratingStats.getValues();
    if (values.length > 0) {
      combinedRating = (ratingStats.getMin() + ratingStats.getValueAtPercentile(50)) / 2;
      minMaxRating = ratingStats.getMax() - ratingStats.getMin();
      minRating = ratingStats.getMin();
    }
    combinedRatingPerStatement.push(combinedRating!);
    minMaxRatingPerStatement.push(minMaxRating!);
    minRatingPerStatement.push(minRating!);
  }

  const relevanceScores: number[] = new Array(12).fill(0);
  const maxScore = 2 * 10;
  const maxScoreWithProbability = maxScore * 100;
  const maxScoreWithVariance = maxScore + 2 * 10 + 2 * 10;
  for (let i = 0; i < combinedRatingPerStatement.length; i++) {
    let combinedRating = combinedRatingPerStatement[i];
    if (combinedRating === null) {
      relevanceScores[i] = -1;
    } else {
      combinedRating = 10 - combinedRating;
      const minMaxRating = minMaxRatingPerStatement[i];
      const minRating = 10 - minRatingPerStatement[i];
      let probability = null;
      if (i < 3) {
        probability = stageProbabilities[0];
      } else if (i < 6) {
        probability = stageProbabilities[1];
      } else if (i < 9) {
        probability = stageProbabilities[2];
      } else {
        probability = stageProbabilities[3];
      }
      const relevanceScoreByProbability = (probability !== null) ? 100 * (combinedRating * 2 * probability) / maxScoreWithProbability : ((combinedRating * 2) / maxScore) * 100;
      const relevanceScoresByVariance = (((combinedRating * 2) + (minMaxRating * 2) + (minRating * 2)) / maxScoreWithVariance) * 100;
      relevanceScores[i] = (relevanceScoreByProbability + relevanceScoresByVariance) / 2;
    }
  }
  return relevanceScores;
}
