import { useMachine } from "@xstate/react";
import { PropsWithChildren, createContext, useContext, useMemo } from "react";
import {
  adminDashboardMachine,
  getSlotsMachine,
} from "../+xstate/machines/dashboard/admin-dashboard";
import { ApolloContext, AppApolloClient } from "./Apollo";
import { useChildMachine } from "../hooks/useChildMachine";

type AdminDashboardState = {
  state: ReturnType<typeof useMachine<typeof adminDashboardMachine>>["0"];
  send: ReturnType<typeof useMachine<typeof adminDashboardMachine>>["1"];
  schedule: {
    getSlotsState: ReturnType<typeof useMachine<typeof getSlotsMachine>>["0"];
    getSlotsSend: ReturnType<typeof useMachine<typeof getSlotsMachine>>["0"];
  };
};

export const AdminDashboardContext = createContext<
  AdminDashboardState & { client: AppApolloClient }
>({} as any);

export const AdminDashboardContextProvider = (props: PropsWithChildren) => {
  const { client } = useContext(ApolloContext);
  const [state, send] = useMachine(adminDashboardMachine, {
    input: { client },
  });
  const [getSlotsState, getSlotsSend] = useChildMachine(
    state,
    getSlotsMachine.id
  );

  const value = useMemo(
    () => ({
      state,
      send,
      client,
      schedule: {
        getSlotsState,
        getSlotsSend,
      },
    }),
    [getSlotsSend, getSlotsState, client, send, state]
  );

  return (
    <AdminDashboardContext.Provider value={value}>
      {props.children}
    </AdminDashboardContext.Provider>
  );
};
