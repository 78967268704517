import { createMachine, fromPromise, assign, sendParent } from "xstate";
import { createAction, props } from "../utils";

export enum FetchState {
  Idle = "idle",
  Fetching = "fetching",
  Success = "success",
  Failure = "failure",
  Done = "done",
}

export interface FetchMachineContext<T = any> {
  data: T | null;
  error: any | null;
  input: any;
}

type FetchMachineTypes<T = any> = {
  context: FetchMachineContext<T>;
  events: undefined;
};

export const fetchMachineFactory = <
  T extends string = string,
  I extends (data: any) => Promise<any> = (data: any) => any
>({
  id,
  triggerOnCreation,
  creationTriggerValue,
  invokeFn,
}:
  | {
      id: T;
      invokeFn: I;
      triggerOnCreation?: false;
      creationTriggerValue?: void;
    }
  | {
      id: T;
      invokeFn: I;
      triggerOnCreation: true;
      creationTriggerValue?: I extends (data: infer U) => any
        ? U extends void
          ? void
          : U
        : void;
    }) => {
  const trigger = createAction(
    `[${id} - Fetch] Fetch Trigger`,
    props<I extends (data: infer U) => any ? U : void>()
  );
  const success = createAction(
    `[${id} - Fetch] Fetch Success`,
    props<{
      output: I extends (data: any) => Promise<infer U> ? U : never;
      input: I extends (data: infer U) => any ? U : void;
    }>()
  );
  const failure = createAction(
    `[${id} - Fetch] Fetch Failure`,
    props<{
      error: any;
      input: I extends (data: infer U) => any ? U : void;
    }>()
  );
  const done = createAction(`[${id} - Fetch] Fetch Done`);
  const reset = createAction(`[${id} - Fetch] Fetch Reset`);
  const reTrigger = createAction(`[${id} - Fetch] Fetch ReTrigger`);

  return {
    machine: createMachine({
      types: {} as FetchMachineTypes<
        I extends (data: any) => Promise<infer U> ? U : void
      >,
      id,
      initial: triggerOnCreation ? FetchState.Fetching : FetchState.Idle,
      context: ({ input }): FetchMachineContext => {
        return {
          data: null,
          error: null,
          input: input || {},
        };
      },
      states: {
        [FetchState.Idle]: {
          on: {
            [trigger.type]: {
              target: FetchState.Fetching,
            },
            [done.type]: {
              target: FetchState.Done,
            },
          },
        },
        [FetchState.Fetching]: {
          entry: assign({
            input: ({ event, context }) => {
              return event.type === "xstate.init"
                ? creationTriggerValue || context.input
                : event.payload;
            },
          }),
          invoke: {
            src: fromPromise(({ input }) => invokeFn(input)),
            input: ({ context }) => ({
              ...context.input,
            }),
            onDone: {
              actions: [
                assign({ data: ({ event }) => event.output }),
                sendParent(({ event, context }) =>
                  success({ output: event.output, input: context.input })
                ),
              ],
              target: FetchState.Success,
            },
            onError: {
              actions: [
                assign({ error: ({ event }) => event.error }),
                sendParent(({ event, context }) =>
                  failure({ error: event.error, input: context.input })
                ),
              ],
              target: FetchState.Failure,
            },
          },
          on: {
            [done.type]: {
              target: FetchState.Done,
            },
          },
        },
        [FetchState.Success]: {
          on: {
            [trigger.type]: {
              target: FetchState.Fetching,
            },
            [done.type]: {
              target: FetchState.Done,
            },
            [reset.type]: {
              target: FetchState.Idle,
            },
            [reTrigger.type]: {
              actions: [
                ({ self, context }: any) => self.send(trigger(context.input)),
              ],
            },
          },
        },
        [FetchState.Failure]: {
          on: {
            [trigger.type]: {
              target: FetchState.Fetching,
            },
            [done.type]: {
              target: FetchState.Done,
            },
            [reset.type]: {
              target: FetchState.Idle,
            },
            [reTrigger.type]: {
              actions: [
                ({ self, context }: any) => self.send(trigger(context.input)),
              ],
            },
          },
        },
        [FetchState.Done]: {
          type: "final",
        },
      },
    }),
    trigger,
    success,
    failure,
    reset,
    reTrigger,
    done,
  };
};
