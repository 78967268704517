import { createMachine, assign, sendTo, spawnChild, raise } from "xstate";
import { AppApolloClient } from "../../contexts/Apollo";
import * as actions from "../actions/team-members";
import { getProfile, getProfiles } from "../../apollo-graphql/queries/profile";
import { Pagination } from "../../types/pagination";
import { fetchMachineFactory } from "./fetch-factory";
import { inviteProfile } from "../../apollo-graphql/mutations/invite-profile";
import { Profile } from "../../apollo-graphql/types/profile";
import { updateProfile } from "../../apollo-graphql/mutations/update-profile";

export enum TeamMembersState {
  List = "list",
  InviteDialog = "inviteDialog",
  EditDialog = "editDialog",
}

interface TeamMembersMachineContext {
  client: AppApolloClient;
  error: string | null;
  selectedTeamMember: Profile | null;
}

type TeamMemberActionCreators = typeof actions;
type TeamMemberActionCreatorKeys = keyof TeamMemberActionCreators;
type TeamMemberActions = ReturnType<
  TeamMemberActionCreators[TeamMemberActionCreatorKeys]
>;

type TeamMembersMachineTypes = {
  context: TeamMembersMachineContext;
  events:
    | TeamMemberActions
    | ReturnType<typeof getTeamMembersSuccess>
    | ReturnType<typeof getTeamMembersFailure>
    | ReturnType<typeof getTeamMembersDone>
    | ReturnType<typeof inviteTeamMemberSuccess>
    | ReturnType<typeof inviteTeamMemberFailure>
    | ReturnType<typeof getTeamMemberSuccess>
    | ReturnType<typeof getTeamMemberFailure>
    | ReturnType<typeof editTeamMemberSuccess>
    | ReturnType<typeof editTeamMemberFailure>;
};

export const {
  machine: getTeamMembersMachine,
  success: getTeamMembersSuccess,
  failure: getTeamMembersFailure,
  done: getTeamMembersDone,
  trigger: getTeamMembersTrigger,
  reTrigger: getTeamMembersReTrigger,
} = fetchMachineFactory({
  id: "getTeamMembers",
  invokeFn: ({
    workspaceId,
    pagination,
    query,
    client,
  }: {
    workspaceId: string;
    pagination: Pagination;
    query?: string;
    client: AppApolloClient;
  }) => {
    return getProfiles(client, {
      workspace_id: workspaceId,
      pagination,
      query,
    }).then((data) => ({
      profiles: data,
      workspaceId,
    }));
  },
  triggerOnCreation: true,
});

const {
  machine: getTeamMemberMachine,
  success: getTeamMemberSuccess,
  failure: getTeamMemberFailure,
  trigger: getTeamMemberTrigger,
} = fetchMachineFactory({
  id: "getTeamMember",
  invokeFn: ({ client, id }: { client: AppApolloClient; id: string }) => {
    return getProfile(client, { id }).then((data) => ({
      profile: data,
    }));
  },
});

export const {
  machine: inviteTeamMemberMachine,
  success: inviteTeamMemberSuccess,
  failure: inviteTeamMemberFailure,
  trigger: inviteTeamMemberTrigger,
} = fetchMachineFactory({
  id: "inviteTeamMember",
  invokeFn: ({
    client,
    payload,
  }: {
    client: AppApolloClient;
    payload: ReturnType<TeamMemberActionCreators["inviteSend"]>["payload"];
  }) => {
    return inviteProfile(client, payload.variables);
  },
});

export const {
  machine: editTeamMemberMachine,
  success: editTeamMemberSuccess,
  failure: editTeamMemberFailure,
  trigger: editTeamMemberTrigger,
} = fetchMachineFactory({
  id: "editTeamMember",
  invokeFn: ({
    client,
    payload,
  }: {
    client: AppApolloClient;
    payload: ReturnType<TeamMemberActionCreators["editSubmit"]>["payload"];
  }) => {
    return updateProfile(client, payload.variables);
  },
});

export const teamMembersMachine = createMachine({
  types: {} as TeamMembersMachineTypes,
  id: "team-members",
  initial: TeamMembersState.List,
  context: ({ input }): TeamMembersMachineContext => {
    const machineInput = input as
      | {
          client?: AppApolloClient;
        }
      | undefined;
    if (!machineInput?.client)
      throw new Error("Apollo client must be provided!");
    return {
      client: machineInput.client,
      error: null,
      selectedTeamMember: null,
    };
  },
  entry: [
    spawnChild(getTeamMembersMachine, {
      id: getTeamMembersMachine.id,
      input: ({ context }: any) => ({ client: context.client }),
      syncSnapshot: true,
    }),
    spawnChild(getTeamMemberMachine, {
      id: getTeamMemberMachine.id,
      input: ({ context }: any) => ({ client: context.client }),
      syncSnapshot: true,
    }),
    spawnChild(editTeamMemberMachine, {
      id: editTeamMemberMachine.id,
      input: ({ context }: any) => ({ client: context.client }),
      syncSnapshot: true,
    }),
    spawnChild(inviteTeamMemberMachine, {
      id: inviteTeamMemberMachine.id,
      input: ({ context }: any) => ({ client: context.client }),
      syncSnapshot: true,
    }),
  ],
  states: {
    [TeamMembersState.List]: {
      on: {
        [actions.fetchTeamMembers.type]: {
          actions: [
            sendTo(getTeamMembersMachine.id, ({ event, context }) => {
              const { currentPage, pageSize, query } = event.payload.filters;
              if (!currentPage || !pageSize) return;

              const pagination = {
                offset: (currentPage - 1) * pageSize,
                limit: pageSize,
              };
              return getTeamMembersTrigger({
                workspaceId: event.payload.workspaceId,
                client: context.client,
                pagination,
                query,
              });
            }),
          ],
        },
        [actions.editOpen.type]: {
          target: TeamMembersState.EditDialog,
        },
        [actions.inviteOpen.type]: {
          target: TeamMembersState.InviteDialog,
        },
      },
    },
    [TeamMembersState.InviteDialog]: {
      on: {
        [actions.inviteSend.type]: {
          actions: [
            sendTo(inviteTeamMemberMachine.id, ({ event, context }) => {
              event = event as ReturnType<
                TeamMemberActionCreators["inviteSend"]
              >;

              return inviteTeamMemberTrigger({
                client: context.client,
                payload: event.payload,
              });
            }),
          ],
        },
        [inviteTeamMemberSuccess.type]: {
          actions: [
            assign({ error: null }),
            raise(() => actions.inviteClose()),
          ],
        },
        [inviteTeamMemberFailure.type]: {
          actions: [
            assign({
              error: ({ event }) => `${event.payload.error.message}`,
            }),
          ],
        },
        [actions.inviteClose.type]: {
          target: TeamMembersState.List,
          actions: [
            sendTo(getTeamMembersMachine.id, () => getTeamMembersReTrigger()),
          ],
        },
      },
    },
    [TeamMembersState.EditDialog]: {
      entry: [
        sendTo(getTeamMemberMachine.id, ({ context, event }) => {
          event = event as ReturnType<TeamMemberActionCreators["editOpen"]>;
          return getTeamMemberTrigger({
            client: context.client,
            id: event.payload.id,
          });
        }),
      ],
      on: {
        [getTeamMemberSuccess.type]: {
          actions: [
            assign({
              selectedTeamMember: ({ event }) => {
                return event.payload.output.profile;
              },
            }),
          ],
        },
        [getTeamMemberFailure.type]: {
          actions: assign({
            error: ({ event }) => event.payload.error,
          }),
        },
        [actions.editSubmit.type]: {
          actions: [
            sendTo(editTeamMemberMachine.id, ({ context, event }) => {
              return editTeamMemberTrigger({
                client: context.client,
                payload: { variables: event.payload.variables },
              });
            }),
          ],
        },
        [editTeamMemberSuccess.type]: {
          actions: [
            assign({ error: null }),
            sendTo(getTeamMembersMachine.id, () => {
              return getTeamMembersReTrigger();
            }),
            raise(() => actions.editClose()),
          ],
        },
        [editTeamMemberFailure.type]: {
          actions: assign({
            error: ({ event }) => event.payload.error,
          }),
        },
        [actions.editClose.type]: {
          target: TeamMembersState.List,
          actions: [assign({ selectedTeamMember: null })],
        },
      },
    },
  },
  exit: sendTo(getTeamMembersMachine.id, () => {
    return getTeamMembersDone();
  }),
});
