import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";

const mutation = gql`
  mutation SetActivityValue(
    $sessionId: String!
    $activityId: String!
    $value: String!
  ) {
    setActivityValue(
      sessionId: $sessionId
      activityId: $activityId
      value: $value
    ) {
      context {
        activityResult {
          key
          value {
            key
            value {
              profileId
              ready
              value
              disconnectTimestamp
              workshopTimeout
              activityTimeout
              activityPartTimeout
            }
          }
        }
        reconnectTimeouts {
          profileId
        }
        currentActiveProfiles {
          profileId
          uuid
        }
        startTimestamp
        lastUpdatedTimestamp
        lastActivityTimestamp
        lastActivityPartTimestamp
        readyActiveProfiles
        minimumWorkshopParticipants
        maximumWorkshopParticipants
      }
      value
      sessionKey
      sessionId
    }
  }
`;

export function setActivityValue(
  client: AppApolloClient,
  variables: { sessionId: string; activityId: string; value: string }
) {
  return client
    .mutate<any>({ mutation, variables, fetchPolicy: "no-cache" })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.disconnect;
    });
}
