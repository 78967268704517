import { StandardSessionActivity } from "../apollo-graphql/types/enums/standard-session-activity";
import { ActivityPart } from "../types/enums/activity-part";

export function extractActivityDataFromSessionState(
  state: string | object | null
) {
  if (state === null) return { id: null, part: "none" as const };
  if (
    state === StandardSessionActivity.Waiting ||
    state === StandardSessionActivity.ViewResults
  ) {
    return { id: state, part: "none" as const };
  }
  try {
    const stateObj = typeof state === "object" ? state : JSON.parse(state);
    const entries = Object.entries(stateObj)[0];
    return {
      id: entries[0],
      part: entries[1] as ActivityPart,
    };
  } catch (e) {
    return { id: null, part: "none" as const };
  }
}
