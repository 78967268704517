import { serverUrl } from "../constants/endpoints";

export const getPreSignedUrl = (
  token: string,
  key: string
): Promise<string> => {
  const requestUrl = `${serverUrl}/presigned-url/${key}`;
  const headers = {
    Authorization: token ? `Bearer ${token}` : "",
  };

  return fetch(requestUrl, { headers }).then((res) => res.text());
};

export const uploadImageToS3 = (url: string, body: File | ReadableStream) => {
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "image/*",
    },
    body,
  };

  return fetch(url, options);
};

export const deleteImageFromS3 = (key: string, token: string) => {
  const requestUrl = `${serverUrl}/asset/${key}`;

  const options = {
    method: "DELETE",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  };

  return fetch(requestUrl, options);
};
