import { createAction, props } from "../../utils";

export const getInvite = createAction(
  "[SESSION] Get Invite",
  props<{
    variables: { email: string; slotId: string };
  }>()
);

export const getSession = createAction(
  "[SESSION] Get Session",
  props<{
    variables: {
      sessionKey: string;
      group?: number;
      includeSlotAndWorkshop: boolean;
    };
  }>()
);

export const jitsiSetupReady = createAction("[Session] Jitsi Setup Ready");
export const jitsiSetupPermissionAllowed = createAction(
  "[Session] Jitsi Setup Permissions Allowed"
);
export const sessionStarting = createAction("[Session] Session Starting");

export const sessionParticipantChange = createAction(
  "[SESSION] Session participant change",
  props<{ participantIds: string[] } | { refetchParticipantIds: string[] }>()
);

export const reset = createAction("[Session] Reset");

export const socketData = createAction(
  "[Session socket] Data",
  props<{ data: any }>()
);

export const socketSubscriptionExecuted = createAction(
  "[Session socket] Subscription executed"
);
