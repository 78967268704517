import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { OngoingSessionResult } from "../types/results/ongoing-session-result";

const query = gql`
  subscription OngoingSessions($slotIds: [String]!) {
    ongoingSessions(slot_ids: $slotIds) {
      sessionId
      slotId
      key
      value {
        context {
          activityResult {
            key
            value {
              key
              value {
                activityPartTimeout
                activityTimeout
                disconnectTimestamp
                profileId
                ready
                value
                workshopTimeout
              }
            }
          }
          currentActiveProfiles {
            profileId
            uuid
          }
          lastActivityPartTimestamp
          lastActivityTimestamp
          lastUpdatedTimestamp
          maximumWorkshopParticipants
          minimumWorkshopParticipants
          readyActiveProfiles
          reconnectTimeouts {
            profileId
          }
          startTimestamp
        }
        value
      }
    }
  }
`;

export function ongoingSessionSubscription(
  client: AppApolloClient,
  variables: { slotIds: string[] }
) {
  return client.subscribe<OngoingSessionResult>({
    query,
    variables,
    fetchPolicy: "no-cache",
  });
}
