import { createAction, props } from "../../utils";

export const startWorkshopClock = createAction(
  "[WORKSHOP CLOCK] Start",
  props<{ serverUnixStartTimestamp: number }>()
);
export const configureWorkshopClock = createAction(
  "[WORKSHOP CLOCK] Configure",
  props<{ durationInMinutes: number }>()
);
export const stopWorkshopClock = createAction("[WORKSHOP CLOCK] Stop");
export const resetWorkshopClock = createAction("[WORKSHOP CLOCK] Reset");
export const disposeWorkshopClock = createAction("[WORKSHOP CLOCK] Dispose");
