import {
  Fragment,
  PropsWithChildren,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";

import cn from "classnames";
import styles from "./Theory.module.css";

import ActionFooter from "../ActionFooter";
import { ActivityCommon } from "../../../types/activity-common";
import { activityTypeFooterTextFactoryMap } from "../../../utils/footer";
import { ActionFooterType } from "../../../types/action-footer";
import { FooterType } from "../../../types/enums/activity-footer";
import { SessionContext } from "../../../contexts/Session";
import Button3D from "../../Shared/Button3D/Button3D";

interface TheoryProps extends ActivityCommon {
  isViewResults: boolean;
}
export default memo(function (props: PropsWithChildren<TheoryProps>) {
  const {
    activity,
    transition,
    isReady,
    currentActiveParticipantCount,
    notReadyProfilesCount,
    setActivityReadyHandler,
    isViewResults,
  } = props;
  const { theory } = activity;

  const sessionContext = useContext(SessionContext);
  const clockInstance =
    sessionContext.workshopClock.state.context.clockInstance;
  const duration = theory?.duration || 0;

  const [showPopup, setShowPopup] = useState<boolean>(false);

  const popupContent = useMemo(() => {
    return showPopup ? (
      <Dialog.Root open={true}>
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content className="DialogContent">
            <h1 className={cn(styles.dialogTitle, "DialogTitle")}>
              Are you sure?
            </h1>
            <div className={cn(styles.dialogContent, "text")}>
              A solid understanding of the workshop theory is necessary for
              successfully completing this workshop. Are you willing to skip it?
            </div>
            <div className={styles.dialogFooter}>
              <Button3D
                className="cancel"
                size="large"
                variant="success"
                onClick={() => setShowPopup(false)}
              >
                Cancel
              </Button3D>
              <Button3D
                className="submit"
                size="large"
                variant="success"
                onClick={() => {
                  setActivityReadyHandler({ activityId: activity.id });
                  setShowPopup(false);
                }}
              >
                Skip theory
              </Button3D>
            </div>

            <Dialog.Close asChild>
              <button
                onClick={() => setShowPopup(false)}
                className={cn(styles.dialogCloseButton, "IconButton")}
                aria-label="Close"
              >
                <Cross2Icon />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    ) : null;
  }, [activity.id, setActivityReadyHandler, showPopup]);

  const actionFooterData: ActionFooterType = useMemo(() => {
    if (transition > 0) {
      return {
        text: (
          <>
            Everyone is ready. Continuing forward in{" "}
            <span className="accent">{transition}...</span>
          </>
        ),
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Ready,
        isLoading: true,
      };
    }
    if (!isReady) {
      return {
        text: activityTypeFooterTextFactoryMap[activity.type](
          currentActiveParticipantCount - notReadyProfilesCount
        ),
        buttonText: "Continue",
        disabledButton: false,
        type: FooterType.Notice,
        isLoading: false,
      };
    }
    return {
      text: (
        <>
          Waiting for{" "}
          <span className="accent">
            {notReadyProfilesCount} more player
            {notReadyProfilesCount > 1 && "s"}...
          </span>
        </>
      ),
      buttonText: "Continue",
      disabledButton: true,
      type: FooterType.Waiting,
      isLoading: false,
    };
  }, [
    transition,
    isReady,
    notReadyProfilesCount,
    activity.type,
    currentActiveParticipantCount,
  ]);

  const onButtonClickHandler = useCallback(() => {
    const [, remainingTimeString] = /(\d+):/.exec(
      clockInstance?.activityParsedTimeRemaining || "00:00"
    ) || ["", "00:00"];

    if (duration - parseInt(remainingTimeString) < 3) {
      setShowPopup(true);
      return;
    }
    setActivityReadyHandler({ activityId: activity.id });
  }, [activity.id, clockInstance, duration, setActivityReadyHandler]);

  return (
    <Fragment key={activity.id}>
      {popupContent}
      <div className="activity-container">
        <div
          className={cn(
            styles.container,
            "main-container",
            isViewResults && styles.viewResultsContainer
          )}
        >
          <div className={styles.iframeContainer}>
            <iframe title={activity.id + "-video"} src={theory!.video}></iframe>
          </div>
          <p className={cn(styles.infoText, "text")}>
            Your goal is to understand the topic rather than being able to
            reproduce the theory throughout the workshop. Learning will happen
            through discussion and persist until the end, aided by author’s
            explanations after each level.
          </p>
        </div>
        {!isViewResults && (
          <ActionFooter
            buttonText={actionFooterData.buttonText}
            type={actionFooterData.type}
            disabledButton={actionFooterData.disabledButton}
            buttonClickHandler={onButtonClickHandler}
            isLoading={actionFooterData.isLoading}
          >
            {actionFooterData.text}
          </ActionFooter>
        )}
      </div>
    </Fragment>
  );
});
