import {
  ChangeEvent,
  FormEvent,
  PropsWithChildren,
  memo,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

import { Cross2Icon } from "@radix-ui/react-icons";
import * as Dialog from "@radix-ui/react-dialog";
import * as Form from "@radix-ui/react-form";

import UserImage from "../../Shared/UserImage/UserImage";
import { Profile, ProfileUpdate } from "../../../apollo-graphql/types/profile";
import { GlobalContext } from "../../../contexts/Global";
import { AuthState, ProfileSettings } from "../../../+xstate/machines/auth";

import cn from "classnames";
import styles from "./EditProfileModal.module.css";
import { FetchState } from "../../../+xstate/machines/fetch-factory";

// For testing purpose
const showDeleteProfileButton = false;

const EditProfileModal = (
  props: PropsWithChildren<{
    profile: Profile;
    errorMessage: string | null;
    updateProfile: (payload: { variables: ProfileUpdate }) => void;
    closeDialogHandler: () => void;
    showChangePasswordDialog: () => void;
  }>
) => {
  const {
    profile,
    errorMessage,
    updateProfile,
    closeDialogHandler,
    showChangePasswordDialog,
  } = props;

  const {
    auth: {
      matches,
      uploadProfileImage,
      deleteProfileImage,
      updateProfileState,
    },
  } = useContext(GlobalContext);

  const fileUploadRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState<string | null>(null);

  const isFileUploading = matches({
    [AuthState.Authenticated]: {
      [ProfileSettings.UpdateProfile]:
        ProfileSettings.UpdateProfile_UploadingProfileImage,
    },
  });
  const isFileDeleting = matches({
    [AuthState.Authenticated]: {
      [ProfileSettings.UpdateProfile]:
        ProfileSettings.UpdateProfile_DeleteProfileImage,
    },
  });

  const onFileInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      const file: File | null = files && files[0];
      if (!file) return;
      uploadProfileImage({ file });
    },
    [uploadProfileImage]
  );

  const onSubmitHandler = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      setError("");
      event.preventDefault();
      const payload = Object.fromEntries(new FormData(event.currentTarget)) as {
        name: string;
      };

      // Validations
      if (payload.name.length === 0) {
        setError("Name cannot be empty.");
        return;
      }

      updateProfile({
        variables: {
          id: profile.id,
          name: payload.name,
        },
      });
    },
    [profile, updateProfile]
  );

  const deleteImageHandler = useCallback(() => {
    deleteProfileImage();
  }, [deleteProfileImage]);

  const dialogContent = useMemo(() => {
    return (
      <div className={styles.content}>
        <div className="user-container">
          <UserImage
            isPublic={false}
            profileId={profile.id}
            profileWorkspaceId={profile.workspace.workspace_id}
            showLoader={isFileUploading || isFileDeleting}
            containerClass={styles.imageContainer}
            fallbackFontAwesomeIconClass="fa fa-user"
            alt="user-profile"
          />
          <label className="upload-container">
            {(isFileUploading || isFileDeleting) && (
              <i className="fa fa-circle-o-notch fa-spin" />
            )}
            Upload picture
            <input
              type="file"
              disabled={isFileUploading}
              ref={fileUploadRef}
              accept="image/*"
              onChange={onFileInputChange}
            />
          </label>
          {showDeleteProfileButton && (
            <button onClick={deleteImageHandler}>Delete picture</button>
          )}
        </div>

        <div className="user-info-container">
          <Form.Root className="form-container" onSubmit={onSubmitHandler}>
            <Form.Field name="name" className="form-row">
              <Form.Label className="label">Name</Form.Label>
              <Form.Control
                className="FormControl"
                type="text"
                defaultValue={profile?.name}
              />
            </Form.Field>
            <Form.Field name="email" className="form-row">
              <Form.Label className="label disabled">Email</Form.Label>
              <Form.Control
                disabled
                className="FormControl"
                type="email"
                value={profile?.email}
              />
            </Form.Field>
            <Form.Field name="jobTitle" className="form-row">
              <Form.Label className="label disabled">Job Title</Form.Label>
              <Form.Control
                disabled
                className="FormControl"
                type="text"
                value={profile?.headline}
              />
            </Form.Field>

            <Form.Field name="actions" className="form-row actions">
              <button
                className="btn change-password"
                type="button"
                onClick={showChangePasswordDialog}
              >
                Change password
              </button>
              <Form.Submit
                className={cn(
                  "btn",
                  "save",
                  updateProfileState === FetchState.Fetching && "loading"
                )}
              >
                {updateProfileState === FetchState.Fetching && (
                  <i className="fa fa-circle-o-notch fa-spin" />
                )}
                Save
              </Form.Submit>
            </Form.Field>
            {(error || errorMessage) && <div>{error || errorMessage}</div>}
          </Form.Root>
        </div>
      </div>
    );
  }, [
    profile.id,
    profile.workspace.workspace_id,
    profile?.name,
    profile?.email,
    profile?.headline,
    isFileUploading,
    isFileDeleting,
    onFileInputChange,
    deleteImageHandler,
    onSubmitHandler,
    showChangePasswordDialog,
    updateProfileState,
    error,
    errorMessage,
  ]);

  return (
    <Dialog.Root open={true} onOpenChange={closeDialogHandler}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content
          className={cn(styles.EditProfileModal, "DialogContent")}
        >
          <Dialog.Title className="DialogTitle">Edit Profile</Dialog.Title>
          {dialogContent}
          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close">
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default memo(EditProfileModal);
