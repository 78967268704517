import {
  createMachine,
  fromPromise,
  assign,
  spawnChild,
  sendTo,
  raise,
} from "xstate";
import * as actions from "../actions/auth";
import { login } from "../../apollo-graphql/mutations/login";
import { updateProfile } from "../../apollo-graphql/mutations/update-profile";
import { Profile } from "../../apollo-graphql/types/profile";
import { getMyProfile } from "../../apollo-graphql/queries/profile";
import { AppApolloClient } from "../../contexts/Apollo";
import { fetchMachineFactory } from "./fetch-factory";
import { changePassword } from "../../apollo-graphql/mutations/change-password";
import { resetPassword } from "../../apollo-graphql/queries/reset-password";
import { deleteImageFromS3, getPreSignedUrl, uploadImageToS3 } from "../../fetch/image";
import { generateWorkspaceProfileImageKey } from "../../utils";
import { getUnixTime } from "date-fns";

export enum AuthState {
  Authenticated = "authenticated",
  Validating = "validating",
  Unauthenticated = "unauthenticated",
  Authenticating = "authenticating",
  UpdateProfile = "updateProfile",
}

export interface AuthMachineContext {
  client: AppApolloClient;
  profile: Profile | null;
  token: string | null;
  error: null | string;
  presignedUrl: null | string;
  fileForUpload: null | File;
  imageLastUpdatedTimeStamp: null | number;
}

export enum ProfileSettings {
  Default = "DEFAULT",
  UpdateProfile = "UPDATE_PROFILE",
  UpdateProfile_Default = "UPDATE_PROFILE__DEFAULT",
  UpdateProfile_GettingPresignedUrl = "UPDATE_PROFILE__GETTING_PRESIGNED_URL",
  UpdateProfile_UploadingProfileImage = "UPDATE_PROFILE__UPLOADING_PROFILE_IMAGE",
  UpdateProfile_DeleteProfileImage = "UPDATE_PROFILE__DELETE_PROFILE_IMAGE",
  ChangePassword = "CHANGE_PROFILE",
}

type AllAuthActionCreators = typeof actions;
type AllAuthActionCreatorKeys = keyof AllAuthActionCreators;
type AllAuthActions =
  | ReturnType<AllAuthActionCreators[AllAuthActionCreatorKeys]>
  | ReturnType<typeof updateProfileSuccess>
  | ReturnType<typeof updateProfileFailure>
  | ReturnType<typeof resetPasswordSuccess>
  | ReturnType<typeof resetPasswordFailure>
  | ReturnType<typeof changePasswordSuccess>
  | ReturnType<typeof changePasswordFailure>
  | ReturnType<typeof getPreSignedUrlSuccess>
  | ReturnType<typeof getPreSignedUrlFailure>
  | ReturnType<typeof uploadProfileImageSuccess>
  | ReturnType<typeof uploadProfileImageFailure>
  | ReturnType<typeof deleteProfileImageSuccess>
  | ReturnType<typeof deleteProfileImageFailure>;

type AuthMachineTypes = {
  context: AuthMachineContext;
  events: AllAuthActions;
};

export const {
  machine: updateProfileMachine,
  success: updateProfileSuccess,
  failure: updateProfileFailure,
  trigger: updateProfileTrigger,
} = fetchMachineFactory({
  id: "updateProfile",
  invokeFn: ({
    client,
    payload,
  }: {
    client: AppApolloClient;
    payload: ReturnType<AllAuthActionCreators["updateProfile"]>["payload"];
  }) => {
    return updateProfile(client, payload.variables);
  },
});

export const {
  machine: resetPasswordMachine,
  success: resetPasswordSuccess,
  failure: resetPasswordFailure,
  trigger: resetPasswordTrigger,
} = fetchMachineFactory({
  id: "resetPassword",
  invokeFn: ({
    client,
    payload,
  }: {
    client: AppApolloClient;
    payload: ReturnType<AllAuthActionCreators["resetPassword"]>["payload"];
  }) => {
    return resetPassword(client, payload.variables);
  },
});

export const {
  machine: changePasswordMachine,
  success: changePasswordSuccess,
  failure: changePasswordFailure,
  trigger: changePasswordTrigger,
} = fetchMachineFactory({
  id: "changePassword",
  invokeFn: ({
    client,
    payload,
  }: {
    client: AppApolloClient;
    payload: ReturnType<AllAuthActionCreators["changePassword"]>["payload"];
  }) => {
    return changePassword(client, payload.variables);
  },
});

const {
  machine: getPreSignedUrlMachine,
  success: getPreSignedUrlSuccess,
  failure: getPreSignedUrlFailure,
  trigger: getPreSignedUrlTrigger,
} = fetchMachineFactory({
  id: "getPreSignedUrl",
  invokeFn: ({ token, key }: { token: string; key: string }) => {
    return getPreSignedUrl(token, key);
  },
  triggerOnCreation: false,
});

const {
  machine: uploadProfileImageMachine,
  success: uploadProfileImageSuccess,
  failure: uploadProfileImageFailure,
  trigger: uploadProfileImageTrigger,
} = fetchMachineFactory({
  id: "uploadProfileImage",
  invokeFn: ({ url, body }: { url: string; body: File | ReadableStream }) => {
    return uploadImageToS3(url, body);
  },
  triggerOnCreation: false,
});

const {
  machine: deleteProfileImageMachine,
  success: deleteProfileImageSuccess,
  failure: deleteProfileImageFailure,
  trigger: deleteProfileImageTrigger,
} = fetchMachineFactory({
  id: "deleteProfileImage",
  invokeFn: ({ key, token }: { key: string; token : string }) => {
    return deleteImageFromS3(key, token);
  },
  triggerOnCreation: false,
});

export const authMachine = createMachine({
  types: {} as AuthMachineTypes,
  id: "auth",
  initial: AuthState.Validating,
  context: ({ input }): AuthMachineContext => {
    const machineInput = input as
      | {
          client?: AppApolloClient;
          token?: string | null;
          profile?: Profile | null;
        }
      | undefined;
    if (!machineInput?.client)
      throw new Error("Apollo client must be provided!");
    return {
      client: machineInput.client,
      profile: machineInput?.profile || null,
      token: machineInput?.token || null,
      error: null,
      presignedUrl: null,
      fileForUpload: null,
      imageLastUpdatedTimeStamp: null,
    };
  },
  entry: [
    spawnChild(updateProfileMachine, {
      id: updateProfileMachine.id,
      input: ({ context }: any) => ({ client: context.client } as any),
      syncSnapshot: true,
    }),
    spawnChild(resetPasswordMachine, {
      id: resetPasswordMachine.id,
      input: ({ context }: any) => ({ client: context.client } as any),
      syncSnapshot: true,
    }),
    spawnChild(changePasswordMachine, {
      id: changePasswordMachine.id,
      input: ({ context }: any) => ({ client: context.client } as any),
      syncSnapshot: true,
    }),
    spawnChild(getPreSignedUrlMachine, {
      id: getPreSignedUrlMachine.id,
      syncSnapshot: true,
    }),
    spawnChild(uploadProfileImageMachine, {
      id: uploadProfileImageMachine.id,
      syncSnapshot: true,
    }),
    spawnChild(deleteProfileImageMachine, {
      id: deleteProfileImageMachine.id,
      syncSnapshot: true,
    }),
  ],
  states: {
    [AuthState.Validating]: {
      invoke: {
        src: fromPromise(({ input }) => {
          const data = input as {
            client: AppApolloClient;
            token: string;
          };
          if (!data.token) return Promise.resolve(null);
          return getMyProfile(data.client);
        }),
        onDone: [
          {
            target: AuthState.Authenticated,
            guard: ({ event }) => {
              return event.output !== null;
            },
            actions: assign({
              profile: ({ event }) => event.output,
              error: null,
            }),
          },
          {
            target: AuthState.Unauthenticated,
            actions: assign({
              profile: null,
              token: null,
              error: null,
            }),
          },
        ],
        onError: {
          target: AuthState.Unauthenticated,
          actions: assign({
            profile: null,
            error: ({ event }) => `${(event.error as any).message}`,
          }),
        },
        input: ({ context }) => ({
          client: context.client,
          token: context.token,
        }),
      },
    },
    [AuthState.Unauthenticated]: {
      on: {
        [actions.login.type]: {
          target: AuthState.Authenticating,
        },
        [actions.resetPassword.type]: {
          actions: [
            sendTo(resetPasswordMachine.id, ({ event, context }: any) => {
              event = event as ReturnType<
                AllAuthActionCreators["resetPassword"]
              >;
              return resetPasswordTrigger({
                client: context.client,
                payload: event.payload,
              });
            }),
          ],
        },
        [resetPasswordSuccess.type]: {
          actions: [
            assign({
              error: null,
            }),
          ],
        },
        [resetPasswordFailure.type]: {
          actions: [
            assign({
              error: ({ event }) => `${event.payload.error.message}`,
            }),
          ],
        },
        [actions.changePassword.type]: {
          actions: [
            sendTo(changePasswordMachine.id, ({ event, context }: any) => {
              event = event as ReturnType<
                AllAuthActionCreators["changePassword"]
              >;
              return changePasswordTrigger({
                client: context.client,
                payload: event.payload,
              });
            }),
          ],
        },
        [changePasswordSuccess.type]: {
          actions: [
            assign({
              error: null,
            }),
          ],
        },
        [changePasswordFailure.type]: {
          actions: [
            assign({
              error: ({ event }) => `${event.payload.error.message}`,
            }),
          ],
        },
      },
    },
    [AuthState.Authenticating]: {
      invoke: {
        src: fromPromise(({ input }) => {
          const data = input as {
            payload: ReturnType<AllAuthActionCreators["login"]>["payload"];
            client: AppApolloClient;
          };
          return login(data.client, data.payload.variables);
        }),
        onDone: [
          {
            target: AuthState.Authenticated,
            guard: ({ event }) => {
              return (
                event.output.profile !== null && event.output.token !== null
              );
            },
            actions: assign({
              profile: ({ event }) => event.output.profile,
              token: ({ event }) => event.output.token,
              error: null,
            }),
          },
          {
            target: AuthState.Unauthenticated,
          },
        ],
        onError: {
          target: AuthState.Unauthenticated,
          actions: assign({
            error: ({ event }) => `${(event.error as any).message}`,
          }),
        },
        input: ({ event, context }) => ({
          payload: event.payload,
          client: context.client,
        }),
      },
    },
    [AuthState.Authenticated]: {
      initial: ProfileSettings.Default,
      states: {
        [ProfileSettings.Default]: {
          on: {
            [actions.updateProfileDialog.type]: [
              {
                target: ProfileSettings.UpdateProfile,
                guard: ({ event }) =>
                  event.payload.open && event.payload.type === "profile",
              },
              {
                target: ProfileSettings.ChangePassword,
                guard: ({ event }) =>
                  event.payload.open && event.payload.type === "password",
              },
            ],
          },
        },
        [ProfileSettings.UpdateProfile]: {
          initial: ProfileSettings.UpdateProfile_Default,
          states: {
            [ProfileSettings.UpdateProfile_Default]: {
              on: {
                [actions.uploadProfileImage.type]: [
                  {
                    target: ProfileSettings.UpdateProfile_GettingPresignedUrl,
                    guard: ({ context }) => !context.presignedUrl,
                    actions: [
                      assign({
                        fileForUpload: ({ event }) => event.payload.file,
                      }),
                    ],
                  },
                ],
                [actions.deleteProfileImage.type]: [
                  {
                    target: ProfileSettings.UpdateProfile_DeleteProfileImage,
                  },
                ],
              },
            },
            [ProfileSettings.UpdateProfile_GettingPresignedUrl]: {
              entry: [
                sendTo(getPreSignedUrlMachine.id, ({ context }) => {
                  return getPreSignedUrlTrigger({
                    token: context.token!,
                    key: generateWorkspaceProfileImageKey(
                      context.profile!.id,
                      context.profile!.workspace.workspace_id
                    ),
                  });
                }),
              ],
              on: {
                [getPreSignedUrlSuccess.type]: {
                  target: ProfileSettings.UpdateProfile_UploadingProfileImage,
                  actions: assign({
                    presignedUrl: ({ event }) => event.payload.output,
                  }),
                },
                [getPreSignedUrlFailure.type]: {
                  target: ProfileSettings.UpdateProfile_Default,
                  // TODO: Handler error message
                  actions: [
                    assign({
                      presignedUrl: null,
                      fileForUpload: null,
                    }),
                  ],
                },
              },
            },
            [ProfileSettings.UpdateProfile_UploadingProfileImage]: {
              entry: [
                sendTo(uploadProfileImageMachine.id, ({ context, self }) => {
                  const file = context.fileForUpload!;

                  return uploadProfileImageTrigger({
                    url: context.presignedUrl!,
                    body: file,
                  });
                }),
              ],
              on: {
                [uploadProfileImageSuccess.type]: {
                  target: ProfileSettings.UpdateProfile_Default,
                  actions: [
                    assign({
                      presignedUrl: null,
                      fileForUpload: null,
                      imageLastUpdatedTimeStamp: () => getUnixTime(new Date()),
                    }),
                  ],
                },
                [uploadProfileImageFailure.type]: {
                  target: ProfileSettings.UpdateProfile_Default,
                  // TODO: Handler error message
                  actions: [
                    assign({
                      presignedUrl: null,
                      fileForUpload: null,
                    }),
                  ],
                },
              },
            },
            [ProfileSettings.UpdateProfile_DeleteProfileImage]: {
              entry: [
                sendTo(deleteProfileImageMachine.id, ({ context, self }) => {

                  return deleteProfileImageTrigger({
                    token: context.token!,
                    key: generateWorkspaceProfileImageKey(
                      context.profile!.id,
                      context.profile!.workspace.workspace_id
                    ),
                  });
                }),
              ],
              on: {
                [deleteProfileImageSuccess.type]: {
                  target: ProfileSettings.UpdateProfile_Default,
                  actions: [
                    assign({
                      imageLastUpdatedTimeStamp: () => getUnixTime(new Date()),
                    }),
                  ],
                },
                [deleteProfileImageFailure.type]: {
                  target: ProfileSettings.UpdateProfile_Default,
                  // TODO: Handler error message
                },
              },
            },
          },
          exit: [
            assign({
              presignedUrl: null,
            }),
          ],
          on: {
            [actions.updateProfileDialog.type]: [
              {
                target: ProfileSettings.ChangePassword,
                guard: ({ event }) =>
                  event.payload.open && event.payload.type === "password",
              },
              {
                target: ProfileSettings.Default,
                guard: ({ event }) => !event.payload.open,
              },
            ],
          },
        },
        [ProfileSettings.ChangePassword]: {
          on: {
            [actions.updateProfileDialog.type]: [
              {
                target: ProfileSettings.UpdateProfile,
                guard: ({ event }) =>
                  event.payload.open && event.payload.type === "profile",
              },
              {
                target: ProfileSettings.Default,
                guard: ({ event }) => !event.payload.open,
              },
            ],
          },
        },
      },
      on: {
        [actions.logout.type]: {
          target: AuthState.Unauthenticated,
          actions: assign({
            profile: null,
            token: null,
            error: null,
          }),
        },
        [actions.updateProfile.type]: {
          actions: [
            sendTo(updateProfileMachine.id, ({ event, context }: any) => {
              event = event as ReturnType<
                AllAuthActionCreators["updateProfile"]
              >;
              return updateProfileTrigger({
                client: context.client,
                payload: event.payload,
              });
            }),
          ],
        },
        [updateProfileSuccess.type]: {
          actions: [
            assign({
              profile: ({ event, context }) => {
                return {
                  ...context.profile,
                  ...event.payload.output,
                };
              },
            }),
            raise(() => actions.updateProfileDialog({ open: false })),
          ],
        },
        [updateProfileFailure.type]: {
          actions: [
            assign({
              error: ({ event }) => `${event.payload.error.message}`,
            }),
          ],
        },
      },
    },
  },
});
