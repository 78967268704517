import {
  PropsWithChildren,
  useState,
  memo,
  forwardRef,
  useEffect,
} from "react";
import styles from "./Video.module.css";
import Loader from "../Loader/Loader";

const Video = memo(
  forwardRef(
    (
      props: PropsWithChildren<{
        participantId: string;
        width: string | number;
        height: string | number;
        loaderTransitionInMilliseconds: number;
        noBorderRadius?: boolean;
        isConnecting?: boolean;
        isMuted?: boolean;
        showVideo?: boolean;
        style?: React.CSSProperties | undefined;
        audioRef?:
          | React.MutableRefObject<HTMLAudioElement | null>
          | ((ref: HTMLAudioElement | null) => void);
        onDataLoadedHandler?: () => void;
      }>,
      ref: React.ForwardedRef<HTMLVideoElement | null>
    ) => {
      const {
        width,
        style,
        height,
        loaderTransitionInMilliseconds,
        audioRef,
        participantId,
        noBorderRadius,
        isConnecting,
        isMuted,
        onDataLoadedHandler,
        showVideo,
      } = props;

      const [isVideoPlaying, setIsVideoPlaying] = useState(false);
      const [dimensions, setDimensions] = useState<{
        width: number | string;
        height: number | string;
      } | null>(null);

      useEffect(() => {
        if (isMuted) {
          setIsVideoPlaying(false);
        }
      }, [isMuted]);

      return (
        <div
          className={styles.videoContainer}
          key={participantId + "-video-container"}
          style={{
            width,
            height,
            borderRadius: noBorderRadius ? undefined : "var(--br-16, 16px)",
            ...(style || {}),
          }}
        >
          <Loader
            className={styles.loaderContainer}
            style={{
              opacity: !isVideoPlaying && showVideo ? 1 : 0,
              width,
              height,
            }}
          />
          {!isConnecting && (
            <>
              <video
                key={participantId + "-video-element"}
                className={styles.videoElement}
                style={{ maxHeight: height }}
                muted={isMuted}
                playsInline={true}
                disablePictureInPicture={true}
                autoPlay={true}
                width={dimensions?.width}
                height={dimensions?.height}
                ref={(_ref) => {
                  const width = _ref?.videoWidth;
                  const height = _ref?.videoHeight;
                  if (
                    width !== dimensions?.width &&
                    height !== dimensions?.height
                  ) {
                    setDimensions(width && height ? { width, height } : null);
                  }
                  if (typeof ref === "function") return void ref(_ref);
                  if (ref) return void (ref.current = _ref);
                }}
                onLoadedData={onDataLoadedHandler}
                onPlay={() => {
                  if (isMuted) return;
                  setTimeout(() => {
                    setIsVideoPlaying(true);
                  }, loaderTransitionInMilliseconds);
                }}
              ></video>
              {audioRef && (
                <audio
                  autoPlay={true}
                  muted={isMuted}
                  key={participantId + "-audio-element"}
                  ref={(_ref) => {
                    if (typeof audioRef === "function")
                      return void audioRef(_ref);
                    if (audioRef) return void (audioRef.current = _ref);
                  }}
                />
              )}
            </>
          )}
        </div>
      );
    }
  )
);

export default Video;
