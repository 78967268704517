import { OngoingSessionResult } from "../../apollo-graphql/types/results/ongoing-session-result";
import { createAction, props } from "../utils";

export const ongoingSessionsSubscribe = createAction(
  "[ONGOING-SESSIONS] Subscribe",
  props<{
    slot_ids: string[];
  }>()
);

export const ongoingSessionsSubscriptionData = createAction(
  "[ONGOING-SESSIONS] subscription data",
  props<{ data: OngoingSessionResult }>()
);
