import { format } from "date-fns";

export function formatSlotScheduleDate(
  timestamp: Date,
  formaDate = "EEEE, dd.MM.yy",
  formatTime = "HH:mm"
) {
  const date = new Date(timestamp.valueOf() * 1000); // Convert seconds to milliseconds
  const formattedDate = format(date, formaDate);
  const formattedTime = format(date, formatTime);
  return `${formattedDate} at ${formattedTime}`;
}
