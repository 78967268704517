import { Link } from "react-router-dom";

export default function Disconnected() {
  return (
    <div>
      <div>You have been disconnected...</div>
      <Link to="/">Go to dashboard</Link>
    </div>
  );
}
