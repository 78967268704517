import { PropsWithChildren, ReactNode, memo, useState } from "react";
import cn from "classnames";

import styles from "./InfoBox.module.css";

const InfoBox = (
  props: PropsWithChildren<{
    title?: string;
    description?: string;
    node?: ReactNode;
    isDismissible?: boolean;
    transparent?: boolean;
  }>
) => {
  const { title, description, node, isDismissible, transparent } = props;

  const [isDismissed, setIsDismissed] = useState(false);

  return !isDismissed ? (
    <div className={cn(styles.container, transparent && styles.transparent)}>
      <div className={cn(styles.iconContainer)}>
        <i className="fa-solid fa-circle-info" />
      </div>
      <div
        className={styles.box}
        style={{ width: isDismissible ? "calc(100% - 64px)" : "auto" }}
      >
        {title && <p className="text small bold extra">{title}</p>}
        {description && <p className="text tiny secondary">{description}</p>}
        {!!node && node}
      </div>
      {isDismissible && (
        <i className="fa fa-xmark icon-dismiss" onClick={() => setIsDismissed(true)} />
      )}
    </div>
  ) : (
    <></>
  );
};

export default memo(InfoBox);
