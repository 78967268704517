import {
  PropsWithChildren,
  MouseEventHandler,
  useState,
  useCallback,
  useEffect,
  useMemo,
  forwardRef,
  memo,
} from "react";
import { SourceData } from "../../+xstate/machines/jitsi";
import Video from "../Shared/Video/Video";
import InfoBox from "../InfoBox/InfoBox";
import { AudioDisabledTooltip } from "./AudioDisabledTooltip";
import DeviceSelect from "../Shared/DeviceSelect/DeviceSelect";
import { OutputDevice } from "../../types/output-device";

import cn from "classnames";
import styles from "./VideoPreview.module.css";

export const VideoPreview = memo(
  forwardRef(
    (
      props: PropsWithChildren<{
        profileId: string;
        width: number | string;
        height: number | string;
        hideConfigurations?: boolean;
        selectedAudioDevice: SourceData | null;
        selectedVideoDevice: SourceData | null;
        availableAudioSources: SourceData[] | null;
        availableVideoSources: SourceData[] | null;
        audioDeviceChangeHandler: (newSourceId: string) => void;
        videoDeviceChangeHandler: (newSourceId: string) => void;
        toggleAudioHandler: MouseEventHandler<HTMLButtonElement>;
        toggleVideoHandler: MouseEventHandler<HTMLButtonElement>;
        className?: string;
      }>,
      ref: React.ForwardedRef<HTMLVideoElement | null>
    ) => {
      const {
        width,
        height,
        profileId,
        hideConfigurations,
        selectedAudioDevice,
        selectedVideoDevice,
        availableAudioSources,
        availableVideoSources,
        audioDeviceChangeHandler,
        videoDeviceChangeHandler,
        toggleAudioHandler,
        toggleVideoHandler,
        className,
      } = props;

      const [actualVideoToggleValue, setActualVideoToggleValue] = useState(
        selectedVideoDevice?.isMuted
      );
      const [actualAudioToggleValue, setActualAudioToggleValue] = useState(
        selectedAudioDevice?.isMuted
      );

      const [showAudioDisabledTooltip, setShowAudioDisabledTooltip] =
        useState(false);

      useEffect(() => {
        setActualVideoToggleValue(selectedVideoDevice?.isMuted);
      }, [selectedVideoDevice?.isMuted]);
      useEffect(() => {
        setActualAudioToggleValue(selectedAudioDevice?.isMuted);
      }, [selectedAudioDevice?.isMuted]);

      const videoToggleHandlerWithDisable = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          setActualVideoToggleValue((prev) => !prev);
          toggleVideoHandler(event);
        },
        [toggleVideoHandler]
      );

      const audioToggleHandlerWithDisable = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          setActualAudioToggleValue((prev) => !prev);
          toggleAudioHandler(event);
        },
        [toggleAudioHandler]
      );

      const showAudioDisabledTooltipHandler = useCallback(() => {
        if (!selectedAudioDevice) {
          setShowAudioDisabledTooltip(true);
        }
      }, [selectedAudioDevice]);

      const hideAudioDisabledTooltipHandler = useCallback(() => {
        setShowAudioDisabledTooltip(false);
      }, []);

      const parsedAvailableAudioSources = useMemo(
        () =>
          (availableAudioSources || [])
            .map((source) => ({
              label: source.label!,
              value: source.sourceId!,
              key: source.sourceId!,
            }))
            .filter((source) => !!source.value),
        [availableAudioSources]
      );

      const parsedAvailableVideoSources = useMemo(
        () =>
          (availableVideoSources || [])
            .map((source) => ({
              label: source.label!,
              value: source.sourceId!,
              key: source.sourceId!,
            }))
            .filter((source) => !!source.value),
        [availableVideoSources]
      );

      const hasVideo = useMemo(
        () => selectedVideoDevice && selectedVideoDevice.sourceId,
        [selectedVideoDevice]
      );
      const showVideo = useMemo(
        () => hasVideo && !selectedVideoDevice?.isMuted,
        [selectedVideoDevice, hasVideo]
      );

      return (
        <div className={cn(styles.container, className)}>
          <Video
            ref={ref}
            width={width}
            height={height}
            participantId={profileId}
            isMuted={selectedVideoDevice?.isMuted === true}
            showVideo={!!showVideo}
            loaderTransitionInMilliseconds={100}
          />
          {!showVideo && (
            <div
              className={styles.mutedVideoContainer}
              style={{ width: width as string, height: height as string }}
            >
              <i className="icon fa fa-user" />
            </div>
          )}
          {!hideConfigurations && (
            <>
              <div className={styles.actions}>
                <div className={styles.action}>
                  <button
                    className={actualAudioToggleValue ? "deactivated" : ""}
                    onClick={audioToggleHandlerWithDisable}
                    onPointerEnter={showAudioDisabledTooltipHandler}
                    onPointerLeave={hideAudioDisabledTooltipHandler}
                  >
                    <i
                      className={cn(
                        "icon fa",
                        actualAudioToggleValue
                          ? "fa-microphone-slash"
                          : "fa-microphone neutral"
                      )}
                    ></i>
                  </button>
                  <DeviceSelect
                    deviceId={selectedAudioDevice?.sourceId}
                    deviceName={OutputDevice.AUDIO}
                    values={parsedAvailableAudioSources}
                    disabled={!!selectedAudioDevice?.isMuted}
                    onClickHandler={audioDeviceChangeHandler}
                    initialArrowDirection="down"
                  />
                  {showAudioDisabledTooltip && <AudioDisabledTooltip />}
                </div>
                {hasVideo && (
                  <div className={styles.action}>
                    <button
                      className={cn(
                        "video-btn",
                        selectedVideoDevice?.isMuted ? "deactivated" : ""
                      )}
                      onClick={videoToggleHandlerWithDisable}
                      disabled={
                        actualVideoToggleValue !== selectedVideoDevice?.isMuted
                      }
                    >
                      <i
                        className={cn(
                          "icon fa",
                          selectedVideoDevice?.isMuted
                            ? "fa-video-slash"
                            : "fa-video neutral"
                        )}
                      ></i>
                    </button>
                    <DeviceSelect
                      deviceId={selectedVideoDevice?.sourceId}
                      deviceName={OutputDevice.VIDEO}
                      values={parsedAvailableVideoSources}
                      disabled={!!selectedVideoDevice?.isMuted}
                      onClickHandler={videoDeviceChangeHandler}
                      initialArrowDirection="down"
                    />
                  </div>
                )}
              </div>
              <InfoBox
                title="Intellectual Property Alert"
                description="By joining this workshop you acknowledge and agree that recording, replication, or distribution of any part of the platform, it’s content, materials, and user experience is strictly forbidden and may result in legal action."
              />
            </>
          )}
        </div>
      );
    }
  )
);
