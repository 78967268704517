import { PropsWithChildren, useMemo, memo } from "react";
import ActionFooter from "./ActionFooter";
import { FooterType } from "../../types/enums/activity-footer";
import { ActionFooterType } from "../../types/action-footer";
import { WORKSHOP_EMOTION_COUNT } from "../../constants/global";

import cn from "classnames";
import styles from "./Emotion.module.css";

const emotionValues = new Array(WORKSHOP_EMOTION_COUNT)
  .fill(null)
  .map((_, i) => `${i + 1}`);
const emotionImagePaths = emotionValues.map(
  (v) => `/images/emotion-${v}-face.svg`
);

export default memo(function Emotion(
  props: PropsWithChildren<{
    workshopHeadline: string;
    isReady: boolean;
    isParticipating: boolean;
    isEndEmotionStage: boolean;
    transition: number;
    currentEmotion: string | null;
    notReadyProfilesCount: number;
    currentActiveParticipantCount: number;
    setEmotionHandler: (emotionValue: string) => void;
    setActivityReadyHandler: () => void;
  }>
) {
  const {
    isReady,
    transition,
    isParticipating,
    // isEndEmotionStage,
    currentEmotion,
    notReadyProfilesCount,
    currentActiveParticipantCount,
    setActivityReadyHandler,
    setEmotionHandler,
  } = props;

  const isTransitioning = useMemo(() => transition > 0, [transition]);

  const actionFooterData: ActionFooterType = useMemo(() => {
    if (isTransitioning) {
      return {
        text: (
          <>
            Everyone is ready. Continuing forward in{" "}
            <span className="accent">{transition}...</span>
          </>
        ),
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Ready,
        isLoading: true,
      };
    }
    if (!currentEmotion && !isReady) {
      const playersClicked =
        currentActiveParticipantCount - notReadyProfilesCount;
      return {
        text: (
          <>
            Click the face that reflects your current emotion.{" "}
            {playersClicked > 0 && (
              <span className="accent">
                {playersClicked} player{playersClicked > 1 && "s"} clicked.
              </span>
            )}
          </>
        ),
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Notice,
        isLoading: false,
      };
    }
    if (currentEmotion && !isReady) {
      const playersClicked =
        currentActiveParticipantCount - notReadyProfilesCount;
      return {
        text: (
          <>
            Click “Continue” to confirm your emotion.{" "}
            {playersClicked > 0 && (
              <span className="accent">
                {playersClicked} player{playersClicked > 1 && "s"} clicked.
              </span>
            )}
          </>
        ),
        buttonText: "Continue",
        disabledButton: false,
        type: FooterType.Notice,
        isLoading: false,
      };
    }
    if (currentEmotion && isReady) {
      return {
        text: (
          <>
            Waiting for{" "}
            <span className="accent">
              {notReadyProfilesCount} more player
              {notReadyProfilesCount > 1 && "s"}...
            </span>
          </>
        ),
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Waiting,
        isLoading: false,
      };
    }
    const playersClicked =
      currentActiveParticipantCount - notReadyProfilesCount;
    return {
      text: (
        <>
          Click the face that reflects your current emotion.{" "}
          {playersClicked > 0 && (
            <span className="accent">
              {playersClicked} player{playersClicked > 1 && "s"} clicked.
            </span>
          )}
        </>
      ),
      buttonText: "Continue",
      disabledButton: true,
      type: FooterType.Notice,
      isLoading: false,
    };
  }, [
    currentActiveParticipantCount,
    currentEmotion,
    isReady,
    notReadyProfilesCount,
    transition,
    isTransitioning,
  ]);

  const headerText = "How do you feel?";
  const infoText =
    "Please share your current emotion to help us analyze how emotions change during the workshop.";

  return (
    <div className={styles.container}>
      <div className={cn(styles.emotionsContainer, "main-container")}>
        <h1>{headerText}</h1>
        <p className="text">{infoText}</p>
        <div className={styles.emotionListContainer}>
          {emotionValues.map((v, idx) => (
            <div
              key={v}
              className={cn(
                styles.emotion,
                currentEmotion === v && "active",
                isTransitioning && "disabled"
              )}
              onClick={() => setEmotionHandler(v)}
            >
              <div className={styles.emotionInner}>
                <img src={emotionImagePaths[idx]} alt="logo" />
              </div>
            </div>
          ))}
        </div>
      </div>
      {isParticipating && (
        <ActionFooter
          isLoading={actionFooterData.isLoading}
          buttonText={actionFooterData.buttonText}
          type={actionFooterData.type}
          disabledButton={actionFooterData.disabledButton}
          buttonClickHandler={setActivityReadyHandler}
        >
          {actionFooterData.text}
        </ActionFooter>
      )}
    </div>
  );
});
