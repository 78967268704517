import { useCallback, useState } from "react";

const useCopyFromClipboard = (props: {
  copyText: string;
  durationInMilliseconds: number;
}) => {
  const { copyText, durationInMilliseconds } = props;

  const [isCopied, setCopied] = useState<boolean>(false);

  const copyHandler = useCallback(() => {
    if (isCopied) return;

    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        setCopied(true);
      })
      .finally(() => {
        setTimeout(() => {
          setCopied(false);
        }, durationInMilliseconds);
      });
  }, [copyText, isCopied, durationInMilliseconds]);

  return {
    copyHandler,
    isCopied,
  };
};

export default useCopyFromClipboard;
