import {
  ChangeEvent,
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import debounce from "lodash/debounce";

import cn from "classnames";

import { ActivityPart } from "../../../types/enums/activity-part";

import styles from "./Conceptualization.module.css";

import { SessionStateValue } from "../../../apollo-graphql/types/session-state";
import InfoBox from "../../InfoBox/InfoBox";
import { FooterType } from "../../../types/enums/activity-footer";
import { ActivityCommon } from "../../../types/activity-common";
import { activityTypeFooterTextFactoryMap } from "../../../utils/footer";
import ActionFooter from "../ActionFooter";
import { ActionFooterType } from "../../../types/action-footer";
import { Doc, YTextEvent, applyUpdate, encodeStateAsUpdate } from "yjs";
import {
  deserializeUint8Array,
  serializeUint8Array,
} from "../../../utils/uint8-array-serializers";
import { ActivityComplexValue } from "../../../types/activity-complex-value";
import { ActivityComplexValueType } from "../../../types/enums/activity-complex-value-type";
import { diffChars } from "diff";

interface ConceptualizationProps extends ActivityCommon {
  activityResultForCurrentProfile:
    | SessionStateValue["context"]["activityResult"][0]["value"][0]["value"][0]
    | null;
  individualActivityResultForCurrentProfile:
    | SessionStateValue["context"]["activityResult"][0]["value"][0]["value"][0]
    | null;
  currentActivityPart: ActivityPart;
  setActivityValueHandler: (args: {
    activityId: string;
    value: string;
  }) => void;
}
export default memo(function Conceptualization(
  props: PropsWithChildren<ConceptualizationProps>
) {
  const {
    transition,
    activity,
    activityResultForCurrentProfile,
    individualActivityResultForCurrentProfile,
    currentActivityPart,
    isReady,
    currentActiveParticipantCount,
    notReadyProfilesCount,
    setActivityValueHandler,
    setActivityReadyHandler,
  } = props;
  const { conceptualization } = activity;

  const textAreaElementRef = useRef<any>();
  const textAreaElementPrevValue = useRef<string>("");
  const yDoc = useMemo(() => new Doc(), []);

  const value = useMemo(() => {
    if (!activityResultForCurrentProfile) {
      return "";
    }
    return activityResultForCurrentProfile.value;
  }, [activityResultForCurrentProfile]);

  useEffect(() => {
    if (currentActivityPart === ActivityPart.Individual || !value || !yDoc)
      return;
    let teamNameData: ActivityComplexValue | null = null;
    try {
      teamNameData = JSON.parse(value);
    } catch (e) {
      console.error("Cannot parse value to extract data", e);
    }
    if (teamNameData && "value" in teamNameData) {
      const update = deserializeUint8Array(teamNameData.value);
      if (update) applyUpdate(yDoc, update);
    }
    const text = yDoc?.getText()?.toString() || "";
    textAreaElementRef.current.value = text;
    textAreaElementPrevValue.current = text;
  }, [currentActivityPart, value, yDoc]);

  useEffect(() => {
    const yDocText = yDoc.getText();
    if (currentActivityPart !== ActivityPart.Group || !yDocText) return;

    const updateHandler = (event: YTextEvent) => {
      if (!event.target.doc) return;
      const update = encodeStateAsUpdate(event.target.doc);
      const uInt8ArrayString = serializeUint8Array(update);
      const activityComplexValue: ActivityComplexValue = {
        value: uInt8ArrayString,
        type: ActivityComplexValueType.yjs,
      };
      setActivityValueHandler({
        activityId: activity.id,
        value: JSON.stringify(activityComplexValue),
      });
    };

    yDocText.observe(updateHandler);

    return () => {
      yDocText.unobserve(updateHandler);
    };
  }, [activity.id, currentActivityPart, setActivityValueHandler, yDoc]);

  const handleGroupTextChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const yDocText = yDoc.getText();
      if (currentActivityPart !== ActivityPart.Group || !yDocText) return;

      const target = event.target;
      const value = target.value;
      const differences = diffChars(textAreaElementPrevValue.current, value);
      textAreaElementPrevValue.current = value;
      const additions = differences.filter((d) => !!d.added);
      const deletions = differences.filter((d) => !!d.removed);

      for (const a of additions) {
        const arrayIndex = differences.indexOf(a);
        const textIndex = differences
          .slice(0, arrayIndex)
          .reduce((acc, curr) => acc + (curr?.count || 0), 0);
        yDocText.insert(textIndex, a.value);
      }
      for (const d of deletions) {
        const arrayIndex = differences.indexOf(d);
        const textIndex = differences
          .slice(0, arrayIndex)
          .reduce((acc, curr) => acc + (curr?.count || 0), 0);
        yDocText.delete(textIndex, d.count!);
      }
    },
    [currentActivityPart, yDoc]
  );

  const individualValue = useMemo(() => {
    return individualActivityResultForCurrentProfile?.value || "";
  }, [individualActivityResultForCurrentProfile]);

  const [textAreaValue, setTextAreaValue] = useState(value);
  const debouncedSetActivityValue = useMemo(
    () => debounce(setActivityValueHandler, 200),
    [setActivityValueHandler]
  );

  const handleIndividualTextChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      setTextAreaValue(newValue);
      debouncedSetActivityValue({ activityId: activity.id, value: newValue }); // TODO - yjs
    },
    [activity.id, debouncedSetActivityValue]
  );
  const inputHandler = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      if (currentActivityPart === ActivityPart.Group) {
        handleGroupTextChange(event);
      } else {
        handleIndividualTextChange(event);
      }
    },
    [currentActivityPart, handleGroupTextChange, handleIndividualTextChange]
  );

  const actionFooterData: ActionFooterType = useMemo(() => {
    const isOnReviewPart = currentActivityPart === ActivityPart.Review;

    if (transition > 0) {
      const type = isOnReviewPart ? FooterType.Notice : FooterType.Ready;
      return {
        text: (
          <>
            Everyone agreed on the team definition. Continuing forward in{" "}
            <span className="accent">{transition}...</span>
          </>
        ),
        buttonText: "",
        disabledButton: false,
        type,
        isLoading: true,
      };
    }
    if (!isReady) {
      let text = activityTypeFooterTextFactoryMap[activity.type](
        currentActiveParticipantCount - notReadyProfilesCount
      );
      let disabledButton = false;
      let buttonText = "Continue";
      const type = isOnReviewPart ? FooterType.Ready : FooterType.Notice;

      if (currentActivityPart === ActivityPart.Individual) {
        const playerHasAnswered = !!activityResultForCurrentProfile;
        disabledButton = !playerHasAnswered;
        text = playerHasAnswered ? (
          <>
            Click on <span className="accent">“Continue”</span> to submit your
            individual definition.
          </>
        ) : (
          <>Write you own definition before you can continue.</>
        );
      }

      if (currentActivityPart === ActivityPart.Group) {
        disabledButton = false;
        const readyPlayers =
          currentActiveParticipantCount - notReadyProfilesCount;
        text = (
          <>
            Everyone must agree on a team definition to continue!{" "}
            {!!readyPlayers && (
              <span className="accent">
                {readyPlayers} player{readyPlayers > 1 && "s"} agreed!
              </span>
            )}
          </>
        );
        buttonText = "I agree";
      }
      return { text, buttonText, disabledButton, type, isLoading: false };
    }
    return {
      text: (
        <>
          Waiting for{" "}
          <span className="accent">
            {notReadyProfilesCount} more player
            {notReadyProfilesCount > 1 && "s"}...
          </span>
        </>
      ),
      buttonText: "",
      disabledButton: true,
      type: FooterType.Waiting,
      isLoading: false,
    };
  }, [
    currentActivityPart,
    transition,
    isReady,
    notReadyProfilesCount,
    activity.type,
    currentActiveParticipantCount,
    activityResultForCurrentProfile,
  ]);

  return (
    <div key={activity.id} className="activity-container">
      <div className={cn(styles.container, "main-container")}>
        <div className={styles.infoContainer}>
          <p className="text bold">{currentActivityPart}</p>
          <p className="text">{conceptualization?.instructions}</p>
          <InfoBox
            title="Some questions are purposely ambiguous!"
            description="You're not allowed to change these restrictions. It's either due to the restrictions on the page, or permission settings for this space."
            isDismissible
          />
        </div>
        <div className={styles.answersContainer}>
          {currentActivityPart === ActivityPart.Group && (
            <div className={styles.answer}>
              <p className="text">
                Write your team definition of “{conceptualization?.concept}”
              </p>
              <textarea
                className={cn(styles.textArea, "text")}
                key={`${activity.id}-group`}
                onInput={inputHandler}
                ref={textAreaElementRef}
              ></textarea>
            </div>
          )}

          <div className={styles.answer}>
            <p className="text">
              Your individual definition{" "}
              {currentActivityPart === ActivityPart.Group && (
                <span className="text secondary">(Visible only for you)</span>
              )}
            </p>
            <textarea
              className="text"
              key={`${activity.id}-individual`}
              onInput={inputHandler}
              value={
                currentActivityPart === ActivityPart.Group
                  ? individualValue
                  : textAreaValue
              }
              disabled={currentActivityPart === ActivityPart.Group}
            ></textarea>
          </div>
        </div>
      </div>
      <ActionFooter
        buttonText={actionFooterData.buttonText}
        type={actionFooterData.type}
        disabledButton={actionFooterData.disabledButton}
        buttonClickHandler={() =>
          setActivityReadyHandler({ activityId: activity.id })
        }
        isLoading={actionFooterData.isLoading}
      >
        {actionFooterData.text}
      </ActionFooter>
    </div>
  );
});
