import { useMemo } from "react";
import { Workshop } from "../../../apollo-graphql/types/workshop";
import { IActivityResult } from "../../../apollo-graphql/types/session-state";
import { ActivityType } from "../../../types/enums/activity-type";
import { ACTIVITY_TIMEOUT_VALUE } from "../../../constants/global";
import { ActivityPart } from "../../../types/enums/activity-part";

export const useGenerateResults = (
  activityResult: IActivityResult[],
  profileId: string,
  workshop: Workshop
) => {

  const activities = useMemo(
    () =>
      workshop.activities
        .slice()
        .sort((a, b) => Number(a.sequence_number) - Number(b.sequence_number)),
    [workshop.activities]
  );

  const activityQuestions = useMemo(
    () => activities.filter(({ type }) => type === ActivityType.Question),
    [activities]
  );

  const teamConsensusCount = useMemo(() => {
    return activityQuestions.reduce((acc, curr) => {
      const { id } = curr;
      const { value } = activityResult.find((a) => a.key === id) || {
        value: [],
      };
      const groupValues = value.find(
        (v) => v.key === ActivityPart.Group
      )?.value;
      const hasConsensus =
        groupValues?.length &&
        groupValues.every(
          ({ value }) =>
            value !== ACTIVITY_TIMEOUT_VALUE && value === groupValues[0].value
        );

      acc += hasConsensus ? 1 : 0;

      return acc;
    }, 0);
  }, [activityResult, activityQuestions]);

  const { correctAnswerIds, wrongAnswerIds } = useMemo(() => {
    return activityQuestions.reduce(
      (acc, curr) => {
        const { answers = [] } = curr;

        const maxPoints = Math.max(...answers.map(({ points }) => points));

        answers?.forEach(({ points, id }) => {
          if (points === maxPoints) {
            acc.correctAnswerIds.push(id);
          } else {
            acc.wrongAnswerIds.push(id);
          }
        });

        return acc;
      },
      { correctAnswerIds: [], wrongAnswerIds: [] } as {
        correctAnswerIds: string[];
        wrongAnswerIds: string[];
      }
    );
  }, [activityQuestions]);

  const { correctAnswersCount, wrongAnswersCount } = useMemo(() => {
    return activityQuestions.reduce(
      (acc, curr) => {
        const { id } = curr;
        const { value } = activityResult.find((a) => a.key === id) || {
          value: [],
        };
        const groupedValues = value.find(
          (v) => v.key === ActivityPart.Group
        )?.value;
        const currentProfileAnswerId = groupedValues?.find(
          (answer) => answer.profileId === profileId
        )?.value;

        if (!!currentProfileAnswerId) {
          acc.correctAnswersCount += correctAnswerIds.includes(
            currentProfileAnswerId
          )
            ? 1
            : 0;
          acc.wrongAnswersCount += wrongAnswerIds.includes(
            currentProfileAnswerId
          )
            ? 1
            : 0;
        }

        return acc;
      },
      { correctAnswersCount: 0, wrongAnswersCount: 0 } as {
        correctAnswersCount: number;
        wrongAnswersCount: number;
      }
    );
  }, [
    activityQuestions,
    activityResult,
    correctAnswerIds,
    profileId,
    wrongAnswerIds,
  ]);


  return {
    activities,
    activityQuestions,
    teamConsensusCount,
    correctAnswerIds,
    wrongAnswerIds,
    correctAnswersCount,
    wrongAnswersCount
  }
};