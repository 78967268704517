import { PropsWithChildren, forwardRef, memo, useMemo } from "react";
import UserImage from "../../Shared/UserImage/UserImage";
import { Workshop } from "../../../apollo-graphql/types/workshop";
import { Activity } from "../../../apollo-graphql/types/activity";

import cn from "classnames";
import styles from "./NextSteps.module.css";

const CardSection = memo(
  ({
    title,
    imagePath,
    imageAlt,
    children,
  }: PropsWithChildren<{
    title: string;
    imagePath: string;
    imageAlt: string;
  }>) => {
    return (
      <div className={styles.card}>
        <h2 className={styles.header}>
          <img src={imagePath} alt={imageAlt} />
          {title}
        </h2>
        <div className={styles.content}>{children}</div>
      </div>
    );
  }
);

const NextSteps = forwardRef<
  HTMLDivElement | null,
  PropsWithChildren<{
    workshop: Workshop;
    nextWorkshop: Workshop | null;
    isFetchingNextWorkshop: boolean;
    activities: Activity[];
    requestNextWorkshop: (id: string) => void;
    nextWorkshopRequested: boolean;
  }>
>(
  (
    {
      nextWorkshop,
      isFetchingNextWorkshop,
      requestNextWorkshop,
      nextWorkshopRequested,
    },
    ref
  ) => {
    const authorData = useMemo(
      () => ({
        id: nextWorkshop?.author.id,
        name: nextWorkshop?.author?.name,
      }),
      [nextWorkshop]
    );

    return (
      <div ref={ref} className={styles.nextStepsContainer}>
        <div className={styles.headerContainer}>
          <img src="/images/party.png" alt="party-icon" />
          <h1>Next Steps</h1>
        </div>
        <div className={styles.nextStepsContent}>
          {/* <CardSection
          title="Check out your video:"
          imagePath="/images/video-icon.svg"
          imageAlt="Video Icon"
        >
          <div className={styles.videoContent}>
            <div className={styles.iframeContainer}>
              <iframe
                title={theoryActivity?.activity_id + "-video"}
                src={theoryActivity?.video}
              ></iframe>
            </div>
            <p className={cn(styles.infoText, "text")}>
              Your goal is to understand the topic rather than being able to
              reproduce the theory throughout the workshop. Learning will happen
              through discussion and persist until the end, aided by author’s
              explanations after each level
            </p>
          </div>
        </CardSection>
        <CardSection
          title="Read recommendation unlocked:"
          imagePath="/images/read-icon.svg"
          imageAlt="Read Icon"
        >
          <div className={styles.cardBody}>
            <h3>Title</h3>
            <div className={styles.userContainer}>
              <div className={styles.user}>
                <img src={authorData.image} alt="Author" />
                <div>
                  <p>Author</p>
                  <p>{authorData.name}</p>
                </div>
              </div>
              <button className="btn primary">
                <i className="fa-solid fa-book"></i>
                Read
              </button>
            </div>
          </div>
        </CardSection>
        <CardSection
          title="Link unlocked:"
          imagePath="/images/link-icon.svg"
          imageAlt="Link Icon"
        >
          <div className={styles.cardBody}>
            <h3>Title</h3>
            <div className={styles.userContainer}>
              <div className={styles.user}>
                <img src={authorData.image} alt="Author" />
                <div>
                  <p>Author</p>
                  <p>{authorData.name}</p>
                </div>
              </div>
              <button className="btn primary">
                <i className="fa-solid fa-link"></i>
                Read
              </button>
            </div>
          </div>
        </CardSection> */}
          <CardSection
            title="Continue on your AhaPlay journey! Request your next workshop:"
            imagePath="/images/journey-icon.svg"
            imageAlt="Journey Icon"
          >
            <div className={cn(styles.cardBody, styles.continue)}>
              <p>Process & Agreement</p>
              {isFetchingNextWorkshop && "Loading..."}
              <h2 className={styles.header}>{nextWorkshop?.topic}</h2>
              <h3 className="text">{nextWorkshop?.headline}</h3>
              <div className={styles.authorContainer}>
                <div className={styles.author}>
                  <UserImage
                    isPublic={true}
                    containerClass={styles.authorImageContainer}
                    profileId={authorData.id || ""}
                    alt="author-avatar"
                    fallbackFontAwesomeIconClass="fa fa-user"
                  />
                  <div>
                    <p className={styles.authorName}>{authorData.name}</p>
                    <p className={styles.authorLabel}>Author</p>
                  </div>
                </div>
                <div className={styles.buttonContainer}>
                  <button
                    disabled={!!nextWorkshopRequested}
                    className="btn primary"
                    onClick={() => requestNextWorkshop(nextWorkshop!.id)}
                  >
                    Workshop requested
                  </button>
                </div>
              </div>
            </div>
          </CardSection>
        </div>
      </div>
    );
  }
);

export default memo(NextSteps);
