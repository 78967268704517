import { FormEvent, useCallback, useContext, useMemo, useState } from "react";
import * as Form from "@radix-ui/react-form";

import { Navigate, useSearchParams } from "react-router-dom";
import { GlobalContext } from "../contexts/Global";
import { AuthState } from "../+xstate/machines/auth";
import { FetchState } from "../+xstate/machines/fetch-factory";

import Button3D from "../components/Shared/Button3D/Button3D";
import { IChangePassword } from "../apollo-graphql/types/change-password";
import { mappedErrorMessages } from "../constants/mapped-error-messages";
import { ChangePasswordErrors } from "../types/enums/errors";
import withTitle from "../hocs/withTitle";

import styles from "./ChangePassword.module.css";

const ChangePassword = () => {
  const {
    auth: { changePassword, context, changePasswordState, matches },
  } = useContext(GlobalContext);
  const isLoggedIn = matches(AuthState.Authenticated);

  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState("");

  const token = useMemo(() => searchParams.get("token") || "", [searchParams]);
  const canAccess = useMemo(() => !!token && !isLoggedIn, [token, isLoggedIn]);
  const isLoading = useMemo(
    () => changePasswordState === FetchState.Fetching,
    [changePasswordState]
  );

  const submitHandler = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      setErrorMessage("");
      event.preventDefault();
      const payload = Object.fromEntries(
        new FormData(event.currentTarget)
      ) as unknown as IChangePassword;

      if (!payload.token) return;

      if (!payload.newPassword || !payload.repeatPassword) {
        setErrorMessage(mappedErrorMessages[ChangePasswordErrors.MISSING_DATA]);
        return;
      }

      if (payload.newPassword !== payload.repeatPassword) {
        setErrorMessage(
          mappedErrorMessages[ChangePasswordErrors.PASSWORDS_MISMATCH]
        );
        return;
      }
      changePassword({ variables: payload });
    },
    [changePassword]
  );

  if (changePasswordState === FetchState.Success) {
    return <Navigate to={"/login"} />;
  }

  return !canAccess ? (
    <Navigate to={"/"} />
  ) : (
    <div className={styles.changePasswordContainer}>
      <div className="FormContainer">
        <h2 className="title">Change your password</h2>
        <Form.Root className="Form" onSubmit={submitHandler}>
          <Form.Field name="newPassword">
            <Form.Control
              type="password"
              className="FormControl"
              placeholder="Enter your new password"
            />
          </Form.Field>
          <Form.Field name="repeatPassword">
            <Form.Control
              type="password"
              className="FormControl"
              placeholder="Confirm your password"
            />
          </Form.Field>
          <Form.Field name="token" style={{ display: "none", height: 0 }}>
            <Form.Control value={token} />
          </Form.Field>
          <Button3D
            className="submit-btn"
            variant="success"
            size="large"
            type="submit"
            disabled={isLoading}
          >
            Continue
          </Button3D>
          {(errorMessage || context?.error) && !isLoading && (
            <div className="error">
              {errorMessage ||
                mappedErrorMessages[
                  context?.error as keyof typeof mappedErrorMessages
                ]}
            </div>
          )}
          {isLoading && <div className="loading">Loading...</div>}
        </Form.Root>
      </div>
    </div>
  );
};

export default withTitle(ChangePassword);
