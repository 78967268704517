import { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { GlobalContext } from "../contexts/Global";
import { AuthState } from "../+xstate/machines/auth";

export default function Logout() {
  const {
    auth: { logout, state },
  } = useContext(GlobalContext);

  useEffect(() => {
    // NOTE: Delay logout dispatch in favour of
    // workshop session disconnect to be called
    // with appropriate credentials when user logout
    //  of ongoing session.
    setTimeout(() => logout(), 0);
  }, [logout]);
  return state === AuthState.Authenticated ? (
    <div>Logging out...</div>
  ) : (
    <Navigate to="/login" />
  );
}
