import {
  PropsWithChildren,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WorkshopClockEvent } from "../../../helpers/workshop-clock";
import { SessionContext } from "../../../contexts/Session";
import Countdown from "../Countdown/Countdown";

import cn from "classnames";
import styles from "./Timer.module.css";

export default memo(function Timer(
  props: PropsWithChildren<{
    title?: string;
    isHeaderTime?: boolean;
    isAhaMomentTimer?: boolean;
    isDone?: boolean;
    hasCurrentActivity?: boolean;
  }>
) {
  const { title, isHeaderTime, isAhaMomentTimer, isDone, hasCurrentActivity } =
    props;
  const sessionContext = useContext(SessionContext);
  const [progress, setProgress] = useState<number>(0);
  const [value, setValue] = useState<string>("");
  const [isHurryUpPopupHidden, setIsHurryUpPopupHidden] = useState(false);

  const { clockInstance } = sessionContext.workshopClock.state.context;
  const isActivityTimer = !isHeaderTime;

  const timeIsUp = useMemo(() => progress >= 100, [progress]);
  const timeIsRunningUp = useMemo(() => {
    if (!value || !value.includes(":")) return false;
    const [, remainingTimeString] = /(\d+):/.exec(value || "00:00") || [
      "",
      "00:00",
    ];

    return parseInt(remainingTimeString) === 0;
  }, [value]);

  const timerContent = useMemo(() => {
    return isHeaderTime ? (
      <div className={cn(styles.container, "timer-container")}>
        {title && <h2 className="thin">{title}</h2>}
        {value && hasCurrentActivity && !isDone && (
          <Countdown
            timeValue={value}
            progress={progress}
            timeIsRunningUp={timeIsRunningUp}
            isPassedTime={true}
          />
        )}
      </div>
    ) : null;
  }, [
    isDone,
    isHeaderTime,
    hasCurrentActivity,
    timeIsRunningUp,
    progress,
    title,
    value,
  ]);

  useEffect(() => {
    const tickEventListener = () => {
      const value = isHeaderTime
        ? clockInstance?.workshopParsedTimeElapsed
        : clockInstance?.activityParsedTimeRemaining;

      const progress = isHeaderTime
        ? clockInstance?.workshopProgress
        : clockInstance?.activityProgress;

      setProgress(progress || 0);
      setValue(value || "");
    };

    clockInstance?.addEventListener(WorkshopClockEvent.TICK, tickEventListener);

    return () => {
      clockInstance?.removeEventListener(
        WorkshopClockEvent.TICK,
        tickEventListener
      );
    };
  }, [clockInstance, isHeaderTime]);

  useEffect(() => {
    if (!timeIsRunningUp) setIsHurryUpPopupHidden(false);
  }, [timeIsRunningUp]);

  return (
    <>
      {clockInstance?.isTicking && (
        <>
          {timerContent}
          {isActivityTimer && (
            <div className={cn(styles.container, "activity-timer")}>
              <div className={styles.infoContainer}>
                {title ? (
                  <div className={styles.timerTitle}>{title}</div>
                ) : (
                  <div className="flex-spacer" />
                )}
                {!!value && !timeIsUp && (
                  <>
                    <div className={cn(styles.timerValue)}>
                      <div className={cn(styles.info, "text", "tiny")}>
                        time left
                      </div>
                      <div
                        className={cn(styles.time, timeIsRunningUp && "isRed")}
                      >
                        {value}
                      </div>
                    </div>
                  </>
                )}
                {timeIsUp && <h2 className={styles.timeIsUp}>Time is up</h2>}
                {timeIsRunningUp && !isHurryUpPopupHidden && !timeIsUp && (
                  <div
                    className={cn(
                      styles.hurryUpContainer,
                      isAhaMomentTimer && "moment-timer"
                    )}
                  >
                    <div className="line">
                      <div className="left">
                        <i className="icon fa fa-triangle-exclamation red" />
                        <span className="text bold">Hurry up</span>
                      </div>
                      <div className="right">
                        <i
                          className="icon fa fa-xmark"
                          onClick={() => setIsHurryUpPopupHidden(true)}
                        />
                      </div>
                    </div>
                    <p className="text small info-text">
                      Your time to answer the question ends soon.
                    </p>
                  </div>
                )}
              </div>
              <div className={styles.barContainer}>
                <div
                  className={cn(styles.bar, timeIsRunningUp && "isRed")}
                  style={{ width: `${progress}%` }}
                />
              </div>
            </div>
          )}
        </>
      )}
      {timerContent}
    </>
  );
});
