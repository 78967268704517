import { Fragment, PropsWithChildren, memo, useMemo } from "react";
import styles from "./AsideMenu.module.css";
import cn from "classnames";

export enum AsideMenuItems {
  OVERVIEW = "Overview",
  WORKSHOP_REVIEW = "Workshop Review",
  NEXT_STEPS = "Next Steps",
}

const AsideMenu = (
  props: PropsWithChildren<{
    selectedMenuItemIdx: number;
    onSelectHandlerIdx: (idx: number) => void;
    isFixed: boolean;
  }>
) => {
  const { selectedMenuItemIdx, onSelectHandlerIdx, isFixed } = props;
  const menuItems = Object.values(AsideMenuItems);

  const menuItemsContent = useMemo(() => {
    return menuItems.map((item, idx) => {
      const isItemSelected = selectedMenuItemIdx === idx;
      const isLastItem = menuItems.length - 1 === idx;
      const activeItemStyles = {
        width: item.length * 10,
      };

      return (
        <Fragment key={item}>
          <div
            className={styles.menuItemContainer}
            style={{ height: isItemSelected ? item.length * 10 + 20 : 50 }}
            onClick={() => onSelectHandlerIdx(idx)}
          >
            <label
              key={item}
              className={cn(
                styles.menuItem,
                isItemSelected ? styles.active : ""
              )}
            >
              {isItemSelected ? (
                <span className={styles.activeText} style={activeItemStyles}>
                  {item}
                </span>
              ) : (
                <span
                  className={idx < selectedMenuItemIdx ? styles.normalText : ""}
                >
                  {idx + 1}
                </span>
              )}
              <input type="radio" name="menu" value={item} />
            </label>
            {!isItemSelected && item === AsideMenuItems.WORKSHOP_REVIEW && (
              <i className="fa-solid fa-circle-info info"></i>
            )}
          </div>
          {!isLastItem && (
            <div className={styles.asideMenuLineContainer}>
              <div className={styles.asideMenuLine}></div>
            </div>
          )}
        </Fragment>
      );
    });
  }, [menuItems, onSelectHandlerIdx, selectedMenuItemIdx]);

  return (
    <div className={styles.container}>
      <div className={cn(styles.asideMenu, isFixed && "fixed")}>
        <div className={styles.menuItems}>{menuItemsContent}</div>
      </div>
    </div>
  );
};

export default memo(AsideMenu);
