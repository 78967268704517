import { PropsWithChildren, memo, useEffect, useState } from "react";
import { ButtonSize, ButtonVariant } from "../../../types/buttons";
import cn from "classnames";

export default memo(function LoadingButton(
  props: PropsWithChildren<{
    isLoading?: boolean;
    size?: ButtonSize;
    variant?: ButtonVariant;
    disabled?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    type?: "button" | "reset" | "submit";
    className?: string;
    iconClass?: string;
    delayInMilliseconds?: number;
  }>
) {
  const {
    size,
    variant,
    className,
    children,
    type = "button",
    onClick,
    disabled,
    iconClass,
    isLoading = false,
    delayInMilliseconds,
  } = props;

  const [showLoading, setShowLoading] = useState(
    delayInMilliseconds ? false : isLoading
  );

  useEffect(() => {
    if (!delayInMilliseconds) return;

    const timerId = setTimeout(
      () => setShowLoading(isLoading),
      delayInMilliseconds
    );

    return () => {
      clearTimeout(timerId);
    };
  }, [delayInMilliseconds, isLoading]);

  return (
    <button
      className={cn("btn", className, size, variant || "primary")}
      type={type}
      onClick={onClick}
      disabled={disabled || showLoading}
      style={{ display: "flex", gap: "5px" }}
    >
      {showLoading && <i className="fa fa-circle-o-notch fa-spin" />}
      {!showLoading && iconClass && <i className={iconClass} />}
      <p style={{ margin: "0 auto" }}>{children}</p>
    </button>
  );
});
