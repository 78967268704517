import { PropsWithChildren, memo, useMemo } from "react";

import { InvitationProfile } from "../../../apollo-graphql/types/slot";
import { InvitationStatus } from "../../../types/enums/invitation-status";
import UserImage from "../UserImage/UserImage";

import styles from "./UserAvatar.module.css";
import cn from "classnames";

const iconTypeMap = {
  [InvitationStatus.ACCEPTED]: "fa-check",
  [InvitationStatus.DECLINED]: "fa-x",
  [InvitationStatus.PENDING]: "fa-question",
  [InvitationStatus.TENTATIVE]: "fa-check",
};

const UserAvatar = (
  props: PropsWithChildren<{
    profile: InvitationProfile;
    status: InvitationStatus;
    inActive?: boolean;
    showIcon?: boolean;
  }>
) => {
  const { profile, status, inActive = false, showIcon = false } = props;

  const profileAbbreviation = useMemo(
    () => profile.name.slice(0, 1).toUpperCase(),
    [profile.name]
  );

  return (
    <div key={profile.id} className={cn(styles.user, inActive && "inactive")}>
      <div className="avatar-container">
        <UserImage
          isPublic={false}
          containerClass={styles.imageContainer}
          profileId={profile.id}
          profileWorkspaceId={profile.workspace.workspace_id}
          alt="user-avatar"
          profileAbbreviation={profileAbbreviation}
        />
        {showIcon && (
          <span className={cn("icon", status.toLocaleLowerCase())}>
            <i
              className={cn(
                "fa",
                iconTypeMap[status as keyof typeof iconTypeMap]
              )}
            />
          </span>
        )}
      </div>
      <p className="username">{profile.name}</p>
    </div>
  );
};

export default memo(UserAvatar);
