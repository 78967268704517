import {
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  DragStartEvent,
  DragOverEvent,
  DragEndEvent,
  DropAnimation,
  defaultDropAnimation,
  DndContext,
  closestCorners,
  DragOverlay,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates, arrayMove } from "@dnd-kit/sortable";
import { PropsWithChildren, useState } from "react";
import MomentCard, { IMoment } from "../MomentCard/MomentCard";
import MomentSection from "../MomentSection/MomentSection";

import { WorkshopAuthor } from "../../../../../../apollo-graphql/types/workshop";
import InfoBox from "../../../../../InfoBox/InfoBox";

import styles from "./MomentSectionList.module.css";

interface ISection {
  authorMoments: IMoment[];
  teamMoments: IMoment[];
}

interface MomentSectionListProps extends ISection {
  currentProfileId: string;
  isTransitioning: boolean;
  workshopAuthor: WorkshopAuthor;
  setGroupValueHandler: (value: string) => void;
}

export const findSectionContainer = (section: ISection, id: string) => {
  if (id in section) {
    return id;
  }

  const container = Object.keys(section).find((key) =>
    section[key as keyof ISection].find((item) => item.id === id)
  );
  return container;
};

export const getItem = (items: IMoment[], id: string) => {
  const index = items.findIndex((item) => item.id === id);
  return {
    index,
    item: items[index],
  };
};

const MomentSectionList = (
  props: PropsWithChildren<MomentSectionListProps>
) => {
  const {
    workshopAuthor,
    authorMoments,
    teamMoments,
    currentProfileId,
    isTransitioning,
    setGroupValueHandler,
  } = props;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [activeItemId, setActiveItemId] = useState<null | string>(null);
  const [sections, setSections] = useState<ISection>({
    authorMoments,
    teamMoments,
  });

  const handleDragStart = ({ active }: DragStartEvent) => {
    setActiveItemId(active.id as string);
  };

  const handleDragOver = ({ active, over }: DragOverEvent) => {
    const activeContainer = findSectionContainer(
      sections,
      active.id as string
    ) as keyof ISection;
    const overContainer = findSectionContainer(
      sections,
      over?.id as string
    ) as keyof ISection;

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    setSections((section) => {
      const activeItems = section[activeContainer];
      const overItems = section[overContainer];

      const activeIndex = activeItems.findIndex(
        (item) => item.id === active.id
      );
      const overIndex = overItems.findIndex((item) => item.id !== over?.id);

      return {
        ...section,
        [activeContainer]: [
          ...section[activeContainer].filter((item) => item.id !== active.id),
        ],
        [overContainer]: [
          ...section[overContainer].slice(0, overIndex),
          sections[activeContainer][activeIndex],
          ...section[overContainer].slice(
            overIndex,
            section[overContainer].length
          ),
        ],
      };
    });
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    const activeContainer = findSectionContainer(
      sections,
      active.id as string
    ) as keyof ISection;
    const overContainer = findSectionContainer(
      sections,
      over?.id as string
    ) as keyof ISection;

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }

    const activeIndex = sections[activeContainer].findIndex(
      (item) => item.id === active.id
    );
    const overIndex = sections[overContainer].findIndex(
      (item) => item.id === over?.id
    );

    if (activeIndex !== overIndex) {
      setSections((section) => {
        const newSectionValue = arrayMove(
          section[overContainer],
          activeIndex,
          overIndex
        );

        const updatedSection = {
          ...section,
          [overContainer]: newSectionValue,
        };

        setGroupValueHandler(
          JSON.stringify({ moments: updatedSection.teamMoments })
        );

        return updatedSection;
      });
    }

    setActiveItemId(null);
  };

  const dropAnimation: DropAnimation = {
    ...defaultDropAnimation,
  };

  const { index, item } = activeItemId
    ? getItem([...authorMoments, ...teamMoments], activeItemId)
    : { item: null, index: 0 };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <div className={styles.groupMoments}>
        <div className={styles.sharedMomentListContainer}>
          <p className="text">{workshopAuthor.name}’s “Aha Moments”:</p>

          <MomentSection
            id="authorMoments"
            currentProfileId={currentProfileId}
            workshopAuthor={workshopAuthor}
            isTransitioning={isTransitioning}
            items={sections.authorMoments}
          />
        </div>
        <div className={styles.delimiter}>
          <img src="/icons/drag-and-drop.svg" alt="drag-and-drop" />
          <div className={styles.line}></div>
        </div>
        <div className={styles.sharedMomentListContainer}>
          <p className="text">Your team’s “Aha Moments”:</p>

          <MomentSection
            id="teamMoments"
            workshopAuthor={workshopAuthor}
            currentProfileId={currentProfileId}
            isTransitioning={isTransitioning}
            items={sections.teamMoments}
          />
        </div>
      </div>

      <DragOverlay dropAnimation={dropAnimation}>
        {item ? (
          <MomentCard
            id={item.id}
            isDraggable
            item={item}
            workshopAuthor={workshopAuthor}
            currentProfileId={currentProfileId}
            index={index}
            isTransitioning
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default MomentSectionList;
