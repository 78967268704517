import styles from "./VoiceIndicator.module.css";

const VoiceIndicator = () => {
  return (
    <div className={styles.voiceIndicatorContainer}>
      <div className="indicator"></div>
      <div className="indicator"></div>
      <div className="indicator"></div>
      <div className="indicator"></div>
      <div className="indicator"></div>
    </div>
  );
};

export default VoiceIndicator;
