import "./App.css";
import { GlobalContextProvider } from "./contexts/Global";
import { ApolloProvider } from "./contexts/Apollo";
import Router from "./Router";
import { PropsWithChildren } from "react";
import { DocumentVisibilityContextProvider } from "./contexts/DocumentVisibility";
import { InternetConnectionContextProvider } from "./contexts/InternetConnection";

function App(props: PropsWithChildren<{ instanceUUID: string }>) {
  const { instanceUUID } = props;
  return (
    <div className="App">
      <InternetConnectionContextProvider>
        <DocumentVisibilityContextProvider>
          <ApolloProvider>
            <GlobalContextProvider instanceUUID={instanceUUID}>
              <Router />
            </GlobalContextProvider>
          </ApolloProvider>
        </DocumentVisibilityContextProvider>
      </InternetConnectionContextProvider>
    </div>
  );
}

export default App;
