import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import {
  GetWorkshopCoverageResult,
  GetWorkshopResult,
  GetWorkshopsResult,
  RequestNextWorkshopResult,
} from "../types/results/get-workshops-result";

const getWorkshopsQuery = gql`
  query GetWorkshops($searchText: String) {
    getWorkshops(searchText: $searchText) {
      about_text
      about_video
      author {
        id
        create_date
        email
        headline
        name
      }
      author_id
      create_date
      duration
      headline
      id
      status
      topic
      type
      typeInstance {
        id
        create_date
        description
        name
        update_date
        video
      }
      activities {
        id
      }
      goals {
        id
        text
      }
      update_date
    }
  }
`;

const getNextWorkshopQuery = gql`
  query GetWorkshop($id: String!) {
    getWorkshop(id: $id) {
      id
      headline
      topic
      author {
        id
        name
      }
    }
  }
`;

const requestNextWorkshopQuery = gql`
  query RequestNextWorkshop($id: String!) {
    requestNextWorkshop(id: $id) {
      id
    }
  }
`;

const getWorkshopCoverageQuery = gql`
  query GetWorkshopCoverage($id: String, $workspaceId: String) {
    getWorkshopCoverage(id: $id, workspaceId: $workspaceId) {
      coverage
      workspaceProfilesCount
    }
  }
`;

export function getWorkshops(
  client: AppApolloClient,
  variables: {
    searchText: string;
  }
) {
  return client
    .query<GetWorkshopsResult>({
      query: getWorkshopsQuery,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data.getWorkshops;
    });
}

export function getNextWorkshop(
  client: AppApolloClient,
  variables: {
    id: string;
  }
) {
  return client
    .query<GetWorkshopResult>({
      query: getNextWorkshopQuery,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data.getWorkshop;
    });
}

export function requestNextWorkshop(
  client: AppApolloClient,
  variables: {
    id: string;
  }
) {
  return client
    .query<RequestNextWorkshopResult>({
      query: requestNextWorkshopQuery,
      variables,
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data.requestNextWorkshop;
    });
}

export function getWorkshopCoverage(
  client: AppApolloClient,
  variables: {
    id: string;
    workspaceId: string;
  }
) {
  return client
    .query<GetWorkshopCoverageResult>({
      query: getWorkshopCoverageQuery,
      variables,
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data.getWorkshopCoverage;
    });
}
