import { PropsWithChildren, memo, useCallback, useMemo } from "react";

import { RemoteParticipantData } from "../../types/jitsi";
import VideoWithBlur from "../VideoWithBlur/VideoWithBlur";

import mutedVideoStyles from "../VideoWithBlur/VideoWithBlur.module.css";
import { Profile } from "../../apollo-graphql/types/profile";
import Video from "../Shared/Video/Video";
import VoiceIndicator from "../VoiceIndicator/VoiceIndicator";
import UserImage from "../Shared/UserImage/UserImage";
import InfoBox from "../InfoBox/InfoBox";

import cn from "classnames";
import styles from "./JitsiList.module.css";

export default memo(function JitsiList(
  props: PropsWithChildren<{
    profile: Profile;
    width: number;
    height: number;
    disableAudioAndVideo?: boolean;
    isCurrentUserVideoMutedOrUnavailable?: boolean;
    isCurrentUserAudioMutedOrUnavailable?: boolean;
    remoteParticipantsData: RemoteParticipantData[];
    attachHtmlVideoElementToLocalTracks(videoElement: HTMLVideoElement): void;
    attachHtmlVideoElementToRemoteTracks(
      participantId: string,
      videoElement: HTMLVideoElement,
      audioElement: HTMLAudioElement
    ): void;
    isUserTalking?: boolean;
    teamName?: string;
  }>
) {
  const {
    width,
    height,
    remoteParticipantsData,
    disableAudioAndVideo,
    isCurrentUserVideoMutedOrUnavailable,
    isCurrentUserAudioMutedOrUnavailable,
    attachHtmlVideoElementToLocalTracks,
    attachHtmlVideoElementToRemoteTracks,
    profile,
    isUserTalking,
    teamName,
  } = props;

  const videoRefCallback = useCallback(
    (ref: HTMLVideoElement | null) => {
      if (!ref) return;
      attachHtmlVideoElementToLocalTracks(ref);
    },
    [attachHtmlVideoElementToLocalTracks]
  );

  const showVideo = useMemo(() => {
    return !isCurrentUserVideoMutedOrUnavailable;
  }, [isCurrentUserVideoMutedOrUnavailable]);

  return (
    <div className={styles.container}>
      <div className={styles.topControls}>
        <span className="text small bold">{teamName || "Participants"}</span>
        {/* Temporary solution, until the feature is implemented */}
        {/* <span className="text small bold partial-underline">
          + Invite members
        </span> */}
      </div>

      <div className={styles.participantsListContainer}>
        <div
          className={styles.participantContainer}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          {!disableAudioAndVideo && isCurrentUserAudioMutedOrUnavailable && (
            <div className={styles.floatingIndicator}>
              <i className="icon fa fa-microphone-slash red" />
            </div>
          )}
          {!disableAudioAndVideo &&
            !isCurrentUserAudioMutedOrUnavailable &&
            isUserTalking && (
              <div className={styles.floatingIndicator}>
                <VoiceIndicator />
              </div>
            )}

          <Video
            participantId={profile.id}
            width={"100%"}
            height={height}
            noBorderRadius={true}
            isMuted={isCurrentUserVideoMutedOrUnavailable}
            showVideo={showVideo}
            ref={videoRefCallback}
            loaderTransitionInMilliseconds={100}
          />

          <div
            className={cn(
              styles.imageContainer,
              !isCurrentUserVideoMutedOrUnavailable && "hidden"
            )}
          >
            <div className={mutedVideoStyles.mutedVideoContainer}>
              <UserImage
                isPublic={false}
                profileId={profile.id}
                profileWorkspaceId={profile.workspace.workspace_id}
                alt="profile-image"
                fallbackFontAwesomeIconClass="icon fa fa-user"
              />
            </div>
          </div>
          <div className={cn(styles.participantName, "text", "tiny")}>
            {profile?.name}
          </div>
        </div>
        {remoteParticipantsData.map(
          ({
            participantId,
            name,
            isConnecting,
            isAudioMuted,
            isVideoMuted,
            isVideoEnabled,
            isTalking,
            profileId,
            workspaceId,
          }) => (
            <div
              key={`${participantId}-${name}`}
              className={styles.participantContainer}
              style={{ width: `${width}px`, height: `${height}px` }}
            >
              {!disableAudioAndVideo && isTalking && (
                <div className={styles.floatingIndicator}>
                  <VoiceIndicator />
                </div>
              )}
              {!disableAudioAndVideo && isAudioMuted && (
                <div className={styles.floatingIndicator}>
                  <i className="icon fa fa-microphone-slash red" />
                </div>
              )}
              <VideoWithBlur
                profileId={profileId!}
                workspaceId={workspaceId!}
                noBorderRadius={true}
                participantId={participantId}
                width={"100%"}
                height={height}
                isConnecting={isConnecting}
                isVideoMuted={!!isVideoMuted || !isVideoEnabled}
                disableAudioAndVideo={!!disableAudioAndVideo}
                attachHtmlVideoElementToTracks={
                  attachHtmlVideoElementToRemoteTracks
                }
              />
              <div className={cn(styles.participantName, "text", "tiny")}>
                {isConnecting ? "Connecting..." : name}
              </div>
            </div>
          )
        )}
      </div>
      {disableAudioAndVideo && (
        <div className={styles.individualSectionCoverContainer}>
          <h2>Individual Session</h2>
          <div className={styles.infoContainer}></div>
          <div className={styles.individualInfoBox}>
            <InfoBox
              description="You must complete this step alone."
              transparent
            />
          </div>
        </div>
      )}
    </div>
  );
});
