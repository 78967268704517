import { useEffect, useRef } from "react";
import { WorkshopClock, WorkshopClockEvent } from "../helpers/workshop-clock";
import { SERVER_TIME_UPDATE } from "../contexts/Apollo";
import { getServerTime } from "../helpers/fetch-server-time";

type UseInfiniteTimerProps = {
  isReadyToInitialize: boolean;
};

type UseInfiniteTimerPropsWithServerTimeEventTarget = UseInfiniteTimerProps & {
  serverTimeEventTarget: EventTarget;
};

type UseInfiniteTimerPropsWithServerTimeHandler = UseInfiniteTimerProps & {
  internalServerTimeFetching: true;
};

export const INFINITE_TIMER_TICK = "INFINITE_TIMER_TICK";

export function useInfiniteTimer(
  config:
    | UseInfiniteTimerPropsWithServerTimeEventTarget
    | UseInfiniteTimerPropsWithServerTimeHandler
) {
  const { isReadyToInitialize, serverTimeEventTarget } = {
    serverTimeEventTarget: null,
    ...config,
  };

  const workshopClockRef = useRef<WorkshopClock | null>(null);
  const currentServerTimeRef = useRef<number | null>(null);
  const tickEventTarget = useRef(new EventTarget());

  useEffect(() => {
    if (!isReadyToInitialize) return;

    const tickHandler = () =>
      tickEventTarget.current.dispatchEvent(
        new CustomEvent(INFINITE_TIMER_TICK, {
          detail: {
            currentServerTime: currentServerTimeRef.current,
            dispose: () => {
              workshopClockRef.current?.dispose();
            },
          },
        })
      );

    const attachListeners = () => {
      workshopClockRef.current?.addEventListener(
        WorkshopClockEvent.TICK,
        tickHandler
      );
    };

    const removeListeners = () => {
      workshopClockRef.current?.removeEventListener(
        WorkshopClockEvent.TICK,
        tickHandler
      );
    };

    const timeUpdateHandler = (event: Event) => {
      const currentServerTime = (event as any).detail as number;
      currentServerTimeRef.current = currentServerTime;
      if (workshopClockRef.current === null) {
        workshopClockRef.current = new WorkshopClock({
          durationInSeconds: Infinity,
        });
        attachListeners();
      }

      if (!workshopClockRef.current.isTicking)
        return void workshopClockRef.current.start(currentServerTime);
      if (
        workshopClockRef.current.workshopUnixEndTimestamp! < currentServerTime
      )
        return;
      workshopClockRef.current.adjustTime(currentServerTime);
    };

    serverTimeEventTarget?.addEventListener(
      SERVER_TIME_UPDATE,
      timeUpdateHandler
    );

    const intervalId = !serverTimeEventTarget
      ? setInterval(() => {
          getServerTime().then((serverTime) => {
            timeUpdateHandler(
              new CustomEvent(SERVER_TIME_UPDATE, { detail: serverTime })
            );
          });
        }, 5000)
      : null;

    return () => {
      removeListeners();
      if (intervalId) clearInterval(intervalId);
      serverTimeEventTarget?.removeEventListener(
        SERVER_TIME_UPDATE,
        timeUpdateHandler
      );
    };
  }, [serverTimeEventTarget, isReadyToInitialize]);

  return tickEventTarget.current;
}
