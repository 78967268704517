import { PropsWithChildren, memo, useMemo } from "react";

import cn from "classnames";

import styles from "./Assignment.module.css";
import ActionFooter from "../ActionFooter";
import { activityTypeFooterTextFactoryMap } from "../../../utils/footer";
import { ActivityCommon } from "../../../types/activity-common";
import { ActionFooterType } from "../../../types/action-footer";
import { FooterType } from "../../../types/enums/activity-footer";

interface AssignmentProps extends ActivityCommon {}
export default memo(function Assignment(
  props: PropsWithChildren<AssignmentProps>
) {
  const {
    activity,
    transition,
    isReady,
    currentActiveParticipantCount,
    notReadyProfilesCount,
    setActivityReadyHandler,
  } = props;
  const { assignment } = activity;

  const actionFooterData: ActionFooterType = useMemo(() => {
    if (transition > 0) {
      return {
        text: (
          <>
            Everyone is ready. Continuing forward in{" "}
            <span className="accent">{transition}...</span>
          </>
        ),
        buttonText: "",
        disabledButton: false,
        type: FooterType.Ready,
        isLoading: true,
      };
    }
    if (!isReady) {
      return {
        text: activityTypeFooterTextFactoryMap[activity.type](
          currentActiveParticipantCount - notReadyProfilesCount
        ),
        buttonText: "Continue",
        disabledButton: false,
        type: FooterType.Notice,
        isLoading: false,
      };
    }
    return {
      text: (
        <>
          Waiting for{" "}
          <span className="accent">
            {notReadyProfilesCount} more player
            {notReadyProfilesCount > 1 && "s"}...
          </span>
        </>
      ),
      buttonText: "",
      disabledButton: true,
      type: FooterType.Waiting,
      isLoading: false,
    };
  }, [
    transition,
    isReady,
    notReadyProfilesCount,
    activity.type,
    currentActiveParticipantCount,
  ]);

  return (
    <div key={activity.id} className="activity-container">
      <div className={cn(styles.container, "main-container")}>
        <div className={styles.iframeContainer}>
          <iframe
            title={activity.id + "-video"}
            src={assignment!.video}
          ></iframe>
        </div>
        <p className={cn(styles.infoText, "text")}>
          Your goal is to understand the topic rather than being able to
          reproduce the theory throughout the workshop. Learning will happen
          through discussion and persist until the end, aided by author’s
          explanations after each level
        </p>
      </div>
      <ActionFooter
        buttonText={actionFooterData.buttonText}
        type={actionFooterData.type}
        disabledButton={actionFooterData.disabledButton}
        buttonClickHandler={() =>
          setActivityReadyHandler({ activityId: activity.id })
        }
        isLoading={actionFooterData.isLoading}
      >
        {actionFooterData.text}
      </ActionFooter>
    </div>
  );
});
