import { PropsWithChildren, memo } from "react";

import Confetti from "react-confetti";

import styles from "./Congratulations.module.css";
import cn from "classnames";

const Congratulations = (
  props: PropsWithChildren<{
    congratulationImageSrc: string;
    showAnimation: boolean;
  }>
) => {
  const { congratulationImageSrc, showAnimation } = props;

  return (
    <div className={cn(styles.congratulationsContainer)}>
      {showAnimation && (
        <div className={styles.congratulationConfetti}>
          <Confetti
            tweenDuration={5000}
            recycle={false}
            numberOfPieces={1000}
          />
        </div>
      )}
      <div className={styles.congratulationsContent}>
        <div className="info-container">
          <h1>Congratulations!</h1>
          <div className="text-content">
            <p>
              <span>You and your team have</span>{" "}
              <span className="successfully">successfully</span>{" "}
              <span> completed the workshop.</span>
            </p>
          </div>
        </div>
        <div className="image-container">
          <img src={congratulationImageSrc} alt="Congratulations-img" />
        </div>
      </div>
    </div>
  );
};

export default memo(Congratulations);
