import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useMachine } from "@xstate/react";
import {
  AuthMachineContext,
  AuthState,
  authMachine,
  changePasswordMachine,
  resetPasswordMachine,
  updateProfileMachine,
} from "../+xstate/machines/auth";
import * as authActions from "../+xstate/actions/auth";
import { ApolloContext } from "./Apollo";
import { Entries, Writeable } from "../types/helpers";
import { authContextLocalStorageName } from "../constants/environment";
import { useChildMachine } from "../hooks/useChildMachine";
import { FetchState } from "../+xstate/machines/fetch-factory";

type AuthActions = typeof authActions;
type ActionDispatchers = {
  [K in keyof AuthActions]: (
    payload: ReturnType<AuthActions[K]>["payload"]
  ) => void;
};

export const GlobalContext = createContext<{
  instanceUUID: string;
  auth: {
    state: AuthState;
    context: AuthMachineContext;
    matches: ReturnType<typeof useMachine<typeof authMachine>>["0"]["matches"];
    resetPasswordState: FetchState;
    changePasswordState: FetchState;
    updateProfileState: FetchState;
  } & ActionDispatchers;
  page: {
    title: string | null;
    setTitle(title: string): void;
  };
}>({} as any);

const actionEntries = Object.entries(authActions) as Entries<
  typeof authActions
>;

export const GlobalContextProvider = (
  props: PropsWithChildren<{ instanceUUID: string }>
) => {
  const { instanceUUID } = props;
  const [pageTitle, setPageTitle] = useState<string | null>(null);
  const apolloContext = useContext(ApolloContext);
  const ctx = useMemo(() => {
    const snapshotString = localStorage.getItem(authContextLocalStorageName);
    if (!snapshotString) return null;
    try {
      return JSON.parse(snapshotString);
    } catch (e) {
      return {};
    }
  }, []);

  const [state, send, actor] = useMachine(authMachine, {
    input: {
      client: apolloContext.client,
      token: ctx?.token,
      profile: ctx?.profile,
      instanceUUID: props.instanceUUID,
    },
  });

  const [{ value: changePasswordState }] = useChildMachine(
    state,
    changePasswordMachine.id
  );
  const [{ value: resetPasswordState }] = useChildMachine(
    state,
    resetPasswordMachine.id
  );
  const [{ value: updateProfileState }] = useChildMachine(
    state,
    updateProfileMachine.id
  );

  const dispatchers = useMemo(
    () =>
      actionEntries.reduce((acc, [key, creator]) => {
        acc[key] = (payload) => {
          const action = creator(payload as any);
          send(action);
        };
        return acc;
      }, {} as Writeable<ActionDispatchers>),
    [send]
  );

  const value = useMemo(
    () => ({
      auth: {
        state: state.value as AuthState,
        context: state.context as AuthMachineContext,
        matches: (value: any) => state.matches(value),
        ...dispatchers,
        changePasswordState,
        resetPasswordState,
        updateProfileState,
      },
      page: {
        title: pageTitle,
        setTitle: setPageTitle,
      },
      instanceUUID,
    }),
    [
      dispatchers,
      instanceUUID,
      pageTitle,
      state,
      changePasswordState,
      resetPasswordState,
      updateProfileState,
    ]
  );

  useEffect(() => {
    apolloContext.setToken(state.context.token);
  }, [apolloContext, state.context.token]);

  useEffect(() => {
    actor.subscribe((snapshot) => {
      localStorage.setItem(
        authContextLocalStorageName,
        JSON.stringify({
          token: snapshot.context.token,
          profile: snapshot.context.token,
        })
      );
    });
  }, [actor]);

  return (
    <GlobalContext.Provider value={value}>
      {props.children}
    </GlobalContext.Provider>
  );
};
