import { gql } from "@apollo/client";
import { SessionStateResult } from "../types/results/session-state-result";
import { AppApolloClient } from "../../contexts/Apollo";

const query = gql`
  subscription Subscription($sessionId: String!, $connectionUUID: String!) {
    sessionState(sessionId: $sessionId, connectionUUID: $connectionUUID) {
      context {
        activityResult {
          key
          value {
            key
            value {
              profileId
              ready
              value
              disconnectTimestamp
              workshopTimeout
              activityTimeout
              activityPartTimeout
            }
          }
        }
        reconnectTimeouts {
          profileId
        }
        currentActiveProfiles {
          profileId
          uuid
        }
        startTimestamp
        lastUpdatedTimestamp
        lastActivityTimestamp
        lastActivityPartTimestamp
        readyActiveProfiles
        minimumWorkshopParticipants
        maximumWorkshopParticipants
      }
      value
      sessionKey
      sessionId
    }
  }
`;

export function openSessionStateSubscription(
  client: AppApolloClient,
  variables: { sessionId: string; connectionUUID: string }
) {
  return client.subscribe<SessionStateResult>({
    query,
    variables,
    fetchPolicy: "no-cache",
  });
}
