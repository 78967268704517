import {
  FormEvent,
  PropsWithChildren,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";

import { Cross2Icon } from "@radix-ui/react-icons";
import * as Dialog from "@radix-ui/react-dialog";
import * as Form from "@radix-ui/react-form";

import cn from "classnames";
import styles from "./ChangePasswordModal.module.css";
import { ProfileUpdate } from "../../../apollo-graphql/types/profile";

const ChangePasswordModal = (
  props: PropsWithChildren<{
    closeDialogHandler: () => void;
    updateProfile: (payload: { variables: ProfileUpdate }) => void;
    profileId: string;
    errorMessage: string | null;
  }>
) => {
  const { closeDialogHandler, profileId, updateProfile, errorMessage } = props;

  const [error, setError] = useState<string>("");
  const [
    { showCurrentPassword, showNewPassword, showRepeatPassword },
    setState,
  ] = useState({
    showCurrentPassword: false,
    showNewPassword: false,
    showRepeatPassword: false,
  });

  const onSubmitHandler = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      setError("");
      event.preventDefault();
      const payload = Object.fromEntries(new FormData(event.currentTarget)) as {
        currentPassword: string;
        newPassword: string;
        repeatPassword: string;
      };

      // Validations
      if (
        payload.currentPassword.length === 0 ||
        payload.newPassword.length === 0 ||
        payload.repeatPassword.length === 0 ||
        !profileId
      ) {
        setError("All fields are required!");
        return;
      }

      if (payload.newPassword !== payload.repeatPassword) {
        setError("Passwords do not match!");
        return;
      }

      updateProfile({
        variables: {
          id: profileId,
          currentPassword: payload.currentPassword,
          newPassword: payload.newPassword,
        },
      });
    },
    [profileId, updateProfile]
  );

  const dialogContent = useMemo(() => {
    return (
      <div className={styles.content}>
        <Form.Root className="form-container" onSubmit={onSubmitHandler}>
          <Form.Field name="currentPassword" className="form-row">
            <Form.Label className="label">Current password</Form.Label>
            <Form.Control
              className="FormControl"
              type={showCurrentPassword ? "text" : "password"}
            />
            <div
              className="icon-container"
              onClick={() =>
                setState((prev) => ({
                  ...prev,
                  showCurrentPassword: !prev.showCurrentPassword,
                }))
              }
            >
              <i
                className={`fa fa-eye${!showCurrentPassword ? "-slash" : ""}`}
              />
            </div>
          </Form.Field>
          <Form.Field name="newPassword" className="form-row">
            <Form.Label className="label">New Password</Form.Label>
            <Form.Control
              className="FormControl"
              type={showNewPassword ? "text" : "password"}
            />
            <div
              className="icon-container"
              onClick={() =>
                setState((prev) => ({
                  ...prev,
                  showNewPassword: !prev.showNewPassword,
                }))
              }
            >
              <i className={`fa fa-eye${!showNewPassword ? "-slash" : ""}`} />
            </div>
          </Form.Field>
          <Form.Field name="repeatPassword" className="form-row">
            <Form.Label className="label">Repeat password</Form.Label>
            <Form.Control
              className="FormControl"
              type={showRepeatPassword ? "text" : "password"}
            />
            <div
              className="icon-container"
              onClick={() =>
                setState((prev) => ({
                  ...prev,
                  showRepeatPassword: !prev.showRepeatPassword,
                }))
              }
            >
              <i
                className={`fa fa-eye${!showRepeatPassword ? "-slash" : ""}`}
              />
            </div>
          </Form.Field>

          <Form.Field name="actions" className="form-row actions">
            <Form.Submit className="btn save">
              Change
            </Form.Submit>
          </Form.Field>
          {(error || errorMessage) && <div>{error || errorMessage}</div>}
        </Form.Root>
      </div>
    );
  }, [
    showCurrentPassword,
    showNewPassword,
    showRepeatPassword,
    onSubmitHandler,
    error,
    errorMessage,
  ]);

  return (
    <Dialog.Root open={true} onOpenChange={closeDialogHandler}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content
          className={cn(styles.ChangePasswordModal, "DialogContent")}
        >
          <Dialog.Title className="DialogTitle">Change Password</Dialog.Title>
          {dialogContent}
          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close">
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default memo(ChangePasswordModal);
