import { useMachine, useSelector } from "@xstate/react";

export function useChildMachine<T extends string = string>(
  parentState: ReturnType<typeof useMachine>[0],
  machineId: T
): [
  Parameters<
    Parameters<typeof useSelector<(typeof parentState)["children"][T], void>>[1]
  >[0],
  (typeof parentState)["children"][T]["send"]
] {
  const childActor = parentState.children[machineId];
  return useSelector(childActor, (state) => {
    return [state, childActor?.send];
  });
}
