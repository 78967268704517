import { CSSProperties, FC, memo, useMemo } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";

type SortableItemProps = {
  id: string;
  isDraggable: boolean;
};

type IDragNDropItemProps = {
  attributes: DraggableAttributes;
  listeners: SyntheticListenerMap;
};

export type IWithSortableProps = IDragNDropItemProps &
  SortableItemProps & {
    setNodeRef: (node: HTMLElement | null) => void;
    style: CSSProperties;
    isDragging?: boolean;
  };

function withSortable<T extends IWithSortableProps & object>(
  Component: FC<T>
): FC<SortableItemProps & Omit<T, keyof IWithSortableProps>> {
  const SortableHoc: FC<
    SortableItemProps & Omit<T, keyof IWithSortableProps>
  > = ({ id, isDraggable, ...rest }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id });

    const style: CSSProperties = useMemo(
      () => ({
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0 : 1,
      }),
      [isDragging, transform, transition]
    );

    const props = useMemo(
      () => ({
        ...rest,
        attributes: isDraggable ? attributes : {},
        listeners: isDraggable ? listeners : {},
        setNodeRef,
        style,
        isDraggable,
      }),
      [attributes, isDraggable, listeners, rest, setNodeRef, style]
    ) as unknown as T;

    return <Component {...props} />;
  };

  SortableHoc.displayName = "SortableHoc";

  return memo(SortableHoc);
}

export default withSortable;
