import { PropsWithChildren, memo, useCallback, useMemo } from "react";
import { Activity } from "../../apollo-graphql/types/activity";
import { ActivityPart } from "../../types/enums/activity-part";
import { ActivityType } from "../../types/enums/activity-type";
import Timer from "../Shared/Timer/Timer";
import Theory from "./Activities/Theory";
import Question from "./Activities/Question";
import Assignment from "./Activities/Assignment";
import Benchmark from "./Activities/Benchmark";
import Action from "./Activities/Action";
import Moment from "./Activities/Moment/Moment";
import { SessionStateValue } from "../../apollo-graphql/types/session-state";
import Survey from "./Activities/Survey";
import Conceptualization from "./Activities/Conceptualization";
import { ActivityCommon } from "../../types/activity-common";
import { WorkshopAuthor } from "../../apollo-graphql/types/workshop";

import styles from "./Activity.module.css";

const activityTitleQuestionMap = {
  [ActivityPart.Individual]: "Make an individual choice",
  [ActivityPart.Group]: "Make a collective choice",
  [ActivityPart.Review]: "Review your choice",
};

export default memo(function WorkshopActivity(
  props: PropsWithChildren<{
    activity: Activity;
    profileId: string;
    workshopAuthor: WorkshopAuthor;
    currentActivityId: string;
    currentActivityPart: ActivityPart;
    isReady: boolean;
    transition: number;
    sessionState: SessionStateValue;
    notReadyProfilesCount: number;
    currentActiveParticipants: string[];
    currentActiveParticipantCount: number;
    currentActivityResultsForOtherProfiles: SessionStateValue["context"]["activityResult"][0]["value"];
    currentActivityGroupResults: SessionStateValue["context"]["activityResult"][0]["value"];
    workshopActivities: Activity[];
    isSettingValue?: boolean;
    teamName?: string;
    isViewResults?: boolean;
    showAnswersAsCorrect?: boolean;
    setActivityValueHandler: (args: {
      activityId: string;
      value: string;
    }) => void;
    setActivityReadyHandler: (args: { activityId: string }) => void;

  }>
) {
  const {
    isReady,
    profileId,
    workshopAuthor,
    activity,
    transition,
    sessionState,
    currentActivityId,
    currentActivityPart,
    notReadyProfilesCount,
    currentActiveParticipants,
    currentActiveParticipantCount,
    currentActivityResultsForOtherProfiles,
    currentActivityGroupResults,
    workshopActivities,
    setActivityValueHandler,
    setActivityReadyHandler,
    teamName,
    isSettingValue = false,
    isViewResults = false,
    showAnswersAsCorrect = false,
  } = props;

  const lastQuestionId = useMemo(() => {
    const questions = workshopActivities
      .filter((a) => a.type === ActivityType.Question)
      .sort((a, b) => Number(a.sequence_number) - Number(b.sequence_number));
    return questions[questions.length - 1].id;
  }, [workshopActivities]);

  const activityResult = useMemo(
    () => sessionState.context.activityResult,
    [sessionState.context.activityResult]
  );

  const currentActivityResult = useMemo(
    () => activityResult.find((a) => a.key === currentActivityId),
    [activityResult, currentActivityId]
  );

  const activityResultForCurrentProfile = useMemo(
    () =>
      currentActivityResult?.value
        ?.find((a) => a.key === currentActivityPart)
        ?.value.find((a) => a.profileId === profileId) || null,
    [currentActivityResult, currentActivityPart, profileId]
  );

  const individualActivityResultForCurrentProfile = useMemo(
    () =>
      currentActivityResult?.value
        ?.find((a) => a.key === ActivityPart.Individual)
        ?.value.find((a) => a.profileId === profileId) || null,
    [currentActivityResult, profileId]
  );

  const individualActivityResultForAllProfiles = useMemo(
    () =>
      currentActivityResult?.value?.find(
        (a) => a.key === ActivityPart.Individual
      )?.value || null,
    [currentActivityResult]
  );

  const getActivityResult = useCallback(
    (activityId: string, activityPart: ActivityPart) =>
      activityResult
        .find((a) => a.key === activityId)
        ?.value?.find((a) => a.key === activityPart) || null,
    [activityResult]
  );

  const individualActivityResults = useMemo(
    () =>
      currentActivityResult?.value?.find(
        (a) => a.key === ActivityPart.Individual
      ) || null,
    [currentActivityResult]
  );

  const conceptualizationResults:
    | SessionStateValue["context"]["activityResult"][0]
    | null = useMemo(() => {
    const { type, benchmark } = activity;
    if (type !== ActivityType.Benchmark || !benchmark) {
      return null;
    }
    const { conceptualization_id } = benchmark;
    return activityResult!.find((a) => a.key === conceptualization_id) || null;
  }, [activity, activityResult]);

  const activityTitle = useMemo(() => {
    if (
      activity.type !== ActivityType.Question &&
      activity.type !== ActivityType.Theory
    )
      return activity.description;

    if (activity.type === ActivityType.Question) {
      return `${activity.description.split(":")[0]}: ${
        activityTitleQuestionMap[currentActivityPart]
      }`;
    }

    if (activity.type === ActivityType.Theory) {
      return `${activity.description.split(":")[0]}: Review the theory`;
    }
  }, [activity.description, activity.type, currentActivityPart]);

  const activityNode = useMemo(() => {
    const commonProps: ActivityCommon = {
      transition,
      isSettingValue,
      isReady,
      currentActivityPart,
      currentActiveParticipantCount,
      notReadyProfilesCount,
      setActivityReadyHandler,
      activity,
    };

    if (activity.type === ActivityType.Moment)
      return (
        <Moment
          {...commonProps}
          currentActivityResult={currentActivityResult!}
          profileId={profileId}
          workshopAuthor={workshopAuthor}
          setActivityValueHandler={setActivityValueHandler}
        />
      );

    if (activity.type === ActivityType.Theory)
      return <Theory {...commonProps} isViewResults={isViewResults} />;
    if (activity.type === ActivityType.Question)
      return (
        <Question
          {...commonProps}
          currentActiveParticipants={currentActiveParticipants}
          activityResultForCurrentProfile={activityResultForCurrentProfile}
          individualActivityResultForCurrentProfile={
            individualActivityResultForCurrentProfile
          }
          currentActivityResultsForOtherProfiles={
            currentActivityResultsForOtherProfiles
          }
          currentActivityGroupResults={currentActivityGroupResults}
          setActivityValueHandler={setActivityValueHandler}
          isViewResults={isViewResults}
          teamName={teamName || ""}
          lastQuestionId={lastQuestionId}
          showAnswersAsCorrect={showAnswersAsCorrect}
        />
      );
    if (activity.type === ActivityType.Assignment)
      return <Assignment {...commonProps} />;
    if (activity.type === ActivityType.Benchmark)
      return (
        <Benchmark
          {...commonProps}
          profileId={profileId}
          conceptualizationResults={conceptualizationResults}
          currentActivityGroupResults={currentActivityGroupResults}
          activityResultForCurrentProfile={activityResultForCurrentProfile}
          individualActivityResults={individualActivityResults}
          setActivityValueHandler={setActivityValueHandler}
        />
      );
    if (activity.type === ActivityType.Conceptualization)
      return (
        <Conceptualization
          {...commonProps}
          activityResultForCurrentProfile={activityResultForCurrentProfile}
          individualActivityResultForCurrentProfile={
            individualActivityResultForCurrentProfile
          }
          setActivityValueHandler={setActivityValueHandler}
        />
      );
    if (activity.type === ActivityType.Survey)
      return (
        <Survey
          {...commonProps}
          setActivityValueHandler={setActivityValueHandler}
          activityResultForCurrentProfile={activityResultForCurrentProfile}
        />
      );
    if (activity.type === ActivityType.Action)
      return (
        <Action
          {...commonProps}
          currentActiveParticipants={currentActiveParticipants}
          individualActivityResultForAllProfiles={
            individualActivityResultForAllProfiles
          }
          individualActivityResultForCurrentProfile={
            individualActivityResultForCurrentProfile
          }
          currentActivityGroupResults={currentActivityGroupResults}
          currentActivityResultsForOtherProfiles={
            currentActivityResultsForOtherProfiles
          }
          activityResultForCurrentProfile={activityResultForCurrentProfile}
          getActivityResult={getActivityResult}
          workshopActivities={workshopActivities}
          setActivityValueHandler={setActivityValueHandler}
        />
      );
    return null;
  }, [
    transition,
    isSettingValue,
    isReady,
    currentActivityPart,
    currentActiveParticipantCount,
    notReadyProfilesCount,
    setActivityReadyHandler,
    activity,
    currentActivityResult,
    profileId,
    workshopAuthor,
    setActivityValueHandler,
    isViewResults,
    currentActiveParticipants,
    activityResultForCurrentProfile,
    individualActivityResultForCurrentProfile,
    currentActivityResultsForOtherProfiles,
    currentActivityGroupResults,
    teamName,
    lastQuestionId,
    showAnswersAsCorrect,
    conceptualizationResults,
    individualActivityResults,
    individualActivityResultForAllProfiles,
    getActivityResult,
    workshopActivities,
  ]);

  return (
    <div className={styles.container}>
      {/* TODO Put the title in a better place */} {/*  */}
      {!isViewResults && activity.type !== ActivityType.Moment && (
        <Timer title={activityTitle} />
      )}
      <div className={styles.activityContainer}>{activityNode}</div>
    </div>
  );
});
