import { PropsWithChildren, memo, useState, useMemo, useCallback } from "react";
import * as Collapsible from "@radix-ui/react-collapsible";
import cn from "classnames";

import styles from "./Action.module.css";
import InfoBox from "../../InfoBox/InfoBox";
import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons";
import { Recommendation } from "../../../apollo-graphql/types/recommendation";
import { SessionStateValue } from "../../../apollo-graphql/types/session-state";
import { ActivityCommon } from "../../../types/activity-common";
import { ActionFooterType } from "../../../types/action-footer";
import { ActivityPart } from "../../../types/enums/activity-part";
import { FooterType } from "../../../types/enums/activity-footer";
import ActionFooter from "../ActionFooter";
import { getFooterText } from "../../../utils/footer";
import { Activity } from "../../../apollo-graphql/types/activity";
import { ActivityType } from "../../../types/enums/activity-type";
import {
  calculateRelevanceScores,
  determineTeamStagesProbabilities,
} from "../../../utils/assess";

const NUMBER_OF_RECOMMENDATIONS_TO_PROPOSE = 2;

interface ActionProps extends ActivityCommon {
  currentActiveParticipants: string[];
  individualActivityResultForAllProfiles:
    | SessionStateValue["context"]["activityResult"][0]["value"][0]["value"]
    | null;
  individualActivityResultForCurrentProfile:
    | SessionStateValue["context"]["activityResult"][0]["value"][0]["value"][0]
    | null;
  activityResultForCurrentProfile:
    | SessionStateValue["context"]["activityResult"][0]["value"][0]["value"][0]
    | null;
  currentActivityGroupResults: SessionStateValue["context"]["activityResult"][0]["value"];
  currentActivityResultsForOtherProfiles: SessionStateValue["context"]["activityResult"][0]["value"];
  workshopActivities: Activity[];
  getActivityResult: (
    activityId: string,
    activityPart: ActivityPart
  ) => SessionStateValue["context"]["activityResult"][0]["value"][0] | null;
  setActivityValueHandler: (args: {
    activityId: string;
    value: string;
  }) => void;
}
export default memo(function Action(props: PropsWithChildren<ActionProps>) {
  const {
    isReady,
    activity,
    transition,
    workshopActivities,
    currentActivityPart,
    notReadyProfilesCount,
    currentActivityGroupResults,
    currentActiveParticipants,
    currentActiveParticipantCount,
    activityResultForCurrentProfile,
    currentActivityResultsForOtherProfiles,
    individualActivityResultForAllProfiles,
    individualActivityResultForCurrentProfile,
    getActivityResult,
    setActivityValueHandler,
    setActivityReadyHandler,
  } = props;

  const isTransitioning = useMemo(() => transition > 0, [transition]);

  const { recommendations, action } = activity;

  const sortedRecommendations = useMemo(
    () =>
      [...recommendations!].sort(
        (a, b) => a.sequence_number - b.sequence_number
      ),
    [recommendations]
  );
  const [openRecommendations, setOpenRecommendations] = useState<boolean[]>(
    () => new Array(recommendations!.length).fill(false)
  );
  const handleOpenChange = (open: boolean, indexOfTarget: number) => {
    setOpenRecommendations((prev) => [...prev].splice(indexOfTarget, 1, open));
  };

  function handleChoiceSelection(choiceSelected: Recommendation) {
    if (choiceSelected.id === activityResultForCurrentProfile?.value) {
      setActivityValueHandler({ activityId: activity.id, value: "" });
    } else {
      setActivityValueHandler({
        activityId: activity.id,
        value: choiceSelected.id,
      });
    }
  }
  const selectedRecommendation = useMemo(() => {
    return sortedRecommendations.find(
      ({ id }) => activityResultForCurrentProfile?.value === id
    );
  }, [activityResultForCurrentProfile?.value, sortedRecommendations]);

  // Voted recommendation (next action) from previous activity
  const recommendationVotesMap = useMemo(() => {
    if (currentActivityPart !== ActivityPart.Individual)
      return individualActivityResultForAllProfiles
        ?.map(({ value: recommendationId }) => recommendationId)
        .reduce<{ [recommendationId: string]: number }>(
          (mapAcc, recommendationId) => {
            if (!mapAcc[recommendationId]) {
              return {
                ...mapAcc,
                [recommendationId]: 1,
              };
            }

            const currentAccumulatedVotes = mapAcc[recommendationId];
            return {
              ...mapAcc,
              [recommendationId]: currentAccumulatedVotes + 1,
            };
          },
          {}
        );
  }, [currentActivityPart, individualActivityResultForAllProfiles]);

  const getSurveyActivityData = useCallback(() => {
    const surveyActivities = workshopActivities.filter(
      ({ type }) => type === ActivityType.Survey
    );
    if (surveyActivities.length > 1)
      throw new Error(
        "More than one Survey activity is currently not supported."
      );
    return surveyActivities[0];
  }, [workshopActivities]);

  const extractParticipantsSurveyResults = (
    surveyResults: SessionStateValue["context"]["activityResult"][0]["value"][0]
  ) =>
    surveyResults &&
    surveyResults.value
      .map(({ value }) => {
        try {
          return JSON.parse(value) as (number | null)[];
        } catch (err) {
          return [];
        }
      })
      .filter((d) => d);

  const transformToValidParticipantSurveyResults = (
    participantsSurveyResults: (number | null)[][],
    surveyItemsCount: number
  ) =>
    participantsSurveyResults?.map((psr) =>
      new Array(surveyItemsCount)
        .fill(null)
        .map((_, i) => (psr[i] ? psr[i] : null))
    );

  const generatedRecommendationIds = useMemo(() => {
    const surveyActivity = getSurveyActivityData();
    const surveyItemsCount = surveyActivity?.items?.length;
    const surveyResults = getActivityResult(
      surveyActivity.id,
      ActivityPart.Individual
    );
    const transformedParticipantsSurveyResults =
      surveyResults &&
      surveyItemsCount &&
      transformToValidParticipantSurveyResults(
        extractParticipantsSurveyResults(surveyResults),
        surveyItemsCount
      );

    if (transformedParticipantsSurveyResults) {
      const teamStageProbabilities = determineTeamStagesProbabilities(
        transformedParticipantsSurveyResults
      );
      const recommendationRelevanceScores = calculateRelevanceScores(
        transformedParticipantsSurveyResults,
        teamStageProbabilities
      );

      const highestRelevantIndices = recommendationRelevanceScores
        .map((_, i) => i)
        .sort(
          (a, b) =>
            recommendationRelevanceScores[b] - recommendationRelevanceScores[a]
        )
        .slice(0, NUMBER_OF_RECOMMENDATIONS_TO_PROPOSE);

      return highestRelevantIndices.map(
        (i) => sortedRecommendations[i] && sortedRecommendations[i].id
      );
    }
  }, [getActivityResult, getSurveyActivityData, sortedRecommendations]);

  const isRecommendationVoted = useCallback(
    (recommendation: Recommendation) =>
      recommendationVotesMap && !!recommendationVotesMap[recommendation.id],
    [recommendationVotesMap]
  );

  const isRecommendedByAhaPlay = useCallback(
    (recommendation: Recommendation) =>
      generatedRecommendationIds
        ? generatedRecommendationIds.includes(recommendation.id)
        : false,
    [generatedRecommendationIds]
  );

  const getVotesForRecommendation = useCallback(
    (recommendation: Recommendation) =>
      recommendationVotesMap?.[recommendation.id] || 0,
    [recommendationVotesMap]
  );

  const groupRecommendations = useMemo(() => {
    if (!recommendationVotesMap && !generatedRecommendationIds) {
      throw new Error(
        "Could not create group recommendations. Group did not vote for any recommendation and there were no generated recommendations by Ahaplay."
      );
    }

    const votedRecommendations = sortedRecommendations.filter(
      isRecommendationVoted
    );
    const recommendedByAhaPlayRecommendations = sortedRecommendations.filter(
      isRecommendedByAhaPlay
    );

    const votedAndRecommendedByAhaPlayRecommendations = Array.from(
      new Set([...votedRecommendations, ...recommendedByAhaPlayRecommendations])
    );

    const groupRecommendations = [
      ...votedAndRecommendedByAhaPlayRecommendations,
    ].sort(
      (r1, r2) => getVotesForRecommendation(r2) - getVotesForRecommendation(r1)
    );

    return groupRecommendations;
  }, [
    generatedRecommendationIds,
    getVotesForRecommendation,
    isRecommendationVoted,
    isRecommendedByAhaPlay,
    recommendationVotesMap,
    sortedRecommendations,
  ]);

  const buildGroupRecommendationText = (recommendation: Recommendation) =>
    `(✋ ${getVotesForRecommendation(recommendation)} Votes ${
      isRecommendedByAhaPlay(recommendation)
        ? ` | 📌 AhaPlay’s Recommendation `
        : ""
    })`;

  const recommendationIds = useMemo(
    () =>
      currentActivityGroupResults[0]?.value
        .filter(({ profileId }) =>
          currentActiveParticipants.includes(profileId)
        )
        .map(({ value }) => value),
    [currentActiveParticipants, currentActivityGroupResults]
  );
  const groupIsAligned = useMemo(
    () =>
      recommendationIds?.every(
        (id) => id === recommendationIds[0] && id !== ""
      ),
    [recommendationIds]
  );

  const actionFooterData: ActionFooterType = useMemo(() => {
    if (isTransitioning) {
      return {
        text: (
          <>
            Everyone is ready. Continuing forward in{" "}
            <span className="accent">{transition}...</span>
          </>
        ),
        buttonText: "",
        disabledButton: false,
        type: FooterType.Ready,
        isLoading: true,
      };
    }

    if (!isReady) {
      let text = getFooterText(
        ActivityType.Action,
        currentActivityPart,
        currentActiveParticipantCount - notReadyProfilesCount
      );
      let disabledButton = false;
      let buttonText = "Continue";

      if (currentActivityPart === ActivityPart.Individual) {
        const playerHasAnswered =
          !!individualActivityResultForCurrentProfile?.value;
        disabledButton = !playerHasAnswered;
      }

      if (currentActivityPart === ActivityPart.Group) {
        disabledButton = !groupIsAligned;
        text = (
          <>
            {groupIsAligned
              ? getFooterText(
                  ActivityType.Action,
                  currentActivityPart,
                  currentActiveParticipantCount - notReadyProfilesCount
                )
              : "You have to match your opinions to continue."}
          </>
        );
      }

      return {
        buttonText,
        disabledButton,
        text,
        type: FooterType.Notice,
        isLoading: false,
      };
    }

    return {
      text: (
        <>
          Waiting for{" "}
          <span className="accent">
            {notReadyProfilesCount} more player
            {notReadyProfilesCount > 1 && "s"}...
          </span>
        </>
      ),
      buttonText: "",
      disabledButton: true,
      type: FooterType.Waiting,
      isLoading: false,
    };
  }, [
    isTransitioning,
    isReady,
    notReadyProfilesCount,
    transition,
    currentActivityPart,
    currentActiveParticipantCount,
    individualActivityResultForCurrentProfile?.value,
    groupIsAligned,
  ]);

  // TODO: This is taken from Question.tsx -  lift up and reuse
  const getGroupProfileIdsSelectedRecommendation = useCallback(
    (id: string) => {
      if (currentActivityPart !== ActivityPart.Group) {
        return [];
      }
      const recommendationData =
        currentActivityResultsForOtherProfiles?.[0]?.value;

      return recommendationData
        ? recommendationData
            .filter(({ value }) => value === id)
            .map(({ profileId }) => profileId)
        : [];
    },
    [currentActivityResultsForOtherProfiles, currentActivityPart]
  );

  return (
    <div key={activity.id} className="activity-container">
      <div className={cn(styles.container, "main-container")}>
        <div className={styles.infoContainer}>
          <p className="text bold">{currentActivityPart}</p>
          <p className="text">{action?.text}</p>
          <InfoBox
            title="Some questions are purposely ambiguous!"
            description="You're not allowed to change these restrictions. It's either due to the restrictions on the page, or permission settings for this space."
            isDismissible
          />
        </div>
        <div className={styles.choicesContainer}>
          {ActivityPart.Individual === currentActivityPart &&
            sortedRecommendations.map((r, i) => (
              <Collapsible.Root
                className={styles.collapsibleRoot}
                open={openRecommendations[i]}
                onOpenChange={(o) => handleOpenChange(o, i)}
              >
                <Collapsible.Trigger
                  className={cn(
                    styles.choice,
                    selectedRecommendation?.id === r.id ? "selected" : null
                  )}
                >
                  <div className={styles.triggerContainer}>
                    <span className={styles.choiceSelectorContainer}>
                      <span className={styles.choiceSelectorContainer}>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            if (isTransitioning) {
                              return;
                            }
                            handleChoiceSelection(r);
                          }}
                          className={cn(
                            styles.choiceSelector,
                            isTransitioning && "disabled"
                          )}
                        >
                          {" "}
                          {selectedRecommendation?.id === r.id && (
                            <CheckIcon className={styles.checkCircleIcon} />
                          )}{" "}
                        </span>
                      </span>
                      <p className="text bold">
                        {r.sequence_number}. {r.text}
                      </p>
                    </span>
                    <ChevronDownIcon className={styles.collapsibleIcon} />
                  </div>
                  <Collapsible.Content
                    className={styles.collapsibleContentContainer}
                  >
                    <div className={styles.collapsibleContent}>
                      <p>{r.details}</p>
                    </div>
                  </Collapsible.Content>
                </Collapsible.Trigger>
              </Collapsible.Root>
            ))}

          {ActivityPart.Group === currentActivityPart &&
            groupRecommendations.map((r, i) => {
              const profilesSelectedRecommendation =
                getGroupProfileIdsSelectedRecommendation(r.id);

              return (
                <Collapsible.Root
                  className={styles.collapsibleRoot}
                  open={openRecommendations[i]}
                  onOpenChange={(o) => handleOpenChange(o, i)}
                >
                  <Collapsible.Trigger
                    className={cn(
                      styles.choice,
                      selectedRecommendation?.id === r.id ? "selected" : null,
                      profilesSelectedRecommendation.length > 0
                        ? "group-selected"
                        : ""
                    )}
                  >
                    <div className={styles.triggerContainer}>
                      <span className={styles.choiceSelectorContainer}>
                        <span className={styles.choiceSelectorContainer}>
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              if (isTransitioning) {
                                return;
                              }
                              handleChoiceSelection(r);
                            }}
                            className={cn(
                              styles.choiceSelector,
                              isTransitioning && "disabled"
                            )}
                          >
                            {" "}
                            {selectedRecommendation?.id === r.id && (
                              <CheckIcon className={styles.checkCircleIcon} />
                            )}{" "}
                          </span>
                        </span>
                        <p className="text bold">
                          {r.sequence_number}. {r.text}{" "}
                          {buildGroupRecommendationText(r)}{" "}
                          {profilesSelectedRecommendation.length > 0 && (
                            <i
                              className={cn(
                                "fa icon",
                                groupIsAligned ? "fa-users" : "fa-user"
                              )}
                            />
                          )}
                        </p>
                      </span>
                      <ChevronDownIcon className={styles.collapsibleIcon} />
                    </div>
                    <Collapsible.Content
                      className={styles.collapsibleContentContainer}
                    >
                      <div className={styles.collapsibleContent}>
                        <p>{r.details}</p>
                      </div>
                    </Collapsible.Content>
                  </Collapsible.Trigger>
                </Collapsible.Root>
              );
            })}
          <hr />
        </div>
      </div>

      <ActionFooter
        buttonText={actionFooterData.buttonText}
        type={actionFooterData.type}
        disabledButton={actionFooterData.disabledButton}
        buttonClickHandler={() =>
          setActivityReadyHandler({ activityId: activity.id })
        }
        isLoading={actionFooterData.isLoading}
      >
        {actionFooterData.text}
      </ActionFooter>
    </div>
  );
});
