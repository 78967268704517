import { ProfileUpdate } from "../../apollo-graphql/types/profile";
import { IChangePassword } from "../../apollo-graphql/types/change-password";
import { IResetPassword } from "../../apollo-graphql/types/reset-password";
import { ILogin } from "../../apollo-graphql/types/login";
import { createAction, props } from "../utils";

export const login = createAction(
  "[AUTH] Login",
  props<{
    variables: ILogin;
  }>()
);

export const resetPassword = createAction(
  "[AUTH] Reset Password",
  props<{
    variables: IResetPassword;
  }>()
);

export const changePassword = createAction(
  "[AUTH] Change Password",
  props<{
    variables: IChangePassword;
  }>()
);

export const logout = createAction("[AUTH] Logout");

export const updateProfile = createAction(
  "[AUTH] Update Profile",
  props<{ variables: ProfileUpdate }>()
);

export const updateProfileDialog = createAction(
  "[AUTH] Update Profile Dialog",
  props<
    | {
        open: true;
        type: "profile" | "password";
      }
    | { open: false }
  >()
);

export const uploadProfileImage = createAction(
  "[AUTH] Get Presigned Image Url",
  props<{ file: File }>()
);

export const deleteProfileImage = createAction("[AUTH] Delete Profile Image");
