import { PropsWithChildren, forwardRef, memo, useMemo, useState } from "react";

import SidePanel from "../../SidePanel/SidePanel";
import { Concept } from "../../../apollo-graphql/types/concept";
import { ActivityType } from "../../../types/enums/activity-type";
import { Workshop } from "../../../apollo-graphql/types/workshop";
import WorkshopActivity from "../Activity";
import {
  IActivityResult,
  SessionStateValue,
} from "../../../apollo-graphql/types/session-state";
import { ActivityPart } from "../../../types/enums/activity-part";
import { Activity } from "../../../apollo-graphql/types/activity";

import styles from "./WorkshopReview.module.css";
import cn from "classnames";

const WorkshopReview = forwardRef<
  HTMLDivElement | null,
  PropsWithChildren<{
    workshop: Workshop;
    activityResult: IActivityResult[];
    activities: Activity[];
    profileId: string;
    sessionState: SessionStateValue;
    wrongAnswerIds: string[];
  }>
>(
  (
    {
      workshop,
      activityResult,
      activities,
      profileId,
      sessionState,
      wrongAnswerIds,
    },
    ref
  ) => {
    const [selectedActivityId, setActivityId] = useState(
      activities.find((a) => a.type === ActivityType.Theory)?.id || ""
    );

    const theoryConcepts: Concept[] = useMemo(
      () =>
        workshop?.activities?.find((a) => a.type === ActivityType.Theory)
          ?.concepts || [],
      [workshop?.activities]
    );

    const activitiesContent = useMemo(() => {
      return activities
        .filter((a) => a.type !== ActivityType.Moment)
        .map((activity, idx) => {
          let isWrongAnswer = false;

          const groupResultForCurrentProfile = activityResult
            .find(({ key }) => key === activity.id)
            ?.value?.find(({ key }) => key === ActivityPart.Group)?.value?.[0];

          if (groupResultForCurrentProfile) {
            isWrongAnswer = wrongAnswerIds.includes(
              groupResultForCurrentProfile.value
            );
          }

          return {
            value: activity.id,
            label: activity.type === ActivityType.Theory ? "Assignment" : idx,
            isWrongAnswer,
          };
        })
        .map(({ value, label, isWrongAnswer }) => (
          <button
            key={value}
            className={cn(
              styles.activity,
              "btn",
              "primary",
              value === selectedActivityId ? styles.active : "",
              isWrongAnswer ? styles.wrongAnswer : ""
            )}
            onClick={() => setActivityId(value)}
          >
            {label}
            {isWrongAnswer && <i className="fa-solid fa-circle-info"></i>}
          </button>
        ));
    }, [activities, activityResult, selectedActivityId, wrongAnswerIds]);

    const currentActivityResultsForOtherProfiles = useMemo(
      () =>
        activityResult
          .find((a) => a.key === selectedActivityId)
          ?.value?.filter((a) => a.key === ActivityPart.Individual)
          .filter((a) => a.value.find((v) => v.profileId !== profileId)) || [],
      [activityResult, profileId, selectedActivityId]
    );

    const currentActivityGroupResults = useMemo(() => {
      return (
        activityResult
          .find((a) => a.key === selectedActivityId)
          ?.value?.filter((a) => a.key === "group") || []
      );
    }, [activityResult, selectedActivityId]);

    const currentActiveParticipants = useMemo(
      () =>
        sessionState.context.currentActiveProfiles.map(
          ({ profileId }) => profileId
        ),
      [sessionState.context.currentActiveProfiles]
    );

    const currentActiveParticipantCount = useMemo(
      () => currentActiveParticipants.length,
      [currentActiveParticipants.length]
    );

    const workshopReviewContent = useMemo(() => {
      const currentActivity = activities.find(
        ({ id }) => id === selectedActivityId
      );

      if (currentActivity) {
        return (
          <>
            <WorkshopActivity
              profileId={profileId}
              workshopAuthor={workshop.author}
              isReady={false}
              transition={0}
              activity={currentActivity}
              sessionState={sessionState}
              currentActivityId={selectedActivityId}
              currentActivityPart={ActivityPart.Review}
              notReadyProfilesCount={0}
              currentActiveParticipantCount={currentActiveParticipantCount}
              currentActiveParticipants={currentActiveParticipants}
              currentActivityResultsForOtherProfiles={
                currentActivityResultsForOtherProfiles
              }
              currentActivityGroupResults={currentActivityGroupResults}
              workshopActivities={activities}
              setActivityValueHandler={() => {}}
              setActivityReadyHandler={() => {}}
              isViewResults={true}
              showAnswersAsCorrect
            />
            {currentActivity.type === ActivityType.Theory && (
              <SidePanel
                containerClass="side-panel-final-screen"
                theoryConcepts={theoryConcepts}
                hideTabSwitched={true}
                hideControlPanel={true}
              />
            )}
          </>
        );
      }

      return null;
    }, [
      activities,
      selectedActivityId,
      profileId,
      workshop.author,
      sessionState,
      currentActiveParticipantCount,
      currentActiveParticipants,
      currentActivityResultsForOtherProfiles,
      currentActivityGroupResults,
      theoryConcepts,
    ]);

    return (
      <div ref={ref} className={styles.workshopReviewContainer}>
        <div className={styles.headerContainer}>
          <h1>Workshop Review</h1>
        </div>
        <div className={styles.scrollableContainer}>
          <div className={styles.scrollableInnerContainer}>
            <div className={styles.activitiesContainer}>
              {activitiesContent}
            </div>
            <div className={styles.workshopReviewContent}>
              {workshopReviewContent}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default memo(WorkshopReview);
