import { useMachine } from "@xstate/react";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useMemo,
} from "react";
import { jitsiMachine } from "../+xstate/machines/jitsi";
import * as jitsiActions from "../+xstate/actions/jitsi";

type StateType = ReturnType<typeof useMachine<typeof jitsiMachine>>["0"];

type AllActions = typeof jitsiActions;
// NoPermissions is not used at the moment, figure out how to do it !!!
type AllowedActionCreators = Pick<
  AllActions,
  | "initialize"
  | "configure"
  | "joinConference"
  | "done"
  | "reset"
  | "handleUserNameChange"
  | "handleActivityPartChange"
>;

type AllowedActionDispatchers = {
  [K in keyof AllowedActionCreators]: (
    payload: ReturnType<AllowedActionCreators[K]>["payload"]
  ) => void;
};

type JitsiContextValue = {
  state: StateType;
  attachHtmlVideoElementToRemoteTracks: (
    participantId: string,
    htmlVideoElement: HTMLVideoElement,
    htmlAudioElement: HTMLAudioElement
  ) => void;
  attachHtmlVideoElementToLocalTracks: (
    htmlVideoElement: HTMLVideoElement
  ) => void;
} & AllowedActionDispatchers;

export const JitsiContext = createContext<JitsiContextValue>({} as any);

export const JitsiContextProvider = (props: PropsWithChildren) => {
  const [state, send] = useMachine(jitsiMachine);

  const jitsiInstance = useMemo(() => {
    return state.context.jitsiInstance;
  }, [state.context.jitsiInstance]);

  const attachHtmlVideoElementToRemoteTracks = useCallback(
    (
      participantId: string,
      htmlVideoElement: HTMLVideoElement,
      htmlAudioElement: HTMLAudioElement
    ) => {
      if (!jitsiInstance) return;
      jitsiInstance.attachVideoElementToRemoteTracks(
        participantId,
        htmlVideoElement,
        htmlAudioElement
      );
    },
    [jitsiInstance]
  );

  const attachHtmlVideoElementToLocalTracks = useCallback(
    (htmlVideoElement: HTMLVideoElement) => {
      if (!jitsiInstance) return;
      jitsiInstance.attachLocalVideoElement(htmlVideoElement);
    },
    [jitsiInstance]
  );

  const allowedComponentActionDispatchers = useMemo(
    () => ({
      initialize: (
        payload: Parameters<AllowedActionDispatchers["initialize"]>[0]
      ) => {
        send(jitsiActions.initialize(payload));
      },
      done: (payload: Parameters<AllowedActionDispatchers["done"]>[0]) => {
        send(jitsiActions.done(payload));
      },
      configure: (
        payload: Parameters<AllowedActionDispatchers["configure"]>[0]
      ) => {
        send(jitsiActions.configure(payload));
      },
      joinConference: (
        payload: Parameters<AllowedActionDispatchers["joinConference"]>[0]
      ) => {
        send(jitsiActions.joinConference(payload));
      },
      reset: (payload: Parameters<AllowedActionDispatchers["reset"]>[0]) => {
        send(jitsiActions.reset(payload));
      },
      handleUserNameChange: (
        payload: Parameters<AllowedActionDispatchers["handleUserNameChange"]>[0]
      ) => {
        send(jitsiActions.handleUserNameChange(payload));
      },
      handleActivityPartChange: (
        payload: Parameters<AllowedActionDispatchers["handleActivityPartChange"]>[0]
      ) => {
        send(jitsiActions.handleActivityPartChange(payload));
      },
    }),
    [send]
  );

  const value = useMemo(() => {
    return {
      state,
      attachHtmlVideoElementToRemoteTracks,
      attachHtmlVideoElementToLocalTracks,
      ...allowedComponentActionDispatchers,
    } as JitsiContextValue;
  }, [
    state,
    attachHtmlVideoElementToRemoteTracks,
    attachHtmlVideoElementToLocalTracks,
    allowedComponentActionDispatchers,
  ]);

  return (
    <JitsiContext.Provider value={value}>
      {props.children}
    </JitsiContext.Provider>
  );
};
