import { SessionStateValue } from "../apollo-graphql/types/session-state";
import { parseToJson } from "./parse-to-json";

export const parseSession = (state: string) => {
  const { context, value } = parseToJson<
    SessionStateValue | { context: undefined; value: undefined }
  >(state, { context: undefined, value: undefined });

  return {
    value,
    context: {
      ...(context || {}),
      activityResult: Object.entries(context?.activityResult || {}).map(
        ([key, value]) => ({
          key,
          value: Object.entries(value).map(([key, value]) => ({
            key,
            value,
          })),
        })
      ),
    },
  } as SessionStateValue;
};
