import {
  FormEvent,
  PropsWithChildren,
  memo,
  useCallback,
  useMemo,
} from "react";

import { v1 } from "uuid";

import { parseToJson } from "../../../../utils";
import Congratulations from "../../Congratulations/Congratulations";
import ActionFooter from "../../ActionFooter";
import Timer from "../../../Shared/Timer/Timer";
import LoadingButton from "../../../Shared/LoadingButton/LoadingButton";
import InfoBox from "../../../InfoBox/InfoBox";
import MomentSectionList from "./components/MomentSectionList/MomentSectionList";
import MomentCard from "./components/MomentCard/MomentCard";

import { ActionFooterType } from "../../../../types/action-footer";
import { FooterType } from "../../../../types/enums/activity-footer";
import { ActivityCommon } from "../../../../types/activity-common";
import { IActivityResult } from "../../../../apollo-graphql/types/session-state";
import { ActivityPart } from "../../../../types/enums/activity-part";
import { WorkshopAuthor } from "../../../../apollo-graphql/types/workshop";

import cn from "classnames";
import styles from "./Moment.module.css";

interface IMoment {
  id: string;
  profileId: string;
  text: string;
}

interface MomentProps extends ActivityCommon {
  currentActivityResult: IActivityResult;
  profileId: string;
  workshopAuthor: WorkshopAuthor;
  setActivityValueHandler: (args: {
    activityId: string;
    value: string;
  }) => void;
}

const Moment = (props: PropsWithChildren<MomentProps>) => {
  const {
    activity,
    currentActivityResult,
    profileId,
    workshopAuthor,
    isReady,
    transition,
    isSettingValue,
    notReadyProfilesCount,
    currentActivityPart,
    currentActiveParticipantCount,
    setActivityValueHandler,
    setActivityReadyHandler,
  } = props;

  const isTransitioning = useMemo(() => transition > 0, [transition]);

  const { currentProfileMoments, teamMoments } = useMemo(() => {
    const individualResults =
      currentActivityResult?.value?.find(
        (v) => v.key === ActivityPart.Individual
      )?.value || [];

    const currentProfileMoments = parseToJson<{
      moments: IMoment[];
    }>(individualResults.find((r) => r.profileId === profileId)?.value || "", {
      moments: [],
    }).moments;

    const teamMoments =
      individualResults
        .filter((r) => r.profileId !== profileId)
        ?.flatMap((r) => {
          return parseToJson<{
            moments: IMoment[];
          }>(r.value, { moments: [] }).moments;
        }) || [];

    return { currentProfileMoments, teamMoments };
  }, [currentActivityResult?.value, profileId]);

  const allMoments = useMemo(
    () =>
      currentProfileMoments
        .concat(teamMoments)
        .sort((a, b) => (a.id > b.id ? -1 : 1)),
    [currentProfileMoments, teamMoments]
  );

  const authorMoments = useMemo(() => {
    return (activity?.moment?.momentItems || []).map(({ id, text }) => ({
      id,
      text,
      profileId: workshopAuthor.id,
    }));
  }, [activity?.moment?.momentItems, workshopAuthor.id]);

  const onSubmitHandler = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const payload = Object.fromEntries(new FormData(event.currentTarget)) as {
        text: string;
      };
      if (!payload.text) return;

      const newMoment: IMoment = {
        id: v1({ msecs: new Date().getTime() }),
        profileId,
        text: payload.text,
      };

      setActivityValueHandler({
        activityId: activity.id,
        value: JSON.stringify({
          moments: [newMoment, ...currentProfileMoments],
        }),
      });

      event.currentTarget.reset();
    },
    [activity.id, currentProfileMoments, profileId, setActivityValueHandler]
  );

  const actionFooterData: ActionFooterType = useMemo(() => {
    if (isTransitioning) {
      return {
        text: (
          <>
            Everyone is ready. Continuing forward in{" "}
            <span className="accent">{transition}...</span>
          </>
        ),
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Ready,
      };
    }

    if (isReady) {
      return {
        text: (
          <>
            Waiting for{" "}
            <span className="accent">
              {notReadyProfilesCount} more player
              {notReadyProfilesCount > 1 && "s"}...
            </span>
          </>
        ),
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Waiting,
      };
    }

    const playersClicked =
      currentActiveParticipantCount - notReadyProfilesCount;

    return {
      text: (
        <>
          When ready click Continue to proceed forward.{" "}
          {playersClicked > 0 && (
            <span className="accent">
              {playersClicked} player{playersClicked > 1 && "s"} clicked.
            </span>
          )}
        </>
      ),
      buttonText: "Continue",
      disabledButton: false,
      type: FooterType.Notice,
    };
  }, [
    isTransitioning,
    isReady,
    transition,
    notReadyProfilesCount,
    currentActiveParticipantCount,
  ]);

  const setGroupValueHandler = useCallback(
    (value: string) => {
      setActivityValueHandler({
        activityId: activity.id,
        value,
      });
    },
    [activity.id, setActivityValueHandler]
  );

  const activityTitle = useMemo(
    () =>
      currentActivityPart === ActivityPart.Individual
        ? "Share your individual “Aha Moments” with your team:"
        : "Did you also experience the author’s “Aha Moments”?",
    [currentActivityPart]
  );

  const individualPartContent = useMemo(
    () => (
      <>
        <form
          className={styles.shareMomentContainer}
          onSubmit={onSubmitHandler}
        >
          <p className="text">
            Share your individual “Aha Moments”{" "}
            <span className="one-by-one">one by one</span>:
          </p>
          <textarea
            className="share-input"
            name="text"
            placeholder="Your “Aha Moment” here..."
          />
          <div className={styles.shareButtonContainer}>
            <LoadingButton
              size="small"
              iconClass="fa fa-right-left"
              type="submit"
              disabled={isTransitioning || isSettingValue}
              isLoading={isSettingValue}
              delayInMilliseconds={1000}
            >
              Share
            </LoadingButton>
          </div>
        </form>
        <div className={styles.sharedMomentListContainer}>
          <p className="text">Shared “Aha Moments”: (Visible for everyone)</p>
          <div className={styles.moments}>
            {allMoments.length === 0 ? (
              <InfoBox
                title="No “Aha Moments” were shared yet!"
                description="All shared “Aha Moments” will be listed here."
              />
            ) : (
              allMoments.map((item, index) => {
                return (
                  <MomentCard
                    index={index}
                    id={item.id}
                    item={item}
                    isDraggable={false}
                    currentProfileId={profileId}
                    isTransitioning={isTransitioning}
                    workshopAuthor={workshopAuthor}
                    key={item.id}
                  />
                );
              })
            )}
          </div>
        </div>
      </>
    ),
    [
      allMoments,
      isSettingValue,
      isTransitioning,
      onSubmitHandler,
      profileId,
      workshopAuthor,
    ]
  );

  const groupPartContent = useMemo(() => {
    return (
      <>
        <p>
          <span className="text bold">
            If you have also experienced the “Aha Moments” designed by{" "}
            {workshopAuthor.name}
          </span>{" "}
          add them to your list by clicking or dragging them.
        </p>
        <MomentSectionList
          workshopAuthor={workshopAuthor}
          authorMoments={authorMoments}
          teamMoments={allMoments}
          currentProfileId={profileId}
          isTransitioning={isTransitioning}
          setGroupValueHandler={setGroupValueHandler}
        />
      </>
    );
  }, [
    workshopAuthor,
    authorMoments,
    allMoments,
    profileId,
    isTransitioning,
    setGroupValueHandler,
  ]);

  const content = useMemo(() => {
    return (
      <>
        <Timer title={activityTitle} isAhaMomentTimer />
        <div
          className={cn(
            styles.momentContainer,
            currentActivityPart === ActivityPart.Individual
              ? "individual"
              : "group"
          )}
        >
          {currentActivityPart === ActivityPart.Individual
            ? individualPartContent
            : groupPartContent}
        </div>
      </>
    );
  }, [
    currentActivityPart,
    groupPartContent,
    individualPartContent,
    activityTitle,
  ]);

  return (
    <div className={cn(styles.activityContainer, "activity-container")}>
      <div className={cn(styles.container, "main-container")}>
        <Congratulations
          congratulationImageSrc="/images/congratulations_aha_moments.svg"
          showAnimation={false}
        />
        <div className={styles.content}>{content}</div>
      </div>
      <ActionFooter
        buttonText={actionFooterData.buttonText}
        type={actionFooterData.type}
        isLoading={false}
        disabledButton={isTransitioning || isSettingValue}
        buttonClickHandler={() => {
          setActivityReadyHandler({ activityId: activity.id });
        }}
      >
        {actionFooterData.text}
      </ActionFooter>
    </div>
  );
};

export default memo(Moment);
