import {
  standardSessionActivityList,
  StandardSessionActivity,
} from "../apollo-graphql/types/enums/standard-session-activity";
import { SessionStateValue } from "../apollo-graphql/types/session-state";
import { parseToJson } from "./parse-to-json";

export const getCurrentLevel = (value: SessionStateValue) => {
  const currentActivityId = standardSessionActivityList.includes(
    value.value as StandardSessionActivity
  )
    ? null
    : Object.keys(parseToJson(value.value, [null]))[0];
  const filteredActivities = value.context.activityResult.filter(
    (res) =>
      !standardSessionActivityList.includes(res.key as StandardSessionActivity)
  );
  const currentLevel =
    filteredActivities.length +
    (!!filteredActivities.find((x) => x.key === currentActivityId) ? 0 : 1);

  return currentLevel;
};
