export function serializeUint8Array(uint8Array: Uint8Array) {
  return JSON.stringify(Array.from(uint8Array));
}

export function deserializeUint8Array(jsonString: string) {
  try {
    const array = JSON.parse(jsonString);
    return new Uint8Array(array);
  } catch (e) {
    console.error("Cannot deserialize uint8array", e);
    return null;
  }
}
