import { gql } from "@apollo/client";
import { FeedbackResult } from "../types/results/feedback-results";
import { AppApolloClient } from "../../contexts/Apollo";

const query = gql`
  query GetFeedbacks {
    getFeedbacks {
      create_date
      id
      text
      update_date
    }
  }
`;

export function getFeedbacks(client: AppApolloClient) {
  return client.query<FeedbackResult>({ query }).then((result) => {
    if (result.errors) return Promise.reject(result.errors[0]);
    return result.data!.getFeedbacks;
  });
}
