import { PropsWithChildren, memo, useMemo } from "react";
import styles from "./Alert.module.css";
import cn from "classnames";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";

export enum AlertType {
  Error = "error",
  Success = "success",
  Info = "info",
}

type AlertContent = {
  icon: string;
  heading: string;
  style: string;
};

const mapTypeToContent: Record<AlertType, AlertContent> = {
  [AlertType.Error]: {
    icon: "fa-solid fa-circle-exclamation",
    heading: "An error occured",
    style: styles.error,
  },
  [AlertType.Success]: {
    icon: "fa-solid fa-circle-check",
    heading: "Success!",
    style: styles.success,
  },
  [AlertType.Info]: {
    icon: "fa-solid fa-circle-info",
    heading: "Helpful tip",
    style: styles.info,
  },
};

export default memo(function Alert(
  props: PropsWithChildren<{
    type: AlertType;
    heading?: string;
    description: string;
    onClose: () => void;
  }>
) {
  const { type, description, onClose } = props;
  const { icon, heading: defaultHeading, style } = useMemo(
    () => mapTypeToContent[type],
    [type]
  );
  const heading = useMemo(
    () => (props?.heading ? props.heading : defaultHeading),
    [defaultHeading, props.heading]
  );

  const content = useMemo(
    () => (
      <>
        <div className={styles.icon}>
          <i className={cn(icon, style)} aria-hidden="true" />
        </div>
        <div className={styles.texts}>
          <p className={styles.heading}>{heading}</p>
          <p className={styles.description}>{description}</p>
        </div>
      </>
    ),
    [heading, icon, description, style]
  );

  return (
    <Dialog.Root open={true} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className={cn(styles.dialogContent, style, "DialogContent")}>
          {content}
          <Dialog.Close asChild>
            <button
              className={cn(styles.dialogCloseButton, type, "IconButton")}
              aria-label="Close"
            >
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
});
