import { Activity } from "../apollo-graphql/types/activity";

export function getGroupDuration(activity: Activity) {
  return (
    activity.benchmark?.g_duration ||
    activity.question?.g_duration ||
    activity.conceptualization?.g_duration ||
    activity.moment?.g_duration ||
    null
  );
}

export function getIndividualDuration(activity: Activity) {
  return (
    activity.benchmark?.i_duration ||
    activity.question?.i_duration ||
    activity.conceptualization?.i_duration ||
    activity.moment?.i_duration ||
    null
  );
}

export function getDuration(activity: Activity) {
  return activity.assignment?.duration || activity.theory?.duration || null;
}
