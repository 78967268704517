import {
  PropsWithChildren,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import cn from "classnames";
import { ActivityPart } from "../../../types/enums/activity-part";
import { Answer } from "../../../apollo-graphql/types/answer";
import { SessionStateValue } from "../../../apollo-graphql/types/session-state";
import InfoBox from "../../InfoBox/InfoBox";

import styles from "./Question.module.css";
import { ActivityCommon } from "../../../types/activity-common";
import ActionFooter from "../ActionFooter";
import { activityTypeFooterTextFactoryMap } from "../../../utils/footer";
import { ActionFooterType } from "../../../types/action-footer";
import { FooterType } from "../../../types/enums/activity-footer";
import ChallengeAuthorModal from "./ChallengeAuthorModal";
import { createChallenge } from "../../../apollo-graphql/mutations/author-challenge";
import { ApolloContext } from "../../../contexts/Apollo";
import { SessionContext } from "../../../contexts/Session";
import { ActivityType } from "../../../types/enums/activity-type";
import {
  ACTIVITY_TIMEOUT_VALUE,
  GROUP_SUSPENSION_POINTS,
  INDIVIDUAL_SUSPENSION_POINTS,
} from "../../../constants/global";

const getReadyText = (
  transition: number,
  isOnReviewPart: boolean,
  timesUp: boolean
) =>
  timesUp ? (
    <>
      Time is up. Continuing forward in{" "}
      <span className="accent">{transition}...</span>
    </>
  ) : (
    <>
      Everyone is ready.{" "}
      {isOnReviewPart
        ? "Proceeding to the next question"
        : "Continuing forward in"}{" "}
      <span className="accent">{transition}...</span>
    </>
  );

const getGroupText = (
  groupIsAligned: boolean,
  playersClicked: number,
  hasSelectedAnswer: boolean
) =>
  !groupIsAligned ? (
    <>
      You have to <span className="accent">match your answers</span> to
      continue.{" "}
      {!hasSelectedAnswer && (
        <span className="accent">
          You have to select an answer to continue.
        </span>
      )}
    </>
  ) : (
    <>
      Your individual answers match! Click{" "}
      <span className="accent green">“Continue”</span> to confirm them as a team
      answer!{" "}
      {playersClicked > 0 && (
        <span className="accent green">
          {playersClicked} player{playersClicked > 1 && "s"} clicked.
        </span>
      )}
    </>
  );

const getReviewText = (
  teamPointsData: { points: number; timeout: boolean },
  currentPlayerPointsData: { points: number; timeout: boolean },
  maxPoints: number,
  teamName: string,
  playersClicked: number
) => (
  <>
    Team “{teamName}”
    <span
      className={
        teamPointsData.points < 0
          ? "error"
          : teamPointsData.points === maxPoints
          ? "accent"
          : ""
      }
    >
      {teamPointsData.timeout && " didn't agree and "}
      {teamPointsData.points < 0 ? "loses" : "earned"} {teamPointsData.points}{" "}
      points
    </span>
    !
    <br />
    {!currentPlayerPointsData.timeout ? (
      <>
        Your individual answer earns you {currentPlayerPointsData.points}{" "}
        points!
      </>
    ) : (
      <>
        You didn't choose an answer before the discussion so you earn{" "}
        {currentPlayerPointsData.points} points!
      </>
    )}{" "}
    {playersClicked > 0 && (
      <span className="accent">
        {playersClicked} player{playersClicked > 1 && "s"} clicked.
      </span>
    )}
  </>
);

interface QuestionProps extends ActivityCommon {
  currentActiveParticipants: string[];
  activityResultForCurrentProfile:
    | SessionStateValue["context"]["activityResult"][0]["value"][0]["value"][0]
    | null;
  individualActivityResultForCurrentProfile:
    | SessionStateValue["context"]["activityResult"][0]["value"][0]["value"][0]
    | null;
  currentActivityResultsForOtherProfiles: SessionStateValue["context"]["activityResult"][0]["value"];
  currentActivityGroupResults: SessionStateValue["context"]["activityResult"][0]["value"];
  setActivityValueHandler: (args: {
    activityId: string;
    value: string;
  }) => void;
  isViewResults: boolean;
  teamName: string;
  lastQuestionId: string;
  showAnswersAsCorrect: boolean;
}

export default memo(function Question(props: PropsWithChildren<QuestionProps>) {
  const {
    activity,
    currentActiveParticipants,
    currentActiveParticipantCount,
    notReadyProfilesCount,
    transition,
    isReady,
    currentActivityPart,
    activityResultForCurrentProfile,
    individualActivityResultForCurrentProfile,
    currentActivityResultsForOtherProfiles,
    currentActivityGroupResults,
    setActivityValueHandler,
    setActivityReadyHandler,
    isViewResults,
    teamName,
    lastQuestionId,
    showAnswersAsCorrect,
  } = props;
  const { question, answers, description } = activity;

  const apolloContext = useContext(ApolloContext);
  const sessionContext = useContext(SessionContext);
  const clockInstance =
    sessionContext.workshopClock.state.context.clockInstance;

  const [expandedAnswerExplanations, setExpandedAnswerExplanations] = useState<
    string[]
  >([]);
  const [isChallengeModalVisible, setChallengeModalVisible] =
    useState<boolean>(false);

  const isTransitioning = useMemo(() => transition > 0, [transition]);

  const teamPointsData = useMemo(() => {
    if (currentActivityPart !== ActivityPart.Review) {
      return { points: null, timeout: false };
    }
    const answerIds = currentActivityGroupResults[0].value
      .filter(
        ({ profileId, ready }) =>
          currentActiveParticipants.includes(profileId) && ready
      )
      .map(({ value }) => value);

    // If all players didn't choose group answer must be suspended with GROUP_SUSPENSION_POINTS points.
    const teamAnswerTimeout = answerIds.every(
      (id) => id === ACTIVITY_TIMEOUT_VALUE
    );

    if (teamAnswerTimeout) {
      return {
        points: GROUP_SUSPENSION_POINTS,
        timeout: true,
      };
    }
    const filteredAnswerIds = answerIds.filter(
      (id) => id !== ACTIVITY_TIMEOUT_VALUE
    );

    const groupIsAligned = filteredAnswerIds.every((id) => id === answerIds[0]);

    return {
      points: !groupIsAligned
        ? 0
        : answers?.find((a) => a.id === filteredAnswerIds[0])?.points || 0,
      timeout: false,
    };
  }, [
    answers,
    currentActiveParticipants,
    currentActivityGroupResults,
    currentActivityPart,
  ]);

  const currentPlayerPointsData = useMemo(() => {
    if (!answers || currentActivityPart !== ActivityPart.Review) {
      return { points: null, timeout: false };
    }
    const currentProfileAnswerId =
      individualActivityResultForCurrentProfile?.value;
    const currentProfileIsReady =
      individualActivityResultForCurrentProfile?.ready;

    // If the player didn't choose individual answer must be suspended with SUSPENSION_POINTS points.
    if (currentProfileAnswerId === ACTIVITY_TIMEOUT_VALUE) {
      return {
        points: INDIVIDUAL_SUSPENSION_POINTS,
        timeout: true,
      };
    }

    const answerPoints =
      answers.find(
        (p) => p.id === currentProfileAnswerId && currentProfileIsReady
      )?.points || 0;

    return {
      points: answerPoints,
      timeout: false,
    };
  }, [answers, currentActivityPart, individualActivityResultForCurrentProfile]);

  const answerIds = useMemo(
    () =>
      currentActivityGroupResults[0]?.value
        .filter(({ profileId }) =>
          currentActiveParticipants.includes(profileId)
        )
        .map(({ value }) => value),
    [currentActiveParticipants, currentActivityGroupResults]
  );

  const groupIsAligned = useMemo(
    () =>
      answerIds?.every(
        (id) => !!id && id !== ACTIVITY_TIMEOUT_VALUE && id === answerIds[0]
      ),
    [answerIds]
  );

  const sortedAnswers = useMemo(
    () => (answers || []).slice().sort((a, b) => a.text.localeCompare(b.text)),
    [answers]
  );

  const maxPoints = useMemo(
    () => (answers ? Math.max(...answers.map(({ points }) => points)) : null),
    [answers]
  );

  const actionFooterData: ActionFooterType = useMemo(() => {
    const isOnReviewPart = currentActivityPart === ActivityPart.Review;
    const timeIsUp =
      !isOnReviewPart &&
      clockInstance?.activityParsedTimeRemaining === "" &&
      !isReady;

    if (isTransitioning) {
      return {
        text: getReadyText(
          transition,
          isOnReviewPart && lastQuestionId !== activity.id,
          timeIsUp
        ),
        buttonText:
          currentActivityPart === ActivityPart.Individual
            ? "Go to discussion"
            : "Continue",
        disabledButton: true,
        type: isOnReviewPart ? FooterType.Notice : FooterType.Ready,
        isLoading: true,
      };
    }

    if (!isReady) {
      const playersClicked =
        currentActiveParticipantCount - notReadyProfilesCount;
      const playerHasAnswered =
        !!activityResultForCurrentProfile?.value &&
        activityResultForCurrentProfile?.value !== ACTIVITY_TIMEOUT_VALUE;

      let text =
        activityTypeFooterTextFactoryMap[activity.type](playersClicked);
      let disabledButton = false;
      let buttonText = "Go to discussion";
      let type = isOnReviewPart ? FooterType.Ready : FooterType.Notice;

      if (currentActivityPart === ActivityPart.Individual) {
        disabledButton = !playerHasAnswered;
        text = playerHasAnswered
          ? text
          : activityTypeFooterTextFactoryMap[ActivityType.Action](
              playersClicked
            );

        buttonText = "Go to discussion";
      }

      if (currentActivityPart === ActivityPart.Group) {
        disabledButton =
          currentActiveParticipantCount === 1
            ? !groupIsAligned || !playerHasAnswered
            : !groupIsAligned;

        text = getGroupText(
          currentActiveParticipantCount === 1
            ? playerHasAnswered
            : groupIsAligned,
          playersClicked,
          playerHasAnswered
        );
        buttonText = "Continue";
      }
      if (
        isOnReviewPart &&
        teamPointsData.points !== null &&
        currentPlayerPointsData.points !== null &&
        maxPoints !== null
      ) {
        type = teamPointsData.points <= 0 ? FooterType.Error : type;
        buttonText = "Continue";
        text = getReviewText(
          teamPointsData,
          currentPlayerPointsData,
          maxPoints,
          teamName,
          playersClicked
        );
      }
      return { text, buttonText, disabledButton, type, isLoading: false };
    }
    return {
      text: (
        <>
          Waiting for{" "}
          <span className="accent">
            {notReadyProfilesCount} more player
            {notReadyProfilesCount > 1 && "s"}...
          </span>
        </>
      ),
      buttonText:
        currentActivityPart === ActivityPart.Individual
          ? "Go to discussion"
          : "Continue",
      disabledButton: true,
      type: FooterType.Waiting,
      isLoading: false,
    };
  }, [
    currentActivityPart,
    clockInstance?.activityParsedTimeRemaining,
    isReady,
    isTransitioning,
    notReadyProfilesCount,
    transition,
    lastQuestionId,
    activity.id,
    activity.type,
    currentActiveParticipantCount,
    teamPointsData,
    maxPoints,
    currentPlayerPointsData,
    activityResultForCurrentProfile?.value,
    groupIsAligned,
    teamName,
  ]);

  const reviewGroupAnswer = useMemo(() => {
    if (currentActivityPart !== ActivityPart.Review) {
      return null;
    }
    const answerIds = currentActivityGroupResults[0].value
      .filter(
        ({ profileId, ready }) =>
          currentActiveParticipants.includes(profileId) && ready
      )
      .map(({ value }) => value);
    const groupIsAligned = answerIds.every(
      (id) => id !== ACTIVITY_TIMEOUT_VALUE && id === answerIds[0]
    );
    return groupIsAligned ? answerIds[0] : null;
  }, [
    currentActiveParticipants,
    currentActivityGroupResults,
    currentActivityPart,
  ]);

  const infoBoxDescription = useMemo(() => {
    const isOnReviewPart = currentActivityPart === ActivityPart.Review;

    return isOnReviewPart
      ? "You will be able to review all explanations again at the end of the workshop."
      : "To encourage insightful discussions, questions might be purposely ambiguous. The points awarded for each answer may vary.";
  }, [currentActivityPart]);

  const getGroupProfileIdsSelectedAnswer = useCallback(
    (answerId: string) => {
      if (currentActivityPart !== ActivityPart.Group) {
        return [];
      }
      const answerData =
        currentActivityResultsForOtherProfiles?.[0]?.value || [];
      return answerData
        .filter(({ value }) => value === answerId)
        .map(({ profileId }) => profileId)
        .filter((profileId) => currentActiveParticipants.includes(profileId));
    },
    [
      currentActiveParticipants,
      currentActivityPart,
      currentActivityResultsForOtherProfiles,
    ]
  );

  const onChallengeModalVisibility = useCallback(
    (visibility: boolean) => {
      setChallengeModalVisible(visibility && !isTransitioning);
    },
    [isTransitioning]
  );

  const onChallengeModalSubmit = useCallback(
    (challenge: string) => {
      if (isTransitioning) {
        return Promise.resolve();
      }
      return createChallenge(apolloContext.client, {
        activity_id: activity.id,
        workshop_id: activity.workshop_id,
        challenge,
      });
    },
    [activity.id, activity.workshop_id, apolloContext.client, isTransitioning]
  );

  useEffect(() => {
    if (currentActivityPart === ActivityPart.Review) {
      const answer = answers?.find(({ id }) => id === reviewGroupAnswer);
      if (answer?.id) {
        setExpandedAnswerExplanations((prev) => [...prev, answer.id]);
      }
    }
  }, [currentActivityPart, answers, reviewGroupAnswer]);

  return (
    <div key={activity.id} className="activity-container">
      {isChallengeModalVisible && (
        <ChallengeAuthorModal
          submitDialogHandler={onChallengeModalSubmit}
          closeDialogHandler={() => onChallengeModalVisibility(false)}
        />
      )}
      <div
        className={cn(
          styles.container,
          "main-container",
          isViewResults && styles.viewResultsContainer
        )}
      >
        <div className={styles.infoContainer}>
          {isViewResults && (
            <p className={cn(styles.description, "text bold")}>{description}</p>
          )}
          {/* This is actually the title */}
          <p
            className="text"
            dangerouslySetInnerHTML={{
              __html: question!.text.replaceAll(/\\n|\\/gm, ""),
            }}
          />
          {!isViewResults && (
            <InfoBox
              description={infoBoxDescription}
              isDismissible
              transparent
            />
          )}
        </div>
        <div className={styles.answersContainer}>
          {currentActivityPart !== ActivityPart.Review && !isViewResults && (
            <p className="text">Choose the most correct answer:</p>
          )}
          {sortedAnswers.map((answer: Answer) => {
            if (currentActivityPart === ActivityPart.Individual)
              return (
                <div
                  key={answer.id + "-individual"}
                  className={cn(
                    styles.answer,
                    activityResultForCurrentProfile?.value === answer.id &&
                      "selected",
                    isTransitioning && "disabled"
                  )}
                  onClick={() => {
                    setActivityValueHandler({
                      activityId: activity.id,
                      value:
                        activityResultForCurrentProfile?.value === answer.id
                          ? ACTIVITY_TIMEOUT_VALUE
                          : answer.id,
                    });
                  }}
                >
                  {answer.text}
                </div>
              );
            if (currentActivityPart === ActivityPart.Group) {
              const profilesSelectedAnswer = getGroupProfileIdsSelectedAnswer(
                answer.id
              );
              return (
                <div
                  key={answer.id + "-group"}
                  className={cn(
                    styles.answer,
                    activityResultForCurrentProfile?.value === answer.id &&
                      "selected",
                    profilesSelectedAnswer.length > 0 && "group-selected",
                    isTransitioning && "disabled"
                  )}
                  onClick={() => {
                    setActivityValueHandler({
                      activityId: activity.id,
                      value:
                        activityResultForCurrentProfile?.value === answer.id
                          ? ACTIVITY_TIMEOUT_VALUE
                          : answer.id,
                    });
                  }}
                >
                  {answer.text}{" "}
                  {profilesSelectedAnswer.length > 0 && (
                    <i
                      className={cn(
                        "fa icon",
                        groupIsAligned ? "fa-users" : "fa-user"
                      )}
                    />
                  )}
                </div>
              );
            }
            if (currentActivityPart === ActivityPart.Review) {
              const isGroupAlignedAnswer = reviewGroupAnswer === answer.id;
              const isUserIndividualAnswer =
                individualActivityResultForCurrentProfile?.value ===
                  answer.id && individualActivityResultForCurrentProfile.ready;

              return (
                <div
                  key={answer.id + "-review"}
                  className={cn(
                    styles.answer,
                    isGroupAlignedAnswer ? "group-selected" : "",
                    "review",
                    isTransitioning && "disabled"
                  )}
                  onClick={() => {
                    if (expandedAnswerExplanations.includes(answer.id)) {
                      setExpandedAnswerExplanations(
                        expandedAnswerExplanations.filter(
                          (id) => id !== answer.id
                        )
                      );
                    } else {
                      setExpandedAnswerExplanations([
                        ...expandedAnswerExplanations,
                        answer.id,
                      ]);
                    }
                  }}
                >
                  <div className={styles.answerTopLine}>
                    {answer.text}
                    {isGroupAlignedAnswer && (
                      <>
                        {" "}
                        <i className="fa icon fa-users" />
                      </>
                    )}
                    {isUserIndividualAnswer && (
                      <>
                        {" "}
                        <i className="fa icon fa-user" />
                      </>
                    )}
                  </div>
                  <div
                    className={cn(
                      styles.answerExplanationToggle,
                      expandedAnswerExplanations.includes(answer.id)
                        ? "expanded"
                        : ""
                    )}
                  >
                    <i
                      className={cn(
                        "icon fa fa-chevron-down",
                        styles.answerExplanationToggleIcon
                      )}
                    ></i>
                  </div>
                  <div
                    className={cn(
                      styles.answerExplanation,
                      expandedAnswerExplanations.includes(answer.id)
                        ? "expanded"
                        : "",
                      "text",
                      "small"
                    )}
                    dangerouslySetInnerHTML={{
                      __html: answer.explanation_text.replaceAll(
                        /\\n|\\/gm,
                        ""
                      ),
                    }}
                  />
                  {(isGroupAlignedAnswer || isUserIndividualAnswer) && (
                    <div className={styles.answerInfoContainer}>
                      {isUserIndividualAnswer && (
                        <div className={styles.answerInfo}>
                          <span>
                            <i className={cn("fa", "fa-user")} />
                          </span>
                          <span className="text small">
                            Your individual choice
                          </span>
                        </div>
                      )}
                      {isGroupAlignedAnswer && (
                        <div className={styles.answerInfo}>
                          <span>
                            <i className={cn("fa", "fa-users")} />
                          </span>
                          <span className="text small">
                            Your collective choice
                          </span>
                        </div>
                      )}
                    </div>
                  )}

                  <div
                    className={cn(
                      styles.answerPoints,
                      isGroupAlignedAnswer && answer.points === maxPoints
                        ? "group-answer"
                        : "",
                      isUserIndividualAnswer && answer.points === maxPoints
                        ? "individual-answer"
                        : "",
                      showAnswersAsCorrect &&
                        (isGroupAlignedAnswer || isUserIndividualAnswer) &&
                        "group-answer"
                    )}
                  >
                    {answer.points}
                  </div>
                </div>
              );
            }
            return (
              <div key={answer.id + "-review"} className={styles.answer}>
                {answer.text}
              </div>
            );
          })}
          {currentActivityPart === ActivityPart.Review && (
            <InfoBox
              node={
                <p
                  className={cn(
                    "text tiny secondary",
                    styles.challengeAuthorText
                  )}
                >
                  Don’t agree?{" "}
                  <span
                    className="challenge-author"
                    onClick={() => onChallengeModalVisibility(true)}
                  >
                    Challenge the author!
                  </span>
                </p>
              }
              isDismissible
              transparent
            />
          )}
        </div>
      </div>
      {!isViewResults && (
        <ActionFooter
          buttonText={actionFooterData.buttonText}
          type={actionFooterData.type}
          disabledButton={actionFooterData.disabledButton}
          buttonClickHandler={() => {
            setActivityReadyHandler({ activityId: activity.id });
          }}
          isLoading={actionFooterData.isLoading}
        >
          {actionFooterData.text}
        </ActionFooter>
      )}
    </div>
  );
});
