import { setup, fromObservable, assign, spawnChild, ActorRef } from "xstate";
import { ongoingSessionSubscription } from "../../apollo-graphql/subscriptions/ongoing-session";
import { SessionStateValue } from "../../apollo-graphql/types/session-state";
import { AppApolloClient } from "../../contexts/Apollo";

import * as actions from "../actions/ongoing-session";

export enum OngoingSessionsState {
  Initial = "initial",
  Active = "active",
}

interface OngoingSessionsContext {
  client: AppApolloClient;
  ongoingSessions:
    | {
        key: string;
        sessionId: string;
        slotId: string;
        value: SessionStateValue;
      }[]
    | null;
}

type OngoingSessionsMachineTypes = {
  context: OngoingSessionsContext;
  events: ReturnType<(typeof actions)[keyof typeof actions]>;
};

export const ongoingSessionMachine = setup({
  types: {} as OngoingSessionsMachineTypes,
  actors: {
    subscription: fromObservable(({ input }) => {
      const {
        payload: { slot_ids },
        parentActor,
        client,
      } = input as {
        payload: ReturnType<typeof actions.ongoingSessionsSubscribe>["payload"];
        client: AppApolloClient;
        parentActor: ActorRef<any, any>;
      };

      return ongoingSessionSubscription(client, {
        slotIds: slot_ids,
      }).map(({ data }) => {
        parentActor.send(
          actions.ongoingSessionsSubscriptionData({ data: data! })
        );
        return data;
      });
    }),
  },
}).createMachine({
  id: "ongoing-sessions",
  initial: OngoingSessionsState.Initial,
  context: ({ input }): OngoingSessionsContext => {
    const machineInput = input as { client?: AppApolloClient } | undefined;
    if (!machineInput?.client)
      throw new Error("Apollo client must be provided!");

    return {
      client: machineInput.client,
      ongoingSessions: null,
    };
  },
  states: {
    [OngoingSessionsState.Initial]: {
      on: {
        [actions.ongoingSessionsSubscribe.type]: {
          target: OngoingSessionsState.Active,
        },
      },
    },
    [OngoingSessionsState.Active]: {
      entry: [
        spawnChild("subscription", {
          id: "apolloSubscription",
          input: ({ event, context, self }) => {
            const subscribeEvent = event as ReturnType<
              typeof actions.ongoingSessionsSubscribe
            >;
            const payload = subscribeEvent.payload;
            return {
              payload,
              client: context.client,
              parentActor: self,
            };
          },
          syncSnapshot: true,
        }),
      ],
    },
  },
  on: {
    [actions.ongoingSessionsSubscriptionData.type]: {
      actions: assign({
        ongoingSessions: ({ event }) => {
          const subscriptionDataEvent = event as ReturnType<
            typeof actions.ongoingSessionsSubscriptionData
          >;
          const ongoingSessionsState =
            subscriptionDataEvent.payload.data.ongoingSessions || [];
          return ongoingSessionsState;
        },
      }),
    },
  },
});
