// Utility function to parse the data from string to json format
export function parseToJson<T>(data: string | null, defaultData: T): T {
  if (typeof data !== "string") {
    return defaultData;
  }

  try {
    return JSON.parse(data) as T;
  } catch (error) {
    console.log("Error parsing data to json");
  }

  return defaultData;
}