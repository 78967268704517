import { memo } from "react";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import MomentCard, { IMoment } from "../MomentCard/MomentCard";
import { WorkshopAuthor } from "../../../../../../apollo-graphql/types/workshop";

import styles from "./MomentSection.module.css";
import InfoBox from "../../../../../InfoBox/InfoBox";

type MomentSectionProps = {
  id: string;
  items: IMoment[];
  isTransitioning: boolean;
  currentProfileId: string;
  workshopAuthor: WorkshopAuthor;
};

const MomentSection = (props: MomentSectionProps) => {
  const { id, items, isTransitioning, currentProfileId, workshopAuthor } =
    props;
  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <SortableContext
      id={id}
      items={items}
      strategy={verticalListSortingStrategy}
    >
      <div ref={setNodeRef} className={styles.moments}>
        {items?.length > 0 &&
          items.map((item, index) => {
            return (
              <MomentCard
                id={item.id}
                key={item.id}
                isDraggable={item.profileId === workshopAuthor.id}
                index={index}
                workshopAuthor={workshopAuthor}
                currentProfileId={currentProfileId}
                isTransitioning={isTransitioning}
                item={item}
              />
            );
          })}
        {items.length === 0 && (
          <InfoBox
            title="No suggestions found!"
            description={`All of ${workshopAuthor.name}’s “Aha Moments” have been added to your team’s “Aha Moments” list.`}
          />
        )}
      </div>
    </SortableContext>
  );
};

export default memo(MomentSection);
