import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import withTitle from "../../../hocs/withTitle";

import { ApolloContext } from "../../../contexts/Apollo";
import { SocketStates } from "../../../+xstate/machines/socket";
import { InternetConnectionContext } from "../../../contexts/InternetConnection";
import Button3D from "../../Shared/Button3D/Button3D";

import cn from "classnames";
import styles from "./ConnectionLost.module.css";

function ConnectionLost() {
  const params = useParams<{ slotId: string; group: string }>();
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(false);

  const apolloContext = useContext(ApolloContext);
  const isOnline = useContext(InternetConnectionContext);

  useEffect(() => {
    if (isVisible) {
      return;
    }
    setTimeout(() => {
      setIsVisible(true);
    }, 500);
  }, [isVisible]);

  const isSocketOpen = apolloContext.socket.state.matches(SocketStates.Open);

  const canRejoin = useMemo(
    () => isSocketOpen || isOnline,
    [isOnline, isSocketOpen]
  );

  const { slotId, group } = useMemo(
    () => params || { slotId: null, group: null },
    [params]
  );

  const workshopLink = useMemo(
    () => `workshop-redirect/${slotId}/${group}`,
    [group, slotId]
  );

  const handleRejoinWorkshop = useCallback(() => {
    navigate(`/${workshopLink}`);
  }, [navigate, workshopLink]);

  if (!slotId || !group) return <Navigate to="/" />;

  return (
    <div className={styles.container}>
      {isVisible && (
        <div className={styles.content}>
          <p className={styles.emoji}>🫥</p>
          <h1>No internet connection!</h1>
          <p className={cn("text", styles.descriptionText)}>
            No internet connection. Connect to the Internet and try again.
          </p>

          <div className={styles.actions}>
            <Button3D
              size="large"
              variant="info"
              onClick={handleRejoinWorkshop}
              disabled={!canRejoin}
            >
              Reload
            </Button3D>
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(withTitle(ConnectionLost));
