import { PropsWithChildren, memo, useContext, useMemo } from "react";
import { GlobalContext } from "../../../contexts/Global";
import { serverUrl } from "../../../constants/endpoints";
import {
  generateProfileImageKey,
  generateWorkspaceProfileImageKey,
} from "../../../utils";

import Image from "../Image/Image";

type UserImageProps =
  | {
      isPublic: true;
      profileId: string;
      profileWorkspaceId?: undefined;
      alt: string;
      profileAbbreviation?: string;
      fallbackFontAwesomeIconClass?: string;
      showLoader?: boolean;
      loadOnDemand?: boolean;
      containerClass?: string;
    }
  | {
      isPublic: false;
      profileId: string;
      profileWorkspaceId: string;
      alt: string;
      profileAbbreviation?: string;
      fallbackFontAwesomeIconClass?: string;
      showLoader?: boolean;
      loadOnDemand?: boolean;
      containerClass?: string;
    };

const UserImage = (props: PropsWithChildren<UserImageProps>) => {
  const {
    isPublic,
    profileId,
    profileWorkspaceId,
    alt,
    fallbackFontAwesomeIconClass,
    profileAbbreviation,
    showLoader,
    loadOnDemand,
    containerClass = "",
  } = props;

  const {
    auth: {
      context: { token, imageLastUpdatedTimeStamp, profile },
    },
  } = useContext(GlobalContext);

  const s3Key = useMemo(
    () =>
      isPublic
        ? generateProfileImageKey(profileId)
        : generateWorkspaceProfileImageKey(profileId, profileWorkspaceId),
    [isPublic, profileId, profileWorkspaceId]
  );

  const imageTimeStamp = useMemo(
    () => (profileId === profile?.id ? imageLastUpdatedTimeStamp : null),
    [profileId, profile?.id, imageLastUpdatedTimeStamp]
  );

  const imageSrc = useMemo(() => {
    if (!s3Key || !token) return null;
    return `${serverUrl}/asset/${s3Key}?auth=${token}${
      imageTimeStamp ? `&timestamp=${imageTimeStamp}` : ""
    }`;
  }, [imageTimeStamp, s3Key, token]);

  return (
    <Image
      imageSrc={imageSrc}
      alt={alt}
      fallbackFontAwesomeIconClass={fallbackFontAwesomeIconClass}
      profileAbbreviation={profileAbbreviation}
      containerClass={containerClass}
      loadOnDemand={loadOnDemand}
      showLoader={showLoader}
    />
  );
};

export default memo(UserImage);
