import { useContext, useMemo, lazy, Suspense } from "react";
import { GlobalContext } from "../contexts/Global";
import { ProfileWorkspaceAccess } from "../apollo-graphql/types/enums";
import { AdminDashboardContextProvider } from "../contexts/AdminDashboard";
import withTitle from "../hocs/withTitle";

const AdminDashboard = lazy(
  () => import("../components/Dashboard/Admin/AdminDashboard")
);
const MemberDashboard = lazy(
  () => import("../components/Dashboard/Member/MemberDashboard")
);

function Dashboard() {
  const globalContext = useContext(GlobalContext);
  const profileAccess =
    globalContext.auth.context.profile?.workspace.access ||
    ProfileWorkspaceAccess.NONE;

  const hasAdminAccess = useMemo(() => {
    return [
      ProfileWorkspaceAccess.OWNER,
      ProfileWorkspaceAccess.ADMIN,
      ProfileWorkspaceAccess.SUPER_ADMIN,
    ].includes(profileAccess);
  }, [profileAccess]);

  return (
    <Suspense>
      {hasAdminAccess ? (
        <AdminDashboardContextProvider>
          <AdminDashboard />
        </AdminDashboardContextProvider>
      ) : (
        <MemberDashboard />
      )}
    </Suspense>
  );
}

export default withTitle(Dashboard);
