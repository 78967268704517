import { createMachine, fromPromise, assign, spawnChild, sendTo } from "xstate";
import * as sessionActions from "../../actions/session/session";
import * as workshopActions from "../../actions/session/workshop";
import * as workshopClockActions from "../../actions/session/workshop-clock";
import { getInvitation } from "../../../apollo-graphql/queries/invitation";
import { Slot } from "../../../apollo-graphql/types/slot";
import { Session } from "../../../apollo-graphql/types/session";
import { getSession } from "../../../apollo-graphql/queries/session";
import { workshopMachine } from "./workshop";
import { UnionToArray } from "../../../types/helpers";
import { Profile } from "../../../apollo-graphql/types/profile";
import { getProfile } from "../../../apollo-graphql/queries/profile";
import { AppApolloClient } from "../../../contexts/Apollo";
import { workshopClockMachine } from "./workshop-clock";
import { SlotType } from "../../../apollo-graphql/types/enums/slot-type";
import { InvitationStatus } from "../../../types/enums/invitation-status";
import { fetchMachineFactory } from "../fetch-factory";
import { getWorkshopCoverage, requestNextWorkshop } from "../../../apollo-graphql/queries/workshop";

type WorkshopActions = typeof workshopActions;
type WorkshopClockActions = typeof workshopClockActions;

type SendToActionResult<
  V extends Record<string, any>,
  K extends UnionToArray<keyof V> = UnionToArray<keyof V>
> = K extends [infer Head, ...infer Tail]
  ? Head extends keyof V
    ? Record<V[Head]["type"], { actions: ReturnType<typeof sendTo> }> &
        (Tail extends UnionToArray<Exclude<keyof V, Head>>
          ? SendToActionResult<Omit<V, Head>, Tail>
          : {})
    : {}
  : {};

/*
TODO: Test with this approach instead of creating the proxy!
always: {
  // Forward events to the invoked actor
  // This will not cause an infinite loop in XState v5
  actions: sendTo('someId', ({ event }) => event)
}
*/
const workshopActionProxy = Object.values(workshopActions).reduce(
  (acc, actionCreator) => {
    acc[actionCreator.type] = {
      actions: sendTo("workshop", ({ event }) => {
        return event;
      }),
    };
    return acc;
  },
  {} as any
) as SendToActionResult<WorkshopActions>;

const workshopClockActionProxy = Object.values(workshopClockActions).reduce(
  (acc, actionCreator) => {
    acc[actionCreator.type] = {
      actions: sendTo("workshop", ({ event }) => {
        return event;
      }),
    };
    return acc;
  },
  {} as any
) as SendToActionResult<WorkshopClockActions>;

export enum SessionState {
  Initial = "initial",
  WaitingRoom = "waiting-room",
  Invite = "invite",
  InvitationNotFound = "invitation-not-found",
  SessionNotFound = "session-not-found",
  SessionWaiting = "session-waiting",
  SessionOngoing = "session-ongoing",
  SessionEnded = "session-ended",
}

export enum SessionParticipantsState {
  Ready = "ready",
  Syncing = "syncing",
}

export interface SessionMachineContext {
  client: AppApolloClient;
  socketEventTarget: EventTarget;
  slot: Slot | null;
  session: Session | null;
  group: number | null;
  millisecondsToStart: number | null;
  splitMillisecondsWaitingTime: number | null;
  sessionOpeningTimeInMilliseconds: number | null;
  sessionResponseServerTimestamp: number | null;
  invitationResponseServerTimestamp: number | null;
  invitationStatus: InvitationStatus | null;
  invitationId: string | null;
  error: string | null;
  participantIds: string[] | null;
  profiles: Profile[];
}

type AllActionCreators = typeof sessionActions &
  typeof workshopActions &
  typeof workshopClockActions;
type AllActionCreatorKeys = keyof AllActionCreators;
type AllActions = ReturnType<AllActionCreators[AllActionCreatorKeys]>;

type AuthMachineTypes = {
  context: SessionMachineContext;
  events: AllActions;
};

const defaultValues = {
  slot: null,
  session: null,
  millisecondsToStart: null,
  splitMillisecondsWaitingTime: null,
  sessionOpeningTimeInMilliseconds: null,
  error: null,
  participantIds: null,
  profiles: [],
  group: null,
  invitationStatus: null,
  invitationId: null,
  sessionResponseServerTimestamp: null,
  invitationResponseServerTimestamp: null,
};

const requestNextWorkshopMachineId = "requestNextWorkshopMachine" as const;
export const {
  machine: requestNextWorkshopMachine,
  trigger: requestNextWorkshopTrigger,
  success: requestNextWorkshopSuccess,
  failure: requestNextWorkshopFailure,
} = fetchMachineFactory({
  id: requestNextWorkshopMachineId,
  invokeFn: ({ client, id }: { client: AppApolloClient, id: string }) => {
    return requestNextWorkshop(client, { id });
  },
});

const getWorkshopCoverageMachineId = "getWorkshopCoverageMachine" as const;
export const {
  machine: getWorkshopCoverageMachine,
  trigger: getWorkshopCoverageTrigger,
  success: getWorkshopCoverageSuccess,
  failure: getWorkshopCoverageFailure,
} = fetchMachineFactory({
  id: getWorkshopCoverageMachineId,
  invokeFn: ({ client, id, workspaceId }: { client: AppApolloClient, id: string, workspaceId: string }) => {
    return getWorkshopCoverage(client, { id, workspaceId });
  },
});

export const sessionMachine = createMachine({
  types: {} as AuthMachineTypes,
  id: "session",
  context: ({ input }): SessionMachineContext => {
    const machineInput = input as { client?: AppApolloClient, socketEventTarget?: EventTarget } | undefined;
    if (!machineInput?.client || !machineInput?.socketEventTarget)
      throw new Error("Apollo client and socket event target must be provided!");

    return {
      ...defaultValues,
      client: machineInput.client,
      socketEventTarget: machineInput.socketEventTarget,
    };
  },
  entry: [
    spawnChild(workshopMachine, {
      id: "workshop",
      input: (data: any) => {
        const context = data.context as SessionMachineContext;
        const sessionStatus = context.session?.status;
        return { client: context.client, socketEventTarget: context.socketEventTarget, sessionStatus };
      },
    }),
    spawnChild(workshopClockMachine, { id: "workshopClock" }),
    spawnChild(requestNextWorkshopMachine, { id: requestNextWorkshopMachineId }),
    spawnChild(getWorkshopCoverageMachine, { id: getWorkshopCoverageMachineId }),
  ],
  type: "parallel",
  states: {
    session: {
      initial: SessionState.Initial,
      states: {
        [SessionState.Initial]: {
          on: {
            [sessionActions.getInvite.type]: {
              target: SessionState.Invite,
            },
            [sessionActions.getSession.type]: {
              target: SessionState.SessionWaiting,
            },
          },
        },
        [SessionState.WaitingRoom]: {
          on: {
            [sessionActions.getInvite.type]: {
              target: SessionState.Invite,
            },
            [sessionActions.reset.type]: {
              target: SessionState.Initial,
              actions: assign({
                ...defaultValues,
              }),
            },
          },
        },
        [SessionState.Invite]: {
          invoke: {
            src: fromPromise(({ input }) => {
              const data = input as {
                payload: ReturnType<AllActionCreators["getInvite"]>["payload"];
                client: AppApolloClient;
              };
              return getInvitation(data.client, data.payload.variables);
            }),
            onDone: [
              {
                target: SessionState.InvitationNotFound,
                guard: ({ event }) => {
                  return (
                    event.output.invitation === null ||
                    event.output.invitation.slot === null
                  );
                },
              },
              {
                target: SessionState.WaitingRoom,
                guard: ({ event }) =>
                  event.output.millisecondsToStart >
                  (event.output.invitation.slot.type === SlotType.ALL
                    ? event.output.sessionOpeningTimeInMilliseconds!
                    : 0),
                actions: assign({
                  millisecondsToStart: ({ event }) =>
                    event.output.millisecondsToStart,
                  slot: ({ event }) => event.output.invitation.slot,
                  invitationStatus: ({ event }) =>
                    event.output.invitation.status,
                  invitationId: ({ event }) => event.output.invitation.id,
                  splitMillisecondsWaitingTime: ({ event }) =>
                    event.output.splitMillisecondsWaitingTime,
                  sessionOpeningTimeInMilliseconds: ({ event }) =>
                    event.output.sessionOpeningTimeInMilliseconds,
                  invitationResponseServerTimestamp: ({ event }) =>
                    event.output.serverTimestamp,
                }),
              },
              {
                target: SessionState.Invite,
                actions: assign({
                  millisecondsToStart: ({ event }) =>
                    event.output.millisecondsToStart,
                  slot: ({ event }) => event.output.invitation.slot,
                  invitationStatus: ({ event }) =>
                    event.output.invitation.status,
                  invitationId: ({ event }) => event.output.invitation.id,
                  splitMillisecondsWaitingTime: ({ event }) =>
                    event.output.splitMillisecondsWaitingTime,
                  sessionOpeningTimeInMilliseconds: ({ event }) =>
                    event.output.sessionOpeningTimeInMilliseconds,
                  invitationResponseServerTimestamp: ({ event }) =>
                    event.output.serverTimestamp,
                }),
              },
            ],
            onError: {
              target: SessionState.Initial,
              actions: assign({
                error: ({ event }) => `${event.error}`,
              }),
            },
            input: ({ event, context }) => ({
              payload: event.payload,
              client: context.client,
            }),
          },
          on: {
            [sessionActions.getSession.type]: {
              target: SessionState.SessionWaiting,
            },
          },
        },
        [SessionState.InvitationNotFound]: {},
        [SessionState.SessionWaiting]: {
          invoke: {
            src: fromPromise(({ input }) => {
              const data = input as {
                payload: ReturnType<AllActionCreators["getSession"]>["payload"];
                client: AppApolloClient;
              };

              return getSession(data.client, data.payload.variables);
            }),
            onDone: [
              {
                target: SessionState.SessionWaiting,
                guard: ({ event }) => {
                  return event.output.millisecondsToStart > 0;
                },
                actions: assign({
                  millisecondsToStart: ({ event }) =>
                    event.output.millisecondsToStart,
                  slot: ({ event, context }) =>
                    event.output.session?.slot || context.slot,
                  session: ({ event }) => event.output.session,
                  splitMillisecondsWaitingTime: ({ event }) =>
                    event.output.splitMillisecondsWaitingTime,
                  sessionOpeningTimeInMilliseconds: ({ event }) =>
                    event.output.sessionOpeningTimeInMilliseconds,
                  sessionResponseServerTimestamp: ({ event }) =>
                    event.output.serverTimestamp,
                  group: ({ event }) => event.output.group,
                }),
              },
              {
                target: SessionState.SessionOngoing,
                guard: ({ event }) => {
                  return (
                    event.output.millisecondsToStart <= 0 &&
                    event.output.session
                  );
                },
                actions: assign({
                  millisecondsToStart: ({ event }) =>
                    event.output.millisecondsToStart,
                  slot: ({ event, context }) =>
                    event.output.session.slot || context.slot,
                  session: ({ event, context }) =>
                    event.output.session || context.session,
                  splitMillisecondsWaitingTime: ({ event }) =>
                    event.output.splitMillisecondsWaitingTime,
                  sessionOpeningTimeInMilliseconds: ({ event }) =>
                    event.output.sessionOpeningTimeInMilliseconds,
                  sessionResponseServerTimestamp: ({ event }) =>
                    event.output.serverTimestamp,
                  group: ({ event }) => event.output.group,
                }),
              },
              {
                target: SessionState.SessionNotFound,
                guard: ({ event }) => {
                  return event.output.session === null;
                },
              },
            ],
            onError: {
              target: SessionState.Initial,
              actions: assign({
                error: ({ event }) => `${event.error}`,
              }),
            },
            input: ({ event, context }) => ({
              payload: event.payload,
              client: context.client,
            }),
          },
        },
        [SessionState.SessionNotFound]: {},
        [SessionState.SessionOngoing]: {},
      },
    },
    participants: {
      initial: SessionParticipantsState.Ready,
      states: {
        [SessionParticipantsState.Ready]: {
          on: {
            [sessionActions.sessionParticipantChange.type]: {
              target: SessionParticipantsState.Syncing,
            },
          },
        },
        [SessionParticipantsState.Syncing]: {
          invoke: {
            src: fromPromise(({ input }) => {
              const { payload, client, profiles } = input as {
                payload: ReturnType<
                  typeof sessionActions.sessionParticipantChange
                >["payload"];
                client: AppApolloClient;
                profiles: Profile[];
              };

              let profileQueryPromises: Promise<Profile>[] = [];
              let ids: string[] = [];

              if ("participantIds" in payload) {
                // NOTE: participantIds contains all the current participants
                const { participantIds } = payload;
                ids = participantIds;
                const missingParticipantProfileIds = participantIds.filter(
                  (id) => !profiles.find((p) => p.id === id)
                );

                profileQueryPromises = missingParticipantProfileIds.map((id) =>
                  getProfile(client, { id })
                );
              } else {
                // NOTE: refetchParticipantIds contains only the participants
                // that we want to refetch
                const { refetchParticipantIds } = payload;
                ids = profiles.map((p) => p.id);
                profileQueryPromises = refetchParticipantIds.map((id) =>
                  getProfile(client, { id })
                );
              }

              return Promise.allSettled(profileQueryPromises).then(
                (missingProfiles) => {
                  const allProfiles = [
                    ...missingProfiles
                      .filter(
                        (p): p is PromiseFulfilledResult<Profile> =>
                          p.status === "fulfilled" && !!p.value
                      )
                      .map((p) => p.value),
                    ...profiles,
                  ];
                  return ids.map((id) => allProfiles.find((p) => p.id === id));
                }
              );
            }),
            input: ({ event, context }) => ({
              payload: event.payload,
              client: context.client,
              profiles: context.profiles,
            }),
            onDone: {
              target: SessionParticipantsState.Ready,
              actions: assign({
                profiles: ({ event: { output } }) => {
                  return output;
                },
                slot: ({ event: { output }, context }) => {
                  const { slot } = context;
                  const updatedProfiles = output as Profile[];
                  if (!slot) return null;

                  const updatedAuthor = updatedProfiles.find(
                    (p) => p.id === slot.workshop.author_id
                  );

                  if (!updatedAuthor) return context.slot;

                  return {
                    ...context.slot,
                    workshop: {
                      ...slot.workshop,
                      author: {
                        ...slot.workshop.author,
                        name: updatedAuthor.name,
                      },
                    },
                  } as Slot;
                },
              }),
            },
          },
          on: {
            [sessionActions.sessionParticipantChange.type]: {
              target: SessionParticipantsState.Syncing,
            },
          },
        },
      },
    },
  },
  on: {
    ...(workshopActionProxy as any), // for some reason typescript doesn't like it 🤷‍♂️
    ...(workshopClockActionProxy as any),
  },
});
