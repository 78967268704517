import {
  setup,
  fromObservable,
  raise,
  fromPromise,
  assign,
  spawnChild,
  sendParent,
  stopChild,
  fromCallback,
  createActor,
} from "xstate";
import { openSessionStateSubscription } from "../../../apollo-graphql/subscriptions/session-state";
import { join } from "../../../apollo-graphql/mutations/join";
import * as actions from "../../actions/session/workshop";
import * as sessionActions from "../../actions/session/session";
import { SessionStateValue } from "../../../apollo-graphql/types/session-state";
import { disconnect } from "../../../apollo-graphql/mutations/disconnect";
import { readyToStart } from "../../../apollo-graphql/mutations/ready-to-start";
import { setActivityValue } from "../../../apollo-graphql/mutations/set-activity-value";
import { setActivityReady } from "../../../apollo-graphql/mutations/set-activity-ready";
import { SessionStateResult } from "../../../apollo-graphql/types/results/session-state-result";
import { getUnixTime } from "date-fns";
import { diff } from "deep-diff";
import { AppApolloClient, SocketEvents } from "../../../contexts/Apollo";
import { workshopClockMachine } from "./workshop-clock";
import { useMachine } from "@xstate/react";
import {
  StandardSessionActivity,
  standardSessionActivityList,
} from "../../../apollo-graphql/types/enums/standard-session-activity";
import {
  configureWorkshopClock,
  resetWorkshopClock,
  startWorkshopClock,
} from "../../actions/session/workshop-clock";
import { SessionMachineContext } from "./session";
import {
  getDuration,
  getGroupDuration,
  getIndividualDuration,
} from "../../../utils/get-duration";
import { ActivityPart } from "../../../types/enums/activity-part";
import { KickReason } from "../../../types/kick-reason";
import { WorkshopDisconnectType } from "../../../types/enums/workshop-disconnect-type";
import { getNextWorkshop } from "../../../apollo-graphql/queries/workshop";
import { FetchState, fetchMachineFactory } from "../fetch-factory";
import { getFeedbacks } from "../../../apollo-graphql/queries/feedback";
import {
  GetFeedbacksActor,
  GetNextWorkshopActor,
} from "../../../contexts/Session";

const TRANSITION_DELAY_IN_SECONDS = 3;

const getNextWorkshopMachineId = "getNextWorkshopMachine" as const;
const getFeedbacksMachineId = "getFeedbacksMachine" as const;

export const {
  machine: getNextWorkshopMachine,
  trigger: getNextWorkshopTrigger,
  success: getNextWorkshopSuccess,
  failure: getNextWorkshopFailure,
} = fetchMachineFactory({
  id: getNextWorkshopMachineId,
  invokeFn: ({ client }: { client: AppApolloClient }) =>
    getNextWorkshop(client, { id: "70823090-7f0d-4ddf-a7fd-8e0ea0901152" }),
});

export const { machine: getFeedbacksMachine, trigger: getFeedbacksTrigger } =
  fetchMachineFactory({
    id: getFeedbacksMachineId,
    invokeFn: ({ client }: { client: AppApolloClient }) => getFeedbacks(client),
  });

export enum WorkshopState {
  Initial = "initial",
  Start = "start",
  Subscribing = "subscribing",
  Joining = "joining",
  Disconnecting = "disconnecting",
  Ready = "ready",
  SettingReadyToStart = "setting-ready-to-start",
  SettingValue = "setting-value",
  NewSetValueWhileSettingValue = "new-set-value-while-setting-value",
  SettingReady = "setting-ready",
  Ended = "ended",
  Kicked = "kicked",
  Error = "error",
}

interface WorkshopContext {
  client: AppApolloClient;
  socketEventTarget: EventTarget;
  isSubscribed: boolean;
  autoJoinCompleted: boolean;
  sessionState: SessionStateValue | null;
  currentProfileId: string | null;
  transition: number;
  transitionIntervalId: number | null;
  delayedStateData: SessionStateResult[];
  connectionUUID: string | null;
  sessionId: string | null;
  errors: any | null;
}

type WorkshopMachineTypes = {
  context: WorkshopContext;
  events: ReturnType<(typeof actions)[keyof typeof actions]>;
};

function calculateTransition(
  prevData: SessionStateResult | null | undefined,
  currData: SessionStateResult | null | undefined,
  now = new Date()
): { secondsToReady: number } {
  if (!prevData || !currData) return { secondsToReady: -1 };
  const {
    sessionState: {
      context: {
        lastActivityTimestamp: prevLastActivityTimestamp,
        lastActivityPartTimestamp: prevLastActivityPartTimestamp,
      },
    },
  } = prevData;
  const {
    sessionState: {
      context: {
        lastActivityTimestamp: currLastActivityTimestamp,
        lastActivityPartTimestamp: currLastActivityPartTimestamp,
      },
    },
  } = currData;
  if (
    prevLastActivityTimestamp === currLastActivityTimestamp &&
    prevLastActivityPartTimestamp === currLastActivityPartTimestamp
  )
    return { secondsToReady: -1 };
  const timestamp = Math.max(
    currLastActivityTimestamp || 0,
    currLastActivityPartTimestamp || 0
  );
  if (timestamp === 0) return { secondsToReady: -1 };
  return { secondsToReady: TRANSITION_DELAY_IN_SECONDS };
}

export const workshopMachine = setup({
  types: {} as WorkshopMachineTypes,
  guards: {
    isCurrentProfileParticipating: ({ context }) =>
      !!context.sessionState?.context.currentActiveProfiles.find(
        ({ profileId }) => profileId === context.currentProfileId
      ),
    isCurrentProfileNotParticipating: ({ context }) =>
      !context.sessionState?.context.currentActiveProfiles.find(
        ({ profileId }) => profileId === context.currentProfileId
      ),
  },
  actors: {
    subscription: fromObservable(({ input }) => {
      const {
        payload: { sessionId, parentActor, uuid },
        client,
        socketEventTarget,
      } = input as {
        payload: ReturnType<typeof actions.workshopSubscribe>["payload"];
        client: AppApolloClient;
        socketEventTarget: EventTarget;
      };

      const connectedHandler = () => {
        parentActor.send(actions.workshopJoin({ sessionId, uuid }));
        // socketEventTarget.removeEventListener(SocketEvents.CONNECTED, connectedHandler);
      };
      socketEventTarget.addEventListener(SocketEvents.CONNECTED, connectedHandler);

      let prevData: SessionStateResult | null | undefined = undefined;
      return openSessionStateSubscription(client, {
        sessionId,
        connectionUUID: uuid,
      }).map(({ data }) => {
        console.log("New subscription data", data);
        const { secondsToReady } = calculateTransition(prevData, data);
        const participantsDiff = diff(
          prevData?.sessionState.context.currentActiveProfiles.map(
            ({ profileId }) => profileId
          ) || [],
          data?.sessionState.context.currentActiveProfiles.map(
            ({ profileId }) => profileId
          ) || []
        );
        if (participantsDiff) {
          parentActor.send(
            actions.workshopParticipantChange({
              participantIds:
                data?.sessionState.context.currentActiveProfiles.map(
                  ({ profileId }) => profileId
                ) || [],
            })
          );
        }
        prevData = data;

        const { context } = parentActor.getSnapshot();
        const { transitionIntervalId, delayedStateData } =
          context as WorkshopContext;
        if (secondsToReady <= 0) {
          parentActor.send(
            !transitionIntervalId
              ? actions.workshopSubscriptionData({ data: data! })
              : actions.pushDelayedStateData({ data: data! })
          );
        } else if (!transitionIntervalId) {
          parentActor.send(
            actions.workshopSubscriptionDataTransition({
              transition: secondsToReady,
            })
          );

          const intervalStartTimestamp = getUnixTime(new Date());
          const intervalId = setInterval(() => {
            const currentTimestamp = getUnixTime(new Date());
            const elapsedSeconds = currentTimestamp - intervalStartTimestamp;

            const diff = secondsToReady - elapsedSeconds;

            parentActor.send(
              actions.workshopSubscriptionDataTransition({
                transition: diff > 0 ? diff : 0,
              })
            );
            if (diff <= 0) {
              clearInterval(intervalId);
              delayedStateData.forEach((data) => {
                parentActor.send(
                  actions.workshopSubscriptionData({ data: data! })
                );
              });

              parentActor.send(
                actions.workshopSubscriptionData({ data: data! })
              );
              parentActor.send(
                actions.setTransitionIntervalId({
                  transitionIntervalId: null,
                })
              );

              parentActor.send(actions.clearDelayedStateData());
            }
          }, 1000);

          parentActor.send(
            actions.setTransitionIntervalId({
              transitionIntervalId: intervalId as unknown as number,
            })
          );
        }
        return data;
      });
    }),
    join: fromPromise(({ input }) => {
      const {
        payload: { sessionId, uuid },
        client,
      } = input as {
        payload: ReturnType<typeof actions.workshopJoin>["payload"];
        client: AppApolloClient;
      };
      return join(client, { sessionId, uuid });
    }),
    disconnect: fromPromise(({ input }) => {
      const {
        client,
        connectionUUID,
        payload: { sessionId, intended, type, reason },
      } = input as {
        payload: ReturnType<typeof actions.workshopDisconnect>["payload"];
        client: AppApolloClient;
        connectionUUID: string;
      };
      return disconnect(client, { sessionId, intended, connectionUUID }).then(
        () => ({
          intended,
          type,
          reason,
        })
      );
    }),
    readyToStart: fromPromise(({ input }) => {
      const {
        client,
        payload: { sessionId },
      } = input as {
        payload: ReturnType<typeof actions.workshopReadyToStart>["payload"];
        client: AppApolloClient;
      };
      return readyToStart(client, { sessionId });
    }),
    setActivityValue: fromPromise(({ input }) => {
      const {
        client,
        payload: { sessionId, activityId, value },
      } = input as {
        payload: ReturnType<typeof actions.workshopSetActivityValue>["payload"];
        client: AppApolloClient;
      };
      return setActivityValue(client, { sessionId, activityId, value });
    }),
    setActivityReady: fromPromise(({ input }) => {
      const {
        client,
        payload: { sessionId, activityId },
      } = input as {
        payload: ReturnType<typeof actions.workshopSetActivityReady>["payload"];
        client: AppApolloClient;
      };
      return setActivityReady(client, { sessionId, activityId });
    }),
    cleanUp: fromCallback(({ input }) => {
      const { parent } = input as {
        parent: ReturnType<typeof createActor<typeof workshopMachine>>;
      };
      return () => {
        const snapshot = parent.getSnapshot();
        const gamePlayStates = [
          WorkshopState.Ready,
          WorkshopState.SettingReadyToStart,
          WorkshopState.NewSetValueWhileSettingValue,
          WorkshopState.SettingValue,
          WorkshopState.SettingReady,
        ];
        const { client, sessionId, connectionUUID } = snapshot.context;
        const isInGamePlayState = gamePlayStates
          .map((state) => snapshot.matches(state))
          .includes(true);
        if (!isInGamePlayState || !client || !sessionId || !connectionUUID)
          return;
        disconnect(client, { intended: true, sessionId, connectionUUID });
      };
    }),
    [getNextWorkshopMachineId]: getNextWorkshopMachine,
    [getFeedbacksMachineId]: getFeedbacksMachine,
  },
}).createMachine({
  id: "workshop",
  initial: WorkshopState.Initial,
  context: ({ input }): WorkshopContext => {
    const machineInput = input as
      | { client?: AppApolloClient; socketEventTarget?: EventTarget }
      | undefined;
    if (!machineInput?.client || !machineInput?.socketEventTarget)
      throw new Error(
        "Apollo client and socket event target must be provided!"
      );

    return {
      client: machineInput.client,
      socketEventTarget: machineInput.socketEventTarget,
      isSubscribed: false,
      autoJoinCompleted: false,
      sessionState: null,
      currentProfileId: null,
      transition: 0,
      transitionIntervalId: null,
      delayedStateData: [],
      connectionUUID: null,
      sessionId: null,
      errors: null,
    };
  },
  entry: [
    spawnChild(getNextWorkshopMachineId, {
      id: getNextWorkshopMachineId,
      systemId: getNextWorkshopMachineId,
    }),
    spawnChild(getFeedbacksMachineId, {
      id: getFeedbacksMachineId,
      systemId: getFeedbacksMachineId,
    }),
    spawnChild("cleanUp", { input: ({ self }) => ({ parent: self }) }),
  ],
  states: {
    [WorkshopState.Initial]: {
      on: {
        [actions.startWorkshop.type]: {
          target: WorkshopState.Start,
          actions: assign({
            currentProfileId: ({ event }) => event.payload.currentProfileId,
          }),
        },
        [actions.workshopEnd.type]: {
          actions: [
            ({ context, system, self }) => {
              const getNextWorkshopActor = system.get(
                getNextWorkshopMachineId
              ) as GetNextWorkshopActor;
              const getNextWorkshopSnapshot =
                getNextWorkshopActor.getSnapshot();
              if (
                !getNextWorkshopSnapshot.matches(FetchState.Fetching) &&
                !getNextWorkshopSnapshot.matches(FetchState.Success)
              ) {
                return void getNextWorkshopActor.send(
                  getNextWorkshopTrigger({ client: context.client })
                );
              }
              self.send(actions.workshopEnded());
            },
          ],
        },
        [getNextWorkshopSuccess.type]: {
          target: WorkshopState.Ended,
        },
        [getNextWorkshopFailure.type]: {
          target: WorkshopState.Ended,
        },
        [actions.workshopEnded.type]: {
          target: WorkshopState.Ended,
        },
      },
    },
    [WorkshopState.Start]: {
      entry: raise(({ context, event, self }) => {
        const startEvent = event as ReturnType<typeof actions.startWorkshop>;
        if (!context.isSubscribed)
          return actions.workshopSubscribe({
            ...startEvent.payload,
            parentActor: self,
          });
        if (!context.autoJoinCompleted)
          return actions.workshopJoin({
            sessionId: startEvent.payload.sessionId,
            uuid: startEvent.payload.uuid,
          });
        return actions.workshopReady();
      }),
      on: {
        [actions.workshopSubscribe.type]: {
          target: WorkshopState.Subscribing,
          guard: ({ context }) => !context.isSubscribed,
        },
        [actions.workshopJoin.type]: {
          target: WorkshopState.Joining,
        },
      },
    },
    [WorkshopState.Subscribing]: {
      entry: [
        spawnChild("subscription", {
          id: "apolloSubscription",
          input: ({ event, context }) => {
            const subscribeEvent = event as ReturnType<
              typeof actions.workshopSubscribe
            >;
            const payload = subscribeEvent.payload;
            return {
              payload,
              client: context.client,
              socketEventTarget: context.socketEventTarget,
              uuid: context.connectionUUID,
            };
          },
          syncSnapshot: true,
        }),
      ],
      on: {
        [actions.workshopJoin.type]: [
          {
            target: WorkshopState.Joining,
          },
        ],
      },
      exit: assign({
        isSubscribed: true,
      }),
    },
    [WorkshopState.Joining]: {
      invoke: {
        src: "join",
        onDone: {
          target: WorkshopState.Ready,
          actions: assign({
            sessionState: ({ event }) => event.output as SessionStateValue,
          }),
        },
        onError: {
          target: WorkshopState.Error,
          actions: assign({
            errors: ({ event }) => event.error,
          }),
        },
        input: ({ event, context }) => ({
          payload: (event as ReturnType<typeof actions.workshopJoin>).payload,
          client: context.client,
        }),
      },
      entry: assign({
        connectionUUID: ({ event }) =>
          (event as ReturnType<typeof actions.workshopJoin>).payload.uuid,
        sessionId: ({ event }) =>
          (event as ReturnType<typeof actions.workshopJoin>).payload.sessionId,
      }),
      exit: assign({
        autoJoinCompleted: true,
      }),
    },
    [WorkshopState.Disconnecting]: {
      invoke: {
        src: "disconnect",
        onDone: [
          {
            target: WorkshopState.Ready,
            guard: ({ event }) =>
              (
                event.output as {
                  intended: boolean;
                  type: WorkshopDisconnectType;
                }
              ).type === WorkshopDisconnectType.Observe,
          },
          {
            target: WorkshopState.Ended,
            guard: ({ event }) =>
              (
                event.output as {
                  intended: boolean;
                  type: WorkshopDisconnectType;
                }
              ).type === WorkshopDisconnectType.Default,
          },
          {
            target: WorkshopState.Kicked,
            guard: ({ event }) =>
              (
                event.output as {
                  intended: boolean;
                  type: WorkshopDisconnectType;
                  reason: KickReason;
                }
              ).type === WorkshopDisconnectType.Kick,
          },
        ],
        input: ({ event, context }) => ({
          payload: (event as ReturnType<typeof actions.workshopDisconnect>)
            .payload,
          client: context.client,
          connectionUUID: context.connectionUUID,
        }),
      },
    },
    [WorkshopState.Ready]: {
      on: {
        [actions.workshopSetActivityValue.type]: {
          target: WorkshopState.SettingValue,
          guard: "isCurrentProfileParticipating",
        },
        [actions.workshopSetActivityReady.type]: {
          target: WorkshopState.SettingReady,
          guard: "isCurrentProfileParticipating",
        },
        [actions.workshopReadyToStart.type]: {
          target: WorkshopState.SettingReadyToStart,
          guard: "isCurrentProfileParticipating",
        },
        [actions.workshopDisconnect.type]: {
          target: WorkshopState.Disconnecting,
          guard: "isCurrentProfileParticipating",
        },
        [actions.workshopJoin.type]: {
          target: WorkshopState.Joining,
        },
      },
    },
    [WorkshopState.SettingReadyToStart]: {
      invoke: {
        src: "readyToStart",
        input: ({ event, context }) => {
          const readyToStartEvent = event as ReturnType<
            typeof actions.workshopReadyToStart
          >;
          return { payload: readyToStartEvent.payload, client: context.client };
        },
        onDone: {
          target: WorkshopState.Ready,
        },
        onError: {
          target: WorkshopState.Ready, // TODO: Must handle error
        },
      },
    },
    [WorkshopState.NewSetValueWhileSettingValue]: {
      entry: [
        raise(({ event }) => {
          return event;
        }),
      ],
      on: {
        [actions.workshopSetActivityValue.type]: {
          target: WorkshopState.SettingValue,
        },
      },
    },
    [WorkshopState.SettingValue]: {
      on: {
        [actions.workshopSetActivityValue.type]: {
          target: WorkshopState.NewSetValueWhileSettingValue,
        },
      },
      invoke: {
        src: "setActivityValue",
        input: ({ event, context }) => {
          const setActivityValueEvent = event as ReturnType<
            typeof actions.workshopSetActivityValue
          >;
          return {
            payload: setActivityValueEvent.payload,
            client: context.client,
          };
        },
        onDone: {
          target: WorkshopState.Ready,
        },
        onError: {
          target: WorkshopState.Ready, // TODO: Must handle error
        },
      },
    },
    [WorkshopState.SettingReady]: {
      invoke: {
        src: "setActivityReady",
        input: ({ event, context }) => {
          const setActivityReadyEvent = event as ReturnType<
            typeof actions.workshopSetActivityReady
          >;
          return {
            payload: setActivityReadyEvent.payload,
            client: context.client,
          };
        },
        onDone: {
          target: WorkshopState.Ready,
        },
        onError: {
          target: WorkshopState.Ready, // TODO: Must handle error
        },
      },
    },
    [WorkshopState.Ended]: {
      type: "final",
      entry: [
        stopChild("subscription"),
        sendParent(() => {
          console.log("reset clock");
          return resetWorkshopClock();
        }),
      ],
    },
    [WorkshopState.Kicked]: {
      type: "final",
      entry: [
        stopChild("subscription"),
        sendParent(() => {
          console.log("reset clock");
          return resetWorkshopClock();
        }),
      ],
    },
    [WorkshopState.Error]: {
      type: "final",
      entry: [
        stopChild("subscription"),
        sendParent(() => {
          console.log("reset clock");
          return resetWorkshopClock();
        }),
      ],
    },
  },
  on: {
    [actions.workshopSubscriptionData.type]: {
      actions: assign({
        sessionState: ({ event, self, context, system }) => {
          const subscriptionDataEvent = event as ReturnType<
            typeof actions.workshopSubscriptionData
          >;
          const sessionState = subscriptionDataEvent.payload.data.sessionState;
          const incomingActiveProfile =
            sessionState.context.currentActiveProfiles.find(
              (entry) => entry.profileId === context.currentProfileId
            ) || null;
          const contextActiveProfile =
            context.sessionState?.context.currentActiveProfiles.find(
              (entry) => entry.profileId === context.currentProfileId
            ) || null;

          if (
            incomingActiveProfile &&
            contextActiveProfile &&
            incomingActiveProfile.uuid !== contextActiveProfile.uuid
          ) {
            self.send(
              actions.workshopDisconnect({
                sessionId: context.sessionId!,
                intended: true,
                type: WorkshopDisconnectType.Kick,
                reason: KickReason.MULTIPLE_CONNECTIONS,
              })
            );
            return sessionState;
          }

          const getFeedbacksActor = system.get(
            getFeedbacksMachineId
          ) as GetFeedbacksActor;
          const getFeedbackSnapshot = getFeedbacksActor.getSnapshot();
          const getNextWorkshopActor = system.get(
            getNextWorkshopMachineId
          ) as GetNextWorkshopActor;

          const getNextWorkshopSnapshot = getNextWorkshopActor.getSnapshot();

          if (
            sessionState.value.includes(StandardSessionActivity.EndEmotion) ||
            (sessionState.value.includes(StandardSessionActivity.Rating) &&
              !getFeedbackSnapshot.matches(FetchState.Fetching) &&
              !getFeedbackSnapshot.matches(FetchState.Success))
          ) {
            getFeedbacksActor.send(
              getFeedbacksTrigger({ client: context.client })
            );
          }
          if (
            sessionState.value.includes(StandardSessionActivity.Rating) ||
            (sessionState.value.includes(StandardSessionActivity.ViewResults) &&
              !getNextWorkshopSnapshot.matches(FetchState.Fetching) &&
              !getNextWorkshopSnapshot.matches(FetchState.Success))
          ) {
            getNextWorkshopActor.send(
              getNextWorkshopTrigger({ client: context.client })
            );
          }

          const workshopClockActor = self._parent!.getSnapshot().children[
            "workshopClock"
          ] as ReturnType<typeof useMachine<typeof workshopClockMachine>>["2"];
          let clockInstance =
            workshopClockActor.getSnapshot().context.clockInstance;

          const sessionContext = self._parent?.getSnapshot()
            .context as SessionMachineContext;
          const workshop =
            sessionContext.session?.workshop || sessionContext.slot?.workshop;

          if (
            !clockInstance?.isTicking &&
            sessionState.value !== StandardSessionActivity.Waiting &&
            sessionState.value !== StandardSessionActivity.ViewResults
          ) {
            const serverUnixStartTimestamp =
              sessionState.context.startTimestamp!;

            const durationInMinutes = workshop?.duration || 0;

            workshopClockActor.send(
              configureWorkshopClock({ durationInMinutes })
            );
            workshopClockActor.send(
              startWorkshopClock({ serverUnixStartTimestamp })
            );
          }

          const isStandardActivityState = standardSessionActivityList.includes(
            sessionState.value as StandardSessionActivity
          );
          if (!isStandardActivityState) {
            try {
              const [activityId, activityPart] = Object.entries(
                JSON.parse(sessionState.value)
              )[0];
              const activity = workshop?.activities.find(
                (d) => d.id === activityId
              );

              let activityDuration = 0;

              if (activity) {
                if (activityPart === ActivityPart.Individual) {
                  activityDuration = getIndividualDuration(activity) || 0;
                } else if (activityPart === ActivityPart.Group) {
                  activityDuration = getGroupDuration(activity) || 0;
                }
                activityDuration =
                  activityDuration || getDuration(activity) || 0;
              }

              clockInstance =
                workshopClockActor.getSnapshot().context.clockInstance;
              if (activityDuration) {
                const activityStartTimestamp =
                  sessionState.context.lastActivityPartTimestamp;
                clockInstance?.startActivity(
                  activityId,
                  activityStartTimestamp,
                  activityDuration
                );
              } else if (clockInstance?.hasRunningActivity) {
                clockInstance?.resetActivity();
              }
            } catch (e) {
              console.error("Error parsing state value");
            }
          }

          return sessionState;
        },
      }),
    },
    [actions.workshopSubscriptionDataTransition.type]: {
      actions: assign({
        transition: ({ event }) => {
          const subscriptionDataEvent = event as ReturnType<
            typeof actions.workshopSubscriptionDataTransition
          >;
          return subscriptionDataEvent.payload.transition;
        },
      }),
    },
    [actions.workshopParticipantChange.type]: {
      actions: sendParent(({ event: { payload } }) => {
        return sessionActions.sessionParticipantChange(payload);
      }),
    },
    [actions.setTransitionIntervalId.type]: {
      actions: assign({
        transitionIntervalId: ({ event }) => {
          const setTransitionIntervalIdEvent = event as ReturnType<
            typeof actions.setTransitionIntervalId
          >;
          return setTransitionIntervalIdEvent.payload.transitionIntervalId;
        },
      }),
    },
    [actions.pushDelayedStateData.type]: {
      actions: assign({
        delayedStateData: ({ event, context }) => {
          const pushDelayedStateDataEvent = event as ReturnType<
            typeof actions.pushDelayedStateData
          >;
          const {
            payload: {
              data: {
                sessionState: {
                  context: { currentActiveProfiles, reconnectTimeouts },
                },
              },
            },
          } = event;

          return [
            ...context.delayedStateData.map((prevEventData) => ({
              ...prevEventData,
              sessionState: {
                ...prevEventData.sessionState,
                context: {
                  ...prevEventData.sessionState.context,
                  currentActiveProfiles,
                  reconnectTimeouts,
                },
              },
            })),
            pushDelayedStateDataEvent.payload.data,
          ];
        },
        sessionState: ({ event, context }) => {
          // NOTE: Even if we have delayed state data that we have to apply
          // we need to apply the important things because while we are transitioning
          // someone might disconnect or connect and we need to apply the changes to the
          // state.
          const {
            payload: {
              data: {
                sessionState: {
                  context: { currentActiveProfiles, reconnectTimeouts },
                },
              },
            },
          } = event;

          return {
            ...context.sessionState!,
            context: {
              ...context.sessionState!.context,
              currentActiveProfiles,
              reconnectTimeouts,
            },
          };
        },
      }),
    },
    [actions.clearDelayedStateData.type]: {
      actions: assign({
        delayedStateData: [],
      }),
    },
    [actions.kick.type]: {
      target: `.${WorkshopState.Kicked}`,
    },
  },
});
