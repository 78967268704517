import { FC } from "react";
import { Helmet } from "react-helmet";

export type TitleProps = {
  pageTitle: string;
};

function withTitle<P>(Comp: FC<P>) {
  const TitleHoc = (props: P & TitleProps) => {
    const { pageTitle } = props;

    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Comp {...props} />
      </>
    );
  };

  TitleHoc.displayName = "TitleHoc";
  return TitleHoc;
}

export default withTitle;
