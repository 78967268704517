import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";

const mutation = gql`
  mutation Mutation(
    $activity_id: String!
    $workshop_id: String!
    $challenge: String!
  ) {
    createAuthorChallenge(
      activity_id: $activity_id
      workshop_id: $workshop_id
      challenge: $challenge
    ) {
      id
    }
  }
`;

export function createChallenge(
  client: AppApolloClient,
  variables: { activity_id: string; workshop_id: string; challenge: string }
) {
  return client
    .mutate<{ createAuthorChallenge: { id: string } }>({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.createAuthorChallenge;
    });
}
