import {
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import cn from "classnames";
import { RemoteParticipantData } from "../../types/jitsi";
import { Profile } from "../../apollo-graphql/types/profile";
import JitsiList from "../JitsiList/JitsiList";

import styles from "./SidePanel.module.css";
import TabSwitch from "../TabSwitch/TabSwitch";
import { Activity } from "../../apollo-graphql/types/activity";
import { Concept } from "../../apollo-graphql/types/concept";
import { ActivityType } from "../../types/enums/activity-type";
import { ActivityPart } from "../../types/enums/activity-part";
import { SessionStateValue } from "../../apollo-graphql/types/session-state";
import { SourceData } from "../../+xstate/machines/jitsi";
import { StandardSessionActivity } from "../../apollo-graphql/types/enums/standard-session-activity";
import {
  ACTIVITY_TIMEOUT_VALUE,
  INDIVIDUAL_SUSPENSION_POINTS,
  GROUP_SUSPENSION_POINTS,
} from "../../constants/global";
import InfoBox from "../InfoBox/InfoBox";
import DeviceSelect from "../Shared/DeviceSelect/DeviceSelect";
import { OutputDevice } from "../../types/output-device";

enum SidePanelTab {
  THEORY = "theory",
  PARTICIPANTS = "participants",
  ASSIGNMENT = "assignment",
}

const calculateTeamPoints = (
  allActivityResults: SessionStateValue["context"]["activityResult"],
  allQuestionActivitiesAnswersMap: { [key: string]: Activity["answers"] }
): number => {
  let points = 0;
  allActivityResults.forEach((activityResult) => {
    const questionAnswers = allQuestionActivitiesAnswersMap[activityResult.key];
    if (!questionAnswers) {
      return;
    }
    const groupResults = activityResult.value.find(
      ({ key }) => key === ActivityPart.Group
    );
    if (!groupResults) {
      return;
    }
    // If all players didn't choose group answer must be suspended with GROUP_SUSPENSION_POINTS points.
    const teamAnswerTimeout = groupResults.value
      .filter(({ ready }) => ready)
      .every((v) => v.value === ACTIVITY_TIMEOUT_VALUE);

    if (teamAnswerTimeout) {
      points += GROUP_SUSPENSION_POINTS;
      return;
    }

    const answerId = groupResults.value.find(
      (r) => r.ready && r.value !== ACTIVITY_TIMEOUT_VALUE
    )?.value;
    const teamIsAligned = groupResults.value
      .filter(({ ready }) => ready)
      .every((v) => v.value === answerId);

    if (!teamIsAligned) {
      return;
    }

    points += questionAnswers.find((a) => a.id === answerId)?.points || 0;
  });

  return points;
};

const calculateAllPlayerPoints = (
  allActivityResults: SessionStateValue["context"]["activityResult"],
  allQuestionActivitiesAnswersMap: { [key: string]: Activity["answers"] }
): { [playerId: string]: number } => {
  const playerPoints: { [playerId: string]: number } = {};
  allActivityResults.forEach((activityResult) => {
    const questionAnswers = allQuestionActivitiesAnswersMap[activityResult.key];
    if (!questionAnswers) {
      return;
    }
    const individualResults = activityResult.value.find(
      (a) => a.key === ActivityPart.Individual
    );
    if (!individualResults) {
      return;
    }
    individualResults.value
      .filter(({ ready }) => ready)
      .forEach((v) => {
        // If the player didn't choose individual answer must be suspended with SUSPENSION_POINTS points.
        const individualAnswerTimeout = v.value === ACTIVITY_TIMEOUT_VALUE;
        const points = individualAnswerTimeout
          ? INDIVIDUAL_SUSPENSION_POINTS
          : questionAnswers.find((a) => a.id === v.value)?.points || 0;

        const currentPlayerPoints = playerPoints[v.profileId];
        playerPoints[v.profileId] =
          (currentPlayerPoints !== undefined ? currentPlayerPoints : 0) +
          points;
      });
  });

  return playerPoints;
};

const calculatePoints = (
  profileId: string,
  allActivityResults: SessionStateValue["context"]["activityResult"],
  allQuestionActivitiesAnswersMap: { [key: string]: Activity["answers"] }
): {
  currentPlayerPoints: number;
  teamPoints: number;
  leadPlayerPoints: number;
} => {
  const teamPoints = calculateTeamPoints(
    allActivityResults,
    allQuestionActivitiesAnswersMap
  );
  const playerPoints = calculateAllPlayerPoints(
    allActivityResults,
    allQuestionActivitiesAnswersMap
  );

  const currentPlayerPoints = playerPoints[profileId] || 0;
  const leadPlayerPoints = Object.keys(playerPoints).length
    ? Math.max(...Object.values(playerPoints))
    : 0;
  return {
    currentPlayerPoints,
    teamPoints,
    leadPlayerPoints,
  };
};

function SidePanel(
  props: PropsWithChildren<{
    theoryConcepts: Concept[] | undefined;
    assignment?: string | null;
    currentActivity?: Activity | undefined;
    currentActivityPart?: ActivityPart;
    allActivityResults?: SessionStateValue["context"]["activityResult"];
    allQuestionActivitiesAnswersMap?: { [key: string]: Activity["answers"] };
    profile?: Profile;
    isUserTalking?: boolean;
    isParticipating?: boolean;
    disableAudioAndVideo?: boolean;
    containerClass?: string;
    hideTabSwitched?: boolean;
    hideControlPanel?: boolean;
    selectedAudioDevice?: SourceData | null;
    selectedVideoDevice?: SourceData | null;
    availableAudioSources?: SourceData[] | null;
    availableVideoSources?: SourceData[] | null;
    remoteParticipantsData?: RemoteParticipantData[];
    audioDeviceChangeHandler?: (newSourceId: string) => void;
    videoDeviceChangeHandler?: (newSourceId: string) => void;
    toggleParticipationHandler?: () => void;
    attachHtmlVideoElementToLocalTracks?: (
      videoElement: HTMLVideoElement
    ) => void;
    attachHtmlVideoElementToRemoteTracks?: (
      participantId: string,
      videoElement: HTMLVideoElement,
      audioElement: HTMLAudioElement
    ) => void;
    toggleAudioHandler?: (muted?: boolean) => void;
    toggleVideoHandler?: (muted?: boolean) => void;
    teamName?: string;
  }>
) {
  const {
    theoryConcepts,
    assignment,
    currentActivity,
    currentActivityPart,
    allActivityResults,
    allQuestionActivitiesAnswersMap,
    profile,
    isUserTalking,
    selectedAudioDevice,
    selectedVideoDevice,
    availableAudioSources,
    availableVideoSources,
    disableAudioAndVideo,
    remoteParticipantsData,
    isParticipating,
    attachHtmlVideoElementToLocalTracks,
    attachHtmlVideoElementToRemoteTracks,
    toggleParticipationHandler,
    toggleAudioHandler,
    toggleVideoHandler,
    audioDeviceChangeHandler,
    videoDeviceChangeHandler,
    containerClass,
    hideTabSwitched = false,
    hideControlPanel = false,
    teamName,
  } = props;

  const [activeTab, setActiveTab] = useState<SidePanelTab>(
    hideTabSwitched ? SidePanelTab.THEORY : SidePanelTab.PARTICIPANTS
  );

  const showControls = useMemo(
    () => !!selectedVideoDevice && !!selectedAudioDevice,
    [selectedAudioDevice, selectedVideoDevice]
  );

  const videoToggleHandlerWithDisable = useCallback(() => {
    toggleVideoHandler?.();
  }, [toggleVideoHandler]);

  const audioToggleHandlerWithDisable = useCallback(() => {
    toggleAudioHandler?.();
  }, [toggleAudioHandler]);

  const showLeaderBoard = useMemo(() => {
    let shouldShowLeaderBoard = false;
    const questionIds = Object.keys(allQuestionActivitiesAnswersMap || {});
    const activitiesWithReview =
      allActivityResults?.filter((a) =>
        a.value.find((a) => a.key === "review")
      ) || [];

    for (const { key } of activitiesWithReview) {
      if (questionIds.includes(key)) {
        shouldShowLeaderBoard = true;
        break;
      }
    }

    return (
      shouldShowLeaderBoard ||
      (!!currentActivity &&
        (currentActivity.type === ActivityType.Question ||
          currentActivity.id === StandardSessionActivity.Rating))
    );
  }, [allActivityResults, allQuestionActivitiesAnswersMap, currentActivity]);

  const { teamPoints, currentPlayerPoints, leadPlayerPoints } = useMemo(() => {
    if (profile?.id && allActivityResults && allQuestionActivitiesAnswersMap) {
      const activityResults =
        currentActivityPart !== ActivityPart.Review
          ? allActivityResults.filter(({ key }) => key !== currentActivity?.id)
          : allActivityResults;

      return calculatePoints(
        profile.id,
        activityResults,
        allQuestionActivitiesAnswersMap
      );
    }
    return { teamPoints: 0, currentPlayerPoints: 0, leadPlayerPoints: 0 };
  }, [
    allActivityResults,
    allQuestionActivitiesAnswersMap,
    currentActivity?.id,
    currentActivityPart,
    profile?.id,
  ]);

  const showTabSwitcher = useMemo(() => {
    return (
      ((theoryConcepts && theoryConcepts?.length > 0) || !!assignment) &&
      !hideTabSwitched
    );
  }, [assignment, theoryConcepts, hideTabSwitched]);

  const tabs = useMemo(() => {
    if (!showTabSwitcher) {
      return [];
    }
    const hasTheory = theoryConcepts && theoryConcepts?.length > 0;
    const hasAssignment = !!assignment;

    const tabs = [
      {
        id: SidePanelTab.PARTICIPANTS,
        label: "Participants",
      },
    ];
    if (hasTheory) {
      tabs.unshift({
        id: SidePanelTab.THEORY,
        label: "Theory",
      });
    }
    if (hasAssignment) {
      tabs.push({
        id: SidePanelTab.ASSIGNMENT,
        label: "Assignment",
      });
    }
    return tabs;
  }, [assignment, showTabSwitcher, theoryConcepts]);

  const jitsiListConfig: { width: number; height: number } = useMemo(() => {
    const value = !remoteParticipantsData
      ? 0
      : remoteParticipantsData.length > 3
      ? 150
      : 160;
    return {
      width: value,
      height: value,
    };
  }, [remoteParticipantsData]);

  const parsedAvailableAudioSources = useMemo(
    () =>
      (availableAudioSources || [])
        .map((source) => ({
          label: source.label!,
          value: source.sourceId!,
          key: source.sourceId!,
        }))
        .filter((source) => !!source.value),
    [availableAudioSources]
  );

  const parsedAvailableVideoSources = useMemo(
    () =>
      (availableVideoSources || [])
        .map((source) => ({
          label: source.label!,
          value: source.sourceId!,
          key: source.sourceId!,
        }))
        .filter((source) => !!source.value),
    [availableVideoSources]
  );

  useEffect(() => {
    if (!showTabSwitcher) {
      return;
    }
    if (currentActivity?.type === ActivityType.Theory) {
      setActiveTab(SidePanelTab.THEORY);
    }
    if (currentActivity?.type === ActivityType.Assignment) {
      setActiveTab(SidePanelTab.ASSIGNMENT);
    }
    setActiveTab(SidePanelTab.PARTICIPANTS);
  }, [currentActivity?.type, showTabSwitcher]);

  return (
    <div className={cn(styles.sidePanelContainer, containerClass || "")}>
      {showLeaderBoard && (
        <div className={cn(styles.leaderBoardContainer, "main-container")}>
          <h3 className={styles.leaderBoardHeader}>Leaderboard</h3>
          <div className={styles.leaderBoardStatsContainer}>
            <div className={styles.leaderBoardStat}>
              <i
                className={cn(styles.leaderBoardStatIcon, "icon fa fa-users")}
              />
              <span className={cn(styles.leaderBoardStatPoints, "primary")}>
                {teamPoints}
              </span>
              <span className={cn(styles.leaderBoardStatLabel, "text", "tiny")}>
                Team points
              </span>
            </div>

            <div className="separator"></div>

            <div className={styles.leaderBoardStat}>
              <i
                className={cn(styles.leaderBoardStatIcon, "icon fa fa-star")}
              />
              <span className={cn(styles.leaderBoardStatPoints, "primary")}>
                {currentPlayerPoints}
              </span>
              <span className={cn(styles.leaderBoardStatLabel, "text", "tiny")}>
                Your points
              </span>
            </div>

            <div className="separator"></div>

            <div className={styles.leaderBoardStat}>
              <i
                className={cn(styles.leaderBoardStatIcon, "icon fa fa-trophy")}
              />
              <span className={cn(styles.leaderBoardStatPoints, "primary")}>
                {leadPlayerPoints}
              </span>
              <span className={cn(styles.leaderBoardStatLabel, "text", "tiny")}>
                Lead Player
              </span>
            </div>
          </div>
          <div className={styles.pointsInfoBox}>
            <InfoBox description="This is not a competition. Points shed light on your team dynamics." />
          </div>
        </div>
      )}
      <div className={cn(styles.container, "main-container")}>
        {showTabSwitcher && (
          <TabSwitch
            tabs={tabs}
            activeTabId={activeTab}
            setActiveTab={(t) => setActiveTab(t as SidePanelTab)}
          />
        )}
        <div className={styles.content}>
          <div
            style={{
              visibility:
                activeTab === SidePanelTab.PARTICIPANTS ? "visible" : "hidden",
              height: activeTab === SidePanelTab.PARTICIPANTS ? "auto" : 0,
            }}
          >
            {attachHtmlVideoElementToLocalTracks &&
              attachHtmlVideoElementToRemoteTracks &&
              remoteParticipantsData && (
                <div
                  className={cn(
                    styles.participantsContainer,
                    showLeaderBoard ? "leaderBoardVisible" : ""
                  )}
                >
                  <JitsiList
                    width={jitsiListConfig.width}
                    height={jitsiListConfig.height}
                    profile={profile!}
                    isUserTalking={isUserTalking!}
                    disableAudioAndVideo={disableAudioAndVideo}
                    isCurrentUserVideoMutedOrUnavailable={
                      !!selectedVideoDevice?.isMuted ||
                      !selectedVideoDevice?.sourceId
                    }
                    isCurrentUserAudioMutedOrUnavailable={
                      !!selectedAudioDevice?.isMuted ||
                      !selectedAudioDevice?.sourceId
                    }
                    remoteParticipantsData={remoteParticipantsData}
                    attachHtmlVideoElementToLocalTracks={
                      attachHtmlVideoElementToLocalTracks
                    }
                    attachHtmlVideoElementToRemoteTracks={
                      attachHtmlVideoElementToRemoteTracks
                    }
                    teamName={teamName}
                  />
                </div>
              )}
          </div>
          {activeTab === SidePanelTab.THEORY && (
            <div
              className={cn(
                styles.theoryContainer,
                showLeaderBoard ? "leaderBoardVisible" : ""
              )}
            >
              {theoryConcepts?.map((concept) => (
                <div
                  key={concept.sequence_number}
                  className={styles.conceptContainer}
                >
                  <h3
                    className={cn(styles.conceptTitle, "text", "bold")}
                    dangerouslySetInnerHTML={{
                      __html: concept.name.replaceAll(/\\n|\\/gm, ""),
                    }}
                  ></h3>
                  <div
                    className={cn(styles.conceptContent, "text")}
                    dangerouslySetInnerHTML={{
                      __html: concept.text.replaceAll(/\\n|\\/gm, ""),
                    }}
                  />
                </div>
              ))}
            </div>
          )}
          {activeTab === SidePanelTab.ASSIGNMENT && (
            <div
              className={cn(
                styles.theoryContainer,
                showLeaderBoard ? "leaderBoardVisible" : ""
              )}
            >
              <div className={styles.conceptContainer}>
                <div
                  className={cn(styles.conceptContent, "text")}
                  dangerouslySetInnerHTML={{
                    __html: assignment!.replaceAll(/\\n|\\/gm, ""),
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {!hideControlPanel && (
          <div className={styles.controlsContainer}>
            {showControls &&
              audioDeviceChangeHandler &&
              videoDeviceChangeHandler && (
                <div className={styles.deviceControls}>
                  <div
                    className={cn(
                      styles.deviceControl,
                      selectedAudioDevice?.isMuted && "device-muted",
                      disableAudioAndVideo && "deactivated"
                    )}
                  >
                    <button
                      className={cn(
                        "btn small transparent-bg disable-disabled-opacity"
                      )}
                      onClick={audioToggleHandlerWithDisable}
                      disabled={disableAudioAndVideo}
                    >
                      <i
                        className={cn(
                          "icon fa",
                          selectedAudioDevice?.isMuted || disableAudioAndVideo
                            ? "fa-microphone-slash red"
                            : "fa-microphone secondary"
                        )}
                      ></i>
                    </button>
                    <DeviceSelect
                      deviceName={OutputDevice.AUDIO}
                      deviceId={selectedAudioDevice?.sourceId}
                      values={parsedAvailableAudioSources}
                      disabled={
                        !!disableAudioAndVideo || !!selectedAudioDevice?.isMuted
                      }
                      onClickHandler={audioDeviceChangeHandler}
                      initialArrowDirection="up"
                    />
                  </div>
                  <div
                    className={cn(
                      styles.deviceControl,
                      selectedVideoDevice?.isMuted && "device-muted",
                      disableAudioAndVideo && "deactivated"
                    )}
                  >
                    <button
                      className={cn(
                        "btn small secondary transparent-bg disable-disabled-opacity"
                      )}
                      onClick={videoToggleHandlerWithDisable}
                      disabled={disableAudioAndVideo}
                    >
                      <i
                        className={cn(
                          "icon fa",
                          selectedVideoDevice?.isMuted || disableAudioAndVideo
                            ? "fa-video-slash red"
                            : "fa-video"
                        )}
                      ></i>
                    </button>
                    <DeviceSelect
                      deviceName={OutputDevice.VIDEO}
                      deviceId={selectedVideoDevice?.sourceId}
                      values={parsedAvailableVideoSources}
                      disabled={
                        !!disableAudioAndVideo || !!selectedVideoDevice?.isMuted
                      }
                      onClickHandler={videoDeviceChangeHandler}
                      initialArrowDirection="up"
                    />
                  </div>
                </div>
              )}

            {/* Temporary solution to hide Observe button */}
            {!isParticipating && (
              <button
                className="btn small secondary"
                onClick={toggleParticipationHandler}
              >
                <i className="icon fa fa-right-to-bracket" /> Join
              </button>
            )}

            {/* <button
              className="btn small secondary"
              onClick={toggleParticipationHandler}
            >
              {isParticipating ? (
                <>
                  <i className="icon fa fa-eye" /> Observe
                </>
              ) : (
                <>
                  <i className="icon fa fa-right-to-bracket" /> Join
                </>
              )}
            </button> */}
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(SidePanel);
