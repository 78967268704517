import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SessionContext } from "../contexts/Session";
import { useMatches, Navigate, useNavigate } from "react-router-dom";
import { GlobalContext } from "../contexts/Global";
import { SessionState } from "../+xstate/machines/session/session";
import { JitsiSetup } from "../components/JitsiSetup/JitsiSetup";
import { JitsiContext } from "../contexts/Jitsi";
import {
  JitsiConnectionStates,
  JitsiSetupStates,
} from "../+xstate/machines/jitsi";
import WorkshopInstance from "../components/Workshop/Workshop";
import WaitingRoom from "../components/WaitingRoom/WaitingRoom";
import SlotActiveSessionList from "../components/WaitingRoom/SlotActiveSessionList";
import { extractActivityDataFromSessionState } from "../utils/extract-activity-data-from-session-state";
import { ActivityPart } from "../types/enums/activity-part";

import SidePanel from "../components/SidePanel/SidePanel";
import Header from "../components/Workshop/Header";
import WorkshopExitModal from "../components/Workshop/WorkshopExitModal/WorkshopExitModal";
import Loader from "../components/Shared/Loader/Loader";
import ThankYou from "../components/Workshop/ThankYou/ThankYou";
import ConnectionLost from "../components/Workshop/ConnectionLost/ConnectionLost";
import { ActivityType } from "../types/enums/activity-type";
import { Concept } from "../apollo-graphql/types/concept";
import { Activity } from "../apollo-graphql/types/activity";
import { SessionStatus } from "../apollo-graphql/types/enums/session-status";
import { WorkshopClockContextProvider } from "../contexts/WorkshopClock";
import { WorkshopState } from "../+xstate/machines/session/workshop";
import { StandardSessionActivity } from "../apollo-graphql/types/enums/standard-session-activity";
import { WorkshopDisconnectType } from "../types/enums/workshop-disconnect-type";
import { SessionStateValue } from "../apollo-graphql/types/session-state";
import { JitsiEvents } from "../jitsi";
import { SlotType } from "../apollo-graphql/types/enums/slot-type";
import { parseSession } from "../utils/parse-session";
import { getTeamName } from "../utils/get-team-name";
import { Workspace } from "../apollo-graphql/types/workspace";
import { FetchState } from "../+xstate/machines/fetch-factory";
import styles from "./Session.module.css";
import { ApolloContext } from "../contexts/Apollo";

export default function Session() {
  const navigate = useNavigate();
  const matches = useMatches();
  const sessionContext = useContext(SessionContext);
  const globalContext = useContext(GlobalContext);
  const jitsiContext = useContext(JitsiContext);
  const apolloContext = useContext(ApolloContext);

  const connectionStrength =
    apolloContext.socket.state.context.connectionStrength;

  const slotMatch = matches.find((item) => item.id === "session-slot");
  const instanceMatch = matches.find((item) => item.id === "session-instance");
  const instanceGroupMatch = matches.find(
    (item) => item.id === "session-instance-group"
  );
  const waitingRoomMatch = matches.find((item) => item.id === "waiting-room");
  const ongoingSessionsMatch = matches.find(
    (item) => item.id === "ongoing-sessions"
  );
  const waitingRoomRescheduleRedirectMatch = matches.find(
    (item) => item.id === "reschedule-redirect"
  );
  const thankYouMatch = matches.find((item) => item.id === "thank-you");

  const connectionLostMatch = matches.find(
    (item) => item.id === "connection-lost"
  );

  const hasLostConnection = jitsiContext.state.matches({
    connection: JitsiConnectionStates.Disconnected,
  });

  const instanceUUID = globalContext.instanceUUID;
  const profileId = globalContext.auth.context.profile!.id;
  const slotInstance = sessionContext.session.state.context.slot;
  const participantProfiles = sessionContext.session.state.context.profiles;
  const sessionInstance = sessionContext.session.state.context.session;
  const transition = sessionContext.workshop.state.context.transition;
  const workshopErrors = sessionContext.workshop.state.context.errors;
  
  const isSettingValue = sessionContext.workshop.state.matches(WorkshopState.SettingValue);
  const requestNextWorkshopMachine = sessionContext.requestNextWorkshop;
  const requestNextWorkshop = useCallback(
    (id: string) => {
      requestNextWorkshopMachine.trigger(id);
    },
    [requestNextWorkshopMachine]
  );
  const nextWorkshopRequested = !requestNextWorkshopMachine.state.matches(
    FetchState.Idle
  );

  const getWorkshopCoverageMachine = sessionContext.getWorkshopCoverage;
  const getWorkshopCoverage = useCallback(
    (id: string, workspaceId: string) => {
      getWorkshopCoverageMachine.trigger(id, workspaceId);
    },
    [getWorkshopCoverageMachine]
  );
  const getWorkshopCoverageRequested =
    !getWorkshopCoverageMachine.state.matches(FetchState.Idle);
  const workshopCoverageData = getWorkshopCoverageMachine.state.context.data;

  const workshop = slotInstance?.workshop;
  const workspace = (globalContext.auth.context.profile?.workspace as any)
    ?.workspace as Workspace | undefined;

  const nextWorkshop = sessionContext.getNextWorkshop?.state?.context?.data;
  const feedbacks = sessionContext.getFeedbacks?.state?.context?.data;

  const isFetchingNextWorkshop =
    sessionContext.getNextWorkshop?.state?.matches(FetchState.Fetching) ||
    false;
  const isFetchingFeedbacks =
    sessionContext.getFeedbacks?.state?.matches(FetchState.Fetching) || false;

  const sessionId = sessionInstance?.id;
  const {
    millisecondsToStart,
    splitMillisecondsWaitingTime,
    sessionOpeningTimeInMilliseconds,
    invitationStatus,
    invitationId,
    invitationResponseServerTimestamp,
    group,
  } = sessionContext.session.state.context;

  const invitationNotFound = sessionContext.session.state.matches({
    session: SessionState.InvitationNotFound,
  });
  const sessionNotFound =
    sessionContext.session.state.matches({
      session: SessionState.SessionNotFound,
    }) ||
    (!!sessionId && !group);
  const isInviteState = sessionContext.session.state.matches({
    session: SessionState.Invite,
  });

  const nameRef = useRef<string | null>(null);

  useEffect(() => {
    if (!nameRef.current) {
      nameRef.current = globalContext.auth.context.profile?.name || null;
      return;
    }
    if (nameRef.current !== globalContext.auth.context.profile?.name) {
      jitsiContext.handleUserNameChange({
        participantId: profileId,
        name: globalContext.auth.context.profile?.name || "",
      });
      nameRef.current = globalContext.auth.context.profile?.name || null;
    }
  }, [
    globalContext.auth.context.profile?.name,
    jitsiContext,
    profileId,
    sessionContext.session,
  ]);

  // TODO: Refactor when we connect all machines together. At the moment
  // there is no other way for jitsi to communicate to the other machines
  // because they are separate. The idea here is when someone changes their name
  // for us to update their profile information by fetching it from the backend
  useEffect(() => {
    function nameChangeHandler(event: Event) {
      const detail = (event as CustomEvent).detail as {
        attributes: {
          participantId: string;
          name: string;
        };
      };

      const {
        attributes: { participantId },
      } = detail;
      sessionContext.workshop.workshopParticipantChange({
        refetchParticipantIds: [participantId],
      });
    }

    jitsiContext.state.context.jitsiInstance?.addEventListener(
      JitsiEvents.USER_NAME_CHANGED,
      nameChangeHandler
    );
    return () => {
      jitsiContext.state.context.jitsiInstance?.removeEventListener(
        JitsiEvents.USER_NAME_CHANGED,
        nameChangeHandler
      );
    };
  }, [jitsiContext.state.context.jitsiInstance, sessionContext.workshop]);

  const isSessionCompleted =
    sessionInstance?.status === SessionStatus.COMPLETED;

  const isSetupDone = jitsiContext.state.matches({
    setup: JitsiSetupStates.Done,
  });

  const isKicked = sessionContext.workshop.state.matches(WorkshopState.Kicked);
  const isErrored = sessionContext.workshop.state.matches(WorkshopState.Error);

  const sessionState: SessionStateValue = useMemo(() => {
    if (sessionInstance?.status === SessionStatus.COMPLETED) {
      return parseSession(sessionInstance.state);
    }
    return {
      value: sessionContext.workshop.state.context.sessionState?.value,
      context: sessionContext.workshop.state.context.sessionState?.context,
    } as SessionStateValue;
  }, [
    sessionContext.workshop.state.context,
    sessionInstance?.state,
    sessionInstance?.status,
  ]);

  const isJitsiInInitialState = jitsiContext.state.matches({
    setup: JitsiSetupStates.Initial,
  });

  const isConnected = jitsiContext.state.matches({
    connection: JitsiConnectionStates.Connected,
  });
  const selectedAudioSourceData =
    jitsiContext.state.context.selectedAudioSourceData;
  const selectedVideoSourceData =
    jitsiContext.state.context.selectedVideoSourceData;
  const availableAudioSources =
    jitsiContext.state.context.availableAudioSources;
  const availableVideoSources =
    jitsiContext.state.context.availableVideoSources;

  const [videoElementRef, setVideoElementRef] =
    useState<HTMLVideoElement | null>(null);

  const isParticipating = useMemo(
    () =>
      isSessionCompleted
        ? true
        : !!sessionState?.context?.currentActiveProfiles.find(
            (item) => item.profileId === profileId
          ),
    [
      isSessionCompleted,
      profileId,
      sessionState?.context?.currentActiveProfiles,
    ]
  );

  const isReadyToStart = useMemo(
    () =>
      isParticipating &&
      !!sessionState?.context?.readyActiveProfiles.includes(profileId),
    [isParticipating, profileId, sessionState?.context?.readyActiveProfiles]
  );

  // Note: since currentActiveProfiles gets updated with new references when the state gets updated
  // I'm constructing a string from it so we can check if the remote user has configured everything properly
  // before showing him to the others. This filtering happens bellow in remoteSourceData which if not using a
  // string will get recalculated every time the context currentActiveProfiles changes even if we haven't got
  // any changes in the array itself so by using a string we pretty much resolve this recalculation.
  const currentActiveProfilesString = useMemo(() => {
    return isSessionCompleted
      ? (jitsiContext.state.context.remoteParticipantsData || [])
          .map(({ name }) => name)
          .join(",")
      : Array.from(
          new Set([
            ...(sessionState?.context?.currentActiveProfiles.map(
              ({ profileId }) => profileId + "_cap"
            ) || []),
            ...(sessionState?.context?.reconnectTimeouts.map(
              ({ profileId }) => profileId + "_rt"
            ) || []),
          ])
        ).join(",") || "";
  }, [
    isSessionCompleted,
    jitsiContext.state.context.remoteParticipantsData,
    sessionState?.context?.currentActiveProfiles,
    sessionState?.context?.reconnectTimeouts,
  ]);

  const remoteParticipantsData = useMemo(() => {
    if (!currentActiveProfilesString) return [];
    const uniqueRemoteParticipantIds = Array.from(
      new Set(
        currentActiveProfilesString
          .split(",")
          .map((entry) => entry.replace(/_(cap|rt)/g, ""))
      )
    );

    return uniqueRemoteParticipantIds
      .filter((id) => {
        return id !== profileId;
      })
      .map((id) => {
        const remoteParticipantEntry =
          jitsiContext.state.context.remoteParticipantsData?.find(
            (p) => p.name === id
          )!;

        const profile =
          participantProfiles.find((p) => p.id === id) ||
          slotInstance?.invitations.find(
            (invitation) => invitation.profile.id === id
          )?.profile;

        if (!profile)
          return {
            ...remoteParticipantEntry,
            name: "Loading...",
            isConnecting: false,
          };

        const isConnecting = !!sessionState?.context?.reconnectTimeouts.find(
          (r) => r.profileId === profile.id
        );

        return {
          ...remoteParticipantEntry,
          name: profile.name,
          isConnecting,
          profileId: profile.id,
          workspaceId: profile.workspace.workspace_id,
        };
      });
    // I'm commenting out this because it's breaking the
    // logic for showing Connecting... when we have reconnect timeout
    // if this causes some issues please bring it back but if it doesn't
    // let's remove it after some time (12.05.2024)
    // .filter((x) => !!x?.participantId);
  }, [
    currentActiveProfilesString,
    jitsiContext.state.context.remoteParticipantsData,
    participantProfiles,
    profileId,
    sessionState?.context?.reconnectTimeouts,
    slotInstance?.invitations,
  ]);

  const { id: currentActivityId, part: currentActivityPart } = useMemo(
    () => extractActivityDataFromSessionState(sessionState?.value || null),
    [sessionState?.value]
  );
  const currentActivity = useMemo(
    () =>
      slotInstance?.workshop.activities.find((a) => a.id === currentActivityId),
    [currentActivityId, slotInstance?.workshop.activities]
  );

  const isViewResultsStage = useMemo(
    () => currentActivityId === StandardSessionActivity.ViewResults,
    [currentActivityId]
  );

  useEffect(() => {
    if (
      !isViewResultsStage ||
      !workspace?.id ||
      !workshop?.id ||
      getWorkshopCoverageRequested
    )
      return;
    getWorkshopCoverage(workshop.id, workspace.id);
  }, [
    getWorkshopCoverage,
    getWorkshopCoverageRequested,
    isViewResultsStage,
    workshop?.id,
    workspace?.id,
  ]);

  const allQuestionActivitiesAnswersMap: {
    [key: string]: Activity["answers"];
  } = useMemo(
    () =>
      (
        slotInstance?.workshop.activities.filter(
          (activity: Activity) => activity.type === ActivityType.Question
        ) || []
      ).reduce(
        (acc, activity) => ({
          ...acc,
          [activity.id]: activity.answers || [],
        }),
        {}
      ),
    [slotInstance?.workshop.activities]
  );

  const autoDisableAudioAndVideoForIndividualScreen = useMemo(() => {
    if (!currentActivity) return false;
    const { id, type } = currentActivity;
    const isTheoryOrAssignment =
      type === ActivityType.Assignment || type === ActivityType.Theory;
    const isAhaMoment = type === ActivityType.Moment;
    const isIndividualPart = currentActivityPart === ActivityPart.Individual;
    if (isTheoryOrAssignment) return true;
    if (isAhaMoment) return false;
    if (!isIndividualPart) return false;
    const isEmotionActivity =
      id === StandardSessionActivity.EndEmotion ||
      id === StandardSessionActivity.StartEmotion;
    return !isEmotionActivity;
  }, [currentActivity, currentActivityPart]);

  useEffect(() => {
    if (
      jitsiContext.state.context.activityPartShouldBeMuted !==
      autoDisableAudioAndVideoForIndividualScreen
    ) {
      jitsiContext.handleActivityPartChange({
        shouldBeMuted: autoDisableAudioAndVideoForIndividualScreen,
      });
    }
  }, [
    currentActivityPart,
    autoDisableAudioAndVideoForIndividualScreen,
    jitsiContext,
  ]);

  const toggleParticipationHandler = useCallback(() => {
    if (!sessionInstance) return;
    if (isParticipating) {
      sessionContext.workshop.workshopDisconnect({
        sessionId: sessionInstance.id,
        intended: true,
        type: WorkshopDisconnectType.Observe,
      });
    } else {
      sessionContext.workshop.workshopJoin({
        sessionId: sessionInstance.id,
        uuid: instanceUUID,
      });
    }
  }, [instanceUUID, isParticipating, sessionContext.workshop, sessionInstance]);

  const setReadyToStartHandler = useCallback(() => {
    if (!sessionInstance || isReadyToStart) return;
    sessionContext.workshop.workshopReadyToStart({
      sessionId: sessionInstance.id,
    });
  }, [isReadyToStart, sessionContext.workshop, sessionInstance]);

  const setActivityValueHandler = useCallback(
    ({ activityId, value }: { activityId: string; value: string }) => {
      if (!sessionInstance) return;
      sessionContext.workshop.workshopSetActivityValue({
        sessionId: sessionInstance.id,
        activityId,
        value,
      });
    },
    [sessionContext.workshop, sessionInstance]
  );
  const setActivityReadyHandler = useCallback(
    ({ activityId }: { activityId: string }) => {
      if (!sessionInstance) return;
      sessionContext.workshop.workshopSetActivityReady({
        sessionId: sessionInstance.id,
        activityId,
      });
    },
    [sessionContext.workshop, sessionInstance]
  );
  const setupCompletedHandler = useCallback(() => {
    jitsiContext.joinConference({});
    if (isSessionCompleted) {
      sessionContext.workshop.workshopEnd();
    } else {
      sessionContext.workshop.startWorkshop({
        sessionId: sessionInstance!.id,
        currentProfileId: profileId,
        uuid: instanceUUID,
      });
    }
  }, [
    instanceUUID,
    isSessionCompleted,
    jitsiContext,
    profileId,
    sessionContext.workshop,
    sessionInstance,
  ]);
  const audioDeviceChangeHandler = useCallback(
    (newSourceId: string) => {
      const newSelectedAudioSourceData = availableAudioSources!.find(
        (s) => s.sourceId === newSourceId
      )!;

      jitsiContext.configure({
        selectedAudioSourceData: {
          ...newSelectedAudioSourceData,
          isMuted: selectedAudioSourceData?.isMuted || false,
        },
        selectedVideoSourceData: selectedVideoSourceData!,
      });
    },
    [
      availableAudioSources,
      jitsiContext,
      selectedAudioSourceData?.isMuted,
      selectedVideoSourceData,
    ]
  );
  const videoDeviceChangeHandler = useCallback(
    (newSourceId: string) => {
      const newSelectedVideoSourceData = availableVideoSources!.find(
        (s) => s.sourceId === newSourceId
      )!;

      jitsiContext.configure({
        selectedVideoSourceData: {
          ...newSelectedVideoSourceData,
          isMuted: selectedVideoSourceData?.isMuted || false,
        },
        selectedAudioSourceData: selectedAudioSourceData!,
      });
    },
    [
      availableVideoSources,
      jitsiContext,
      selectedAudioSourceData,
      selectedVideoSourceData?.isMuted,
    ]
  );
  const toggleAudioHandler = useCallback(
    (muted?: boolean) => {
      if (!selectedAudioSourceData) return;
      const isMuted =
        typeof muted === "boolean" ? muted : !selectedAudioSourceData!.isMuted;
      if (isMuted === selectedAudioSourceData.isMuted) return;
      jitsiContext.configure({
        selectedAudioSourceData: {
          ...selectedAudioSourceData!,
          isMuted,
        },
        selectedVideoSourceData: selectedVideoSourceData!,
      });
    },
    [jitsiContext, selectedAudioSourceData, selectedVideoSourceData]
  );

  const toggleVideoHandler = useCallback(
    (muted?: boolean) => {
      if (!selectedVideoSourceData) return;
      const isMuted =
        typeof muted === "boolean" ? muted : !selectedVideoSourceData!.isMuted;
      if (isMuted === selectedVideoSourceData.isMuted) return;
      jitsiContext.configure({
        selectedVideoSourceData: {
          ...selectedVideoSourceData!,
          isMuted,
        },
        selectedAudioSourceData: selectedAudioSourceData!,
      });
    },
    [jitsiContext, selectedAudioSourceData, selectedVideoSourceData]
  );

  const waitingRoomTimeoutNavigation = useCallback(
    (slotId: string) => {
      sessionContext.session.reset();
      navigate(`/session/slot/${slotId}`);
    },
    [navigate, sessionContext.session]
  );

  // Info: This load slot and session data
  useEffect(() => {
    if (sessionContext.session.state.context.error) return;

    if (
      !isInviteState &&
      (slotMatch || waitingRoomMatch || ongoingSessionsMatch) &&
      !slotInstance
    ) {
      const slotId = (slotMatch || waitingRoomMatch || ongoingSessionsMatch)!
        .params.slotId!;
      const email = globalContext.auth.context.profile!.email;
      return void sessionContext.session.getInvite({
        variables: { email, slotId },
      });
    }
    if ((instanceMatch || instanceGroupMatch) && !sessionInstance) {
      const sessionKey = (instanceMatch?.params.sessionKey ||
        instanceGroupMatch?.params.sessionKey)!;
      const includeSlotAndWorkshop = !slotInstance;
      const group = instanceGroupMatch?.params?.group
        ? +instanceGroupMatch.params.group
        : undefined;
      return void sessionContext.session.getSession({
        variables: {
          sessionKey,
          includeSlotAndWorkshop,
          group,
        },
      });
    }
  }, [
    globalContext.auth.context.profile,
    instanceMatch,
    isInviteState,
    matches,
    sessionContext,
    sessionInstance,
    slotInstance,
    slotMatch,
    waitingRoomMatch,
    ongoingSessionsMatch,
    instanceGroupMatch,
  ]);

  // Info: Initialize Jitsi when ready
  useLayoutEffect(() => {
    if (!isJitsiInInitialState || !sessionId || !videoElementRef) return;
    jitsiContext.initialize({
      profileId,
      sessionId: sessionInstance.id,
      htmlVideoElement: videoElementRef!,
    });
  }, [
    isJitsiInInitialState,
    jitsiContext,
    profileId,
    sessionId,
    sessionInstance,
    videoElementRef,
  ]);

  const theoryConcepts: Concept[] = useMemo(() => {
    const theoryActivity = workshop?.activities.find(
      (a) => a.type === ActivityType.Theory
    );
    if (!theoryActivity) {
      return [];
    }

    const currentActivityIsTheoryOrIsAfterIt =
      currentActivity?.sequence_number! >= theoryActivity.sequence_number;
    if (currentActivityIsTheoryOrIsAfterIt) {
      return theoryActivity.concepts || [];
    }
    return [];
  }, [currentActivity?.sequence_number, workshop?.activities]);

  const assignment: string | null = useMemo(() => {
    const workshopAssignment = workshop?.activities.find(
      (a) => a.type === ActivityType.Assignment
    );
    if (!workshopAssignment) {
      return null;
    }

    const currentActivityIsAssignmentOrAfterIt =
      currentActivity?.sequence_number! >= workshopAssignment.sequence_number;
    if (currentActivityIsAssignmentOrAfterIt) {
      const assignmentText = workshopAssignment.assignment?.text;
      return assignmentText || null;
    }
    return null;
  }, [currentActivity?.sequence_number, workshop?.activities]);

  const teamName = useMemo(
    () => getTeamName(sessionState?.context?.activityResult, ""),
    [sessionState?.context?.activityResult]
  );

  const reconnectTimeouts = useMemo(
    () => sessionState?.context?.reconnectTimeouts || [],
    [sessionState?.context?.reconnectTimeouts]
  );

  // TODO: consider moving waiting room navigation outside session to remove the
  // need for session reset call because Session will be destroyed and once we navigate
  // back we will have a brand new machine
  useEffect(() => {
    if (!waitingRoomRescheduleRedirectMatch) return;
    sessionContext.session.reset();
  }, [sessionContext.session, waitingRoomRescheduleRedirectMatch]);

  if (isErrored)
    return (
      <div>
        <h1>Error. Please reload page.</h1>
        <div>{JSON.stringify(workshopErrors)}</div>
      </div>
    );
  if (isKicked) return <Navigate to="/disconnected" />;
  if (matches.length < 3) return <Navigate to="/" />;
  if (sessionContext.session.state.context.error) return <div>Error</div>;

  if (thankYouMatch) {
    return <ThankYou pageTitle="Thank You - AhaPlay" />;
  }

  if (connectionLostMatch) {
    return <ConnectionLost pageTitle="Connection Lost - AhaPlay" />;
  }

  if (
    slotInstance &&
    (instanceMatch || instanceGroupMatch) &&
    typeof group === "number" &&
    +(instanceGroupMatch?.params?.group || "0") !== group
  ) {
    return <Navigate to={`/session/instance/${slotInstance.key}/${group}`} />;
  }

  if (waitingRoomRescheduleRedirectMatch)
    return (
      <Navigate
        to={`/session/slot/${waitingRoomRescheduleRedirectMatch.params.newSlotId}`}
      />
    );

  if (
    (slotMatch || ongoingSessionsMatch) &&
    slotInstance &&
    slotInstance.sessions.length > 0 &&
    slotInstance.type === SlotType.SPLIT &&
    (invitationResponseServerTimestamp || 0) -
      Math.min(...slotInstance.sessions.map((s) => s.create_date)) >
      60
  ) {
    if (ongoingSessionsMatch) {
      return (
        <SlotActiveSessionList
          slotId={ongoingSessionsMatch.params.slotId!}
          sessions={slotInstance.sessions}
          workshopDuration={slotInstance.workshop.duration}
          workshopActivities={slotInstance.workshop.activities}
        />
      );
    }

    return <Navigate to={`/session/ongoing-sessions/${slotInstance.id}`} />;
  }

  if (
    !!millisecondsToStart &&
    slotInstance &&
    millisecondsToStart >
      (slotInstance.type === SlotType.ALL
        ? sessionContext.session.state.context.sessionOpeningTimeInMilliseconds!
        : 0)
  ) {
    if (waitingRoomMatch)
      return (
        <WaitingRoom
          slot={slotInstance}
          invitationStatus={invitationStatus!}
          invitationId={invitationId!}
          millisecondsToStart={millisecondsToStart!}
          splitMillisecondsWaitingTime={splitMillisecondsWaitingTime!}
          sessionOpeningTimeInMilliseconds={sessionOpeningTimeInMilliseconds!}
          navigateToSlotInstance={waitingRoomTimeoutNavigation}
        />
      );

    return <Navigate to={`/session/waiting-room/${slotInstance.id}`} />;
  }

  if (
    !instanceMatch &&
    !instanceGroupMatch &&
    !!slotInstance?.key &&
    !ongoingSessionsMatch
  )
    return <Navigate to={`/session/instance/${slotInstance.key}`} />;

  const isProcessing =
    sessionContext.session.state.matches({ session: SessionState.Initial }) ||
    isInviteState ||
    !sessionInstance;

  const isWorkshopInitialState = sessionContext.workshop.state.matches(
    WorkshopState.Initial
  );

  let test = 2;
  return isProcessing && !invitationNotFound ? (
    <Loader className={styles.loaderContainer} />
  ) : (
    <WorkshopClockContextProvider>
      <div>
        <Header
          title={workshop?.topic}
          hasCurrentActivity={!!currentActivity}
          isDone={currentActivityId === StandardSessionActivity.ViewResults}
        />
        <div className={styles.sessionContainer}>
          <WorkshopExitModal shouldSkipModal={hasLostConnection} />
          {isSetupDone &&
            workshop &&
            workspace &&
            slotInstance &&
            (sessionState?.context ? (
              <div className={styles.workshopInstanceContainer}>
                <WorkshopInstance
                  slot={slotInstance}
                  workshop={workshop}
                  connectionStrength={connectionStrength}
                  feedbacks={feedbacks || []}
                  isFetchingFeedbacks={isFetchingFeedbacks}
                  nextWorkshop={nextWorkshop}
                  isFetchingNextWorkshop={isFetchingNextWorkshop}
                  workspace={workspace}
                  transition={transition}
                  isSettingValue={isSettingValue}
                  sessionState={sessionState as SessionStateValue}
                  currentActivity={currentActivity}
                  currentActivityId={currentActivityId}
                  isSessionCompleted={isSessionCompleted}
                  currentActivityPart={currentActivityPart as ActivityPart}
                  profileId={profileId}
                  isParticipating={isParticipating}
                  isConnected={isConnected}
                  isReadyToStart={isReadyToStart}
                  reconnectTimeouts={reconnectTimeouts}
                  setActivityReadyHandler={setActivityReadyHandler}
                  toggleParticipationHandler={toggleParticipationHandler}
                  setReadyToStartHandler={setReadyToStartHandler}
                  setActivityValueHandler={setActivityValueHandler}
                  teamName={teamName}
                  millisecondsToStart={millisecondsToStart}
                  pageTitle={`${workshop.topic} - AhaPlay`}
                  requestNextWorkshop={requestNextWorkshop}
                  nextWorkshopRequested={nextWorkshopRequested}
                  workshopCoverageData={workshopCoverageData}
                />
              </div>
            ) : (
              <div className={styles.workshopInstanceContainer}>
                <Loader className={styles.loaderContainer} />
              </div>
            ))}

          {sessionInstance && !isSetupDone ? (
            <JitsiSetup
              ref={setVideoElementRef}
              profileId={profileId}
              isConnected={isConnected}
              selectedAudioSourceData={selectedAudioSourceData}
              selectedVideoSourceData={selectedVideoSourceData}
              availableAudioSources={availableAudioSources}
              availableVideoSources={availableVideoSources}
              audioDeviceChangeHandler={audioDeviceChangeHandler}
              videoDeviceChangeHandler={videoDeviceChangeHandler}
              setupCompletedHandler={setupCompletedHandler}
              toggleAudioHandler={toggleAudioHandler}
              toggleVideoHandler={toggleVideoHandler}
              isProcessing={!isWorkshopInitialState}
            />
          ) : (
            sessionInstance &&
            sessionState.context && (
              <SidePanel
                theoryConcepts={theoryConcepts}
                assignment={assignment}
                currentActivity={currentActivity}
                currentActivityPart={currentActivityPart as ActivityPart}
                allActivityResults={sessionState?.context?.activityResult || []}
                allQuestionActivitiesAnswersMap={
                  allQuestionActivitiesAnswersMap
                }
                profile={globalContext.auth.context.profile!}
                isUserTalking={!!jitsiContext.state.context.isUserTalking}
                isParticipating={isParticipating}
                selectedAudioDevice={selectedAudioSourceData}
                selectedVideoDevice={selectedVideoSourceData}
                availableAudioSources={availableAudioSources}
                availableVideoSources={availableVideoSources}
                audioDeviceChangeHandler={audioDeviceChangeHandler}
                videoDeviceChangeHandler={videoDeviceChangeHandler}
                disableAudioAndVideo={
                  autoDisableAudioAndVideoForIndividualScreen
                }
                remoteParticipantsData={remoteParticipantsData || []}
                toggleParticipationHandler={toggleParticipationHandler}
                attachHtmlVideoElementToLocalTracks={
                  jitsiContext.attachHtmlVideoElementToLocalTracks
                }
                attachHtmlVideoElementToRemoteTracks={
                  jitsiContext.attachHtmlVideoElementToRemoteTracks
                }
                teamName={teamName}
                toggleAudioHandler={toggleAudioHandler}
                toggleVideoHandler={toggleVideoHandler}
              />
            )
          )}
        </div>
        {invitationNotFound && <div>Invitation not found</div>}
        {sessionNotFound && <div>Session not found</div>}
      </div>
    </WorkshopClockContextProvider>
  );
}
