import { PropsWithChildren, forwardRef, memo, useMemo } from "react";
import { IActivityResult } from "../../../apollo-graphql/types/session-state";
import { ActivityPart } from "../../../types/enums/activity-part";

import styles from "./Overview.module.css";
import cn from "classnames";
import { StandardSessionActivity } from "../../../apollo-graphql/types/enums/standard-session-activity";
import { WORKSHOP_EMOTION_COUNT } from "../../../constants/global";
import { Workshop } from "../../../apollo-graphql/types/workshop";
import { Workspace } from "../../../apollo-graphql/types/workspace";

const Overview = forwardRef<
  HTMLDivElement | null,
  PropsWithChildren<{
    activityResult: IActivityResult[];
    profileId: string;
    workshop: Workshop;
    workspace: Workspace;
    correctAnswersCount: number;
    wrongAnswersCount: number;
    teamConsensusCount: number;
    activityQuestionsCount: number;
    workshopCoverageData: {
      coverage: number;
      workspaceProfilesCount: number;
    } | null;
  }>
>(
  (
    {
      activityResult,
      profileId,
      workshop,
      workspace,
      correctAnswersCount,
      wrongAnswersCount,
      teamConsensusCount,
      activityQuestionsCount,
      workshopCoverageData,
    },
    ref
  ) => {
    const emotionPercentage = useMemo(() => {
      const emojiValue =
        Number(
          activityResult
            .find(({ key }) => key === StandardSessionActivity.EndEmotion)
            ?.value.find(({ key }) => key === ActivityPart.Individual)
            ?.value?.find((v) => v.profileId === profileId)?.value
        ) || 0;

      return Number(((emojiValue / WORKSHOP_EMOTION_COUNT) * 100).toFixed(2));
    }, [activityResult, profileId]);

    const emojiSrc = useMemo(() => {
      const emojiIndex = Math.ceil(emotionPercentage / 20) || 1;
      return `/images/emotion-${emojiIndex}-face.svg`;
    }, [emotionPercentage]);

    const goalsContent = useMemo(
      () =>
        workshop.goals
          .slice()
          .sort((a, b) => a.sequence_number - b.sequence_number)
          .map(({ text }, index) => {
            return (
              <div key={index} className={styles.goal}>
                <i className="icon fa fa-check" />
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: text.replaceAll(/\\n|\\/gm, ""),
                  }}
                />
              </div>
            );
          }),
      [workshop.goals]
    );

    return (
      <div ref={ref} className={styles.overviewContainer}>
        <div className={styles.headerContainer}>
          <h1>Team Overview</h1>
        </div>
        <div className={styles.sectionContainer}>
          <div className={styles.innerSectionContainer}>
            <div className={styles.section}>
              <div>
                <img
                  className={styles.icon}
                  src="/images/flag-line.svg"
                  alt="flag-line"
                />
                <div className={styles.text}>
                  <p>Levels in which</p>{" "}
                  <p>
                    <strong>you've reached</strong>
                  </p>
                  <p>
                    <strong>team consensus:</strong>
                  </p>
                </div>
                <div className={styles.results}>
                  <span className={styles.green}>{teamConsensusCount}</span>
                  <span>/</span>
                  <span className={styles.questionsCount}>
                    {activityQuestionsCount}
                  </span>
                </div>
              </div>
              <div className={styles.delimiter}></div>
              <div>
                <img
                  style={{ width: "50px" }}
                  src="/images/positive-line.svg"
                  alt="positive-line"
                />
                <div className={styles.text}>
                  <p>Levels in which your</p>{" "}
                  <p>
                    <strong>answers matched</strong>
                  </p>
                  <p>
                    <strong>with the author's:</strong>
                  </p>
                </div>
                <div className={styles.results}>
                  <span className={styles.green}>{correctAnswersCount}</span>
                  <span>/</span>
                  <span className={styles.questionsCount}>
                    {activityQuestionsCount}
                  </span>
                </div>
              </div>
              <div className={styles.delimiter}></div>
              <div>
                <img
                  className={styles.icon}
                  style={{ width: "30px" }}
                  src="/images/pointer.svg"
                  alt="pointer"
                />
                <div className={styles.text}>
                  <p>Levels AhaPlay</p>{" "}
                  <p>
                    <strong>recommends you</strong>
                  </p>
                  <p>
                    <strong>to preview:</strong>
                  </p>
                </div>
                <div className={styles.results}>
                  <span className={styles.red}>{wrongAnswersCount}</span>
                  <span>/</span>
                  <span className={styles.questionsCount}>
                    {activityQuestionsCount}
                  </span>
                </div>
              </div>
            </div>

            <div className={cn(styles.section, styles.emotionsAndCoverage)}>
              <div>
                <div className={styles.emotionContainer}>
                  <div className={styles.emotionInner}>
                    <img src={emojiSrc} alt="emoji-logo" />
                  </div>
                </div>
                <div className={styles.text}>
                  <div>
                    <h2>{emotionPercentage}%</h2>
                  </div>
                  <p>
                    <strong>Happy or very happy</strong> players at the end of the
                    workshop
                  </p>
                </div>
              </div>
              <div className={styles.delimiter}></div>

              {workshopCoverageData && (
                <div className={styles.text}>
                  <div className={styles.percentageContainer}>{workshopCoverageData.coverage}%</div>
                  <div>
                    <h2>Coverage of "{workshop.topic}"</h2>
                    <p>Based on all {workshopCoverageData.workspaceProfilesCount} "{workspace.name}"'s team members</p>
                  </div>
                </div>
              )}
            </div>

            <div className={cn(styles.section, styles.goalsContainer)}>
              <h2>You've learned</h2>
              <div className={styles.goalsContent}>{goalsContent}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default memo(Overview);
