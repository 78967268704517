export enum StandardSessionActivity {
  Waiting = "waiting",
  StartEmotion = "startEmotion",
  TeamName = "teamName",
  EndEmotion = "endEmotion",
  ViewResults = "viewResults",
  Rating = "rating",
}

export const standardSessionActivityList = Object.values(
  StandardSessionActivity
) as StandardSessionActivity[];
