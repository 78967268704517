import { createMachine, assign, raise, fromCallback } from "xstate";
import * as workshopClockActions from "../../actions/session/workshop-clock";
import {
  WorkshopClock,
  WorkshopClockEvent,
} from "../../../helpers/workshop-clock";
import { useMachine } from "@xstate/react";

type AllActionCreators = typeof workshopClockActions;
type AllActionCreatorKeys = keyof AllActionCreators;
type AllActions = ReturnType<AllActionCreators[AllActionCreatorKeys]>;

export enum WorkshopClockStates {
  Idle = "idle",
  Running = "running",
  Stopped = "stopped",
}

interface WorkshopClockContext {
  clockInstance: WorkshopClock | null;
}

const context: WorkshopClockContext = {
  clockInstance: null,
};

type WorkshopClockMachineTypes = {
  context: WorkshopClockContext;
  events: AllActions;
};

type WorkshopClockMachineActor = ReturnType<
  typeof useMachine<typeof workshopClockMachine>
>["2"];

export const workshopClockMachine = createMachine({
  id: "workshop-clock",
  context,
  types: {} as WorkshopClockMachineTypes,
  initial: WorkshopClockStates.Idle,
  invoke: {
    src: fromCallback(({ input }: { input: { parent: any } }) => {
      const parent = input.parent as WorkshopClockMachineActor;
      return () => {
        const clockInstance = parent.getSnapshot().context.clockInstance;
        clockInstance?.dispose();
      };
    }),
    input: ({ self }) => ({ parent: self }),
  },
  states: {
    [WorkshopClockStates.Idle]: {
      entry: assign({ clockInstance: null }),
      on: {
        [workshopClockActions.configureWorkshopClock.type]: {
          actions: assign({
            clockInstance: ({ event }) => {
              const { durationInMinutes } = event.payload;
              return new WorkshopClock({ durationInMinutes });
            },
          }),
        },
        [workshopClockActions.startWorkshopClock.type]: {
          target: WorkshopClockStates.Running,
          guard: ({ context }) => !!context.clockInstance,
        },
      },
    },
    [WorkshopClockStates.Running]: {
      entry: assign({
        clockInstance: ({ context, event, self }) => {
          event = event as ReturnType<
            (typeof workshopClockActions)["startWorkshopClock"]
          >;
          context.clockInstance!.start(event.payload.serverUnixStartTimestamp);

          function timeout() {
            context.clockInstance!.removeEventListener(
              WorkshopClockEvent.TIMEOUT,
              timeout
            );
            self.send(workshopClockActions.stopWorkshopClock());
          }

          context.clockInstance!.addEventListener(
            WorkshopClockEvent.TIMEOUT,
            timeout
          );

          return context.clockInstance;
        },
      }),
      on: {
        [workshopClockActions.stopWorkshopClock.type]: {
          target: WorkshopClockStates.Stopped,
          guard: ({ context }) => !context.clockInstance!.isTicking,
        },
        [workshopClockActions.disposeWorkshopClock.type]: {
          target: WorkshopClockStates.Stopped,
        },
      },
    },
    [WorkshopClockStates.Stopped]: {
      entry: [
        assign({
          clockInstance: ({ context }) => {
            context.clockInstance?.dispose();
            return null;
          },
        }),
        raise(() => workshopClockActions.resetWorkshopClock()),
      ],
      on: {
        [workshopClockActions.resetWorkshopClock.type]: {
          target: WorkshopClockStates.Idle,
        },
      },
    },
  },
});
