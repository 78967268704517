import { memo, useCallback, useContext, useMemo, useState } from "react";

import { GlobalContext } from "../../contexts/Global";
import { ApolloContext } from "../../contexts/Apollo";
import { AuthState, ProfileSettings } from "../../+xstate/machines/auth";
import { SocketStates } from "../../+xstate/machines/socket";
import { Link, useLocation, useNavigate } from "react-router-dom";

import EditProfileModal from "../Profile/EditProfileModal/EditProfileModal";
import ChangePasswordModal from "../Profile/ChangePasswordModal/ChangePasswordModal";
import InternetConnectionStrength from "../InternetConnectionStrength/InternetConnectionStrength";

import cn from "classnames";
import styles from "./Header.module.css";

export default memo(function Header() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const {
    auth: {
      matches,
      updateProfileDialog,
      updateProfile,
      context: { profile, error },
    },
    page: { title },
  } = useContext(GlobalContext);
  const apolloContext = useContext(ApolloContext);

  const connectionStrength =
    apolloContext.socket.state.context.connectionStrength;
  const isSocketOpen = apolloContext.socket.state.matches(SocketStates.Open);
  const isLoggedIn = matches(AuthState.Authenticated);
  const isUpdateProfileDialogOpen = matches({
    [AuthState.Authenticated]: ProfileSettings.UpdateProfile,
  });
  const isChangePasswordDialogOpen = matches({
    [AuthState.Authenticated]: ProfileSettings.ChangePassword,
  });

  const [userDropdownVisibility, setUserDropdownVisibility] =
    useState<boolean>(false);

  const closeDialogHandler = useCallback(
    () => updateProfileDialog({ open: false }),
    [updateProfileDialog]
  );

  const showEditProfileModalHandler = useCallback(() => {
    updateProfileDialog({ open: true, type: "profile" });
    setUserDropdownVisibility(false);
  }, [updateProfileDialog]);

  const logoutHandler = useCallback(() => {
    navigate("/logout", { replace: true });
    setUserDropdownVisibility(false);
  }, [navigate]);

  const showChangePasswordDialogHandler = useCallback(() => {
    updateProfileDialog({ open: true, type: "password" });
  }, [updateProfileDialog]);

  const hasSessionInstance = useMemo(
    () => pathname.includes("session/instance"),
    [pathname]
  );

  const userDropdownContent = useMemo(() => {
    return userDropdownVisibility ? (
      <>
        <div
          className={styles.backdrop}
          onClick={() => setUserDropdownVisibility(false)}
        ></div>
        <ul className={styles.userDropdownContainer}>
          <li className="dropdown-item" onClick={showEditProfileModalHandler}>
            <span className="dropdown-icon">
              <i className="fa fa-user" />
            </span>
            <span className="dropdown-text">Profile settings</span>
          </li>
          <li className="dropdown-item" onClick={logoutHandler}>
            <span className="dropdown-icon">
              <i className="fa fa-sign-out" />
            </span>
            <span className="dropdown-text">Logout</span>
          </li>
        </ul>
      </>
    ) : null;
  }, [userDropdownVisibility, showEditProfileModalHandler, logoutHandler]);

  const exitLink = useMemo(() => {
    if (!hasSessionInstance) return "/";
    return `/session/thank-you/${pathname.replace("/session/instance/", "")}`;
  }, [hasSessionInstance, pathname]);

  return (
    <>
      {isUpdateProfileDialogOpen && profile && (
        <EditProfileModal
          profile={profile}
          errorMessage={error}
          updateProfile={updateProfile}
          closeDialogHandler={closeDialogHandler}
          showChangePasswordDialog={showChangePasswordDialogHandler}
        />
      )}

      {isChangePasswordDialogOpen && profile && (
        <ChangePasswordModal
          profileId={profile.id}
          errorMessage={error}
          updateProfile={updateProfile}
          closeDialogHandler={closeDialogHandler}
        />
      )}
      <div className={cn(styles.header, isLoggedIn ? "" : "loggedOut")}>
        <div className={styles.left}>
          <Link to="/">
            <img src="/images/logo.svg" alt="logo" />
          </Link>
          {isLoggedIn && title && <h2 className={styles.title}>{title}</h2>}
        </div>
        {isLoggedIn && (
          <div className={styles.right}>
            {/* Temporary solution, until these features are implemented */}
            {/* <div className={styles.link}>Feedback</div> */}
            {/* <div className={styles.link}>Help</div> */}
            {hasSessionInstance && connectionStrength && isSocketOpen && (
              <InternetConnectionStrength strength={connectionStrength} />
            )}

            <div className={styles.userProfile}>
              <div
                className={cn(styles.headerButton, "profile")}
                onClick={() => setUserDropdownVisibility(true)}
              >
                <i className="fa fa-user" />
              </div>
              {userDropdownContent}
            </div>
            {hasSessionInstance && (
              <Link className={cn(styles.headerButton, "exit")} to={exitLink}>
                Exit
              </Link>
            )}
          </div>
        )}
      </div>
    </>
  );
});
