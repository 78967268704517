import { createAction, props } from "../utils";

export const loadMemberSlots = createAction(
  "[Reschedule] Load Member Slots",
  props<{ email: string; workspaceId: string }>()
);

export const openRescheduleSlot = createAction(
  "[Reschedule] Open Reschedule Slot",
  props<{ workshopId: string; workspaceId: string; slotId: string }>()
);

export const closeRescheduleSlot = createAction(
  "[Reschedule] Close Reschedule Slot"
);

export const reschedule = createAction(
  "[Reschedule] Reschedule",
  props<{ newSlotId: string; invitationId: string }>()
);
