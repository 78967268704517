import { PropsWithChildren, createContext, useMemo } from "react";
import { useMachine } from "@xstate/react";
import { workshopClockMachine } from "../+xstate/machines/session/workshop-clock";

export const WorkshopClockContext = createContext<{
  state: ReturnType<typeof useMachine<typeof workshopClockMachine>>["0"];
  send: ReturnType<typeof useMachine<typeof workshopClockMachine>>["1"];
}>({} as any);

export const WorkshopClockContextProvider = (props: PropsWithChildren) => {
  const [state, send] = useMachine(workshopClockMachine);

  const value = useMemo(
    () => ({
      state,
      send,
    }),
    [send, state]
  );

  return (
    <WorkshopClockContext.Provider value={value}>
      {props.children}
    </WorkshopClockContext.Provider>
  );
};
