import {
  ChangeEvent,
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cn from "classnames";

import styles from "./Benchmark.module.css";
import { ActivityCommon } from "../../../types/activity-common";
import ActionFooter from "../ActionFooter";
import { ActionFooterType } from "../../../types/action-footer";
import { FooterType } from "../../../types/enums/activity-footer";
import { ActivityPart } from "../../../types/enums/activity-part";
import { SessionStateValue } from "../../../apollo-graphql/types/session-state";
import { Doc, YTextEvent, applyUpdate, encodeStateAsUpdate } from "yjs";
import { ActivityComplexValue } from "../../../types/activity-complex-value";
import {
  deserializeUint8Array,
  serializeUint8Array,
} from "../../../utils/uint8-array-serializers";
import { ActivityComplexValueType } from "../../../types/enums/activity-complex-value-type";
import { diffChars } from "diff";

const authorsDefinitionKey = "AUTHORS_DEFINITION_KEY";
const groupDefinitionKey = "GROUP_DEFINITION_KEY";
const newDefinitionKey = "NEW_DEFINITION_KEY";

const generateIndividualValue = (
  option: SessionStateValue["context"]["activityResult"][0]["value"][0]["value"][0]
) => `individual_${option.profileId}`;
const extractProfileIdFromValue = (value: string) => value.split("_")[1];

interface BenchmarkGroupValue {
  newDefinitionString: string;
  playerSelectedValues: {
    [key: string]: string;
  };
}
interface BenchmarkGroupValueNew {
  playerSelectedValues: {
    [key: string]: string;
  };
}
const generateBenchmarkGroupValue = (
  currentValue: BenchmarkGroupValue | null,
  profileId: string,
  profileSelectedValue: string
) => {
  const value: BenchmarkGroupValueNew = {
    playerSelectedValues: {
      ...currentValue?.playerSelectedValues,
      [profileId]: profileSelectedValue,
    },
  };
  return value;
};
const generateValue = (
  currentValue: BenchmarkGroupValue | null,
  profileId: string,
  profileSelectedValue: string,
  newDefinitionString?: string
) => {
  const playerSelectedValues = generateBenchmarkGroupValue(
    currentValue,
    profileId,
    profileSelectedValue
  ).playerSelectedValues;
  const value: BenchmarkGroupValue = {
    ...(currentValue || {}),
    playerSelectedValues,
    newDefinitionString:
      newDefinitionString || currentValue?.newDefinitionString || "",
  };
  return value;
};

interface BenchmarkProps extends ActivityCommon {
  profileId: string;
  activityResultForCurrentProfile:
    | SessionStateValue["context"]["activityResult"][0]["value"][0]["value"][0]
    | null;
  individualActivityResults:
    | SessionStateValue["context"]["activityResult"][0]["value"][0]
    | null;
  currentActivityGroupResults: SessionStateValue["context"]["activityResult"][0]["value"];
  conceptualizationResults:
    | SessionStateValue["context"]["activityResult"][0]
    | null;
  setActivityValueHandler: (args: {
    activityId: string;
    value: string;
  }) => void;
}
export default memo(function Benchmark(
  props: PropsWithChildren<BenchmarkProps>
) {
  const {
    profileId,
    activity,
    transition,
    isReady,
    currentActivityPart,
    notReadyProfilesCount,
    conceptualizationResults,
    individualActivityResults,
    currentActivityGroupResults,
    activityResultForCurrentProfile,
    setActivityValueHandler,
    setActivityReadyHandler,
  } = props;
  const { benchmark } = activity;

  const textAreaElementRef = useRef<any>();
  const textAreaElementPrevValue = useRef<string>("");
  const yDoc = useMemo(() => new Doc(), []);

  const currentParsedValue =
    useMemo<null | ActivityComplexValue<BenchmarkGroupValueNew>>(() => {
      const valueCandidate =
        currentActivityGroupResults?.[0]?.value?.[0]?.value;
      if (!valueCandidate) {
        return null;
      }
      try {
        const parsedValue = JSON.parse(valueCandidate);
        if (
          parsedValue?.value === undefined ||
          parsedValue?.data === undefined
        ) {
          return null;
        }
        return parsedValue;
      } catch (e) {
        return null;
      }
    }, [currentActivityGroupResults]);

  const prepDataAndSetActivityValueHandler = useCallback(
    (newProfileValue: string) => {
      const activityId = activity.id;
      if (currentActivityPart === ActivityPart.Individual) {
        setActivityValueHandler({ activityId, value: newProfileValue });
        return;
      }
      const newValue: ActivityComplexValue<BenchmarkGroupValueNew> = {
        type: ActivityComplexValueType.yjs,
        value: currentParsedValue?.value || "",
        data: {
          playerSelectedValues: {
            ...currentParsedValue?.data?.playerSelectedValues,
            [profileId]: newProfileValue,
          },
        },
      };
      setActivityValueHandler({ activityId, value: JSON.stringify(newValue) });
    },
    [
      activity.id,
      currentActivityPart,
      currentParsedValue,
      profileId,
      setActivityValueHandler,
    ]
  );

  useEffect(() => {
    if (
      currentActivityPart === ActivityPart.Individual ||
      !currentParsedValue ||
      !yDoc
    )
      return;
    if (currentParsedValue && "value" in currentParsedValue) {
      const update = deserializeUint8Array(currentParsedValue.value);
      if (update) applyUpdate(yDoc, update);
    }
    const text = yDoc?.getText()?.toString() || "";
    textAreaElementRef.current.value = text;
    textAreaElementPrevValue.current = text;
  }, [currentActivityPart, currentParsedValue, yDoc]);

  useEffect(() => {
    const yDocText = yDoc.getText();
    if (currentActivityPart !== ActivityPart.Group || !yDocText) return;

    const updateHandler = (event: YTextEvent) => {
      if (!event.target.doc) return;
      const update = encodeStateAsUpdate(event.target.doc);
      const uInt8ArrayString = serializeUint8Array(update);
      const activityComplexValue: ActivityComplexValue<BenchmarkGroupValueNew> =
        {
          value: uInt8ArrayString,
          type: ActivityComplexValueType.yjs,
          data: {
            playerSelectedValues: {
              ...currentParsedValue?.data?.playerSelectedValues,
              [profileId]: newDefinitionKey,
            },
          },
        };
      const newValue = JSON.stringify(activityComplexValue);
      setActivityValueHandler({ activityId: activity.id, value: newValue });
    };

    yDocText.observe(updateHandler);

    return () => {
      yDocText.unobserve(updateHandler);
    };
  }, [
    activity.id,
    currentActivityPart,
    currentParsedValue,
    profileId,
    setActivityValueHandler,
    yDoc,
  ]);

  const inputHandler = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const yDocText = yDoc.getText();
      if (currentActivityPart !== ActivityPart.Group || !yDocText) return;

      const target = event.target;
      const value = target.value;
      const differences = diffChars(textAreaElementPrevValue.current, value);
      textAreaElementPrevValue.current = value;
      const additions = differences.filter((d) => !!d.added);
      const deletions = differences.filter((d) => !!d.removed);

      for (const a of additions) {
        const arrayIndex = differences.indexOf(a);
        const textIndex = differences
          .slice(0, arrayIndex)
          .reduce((acc, curr) => acc + (curr?.count || 0), 0);
        yDocText.insert(textIndex, a.value);
      }
      for (const d of deletions) {
        const arrayIndex = differences.indexOf(d);
        const textIndex = differences
          .slice(0, arrayIndex)
          .reduce((acc, curr) => acc + (curr?.count || 0), 0);
        yDocText.delete(textIndex, d.count!);
      }
    },
    [currentActivityPart, yDoc]
  );

  const selectedDefinitions = useMemo(
    () => Object.values(currentParsedValue?.data?.playerSelectedValues || {}),
    [currentParsedValue?.data?.playerSelectedValues]
  );
  const playersAreAligned = useMemo(
    () =>
      selectedDefinitions.every((value) => value === selectedDefinitions[0]),
    [selectedDefinitions]
  );

  const actionFooterData: ActionFooterType = useMemo(() => {
    if (transition > 0) {
      return {
        text:
          currentActivityPart === ActivityPart.Individual ? (
            <>
              Everyone voted. Continuing forward in{" "}
              <span className="accent">{transition}...</span>
            </>
          ) : (
            <>
              Everyone agreed on the definition. Continuing forward in{" "}
              <span className="accent">{transition}...</span>
            </>
          ),
        buttonText: "",
        disabledButton: false,
        type: FooterType.Ready,
        isLoading: true,
      };
    }
    if (!isReady) {
      if (currentActivityPart === ActivityPart.Individual) {
        const playerHasAnswered = !!activityResultForCurrentProfile;
        const text = !playerHasAnswered ? (
          <>Vote for the most accurate individual definition to continue.</>
        ) : (
          <>
            Click on <span className="accent green">“Continue”</span> to submit
            your choice.
          </>
        );
        return {
          text,
          buttonText: "Continue",
          disabledButton: !playerHasAnswered,
          type: FooterType.Notice,
          isLoading: false,
        };
      }

      return {
        text: (
          <>
            You have to <span className="accent">match your opinions</span> to
            continue
          </>
        ),
        buttonText: "I agree",
        disabledButton: !playersAreAligned,
        type: FooterType.Notice,
        isLoading: false,
      };
    }
    return {
      text: (
        <>
          Waiting for{" "}
          <span className="accent">
            {notReadyProfilesCount} more player
            {notReadyProfilesCount > 1 && "s"} to vote...
          </span>
        </>
      ),
      buttonText: "",
      disabledButton: true,
      type: FooterType.Waiting,
      isLoading: false,
    };
  }, [
    transition,
    isReady,
    notReadyProfilesCount,
    currentActivityPart,
    playersAreAligned,
    activityResultForCurrentProfile,
  ]);

  const individualConceptualizationResults = useMemo(
    () =>
      conceptualizationResults?.value.find(
        ({ key }) => key === ActivityPart.Individual
      ),
    [conceptualizationResults]
  );
  const groupConceptualizationResult = useMemo(() => {
    try {
      const rawGroupValue = conceptualizationResults?.value.find(
        ({ key }) => key === ActivityPart.Group
      )?.value[0];
      if (!rawGroupValue) {
        return "";
      }
      return JSON.parse(rawGroupValue.value).value;
    } catch (e) {
      return "";
    }
  }, [conceptualizationResults]);

  const [expandedAnswerExplanations, setExpandedAnswerExplanations] = useState<
    string[]
  >([]);

  const individualConceptualizationResultsWithVotes = useMemo(() => {
    if (currentActivityPart !== ActivityPart.Group) return [];
    const definitionVoteCountMap: { [key: string]: number } = {};
    individualActivityResults?.value.forEach((result) => {
      const playerId = extractProfileIdFromValue(result.value);
      if (!definitionVoteCountMap[playerId]) {
        definitionVoteCountMap[playerId] = 0;
      }
      definitionVoteCountMap[playerId]++;
    });

    return individualConceptualizationResults?.value
      .map((result, idx) => ({
        ...result,
        idx,
        votes: definitionVoteCountMap[result.profileId],
      }))
      .filter(({ votes }) => votes > 0);
  }, [
    currentActivityPart,
    individualActivityResults?.value,
    individualConceptualizationResults?.value,
  ]);

  const getGroupProfileIdsSelectedAnswer = useCallback(
    (answerId: string) => {
      if (currentActivityPart !== ActivityPart.Group || !currentParsedValue) {
        return 0;
      }

      return Object.values(
        currentParsedValue?.data?.playerSelectedValues!
      ).filter((value) => value === answerId).length;
    },
    [currentActivityPart, currentParsedValue]
  );

  const groupProfileIdCountSelectedGroupDefinition = useMemo(() => {
    return getGroupProfileIdsSelectedAnswer(groupDefinitionKey);
  }, [getGroupProfileIdsSelectedAnswer]);
  const groupProfileIdCountSelectedAuthorDefinition = useMemo(() => {
    return getGroupProfileIdsSelectedAnswer(authorsDefinitionKey);
  }, [getGroupProfileIdsSelectedAnswer]);
  const groupProfileIdCountSelectedNewDefinition = useMemo(() => {
    return getGroupProfileIdsSelectedAnswer(newDefinitionKey);
  }, [getGroupProfileIdsSelectedAnswer]);

  return (
    <div key={activity.id} className="activity-container">
      <div className={cn(styles.container, "main-container")}>
        <div className={styles.infoContainer}>
          <p className="text bold">
            {currentActivityPart === ActivityPart.Individual
              ? "Individual Definitions Alignment"
              : "Final Definition Alignment"}
          </p>
          {currentActivityPart === ActivityPart.Individual && (
            <>
              <p className="text">
                <b>✋ Vote for the best individual definition:</b>
                <br></br>
                Now that you are aware of the author’s definition, please align
                on the most accurate individual definition.
              </p>
              <p className="text">{benchmark?.baseline}</p>
              <p className="text">{benchmark?.reference}</p>
            </>
          )}
          {currentActivityPart === ActivityPart.Group && (
            <>
              <p className="text">
                <b>
                  Align on the most accurate definition or create a new one:
                </b>
                <br></br>
                You can choose between the most voted individual definition, the
                team definition, the author’s definition or create a new one.
              </p>
            </>
          )}
        </div>
        <div className={styles.answersContainer}>
          {/* Individual Part */}
          {currentActivityPart === ActivityPart.Individual && (
            <>
              <p className="text">Choose the most correct answer:</p>
              {(individualConceptualizationResults?.value || []).map(
                (result, idx) => {
                  const value = generateIndividualValue(result);
                  return (
                    <div
                      key={value}
                      className={cn(
                        styles.answer,
                        activityResultForCurrentProfile?.value === value
                          ? "selected"
                          : ""
                      )}
                      onClick={() => {
                        if (activityResultForCurrentProfile?.value === value)
                          return;
                        prepDataAndSetActivityValueHandler(value);
                      }}
                    >
                      <div className={styles.answerTopLine}>
                        Definition {idx + 1}
                      </div>
                      <div
                        className={cn(
                          styles.answerExplanationToggle,
                          expandedAnswerExplanations.includes(value)
                            ? "expanded"
                            : ""
                        )}
                        onClick={() => {
                          if (expandedAnswerExplanations.includes(value)) {
                            setExpandedAnswerExplanations(
                              expandedAnswerExplanations.filter(
                                (id) => id !== value
                              )
                            );
                          } else {
                            setExpandedAnswerExplanations([
                              ...expandedAnswerExplanations,
                              value,
                            ]);
                          }
                        }}
                      >
                        <i
                          className={cn(
                            "icon fa fa-chevron-down",
                            styles.answerExplanationToggleIcon
                          )}
                        />
                      </div>
                      <div
                        className={cn(
                          styles.answerExplanation,
                          expandedAnswerExplanations.includes(value)
                            ? "expanded"
                            : "",
                          "text",
                          "small"
                        )}
                        dangerouslySetInnerHTML={{
                          __html: result.value,
                        }}
                      />
                    </div>
                  );
                }
              )}
            </>
          )}

          {/* Group part */}
          {currentActivityPart === ActivityPart.Group && (
            <>
              <p className="text">Choose the most correct answer:</p>
              {(individualConceptualizationResultsWithVotes || []).map(
                (result) => {
                  const value = generateIndividualValue(result);
                  const profilesSelectedOption =
                    getGroupProfileIdsSelectedAnswer(value);
                  return (
                    <div
                      key={value}
                      className={cn(
                        styles.answer,
                        currentParsedValue?.data?.playerSelectedValues[
                          profileId
                        ] === value
                          ? "selected"
                          : ""
                      )}
                      onClick={() => {
                        if (
                          currentParsedValue?.data?.playerSelectedValues[
                            profileId
                          ] === value
                        )
                          return;
                        prepDataAndSetActivityValueHandler(value);
                      }}
                    >
                      <div className={styles.answerTopLine}>
                        Individual Definition {result.idx + 1} (✋{" "}
                        {result.votes} votes){" "}
                        {profilesSelectedOption > 0 && (
                          <i
                            className={cn(
                              "fa icon",
                              playersAreAligned ? "fa-users" : "fa-user"
                            )}
                          />
                        )}
                      </div>
                      <div
                        className={cn(
                          styles.answerExplanationToggle,
                          expandedAnswerExplanations.includes(value)
                            ? "expanded"
                            : ""
                        )}
                        onClick={() => {
                          if (expandedAnswerExplanations.includes(value)) {
                            setExpandedAnswerExplanations(
                              expandedAnswerExplanations.filter(
                                (id) => id !== value
                              )
                            );
                          } else {
                            setExpandedAnswerExplanations([
                              ...expandedAnswerExplanations,
                              value,
                            ]);
                          }
                        }}
                      >
                        <i
                          className={cn(
                            "icon fa fa-chevron-down",
                            styles.answerExplanationToggleIcon
                          )}
                        />
                      </div>
                      <div
                        className={cn(
                          styles.answerExplanation,
                          expandedAnswerExplanations.includes(value)
                            ? "expanded"
                            : "",
                          "text",
                          "small"
                        )}
                        dangerouslySetInnerHTML={{
                          __html: result.value,
                        }}
                      />
                    </div>
                  );
                }
              )}

              {/* Group definition */}
              <div
                className={cn(
                  styles.answer,
                  currentParsedValue?.data?.playerSelectedValues[profileId] ===
                    groupDefinitionKey
                    ? "selected"
                    : ""
                )}
                onClick={() => {
                  if (
                    currentParsedValue?.data?.playerSelectedValues[
                      profileId
                    ] === groupDefinitionKey
                  )
                    return;
                  prepDataAndSetActivityValueHandler(groupDefinitionKey);
                }}
              >
                <div className={styles.answerTopLine}>
                  Group definition{" "}
                  {groupProfileIdCountSelectedGroupDefinition > 0 && (
                    <i
                      className={cn(
                        "fa icon",
                        playersAreAligned ? "fa-users" : "fa-user"
                      )}
                    />
                  )}
                </div>
                <div
                  className={cn(
                    styles.answerExplanationToggle,
                    expandedAnswerExplanations.includes(groupDefinitionKey)
                      ? "expanded"
                      : ""
                  )}
                  onClick={() => {
                    if (
                      expandedAnswerExplanations.includes(groupDefinitionKey)
                    ) {
                      setExpandedAnswerExplanations(
                        expandedAnswerExplanations.filter(
                          (id) => id !== groupDefinitionKey
                        )
                      );
                    } else {
                      setExpandedAnswerExplanations([
                        ...expandedAnswerExplanations,
                        groupDefinitionKey,
                      ]);
                    }
                  }}
                >
                  <i
                    className={cn(
                      "icon fa fa-chevron-down",
                      styles.answerExplanationToggleIcon
                    )}
                  />
                </div>
                <div
                  className={cn(
                    styles.answerExplanation,
                    expandedAnswerExplanations.includes(groupDefinitionKey)
                      ? "expanded"
                      : "",
                    "text",
                    "small"
                  )}
                  dangerouslySetInnerHTML={{
                    __html: groupConceptualizationResult?.value || "",
                  }}
                />
              </div>

              {/* Author definition */}
              <div
                className={cn(
                  styles.answer,
                  currentParsedValue?.data?.playerSelectedValues[profileId] ===
                    authorsDefinitionKey
                    ? "selected"
                    : ""
                )}
                onClick={() => {
                  if (
                    currentParsedValue?.data?.playerSelectedValues[
                      profileId
                    ] === authorsDefinitionKey
                  )
                    return;
                  prepDataAndSetActivityValueHandler(authorsDefinitionKey);
                }}
              >
                <div className={styles.answerTopLine}>
                  Authors definition{" "}
                  {groupProfileIdCountSelectedAuthorDefinition > 0 && (
                    <i
                      className={cn(
                        "fa icon",
                        playersAreAligned ? "fa-users" : "fa-user"
                      )}
                    />
                  )}
                </div>
                <div
                  className={cn(
                    styles.answerExplanationToggle,
                    expandedAnswerExplanations.includes(authorsDefinitionKey)
                      ? "expanded"
                      : ""
                  )}
                  onClick={() => {
                    if (
                      expandedAnswerExplanations.includes(authorsDefinitionKey)
                    ) {
                      setExpandedAnswerExplanations(
                        expandedAnswerExplanations.filter(
                          (id) => id !== authorsDefinitionKey
                        )
                      );
                    } else {
                      setExpandedAnswerExplanations([
                        ...expandedAnswerExplanations,
                        authorsDefinitionKey,
                      ]);
                    }
                  }}
                >
                  <i
                    className={cn(
                      "icon fa fa-chevron-down",
                      styles.answerExplanationToggleIcon
                    )}
                  />
                </div>
                <div
                  className={cn(
                    styles.answerExplanation,
                    expandedAnswerExplanations.includes(authorsDefinitionKey)
                      ? "expanded"
                      : "",
                    "text",
                    "small"
                  )}
                  dangerouslySetInnerHTML={{
                    __html: benchmark?.baseline || "",
                  }}
                />
              </div>

              {/* New group definition */}
              <div
                className={cn(
                  styles.answer,
                  currentParsedValue?.data?.playerSelectedValues[profileId] ===
                    newDefinitionKey
                    ? "selected"
                    : ""
                )}
                onClick={() => {
                  if (
                    currentParsedValue?.data?.playerSelectedValues[
                      profileId
                    ] === newDefinitionKey
                  )
                    return;
                  prepDataAndSetActivityValueHandler(newDefinitionKey);
                }}
              >
                <div className={styles.answerTopLine}>
                  Create new definition{" "}
                  {groupProfileIdCountSelectedNewDefinition > 0 && (
                    <i
                      className={cn(
                        "fa icon",
                        playersAreAligned ? "fa-users" : "fa-user"
                      )}
                    />
                  )}
                </div>
                <div
                  className={cn(
                    styles.answerExplanationToggle,
                    expandedAnswerExplanations.includes(newDefinitionKey)
                      ? "expanded"
                      : ""
                  )}
                  onClick={() => {
                    if (expandedAnswerExplanations.includes(newDefinitionKey)) {
                      setExpandedAnswerExplanations(
                        expandedAnswerExplanations.filter(
                          (id) => id !== newDefinitionKey
                        )
                      );
                    } else {
                      setExpandedAnswerExplanations([
                        ...expandedAnswerExplanations,
                        newDefinitionKey,
                      ]);
                    }
                  }}
                >
                  <i
                    className={cn(
                      "icon fa fa-chevron-down",
                      styles.answerExplanationToggleIcon
                    )}
                  />
                </div>
                <div
                  className={cn(
                    styles.answerExplanation,
                    expandedAnswerExplanations.includes(newDefinitionKey)
                      ? "expanded"
                      : "",
                    "text",
                    "small"
                  )}
                >
                  <textarea
                    className="text"
                    onInput={inputHandler}
                    ref={textAreaElementRef}
                  ></textarea>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <ActionFooter
        buttonText={actionFooterData.buttonText}
        type={actionFooterData.type}
        disabledButton={actionFooterData.disabledButton}
        buttonClickHandler={() =>
          setActivityReadyHandler({ activityId: activity.id })
        }
        isLoading={actionFooterData.isLoading}
      >
        {actionFooterData.text}
      </ActionFooter>
    </div>
  );
});
