export enum UpdateProfileErrors {
  MISSING_DATA = "UPDATE_PROFILE__MISSING_DATA",
  WRONG_PASSWORD = "UPDATE_PROFILE__WRONG_PASSWORD",
  NOT_FOUND = "UPDATE_PROFILE__NOT_FOUND",
}

export enum InviteProfileErrors {
  MISSING_DATA = "INVITE_PROFILE__MISSING_DATA",
  DOMAIN_NOT_FOUND = "INVITE_PROFILE__DOMAIN_NOT_FOUND",
  PROFILE_ALREADY_EXISTS = "INVITE_PROFILE__PROFILE_ALREADY_EXISTS",
  WORKSPACE_NOT_FOUND = "INVITE_PROFILE__WORKSPACE_NOT_FOUND",
  WORKSPACE_PROFILE_NOT_FOUND = "INVITE_PROFILE__WORKSPACE_PROFILE_NOT_FOUND",
}

export enum ResetPasswordErrors {
  MISSING_DATA = "RESET_PASSWORD__MISSING_DATA",
  DOMAIN_NOT_FOUND = "RESET_PASSWORD__DOMAIN_NOT_FOUND",
  PROFILE_NOT_FOUND = "RESET_PASSWORD__PROFILE_NOT_FOUND",
}

export enum ChangePasswordErrors {
  MISSING_DATA = "CHANGE_PASSWORD__MISSING_DATA",
  PASSWORDS_MISMATCH = "CHANGE_PASSWORD__PASSWORDS_MISMATCH",
  TOKEN_NOT_FOUND = "CHANGE_PASSWORD__TOKEN_NOT_FOUND",
  WRONG_TOKEN = "CHANGE_PASSWORD__WRONG_TOKEN",
  PROFILE_NOT_FOUND = "CHANGE_PASSWORD__PROFILE_NOT_FOUND",
}

export enum LoginErrors {
  MISSING_DATA = "LOGIN_ERROR__MISSING_DATA",
  DOMAIN_NOT_FOUND = "LOGIN_ERROR__DOMAIN_NOT_FOUND",
  PROFILE_NOT_FOUND = "LOGIN_ERROR__PROFILE_NOT_FOUND",
  WRONG_DATA = "LOGIN_ERROR__WRONG_DATA",
}
