import { Doc, applyUpdate } from "yjs";
import { StandardSessionActivity } from "../apollo-graphql/types/enums/standard-session-activity";
import { IActivityResult } from "../apollo-graphql/types/session-state";
import { parseToJson } from "./parse-to-json";
import { deserializeUint8Array } from "./uint8-array-serializers";

export const extractTeamName = (
  teamActivity: IActivityResult | string | undefined
) => {
  if (!teamActivity) return null;

  const teamValueAsString =
    typeof teamActivity === "string"
      ? teamActivity
      : teamActivity?.value?.[0]?.value?.[0]?.value || null;
  const { value: teamName, type } = parseToJson<{
    value: string;
    type: string;
  }>(teamValueAsString, { value: "", type: "yjs" });

  if (teamName) {
    if (type === "yjs") {
      const yDoc = new Doc();
      const update = deserializeUint8Array(teamName);
      applyUpdate(yDoc, update!);
      return yDoc?.getText()?.toString() || null;
    }
    return teamName;
  }

  return null;
};

export const getTeamName = (
  activityResult: IActivityResult[],
  defaultValue: string
) => {
  const teamActivity = ((activityResult as IActivityResult[]) || []).find(
    ({ key }) => key === StandardSessionActivity.TeamName
  );

  return extractTeamName(teamActivity) || defaultValue;
};
