export enum SlotStatus {
  SCHEDULED = "SCHEDULED",
  RESCHEDULED = "RESCHEDULED",
  CANCELLED = "CANCELLED",
  WAITING = "WAITING",
  ONGOING = "ONGOING",
  NOT_ENOUGH_PLAYERS = "NOT_ENOUGH_PLAYERS",
  COMPLETED = "COMPLETED",
}

export enum SlotReminderStatus {
  FORTHCOMING = "FORTHCOMING",
  FARAWAY = "FARAWAY",
  NONE = "NONE",
}
