import { FormEvent, useCallback, useContext, useMemo, useState } from "react";
import * as Form from "@radix-ui/react-form";
import { Link, Navigate, useLocation } from "react-router-dom";
import { GlobalContext } from "../contexts/Global";
import { AuthState } from "../+xstate/machines/auth";
import { mappedErrorMessages } from "../constants/mapped-error-messages";
import { LoginErrors } from "../types/enums/errors";

import { ILogin } from "../apollo-graphql/types/login";
import withTitle from "../hocs/withTitle";

import styles from "./Login.module.css";

function Login() {
  const {
    auth: { login, context, matches },
  } = useContext(GlobalContext);
  const location = useLocation();

  const isAuthenticating = matches(AuthState.Authenticating);
  const isAuthenticated = matches(AuthState.Authenticated);
  const [errorMessage, setErrorMessage] = useState("");

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const redirectUrl = useMemo(
    () => queryParams.get("redirectUrl") || "/",
    [queryParams]
  );

  const loginHandler = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      setErrorMessage("");

      event.preventDefault();
      const payload = Object.fromEntries(
        new FormData(event.currentTarget)
      ) as unknown as ILogin;
      if (!payload.email || !payload.password) {
        setErrorMessage(mappedErrorMessages[LoginErrors.MISSING_DATA]);
        return;
      }

      login({ variables: payload });
    },
    [login]
  );

  return isAuthenticated ? (
    <Navigate to={redirectUrl} />
  ) : (
    <div className={styles.loginContainer}>
      <div className="FormContainer">
        <h2 className="title">Welcome to AhaPlay</h2>
        <Form.Root className="Form" onSubmit={loginHandler}>
          <Form.Field name="email">
            <Form.Control
              className="FormControl"
              placeholder="Work email address"
            />
          </Form.Field>
          <Form.Field name="password">
            <Form.Control
              className="FormControl"
              type="password"
              placeholder="Password"
            />
          </Form.Field>
          <Form.Submit className="btn success" disabled={isAuthenticating}>
            Continue with email
          </Form.Submit>
          <Link className="forgot-password" to={"/reset-password"}>
            Forgot your password?
          </Link>
          {(errorMessage || context?.error) && !isAuthenticating && (
            <div className="error">
              {errorMessage ||
                mappedErrorMessages[
                  context?.error as keyof typeof mappedErrorMessages
                ]}
            </div>
          )}
          {isAuthenticating && <div className="loading">Loading...</div>}
        </Form.Root>
      </div>
      <div className="footer-message">
        By pressing on “Continue”, you agree to accept our{" "}
        <span>Terms of Service</span> & <span>Privacy Policy</span>.
      </div>
    </div>
  );
}

export default withTitle(Login);
