import {
  ChangeEvent,
  PropsWithChildren,
  memo,
  useCallback,
  useMemo,
} from "react";
import cn from "classnames";
import styles from "./Rating.module.css";
import ActionFooter from "./ActionFooter";
import { FooterType } from "../../types/enums/activity-footer";
import { ActionFooterType } from "../../types/action-footer";
import { debounce } from "lodash";
import { parseToJson } from "../../utils";
import { Feedback } from "../../apollo-graphql/types/feedback";

interface RatingValue {
  rate: string | null;
  feedback: {
    [key: string]: boolean;
  };
  comment: string;
}

const SKIP_VALIDATIONS = true;
const options = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

export default memo(function Rating(
  props: PropsWithChildren<{
    activityValue: string | null;
    feedbacks: Feedback[];
    isFetchingFeedbacks: boolean;
    isReady: boolean;
    transition: number;
    notReadyProfilesCount: number;
    currentActiveParticipantCount: number;
    setRatingHandler: (value: string) => void;
    setActivityReadyHandler: () => void;
  }>
) {
  const {
    activityValue,
    feedbacks,
    isFetchingFeedbacks,
    isReady,
    transition,
    notReadyProfilesCount,
    currentActiveParticipantCount,
    setRatingHandler,
    setActivityReadyHandler,
  } = props;

  const defaultRatingValue: RatingValue = useMemo(() => {
    const feedback =
      feedbacks?.reduce(
        (acc, { id }) => {
          acc[id] = false;
          return acc;
        },
        {} as {
          [key: string]: boolean;
        }
      ) || {};

    return {
      rate: null,
      feedback,
      comment: "",
    };
  }, [feedbacks]);

  const { rate, feedback, comment } = useMemo(
    () => parseToJson<RatingValue>(activityValue, defaultRatingValue),
    [activityValue, defaultRatingValue]
  );

  const isTransitioning = useMemo(() => transition > 0, [transition]);

  const debouncedSetRatingValue = useMemo(
    () =>
      debounce(({ rate, comment, feedback }: RatingValue) => {
        const userInputIsValid = SKIP_VALIDATIONS ? true : !(rate === null);

        if (!userInputIsValid) {
          return;
        }

        setRatingHandler(JSON.stringify({ rate, feedback, comment }));
      }, 500),
    [setRatingHandler]
  );

  const onTextAreaChangeHandler = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      const comment = e.target.value;
      debouncedSetRatingValue({
        rate,
        feedback,
        comment,
      });
    },
    [debouncedSetRatingValue, rate, feedback]
  );

  const onCheckboxChangeHandler = useCallback(
    (feedbackId: string) => {
      const currentFeedbackCheckedValue = feedback[feedbackId];
      const updatedFeedback = {
        ...feedback,
        [feedbackId]: !currentFeedbackCheckedValue,
      };

      setRatingHandler(
        JSON.stringify({
          rate,
          feedback: updatedFeedback,
          comment,
        })
      );
    },
    [feedback, setRatingHandler, rate, comment]
  );

  const onRatingChangeHandler = useCallback(
    (rate: string) => {
      const ratingValue: RatingValue = {
        rate,
        feedback,
        comment,
      };
      setRatingHandler(JSON.stringify(ratingValue));
    },
    [comment, feedback, setRatingHandler]
  );

  const actionFooterData: ActionFooterType = useMemo(() => {
    if (isTransitioning) {
      return {
        text: (
          <>
            Everyone is ready. Continuing forward in{" "}
            <span className="accent">{transition}...</span>
          </>
        ),
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Ready,
        isLoading: true,
      };
    }

    if (isReady) {
      return {
        text: (
          <>
            Waiting for{" "}
            <span className="accent">
              {notReadyProfilesCount} more player
              {notReadyProfilesCount > 1 && "s"}...
            </span>
          </>
        ),
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Waiting,
        isLoading: false,
      };
    }

    const playersClicked =
      currentActiveParticipantCount - notReadyProfilesCount;

    if (rate === null) {
      return {
        text: (
          <>
            Please rate us in order to continue.{" "}
            {playersClicked > 0 && (
              <span className="accent">
                {playersClicked} player{playersClicked > 1 && "s"} clicked.
              </span>
            )}
          </>
        ),
        buttonText: "Continue",
        disabledButton: true,
        type: FooterType.Notice,
        isLoading: false,
      };
    }

    return {
      text: (
        <>
          When ready click Continue to proceed forward.
          {playersClicked > 0 && (
            <span className="accent">
              {playersClicked} player{playersClicked > 1 && "s"} clicked.
            </span>
          )}
        </>
      ),
      buttonText: "Continue",
      disabledButton: false, // rateComment.length === 0
      type: FooterType.Notice,
      isLoading: false,
    };
  }, [
    isTransitioning,
    isReady,
    transition,
    notReadyProfilesCount,
    currentActiveParticipantCount,
    rate,
  ]);

  const ratingContent = useMemo(
    () => (
      <>
        <div className={cn(styles.subtitle, "text", "bold")}>
          If you could time travel, how likely would you be to recommend this
          workshop to your past self?
        </div>
        <div className={styles.ratingSectionContainer}>
          <div className="text secondary">Choose your individual option</div>
          <div className={styles.ratingContainer}>
            {options.map((option) => (
              <div
                key={"rating-option-" + option}
                className={cn(
                  styles.rating,
                  rate === option ? "selected" : "",
                  "text",
                  "bold",
                  "extra"
                )}
                onClick={() => onRatingChangeHandler(option)}
              >
                {option}
              </div>
            ))}
          </div>
          <div className={styles.ratingGuide}>
            <span className="text small">Not likely</span>
            <span className="text small">Very likely</span>
          </div>
        </div>
      </>
    ),
    [rate, onRatingChangeHandler]
  );

  const feedbacksContent = useMemo(() => {
    return (
      <div className={styles.feedbackContainer}>
        <p className="text">Help us improve, comment on your experience:</p>
        <div className={styles.feedbackOptions}>
          {feedbacks.map(({ id, text }) => {
            return (
              <label
                htmlFor={id}
                key={id}
                className="feedback-option"
                onClick={() => onCheckboxChangeHandler(id)}
              >
                <input
                  id={id}
                  type="checkbox"
                  name="feedback"
                  value={id}
                  defaultChecked={!!feedback[id]}
                />
                <div className="checkbox">
                  <i className="fa fa-check" />
                </div>
                <p className="text">{text}</p>
              </label>
            );
          })}
        </div>
      </div>
    );
  }, [feedback, feedbacks, onCheckboxChangeHandler]);

  const textAreaContent = useMemo(
    () => (
      <div className={styles.textareaContainer}>
        <p className="text secondary">Other thoughts and proposals:</p>
        <textarea
          className="text"
          onInput={onTextAreaChangeHandler}
          placeholder="Your comment here..."
          defaultValue={comment}
        />
      </div>
    ),
    [onTextAreaChangeHandler, comment]
  );

  return (
    <div className={cn(styles.activityContainer, "activity-container")}>
      <div className={cn(styles.container, "main-container")}>
        <div className={cn(styles.infoText, "text", "bold")}>
          Share your feedback
        </div>
        <div className={styles.content}>
          {ratingContent}
          {isFetchingFeedbacks && "Loading..."}
          {feedbacksContent}
          {textAreaContent}
        </div>
      </div>
      <ActionFooter
        buttonText={actionFooterData.buttonText}
        type={actionFooterData.type}
        disabledButton={actionFooterData.disabledButton}
        buttonClickHandler={setActivityReadyHandler}
        isLoading={actionFooterData.isLoading}
      >
        {actionFooterData.text}
      </ActionFooter>
    </div>
  );
});
