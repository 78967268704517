const domain = "vc.ahaplay.com";
const options = {
  hosts: {
    domain: domain,
    muc: `conference.${domain}`,
    focus: `focus.${domain}`,
  },
  serviceUrl: `https://${domain}/http-bind`,
  clientNode: "http://jitsi.org/jitsimeet",
};

const confOptions = {
  enableLayerSuspension: true,
  p2p: {
    enabled: false,
  },
};

export enum JitsiEvents {
  CONNECTION_ESTABLISHED = "CONNECTION_ESTABLISHED",
  CONNECTION_FAILURE = "CONNECTION_FAILURE",
  CONNECTION_DISCONNECTED = "CONNECTION_DISCONNECTED",
  LOCAL_TRACKS_READY = "LOCAL_TRACKS_READY",
  CONFERENCE_JOINED = "CONFERENCE_JOINED",
  REMOTE_TRACK_ADDED = "REMOTE_TRACK_ADDED",
  TRACK_AUDIO_LEVEL_CHANGED = "TRACK_AUDIO_LEVEL_CHANGED",
  LOCAL_TRACK_AUDIO_LEVEL_CHANGED = "LOCAL_TRACK_AUDIO_LEVEL_CHANGED",
  REMOTE_TRACK_REMOVED = "REMOTE_TRACK_REMOVED",
  REMOTE_TRACK_MUTED = "REMOTE_TRACK_MUTED",
  VIDEO_PLAYING = "VIDEO_PLAYING",
  VIDEO_PAUSED = "VIDEO_PAUSED",
  VIDEO_TRACK_FAILURE = "VIDEO_TRACK_FAILURE",
  USER_LEFT = "USER_LEFT",
  USER_JOINED = "USER_JOINED",
  USER_NAME_CHANGED = "USER_NAME_CHANGED",
}

export enum JitsiCustomCommands {
  USER_NAME_CHANGED = "USER_NAME_CHANGED",
}

export type AvailableSources = {
  audio: MediaDeviceInfo[];
  video: MediaDeviceInfo[];
};

export class Jitsi extends EventTarget {
  localTrackVideoElement: HTMLVideoElement | null = null;
  connection: any = null;
  roomName = "";
  profileId = "";
  localTracks: any[] = [];
  remoteTracks: Record<string, any[]> = {};
  conference: any;
  intervalId: number | null = null;

  emit = (event: JitsiEvents) => {
    this.dispatchEvent(new Event(event));
  };

  init = (
    profileId: string,
    localTrackVideoElement: HTMLVideoElement,
    roomName: string
  ) => {
    if (this.connection) return console.log("Jitsi already initialized!");
    this.roomName = roomName;
    this.profileId = profileId;
    this.localTrackVideoElement = localTrackVideoElement;

    JitsiMeetJS.init();
    JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);

    this.connection = new JitsiMeetJS.JitsiConnection(null, null, options);

    this.connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
      this.onConnectionEstablished
    );

    this.connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_FAILED,
      this.onConnectionFailed
    );

    this.connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
      this.onConnectionDisconnected
    );
    this.connection.connect();
  };

  reconnect = () => {
    this.connection.removeEventListener(
      JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
      this.onConnectionEstablished
    );

    this.connection.removeEventListener(
      JitsiMeetJS.events.connection.CONNECTION_FAILED,
      this.onConnectionFailed
    );

    this.connection.removeEventListener(
      JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
      this.onConnectionDisconnected
    );

    this.connection = new JitsiMeetJS.JitsiConnection(null, null, options);

    this.connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
      this.onConnectionEstablished
    );

    this.connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_FAILED,
      this.onConnectionFailed
    );

    this.connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
      this.onConnectionDisconnected
    );

    this.connection.connect();
  };

  onConferenceJoined = () => {
    const participants = this.conference.getParticipants();
    for (const participant of participants) {
      const participantId = participant.id;
      const remoteTracks = participant.getTracks();
      this.remoteTracks[participantId] = remoteTracks;
    }
    this.emit(JitsiEvents.CONFERENCE_JOINED);
  };

  onConnectionEstablished = () => {
    if (this.intervalId) {
      clearTimeout(this.intervalId);
      this.intervalId = null;
    }
    this.emit(JitsiEvents.CONNECTION_ESTABLISHED);
    this.conference = this.connection.initJitsiConference(
      this.roomName,
      confOptions
    );

    this.conference.setDisplayName(this.profileId);

    this.conference.addCommandListener(
      JitsiCustomCommands.USER_NAME_CHANGED,
      this.onUserNameChanged
    );

    this.conference.on(
      JitsiMeetJS.events.conference.CONFERENCE_JOINED,
      this.onConferenceJoined
    );
    this.conference.on(
      JitsiMeetJS.events.conference.TRACK_ADDED,
      this.onTrackAdded
    );
    this.conference.on(
      JitsiMeetJS.events.conference.TRACK_REMOVED,
      this.onTrackRemoved
    );
    this.conference.on(
      JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED,
      this.onTrackMuted
    );
    this.conference.on(
      JitsiMeetJS.events.conference.USER_LEFT,
      this.onUserLeft
    );
    this.conference.on(
      JitsiMeetJS.events.conference.USER_JOINED,
      this.onUserJoined
    );

    if (this.localTracks.length !== 0) return;
    JitsiMeetJS.createLocalTracks({ devices: ["video", "audio"] })
      .catch((err: any) => {
        console.error("createLocalTracks error", err);
        return JitsiMeetJS.createLocalTracks({ devices: ["audio"] });
      })
      .then((tracks: any) => {
        const videoTrack = tracks.find((t: any) => t.getType() === "video");
        if (!videoTrack) {
          this.emit(JitsiEvents.VIDEO_TRACK_FAILURE);
        }

        for (const track of tracks) {
          this.conference.addTrack(track);
          track.addEventListener(
            JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
            (audioLevel: any) => {
              this.dispatchEvent(
                new CustomEvent(JitsiEvents.LOCAL_TRACK_AUDIO_LEVEL_CHANGED, {
                  detail: { audioLevel },
                })
              );
            }
          );
        }
        this.localTracks = tracks;
        for (const localTrack of tracks) {
          localTrack.attach(this.localTrackVideoElement);
        }
        this.emit(JitsiEvents.LOCAL_TRACKS_READY);
      });
  };

  toggleAudioMute = (deviceId: string) => {
    const audioTrack = this.localTracks.find((t) => t.getType() === "audio");
    if (!audioTrack) {
      this.changeAudioSource(deviceId);
      return;
    }
    return audioTrack.isMuted() ? audioTrack.unmute() : audioTrack.mute();
  };

  toggleVideoMute = (deviceId: string) => {
    const videoTrack = this.localTracks.find((t) => t.getType() === "video");
    if (!videoTrack) {
      this.changeVideoSource(deviceId);
      return;
    }
    return videoTrack.isMuted() ? videoTrack.unmute() : videoTrack.mute();
  };

  changeSource = (type: "audio" | "video", deviceId: string): Promise<any> => {
    return type === "audio"
      ? this.changeAudioSource(deviceId)
      : this.changeVideoSource(deviceId);
  };

  changeVideoSource = (deviceId: string): Promise<any> => {
    if (!deviceId) {
      return Promise.resolve();
    }
    const currentLocalVideoTrack = this.getLocalVideoTrack();
    return this.getAvailableSources().then((availableSources) => {
      const selectedVideoSource = availableSources.video.find((s) =>
        s.deviceId === currentLocalVideoTrack
          ? currentLocalVideoTrack?.getDeviceId()
          : deviceId
      );

      if (!selectedVideoSource || selectedVideoSource?.deviceId === deviceId)
        return;

      return JitsiMeetJS.createLocalTracks({
        devices: ["video"],
        cameraDeviceId: deviceId,
      }).then((tracks: any) => {
        const currentLocalVideoTrack = this.getLocalVideoTrack();
        const removeVideoPromise = currentLocalVideoTrack
          ? (this.conference.removeTrack(
              currentLocalVideoTrack
            ) as Promise<any>)
          : Promise.resolve();

        return removeVideoPromise
          .then((t) => {
            if (!t) return;
            return t.dispose();
          })
          .then(() => {
            for (const track of tracks) {
              this.conference.addTrack(track);
            }
            this.localTracks = this.localTracks.concat(tracks);
            for (const localTrack of tracks) {
              localTrack.attach(this.localTrackVideoElement);
            }
          });
      });
    });
  };

  changeAudioSource = (deviceId: string): Promise<any> => {
    if (!deviceId) {
      return Promise.resolve();
    }
    const currentLocalAudioTrack = this.getLocalAudioTrack();
    return this.getAvailableSources().then((availableSources) => {
      const selectedAudioSource = availableSources.audio.find((s) =>
        s.deviceId === currentLocalAudioTrack
          ? currentLocalAudioTrack?.getDeviceId()
          : deviceId
      );

      if (!selectedAudioSource || selectedAudioSource?.deviceId === deviceId)
        return;

      return JitsiMeetJS.createLocalTracks({
        devices: ["audio"],
        micDeviceId: deviceId,
      }).then((tracks: any) => {
        const currentLocalAudioTrack = this.getLocalAudioTrack();
        const removeAudioPromise = currentLocalAudioTrack
          ? (this.conference.removeTrack(
              currentLocalAudioTrack
            ) as Promise<any>)
          : Promise.resolve();

        return removeAudioPromise
          .then((t) => {
            if (!t) return;
            return t.dispose();
          })
          .then(() => {
            for (const track of tracks) {
              this.conference.addTrack(track);
            }
            this.localTracks = this.localTracks.concat(tracks);
            for (const localTrack of tracks) {
              localTrack.attach(this.localTrackVideoElement);
            }
          });
      });
    });
  };

  onConnectionFailed = () => {
    this.emit(JitsiEvents.CONNECTION_FAILURE);
    this.intervalId = setTimeout(
      () => this.reconnect(),
      3000
    ) as unknown as number;
  };

  onConnectionDisconnected = () => {
    this.emit(JitsiEvents.CONNECTION_DISCONNECTED);
  };

  onVideoPlaying = (event: any) => {
    const target = event.target as HTMLVideoElement;
    const participantId = target.getAttribute("data-participant-id");
    if (!participantId) return;
    this.dispatchEvent(
      new CustomEvent(JitsiEvents.VIDEO_PLAYING, {
        detail: { participantId, videoElement: target },
      })
    );
  };

  onVideoPaused = (event: any) => {
    const target = event.target as HTMLVideoElement;
    const participantId = target.getAttribute("data-participant-id");
    if (!participantId) return;
    this.dispatchEvent(
      new CustomEvent(JitsiEvents.VIDEO_PAUSED, {
        detail: { participantId, videoElement: target },
      })
    );
  };

  onAudioPlaying = (event: any) => {
    const target = event.target as HTMLVideoElement;
    const participantId = target.getAttribute("data-participant-id");
    if (!participantId) return;
    this.dispatchEvent(
      new CustomEvent(JitsiEvents.VIDEO_PLAYING, {
        detail: { participantId, videoElement: target },
      })
    );
  };

  onAudioPaused = (event: any) => {
    const target = event.target as HTMLVideoElement;
    const participantId = target.getAttribute("data-participant-id");
    if (!participantId) return;
    this.dispatchEvent(
      new CustomEvent(JitsiEvents.VIDEO_PAUSED, {
        detail: { participantId, videoElement: target },
      })
    );
  };

  attachVideoElementToRemoteTracks = (
    participantId: any,
    remoteTrackVideoElement: HTMLVideoElement,
    remoteTrackAudioElement: HTMLAudioElement
  ) => {
    const participantTracks = this.remoteTracks[participantId];
    if (!participantTracks || participantTracks.length === 0) return;
    for (const track of participantTracks) {
      const previousContainer = track.containers[0];
      if (previousContainer) track.detach(previousContainer);

      if (track.getType() === "video") {
        track.attach(remoteTrackVideoElement);
      } else {
        track.attach(remoteTrackAudioElement);
      }
    }
    const isSetup = remoteTrackVideoElement.hasAttribute("data-participant-id");
    if (!isSetup) {
      remoteTrackVideoElement.setAttribute(
        "data-participant-id",
        participantId
      );
      remoteTrackAudioElement.setAttribute(
        "data-participant-id",
        participantId
      );

      remoteTrackVideoElement.addEventListener("playing", this.onVideoPlaying);
      remoteTrackVideoElement.addEventListener("pause", this.onVideoPaused);

      remoteTrackAudioElement.addEventListener("playing", this.onAudioPlaying);
      remoteTrackAudioElement.addEventListener("pause", this.onAudioPaused);
    }
  };

  detachVideoElementToRemoteTracks = ({
    participantId,
  }: {
    participantId: string;
  }) => {
    const participantTracks = this.remoteTracks[participantId];
    if (!participantTracks || participantTracks.length === 0) return;
    for (const track of participantTracks) {
      track.containers.forEach((container: HTMLVideoElement) => {
        const isSetup = container.hasAttribute("data-participant-id");
        if (isSetup) {
          container.addEventListener("playing", this.onVideoPlaying);
          container.addEventListener("pause", this.onVideoPaused);
          container.removeAttribute("data-participant-id");
        }
        track.detach(container);
      });
    }
  };

  detachLocalTracksVideoContainer = (
    localTrackVideoElement?: HTMLVideoElement
  ) => {
    for (const localTrack of this.localTracks) {
      const container = localTrackVideoElement || localTrack.containers[0];
      if (container) localTrack.detach(container);
    }
  };

  attachLocalTracksVideoContainer = (
    localTrackVideoElement: HTMLVideoElement
  ) => {
    for (const localTrack of this.localTracks) {
      const previousContainer = localTrack.containers[0];
      if (previousContainer) localTrack.detach(previousContainer);
      localTrack.attach(localTrackVideoElement);
    }
  };

  onTrackAdded = (track: any) => {
    if (track.isLocal() && !this.localTracks.find((t) => t === track)) {
      this.localTracks = this.localTracks.concat(track);
      track.attach(this.localTrackVideoElement);
    } else if (!track.isLocal()) {
      const participant = track.getParticipantId();
      this.remoteTracks[participant] = (
        this.remoteTracks[participant] || []
      ).concat(track);
      this.dispatchEvent(
        new CustomEvent(JitsiEvents.REMOTE_TRACK_ADDED, { detail: track })
      );

      track.addEventListener(
        JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
        (audioLevel: any) => {
          this.dispatchEvent(
            new CustomEvent(JitsiEvents.TRACK_AUDIO_LEVEL_CHANGED, {
              detail: { track, audioLevel },
            })
          );
        }
      );
    }
  };

  onUserLeft = (participantId: string) => {
    const participantTracks = this.remoteTracks[participantId];

    const disposePromises = participantTracks.map((track) => track.dispose());
    Promise.all(disposePromises).then(() => {
      this.remoteTracks[participantId] = [];
      this.dispatchEvent(
        new CustomEvent(JitsiEvents.USER_LEFT, { detail: participantId })
      );
    });
  };
  onUserJoined = (participantId: string) => {
    this.remoteTracks[participantId] = [];
    this.dispatchEvent(
      new CustomEvent(JitsiEvents.USER_JOINED, { detail: participantId })
    );
  };

  onUserNameChanged = (data: { participantId: string; name: string }) => {
    this.dispatchEvent(
      new CustomEvent(JitsiEvents.USER_NAME_CHANGED, { detail: data })
    );
  };

  onTrackRemoved = (track: any) => {
    if (track.isLocal()) {
      this.localTracks = this.localTracks.filter((t) => t !== track);
      track.detach(this.localTrackVideoElement);
    } else {
      const participant = track.getParticipantId();
      this.remoteTracks[participant] = (
        this.remoteTracks[participant] || []
      ).filter((t) => t !== track);
      this.dispatchEvent(
        new CustomEvent(JitsiEvents.REMOTE_TRACK_REMOVED, { detail: track })
      );
    }
  };

  onTrackMuted = (track: any) => {
    if (track.isLocal()) return;
    this.dispatchEvent(
      new CustomEvent(JitsiEvents.REMOTE_TRACK_MUTED, { detail: track })
    );
  };

  detachLocalVideoElement = () => {
    for (const track of this.localTracks) {
      track.detach(this.localTrackVideoElement);
    }
    this.localTrackVideoElement = null;
  };

  attachLocalVideoElement = (newLocalVideoElement: HTMLVideoElement) => {
    for (const track of this.localTracks) {
      track.attach(newLocalVideoElement);
    }
    this.localTrackVideoElement = newLocalVideoElement;
  };

  availableAudioDevices: AvailableSources["audio"] = [];
  availableVideoDevices: AvailableSources["video"] = [];
  getAvailableSources = (): Promise<AvailableSources> => {
    this.availableAudioDevices = [];
    this.availableVideoDevices = [];
    return navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        return devices.reduce(
          (acc, device) => {
            if (device.kind === "audioinput") {
              acc.audio.push(device);
              this.availableAudioDevices.push(device);
            } else if (device.kind === "videoinput") {
              acc.video.push(device);
              this.availableVideoDevices.push(device);
            }
            return acc;
          },
          { audio: [], video: [] } as AvailableSources
        );
      })
      .catch(() => ({ audio: [], video: [] } as AvailableSources));
  };

  getLocalAudioTrack = () => {
    return this.localTracks.find((t) => t.getType() === "audio");
  };
  getLocalVideoTrack = () => {
    return this.localTracks.find((t) => t.getType() === "video");
  };
  getParticipantData = (participantId: string) => {
    return this.conference.getParticipantById(participantId);
  };

  sendCommand = (
    command: JitsiCustomCommands,
    {
      attributes,
      children,
    }: {
      attributes: {
        participantId: string;
        name: string;
      };
      children?: [];
    }
  ) => {
    if (!this.conference) return;
    const commandData = {
      attributes: attributes || {},
      children: children || [],
    };
    this.conference.sendCommandOnce(command, commandData);
  };
}
