import {
  PropsWithChildren,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import WorkshopDetails, { WorkshopDetailProps } from "./WorkshopDetails";

import styles from "./Waiting.module.css";
import ActionFooter from "./ActionFooter";
import { Slot } from "../../apollo-graphql/types/slot";
import { getHours, getMinutes, getUnixTime } from "date-fns";
import { FooterType } from "../../types/enums/activity-footer";
import {
  WorkshopClock,
  WorkshopClockEvent,
} from "../../helpers/workshop-clock";
import { ApolloContext, SERVER_TIME_UPDATE } from "../../contexts/Apollo";

const defaultButtonText = "Let's start";

export default memo(function Waiting(
  props: PropsWithChildren<
    {
      slot: Slot;
      isReady: boolean;
      transition: number;
      isParticipating: boolean;
      notReadyProfilesCount: number;
      invitedParticipantCount: number;
      currentActiveParticipantCount: number;
      minimumWorkshopParticipants: number;
      millisecondsToStart: number | null;
      toggleParticipationHandler: () => void;
      setActivityReadyToStartHandler: () => void;
    } & WorkshopDetailProps
  >
) {
  const {
    slot,
    author,
    isReady,
    workshop,
    transition,
    isParticipating,
    notReadyProfilesCount,
    invitedParticipantCount,
    millisecondsToStart,
    minimumWorkshopParticipants,
    currentActiveParticipantCount,
    setActivityReadyToStartHandler,
  } = props;

  const { serverTimeEventTarget } = useContext(ApolloContext);

  const [isTimeToStart, setIsTimeToStart] = useState<boolean>(
    !millisecondsToStart || millisecondsToStart <= 0
  );
  const [startTimeString, setStartTimeString] = useState<string>("--:--");
  const [buttonText, setButtonText] = useState<string>("");

  const clockRef = useRef<WorkshopClock | null>(null);

  const isTransitioning = useMemo(() => transition > 0, [transition]);

  const footerText = useMemo(() => {
    if (isTimeToStart) {
      if (currentActiveParticipantCount < minimumWorkshopParticipants)
        // TODO @ak Take reconnect timeouts into account here when counting
        return <>Waiting for other players to join</>;
      if (notReadyProfilesCount > 0 && transition === 0) {
        if (notReadyProfilesCount === currentActiveParticipantCount) {
          return (
            <>
              Once all players join, everyone has to click{" "}
              <span className="accent">“Let's start”</span> to begin.
            </>
          );
        }

        const playersClicked =
          currentActiveParticipantCount - notReadyProfilesCount;

        if (!isReady && playersClicked) {
          return (
            <>
              Once all players join, everyone has to click{" "}
              <span className="accent">“Let's start”</span> to begin.{" "}
              {playersClicked > 0 && (
                <span className="accent">
                  {playersClicked} player{playersClicked > 1 && "s"} clicked.
                </span>
              )}
            </>
          );
        }

        return (
          <>
            Waiting for{" "}
            <span className="accent">
              {notReadyProfilesCount} more player
              {notReadyProfilesCount > 1 && "s"}...
            </span>
          </>
        );
      }
      return (
        <>
          Everyone is ready. Continuing forward in{" "}
          <span className="accent">{transition}...</span>
        </>
      );
    }
    return (
      <>
        You will be able to start the workshop{" "}
        <span className="time">at {startTimeString}</span>
      </>
    );
  }, [
    currentActiveParticipantCount,
    isReady,
    isTimeToStart,
    minimumWorkshopParticipants,
    notReadyProfilesCount,
    startTimeString,
    transition,
  ]);

  const footerType: FooterType = useMemo(
    () =>
      transition > 0
        ? FooterType.Ready
        : isReady
        ? FooterType.Waiting
        : FooterType.Notice,
    [isReady, transition]
  );

  const footerButtonDisabled: boolean = useMemo(
    () =>
      isReady ||
      currentActiveParticipantCount < minimumWorkshopParticipants ||
      footerType !== FooterType.Notice ||
      buttonText !== defaultButtonText,
    [
      isReady,
      buttonText,
      footerType,
      minimumWorkshopParticipants,
      currentActiveParticipantCount,
    ]
  );

  const showInvitedPeople = useMemo(() => {
    return slot.schedule_date !== slot.create_date;
  }, [slot.create_date, slot.schedule_date]);

  useEffect(() => {
    if (isTimeToStart) {
      setButtonText(defaultButtonText);
      return;
    }

    const serverTimeEventHandler = (event: Event) => {
      const currentServerTime = (event as any).detail;
      const secondsToStart = slot.schedule_date.valueOf() - currentServerTime;
      const new_isTimeToStart = secondsToStart <= 0;

      setIsTimeToStart(new_isTimeToStart);
      if (new_isTimeToStart) {
        serverTimeEventTarget.removeEventListener(
          SERVER_TIME_UPDATE,
          serverTimeEventHandler
        );
        clockRef.current?.dispose();
        setButtonText(defaultButtonText);
        return;
      }

      const now = getUnixTime(new Date());
      const localStartUnixTime = now + secondsToStart;

      const hours = getHours(localStartUnixTime * 1000);
      const minutes = getMinutes(localStartUnixTime * 1000);
      const hoursText = hours < 10 ? `0${hours}` : `${hours}`;
      const minutesText = minutes < 10 ? `0${minutes}` : `${minutes}`;
      setStartTimeString(`${hoursText}:${minutesText}`);

      if (!clockRef.current) {
        clockRef.current = new WorkshopClock({
          durationInSeconds: secondsToStart,
        });
        clockRef.current.addEventListener(WorkshopClockEvent.TICK, () => {
          setButtonText(clockRef.current!.workshopParsedTimeRemaining);
        });
        clockRef.current.addEventListener(WorkshopClockEvent.TIMEOUT, () => {
          setButtonText(defaultButtonText);
          clockRef.current?.dispose();
        });
        clockRef.current.start(currentServerTime);
        return;
      }
      clockRef.current.adjustTime(currentServerTime);
    };
    serverTimeEventTarget.addEventListener(
      SERVER_TIME_UPDATE,
      serverTimeEventHandler
    );

    return () => {
      serverTimeEventTarget.removeEventListener(
        SERVER_TIME_UPDATE,
        serverTimeEventHandler
      );
      clockRef.current?.dispose();
    };
  }, [serverTimeEventTarget, slot.schedule_date, isTimeToStart]);

  return (
    <div className={styles.container}>
      <div className="main-container">
        <WorkshopDetails
          workshop={workshop}
          author={author}
          invitedParticipantCount={invitedParticipantCount}
          showInvitedPeople={showInvitedPeople}
        />
      </div>
      {isParticipating && (
        <ActionFooter
          buttonText={buttonText}
          type={footerType}
          disabledButton={footerButtonDisabled}
          buttonClickHandler={setActivityReadyToStartHandler}
          isLoading={isTransitioning}
        >
          {footerText}
        </ActionFooter>
      )}
    </div>
  );
});
