import { RemoteParticipantData } from "../../types/jitsi";
import { SourceData } from "../machines/jitsi";
import { createAction, props } from "../utils";

export const connect = createAction("[JITSI Connection] Connect");
export const disconnect = createAction(
  "[JITSI Connection] Disconnect",
  props<{ serverDisconnect: boolean }>()
);
export const reconnect = createAction("[JITSI Connection] Reconnect");
export const dispose = createAction("[JITSI Connection] Dispose");

export const remoteParticipantJoined = createAction(
  "[JITSI Setup] Remote participant joined",
  props<{ remoteParticipantData: RemoteParticipantData }>()
);
type ParticipantUpdatePayload =
  | {
      remoteParticipantData: RemoteParticipantData;
      updates?: void;
    }
  | { updates: Partial<RemoteParticipantData>; remoteParticipantData?: void };

export const remoteParticipantUpdate = createAction(
  "[JITSI Setup] Remote participant update",
  props<ParticipantUpdatePayload>()
);
export const remoteParticipantDisconnected = createAction(
  "[JITSI Setup] Remote participant disconnected",
  props<{ participantId: string }>()
);
export const remoteParticipantMuted = createAction(
  "[JITSI Setup] Remote participant muted",
  props<{ participantId: string; type: "video" | "audio" }>()
);

export const attachParticipantVideoElement = createAction(
  "[JITSI Setup] Attach remote participant video",
  props<{ participantId: string; videoElement: HTMLVideoElement }>()
);

export const detachParticipantVideoElement = createAction(
  "[JITSI Setup] Detach remote participant video",
  props<{ participantId: string }>()
);

export const initialize = createAction(
  "[JITSI Setup] Initialize",
  props<{
    profileId: string;
    sessionId: string;
    htmlVideoElement: HTMLVideoElement;
  }>()
);
export const configure = createAction(
  "[JITSI Setup] Configure",
  props<{
    availableVideoSources?: SourceData[];
    availableAudioSources?: SourceData[];
    selectedVideoSourceData: SourceData;
    selectedAudioSourceData: SourceData;
  }>()
);
export const handleActivityPartChange = createAction(
  "[JITSI Setup] Handle activity part change",
  props<{
    shouldBeMuted: boolean;
  }>()
);
export const noPermissions = createAction("[JITSI Setup] No Permissions");
export const joinConference = createAction(
  "[JITSI Setup] Join Conference",
  props<{
    selectedVideoSourceData?: SourceData;
    selectedAudioSourceData?: SourceData;
  }>()
);
export const done = createAction(
  "[JITSI Setup] Done",
  props<{
    selectedVideoSourceData?: SourceData;
    selectedAudioSourceData?: SourceData;
  }>()
);

export const remoteParticipantIsTalking = createAction(
  "[JITSI Setup] Remote participant is talking",
  props<{ participantId: string; isTalking: boolean }>()
);

export const userIsTalking = createAction(
  "[JITSI Setup] Current user is talking",
  props<{ isTalking: boolean }>()
);

export const reset = createAction("[JITSI Setup] Reset");

export const handleUserNameChange = createAction(
  "[JITSI Setup] Handle user name change",
  props<{ participantId: string, name: string }>()
);
