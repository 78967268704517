import { FormEvent, useCallback, useContext, useMemo, useState } from "react";
import * as Form from "@radix-ui/react-form";

import { Link, Navigate } from "react-router-dom";
import { GlobalContext } from "../contexts/Global";
import { AuthState } from "../+xstate/machines/auth";

import Button3D from "../components/Shared/Button3D/Button3D";
import { IResetPassword } from "../apollo-graphql/types/reset-password";
import { FetchState } from "../+xstate/machines/fetch-factory";
import { mappedErrorMessages } from "../constants/mapped-error-messages";
import { ResetPasswordErrors } from "../types/enums/errors";
import withTitle from "../hocs/withTitle";

import styles from "./ResetPassword.module.css";

const ResetPassword = () => {
  const {
    auth: { resetPassword, context, resetPasswordState, matches },
  } = useContext(GlobalContext);
  const [errorMessage, setErrorMessage] = useState("");

  const isLoggedIn = matches(AuthState.Authenticated);
  const isLoading = useMemo(
    () => resetPasswordState === FetchState.Fetching,
    [resetPasswordState]
  );

  const successMessage = useMemo(
    () =>
      resetPasswordState === FetchState.Success
        ? "The link to reset your password has been sent. Please check your email box."
        : "",
    [resetPasswordState]
  );

  const submitButtonDisabled = useMemo(
    () => resetPasswordState === FetchState.Fetching,
    [resetPasswordState]
  );

  const submitHandler = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      setErrorMessage("");
      event.preventDefault();
      const payload = Object.fromEntries(
        new FormData(event.currentTarget)
      ) as unknown as IResetPassword;

      if (!payload.email || payload.email.length === 0) {
        setErrorMessage(mappedErrorMessages[ResetPasswordErrors.MISSING_DATA]);
        return;
      }
      resetPassword({ variables: payload });
    },
    [resetPassword]
  );

  return isLoggedIn ? (
    <Navigate to={"/"} />
  ) : (
    <div className={styles.resetPasswordContainer}>
      <div className="FormContainer">
        <h2 className="title">Reset your password</h2>
        <div className="sub-title text">
          Enter your email and we'll send you instructions on how to reset your
          password.
        </div>
        <Form.Root className="Form" onSubmit={submitHandler}>
          <Form.Field name="email">
            <Form.Control
              className="FormControl"
              placeholder="Enter your email address"
            />
          </Form.Field>
          <Button3D
            className="submit-btn"
            variant="success"
            size="large"
            type="submit"
            disabled={submitButtonDisabled}
          >
            Send instructions
          </Button3D>
          {(errorMessage || context?.error) && !isLoading && (
            <div className="error">
              {errorMessage ||
                mappedErrorMessages[
                  context?.error as keyof typeof mappedErrorMessages
                ]}
            </div>
          )}
          {isLoading && <div className="loading">Loading...</div>}
          {successMessage && <div className="success">{successMessage}</div>}
        </Form.Root>
      </div>
      <div className="login-link">
        or go back to <Link to={"/login"}>login page</Link>
      </div>
    </div>
  );
};

export default withTitle(ResetPassword);
