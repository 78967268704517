import { CSSProperties, PropsWithChildren, memo } from "react";

import styles from "./Loader.module.css";
import cn from "classnames";

const Loader = (
  props: PropsWithChildren<{ className?: string; style?: CSSProperties }>
) => {
  const { className = "", style = {} } = props;
  return (
    <div className={cn(styles.loaderContainer, className)} style={style}>
      <div className="loader"></div>
    </div>
  );
};

export default memo(Loader);
