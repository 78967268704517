import { gql } from "@apollo/client";
import { SessionResult } from "../types/results/session-result";
import { AppApolloClient } from "../../contexts/Apollo";

const queryWithSlotAndWorkshop = gql`
  query Query($sessionKey: String!, $group: Int) {
    getSession(session_key: $sessionKey, group: $group) {
      group
      serverTimestamp
      millisecondsToStart
      splitMillisecondsWaitingTime
      sessionOpeningTimeInMilliseconds
      session {
        complete_date
        completed_activities
        create_date
        creator_id
        id
        session_key
        state
        status
        slot {
          id
          create_date
          update_date
          creator_id
          ics
          ics_uid
          key
          reminder_status
          schedule_date
          status
          type
          sessions {
            id
            create_date
            update_date
            complete_date
            session_key
            state
            status
            slot_id
            creator_id
            workshop_id
            workspace_id
          }
          invitations {
            email
            status
            profile {
              id
              name
              headline
              email
              workspace {
                workspace_id
              }
            }
          }
          workshop {
            about_text
            about_video
            author_id
            create_date
            duration
            headline
            id
            topic
            status
            type
            update_date
            typeInstance {
              id
              name
              video
            }
            author {
              id
              email
              headline
              image
              name
            }
            goals {
              id
              create_date
              update_date
              text
              sequence_number
              workshop_id
            }
            activities {
              description
              sequence_number
              type
              workshop_id
              id
              action {
                g_duration
                i_duration
                text
                activity_id
              }
              recommendations {
                activity_id
                create_date
                id
                sequence_number
                text
                details
                update_date
                workshops {
                  about_text
                  about_video
                  author {
                    id
                    email
                    headline
                    name
                  }
                  author_id
                  create_date
                  duration
                  headline
                  id
                  recommendationWorkshop {
                    recommendation_id
                    weight
                    workshop_id
                  }
                  status
                  topic
                  type
                  update_date
                }
              }
              answers {
                activity_id
                create_date
                explanation_text
                id
                points
                text
                update_date
              }
              assignment {
                activity_id
                duration
                conceptualization_id
                text
                video
              }
              benchmark {
                activity_id
                baseline
                conceptualization_id
                g_duration
                i_duration
                reference
              }
              moment {
                id
                activity_id
                g_duration
                i_duration
                text

                momentItems {
                  id
                  text
                }
              }
              concepts {
                activity_id
                create_date
                id
                name
                sequence_number
                text
                update_date
              }
              conceptualization {
                activity_id
                concept
                g_duration
                i_duration
                instructions
              }
              question {
                activity_id
                assignment_id
                g_duration
                i_duration
                text
                theory_id
              }
              theory {
                activity_id
                conceptualization_id
                duration
                video
              }
              items {
                details
                activity_id
                max_text
                min_text
                more
                question
                sequence_number
                text
              }
              survey {
                activity_id
                i_duration
              }
            }
          }
          workshop_id
          workspace_id
        }
      }
    }
  }
`;

const query = gql`
  query Query($sessionKey: String!, $group: Int) {
    getSession(session_key: $sessionKey, group: $group) {
      group
      millisecondsToStart
      splitMillisecondsWaitingTime
      sessionOpeningTimeInMilliseconds
      session {
        complete_date
        completed_activities
        create_date
        creator_id
        status
        id
        session_key
        state
      }
    }
  }
`;

export function getSession(
  client: AppApolloClient,
  variables: {
    sessionKey: string;
    group?: number;
    includeSlotAndWorkshop: boolean;
  }
) {
  const { includeSlotAndWorkshop, ...queryVariables } = variables;
  return client
    .query<SessionResult>({
      query: includeSlotAndWorkshop ? queryWithSlotAndWorkshop : query,
      variables: queryVariables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.getSession;
    });
}
