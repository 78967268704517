import { PropsWithChildren, forwardRef, memo, useMemo } from "react";

import { VideoPreview } from "./VideoPreview";
import Button3D from "../Shared/Button3D/Button3D";
import SkeletonLoader from "../Shared/SkeletonLoader/SkeletonLoader";
import { SourceData } from "../../+xstate/machines/jitsi";

import styles from "./JitsiSetup.module.css";
import cn from "classnames";

export const JitsiSetup = memo(
  forwardRef(
    (
      props: PropsWithChildren<{
        profileId: string;
        isConnected: boolean;
        selectedAudioSourceData: SourceData | null;
        selectedVideoSourceData: SourceData | null;
        availableAudioSources: SourceData[] | null;
        availableVideoSources: SourceData[] | null;
        isProcessing: boolean;
        toggleAudioHandler: () => void;
        toggleVideoHandler: () => void;
        setupCompletedHandler: () => void;
        audioDeviceChangeHandler: (newSourceId: string) => void;
        videoDeviceChangeHandler: (newSourceId: string) => void;
      }>,
      ref: React.ForwardedRef<HTMLVideoElement | null>
    ) => {
      const {
        profileId,
        isConnected,
        selectedAudioSourceData,
        selectedVideoSourceData,
        availableAudioSources,
        availableVideoSources,
        isProcessing,
        toggleAudioHandler,
        toggleVideoHandler,
        setupCompletedHandler,
        audioDeviceChangeHandler,
        videoDeviceChangeHandler,
      } = props;

      const content = useMemo(() => {
        return (
          <>
            <div
              className={cn(
                styles.loaderContent,
                (isConnected || isProcessing) && "hidden"
              )}
            >
              <SkeletonLoader
                className={styles.loaderVideo}
                borderRadius={16}
              />
              <SkeletonLoader
                className={styles.loaderActions}
                width={120}
                height={32}
                baseColor="#f9f9f9"
              />
              <SkeletonLoader width={390} height={115} borderRadius={16} />
            </div>

            <VideoPreview
              className={cn(
                styles.videoContent,
                !isConnected && !isProcessing && "hidden"
              )}
              ref={ref}
              width={390}
              height={390}
              profileId={profileId}
              selectedAudioDevice={selectedAudioSourceData}
              availableAudioSources={availableAudioSources}
              selectedVideoDevice={selectedVideoSourceData}
              availableVideoSources={availableVideoSources}
              hideConfigurations={false}
              audioDeviceChangeHandler={audioDeviceChangeHandler}
              videoDeviceChangeHandler={videoDeviceChangeHandler}
              toggleAudioHandler={toggleAudioHandler}
              toggleVideoHandler={toggleVideoHandler}
            />
          </>
        );
      }, [
        audioDeviceChangeHandler,
        availableAudioSources,
        availableVideoSources,
        isConnected,
        isProcessing,
        profileId,
        ref,
        selectedAudioSourceData,
        selectedVideoSourceData,
        toggleAudioHandler,
        toggleVideoHandler,
        videoDeviceChangeHandler,
      ]);

      const actionsContent = useMemo(() => {
        return !isConnected ? (
          <SkeletonLoader width={390} height={55} borderRadius={16} />
        ) : (
          <Button3D
            disabled={isProcessing || !selectedAudioSourceData}
            variant="success"
            onClick={setupCompletedHandler}
            isLoading={isProcessing}
          >
            {isProcessing ? `Processing ...` : `Let's start`}
          </Button3D>
        );
      }, [
        isConnected,
        isProcessing,
        selectedAudioSourceData,
        setupCompletedHandler,
      ]);

      return (
        <div className={styles.container}>
          <div className={styles.inner}>
            <h1>Initial Setup</h1>
            <div className={styles.content}>{content}</div>
            {actionsContent}
          </div>
        </div>
      );
    }
  )
);
