import { PropsWithChildren, memo } from "react";

import styles from "./Countdown.module.css";
import cn from "classnames";

const Countdown = (
  props: PropsWithChildren<{
    timeValue: string;
    progress: number;
    timeIsRunningUp: boolean;
    isPassedTime?: boolean
  }>
) => {
  const { timeValue, progress, timeIsRunningUp, isPassedTime } = props;

  return (
    <div className={cn(styles.timerWrapper, timeIsRunningUp && "is-red" , isPassedTime && "is-passed-time")}>
      <div className={cn("timer-value", "text", "small", "bold", "extra")}>
        {timeValue}
      </div>
      <div className={cn("timer-container", "inner")}>
        <div
          className={cn(
            "progress-color-container",
            timeIsRunningUp && "is-red"
          )}
          style={{ width: `${progress}%` }}
        >
          <div className="progress-text-container">
            <div
              className={cn("timer-value", "text", "small", "bold", "extra")}
            >
              {timeValue}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Countdown);
