import { gql } from "@apollo/client";
import {
  MyProfileResult,
  ProfileResult,
  ProfilesResult,
} from "../types/results";
import { AppApolloClient } from "../../contexts/Apollo";
import { Pagination } from "../../types/pagination";

const getMyProfileQuery = gql`
  query GetMyProfile {
    getMyProfile {
      create_date
      email
      id
      headline
      image
      is_completed
      login_date
      name
      update_date
      workspace {
        access
        profile_id
        status
        title
        workspace_id
        workspace {
          create_date
          domains {
            domain
          }
          id
          image
          name
          update_date
          workspace_key
        }
      }
    }
  }
`;

export function getMyProfile(client: AppApolloClient) {
  return client
    .query<MyProfileResult>({ query: getMyProfileQuery })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.getMyProfile;
    });
}

const getProfileQuery = gql`
  query GetProfile($id: String!) {
    getProfile(id: $id) {
      create_date
      email
      headline
      id
      image
      is_completed
      name
      workspace {
        workspace_id
        access
        status
      }
    }
  }
`;

export function getProfile(client: AppApolloClient, variables: { id: string }) {
  return client
    .query<ProfileResult>({
      query: getProfileQuery,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.getProfile;
    });
}

const profileAllFragment = gql`
  fragment profileAll on Profile {
    create_date
    email
    headline
    id
    image
    is_completed
    login_date
    name
    update_date
    workspace {
      access
      profile_id
      status
      title
      workspace {
        create_date
        domains {
          domain
        }
        id
        image
        name
        update_date
        workspace_key
      }
    }
  }
`;

const getProfilesQuery = gql`
  query GetProfiles(
    $workspaceId: String
    $pagination: OffsetPagination
    $query: String
  ) {
    getProfiles(
      workspace_id: $workspaceId
      pagination: $pagination
      query: $query
    ) {
      nodes {
        ...profileAll
      }
      pageInfo {
        total
      }
    }
  }
  ${profileAllFragment}
`;

export function getProfiles(
  client: AppApolloClient,
  variables: { workspace_id: string; pagination: Pagination; query?: string }
) {
  return client
    .query<ProfilesResult>({
      query: getProfilesQuery,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.getProfiles;
    });
}
