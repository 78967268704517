import { SlotStatus } from "../../../apollo-graphql/types/enums";
import { IEditSlot } from "../../../apollo-graphql/types/slot";
import { SortDirection } from "../../../types/enums/sort-direction";
import { createAction, props } from "../../utils";

export const goToDashboard = createAction("[Admin Dashboard] Go To Dashboard");
export const goToWorkshops = createAction(
  "[Admin Dashboard] Go To Workshops",
  props<{ searchText: string }>()
);
export const goToSchedule = createAction(
  "[Admin Dashboard] Go To Schedule",
  props<{
    workspaceId: string | null;
    sortDirection: SortDirection;
    slotStatuses?: SlotStatus[];
  }>()
);

export const editSlot = createAction(
  "[Admin Dashboard Schedule] Edit slot",
  props<IEditSlot>()
);
export const goToTeamMembers = createAction(
  "[Admin Dashboard] Go To Team Members",
  props<{
    workspaceId: string | null;
    currentPage: number;
    pageSize: number;
    query?: string;
  }>()
);
