import { PropsWithChildren, createContext, useEffect, useState } from "react";

export const DocumentVisibilityContext = createContext<DocumentVisibilityState>(
  {} as any
);

export const DocumentVisibilityContextProvider = (props: PropsWithChildren) => {
  const [visibilityState, setVisibilityState] = useState(
    document.visibilityState || "visible"
  );
  useEffect(() => {
    const visibilityChangeHandler = () => {
      console.log("Document visibility: ", document.visibilityState);
      setVisibilityState(document.visibilityState);
    };
    document.addEventListener("visibilitychange", visibilityChangeHandler);
    return () => {
      document.removeEventListener("visibilitychange", visibilityChangeHandler);
    };
  }, []);
  return (
    <DocumentVisibilityContext.Provider value={visibilityState}>
      {props.children}
    </DocumentVisibilityContext.Provider>
  );
};
