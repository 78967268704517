import * as Form from "@radix-ui/react-form";
import {
  ChangeEvent,
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cn from "classnames";
import { Doc, encodeStateAsUpdate, applyUpdate, YTextEvent } from "yjs";

import styles from "./TeamName.module.css";
import ActionFooter from "./ActionFooter";
import { ActionFooterType } from "../../types/action-footer";
import { FooterType } from "../../types/enums/activity-footer";
import {
  serializeUint8Array,
  deserializeUint8Array,
} from "../../utils/uint8-array-serializers";
import { diffChars } from "diff";
import { ActivityComplexValue } from "../../types/activity-complex-value";
import { ActivityComplexValueType } from "../../types/enums/activity-complex-value-type";
import { debounce } from "lodash";

export default memo(function TeamName(
  props: PropsWithChildren<{
    teamNameValue: string;
    isReady: boolean;
    transition: number;
    isParticipating: boolean;
    notReadyProfilesCount: number;
    currentActiveParticipantCount: number;
    changeTeamNameHandler: (inputValue: string) => void;
    setActivityReadyHandler: () => void;
  }>
) {
  const {
    teamNameValue,
    isReady,
    transition,
    notReadyProfilesCount,
    currentActiveParticipantCount,
    isParticipating,
    changeTeamNameHandler,
    setActivityReadyHandler,
  } = props;

  const [currentInputValue, setCurrentInputValue] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);

  const inputElementPrevValue = useRef<string>("");
  const inputElementRef = useRef<HTMLInputElement | null>(null);

  const yDoc = useMemo(() => new Doc(), []);
  const isTransitioning = useMemo(() => transition > 0, [transition]);

  const debouncedChangeTeamNameHandler = useMemo(
    () => debounce(changeTeamNameHandler, 100),
    [changeTeamNameHandler]
  );

  const actionFooterData: ActionFooterType = useMemo(() => {
    const teamName = inputElementRef?.current?.value;

    if (isTransitioning) {
      return {
        text: (
          <>
            Everyone agreed{teamName && <> on “{teamName}”</>}! Continuing
            forward <span className="accent">in {transition}...</span>
          </>
        ),
        buttonText: "I agree",
        disabledButton: true,
        type: FooterType.Ready,
        isLoading: true,
      };
    }
    if (isReady) {
      return {
        text: (
          <>
            Waiting for{" "}
            <span className="accent">
              {notReadyProfilesCount} more player
              {notReadyProfilesCount > 1 && "s"}...
            </span>
          </>
        ),
        buttonText: "I agree",
        disabledButton: true,
        type: FooterType.Waiting,
        isLoading: false,
      };
    }

    const playersClicked =
      currentActiveParticipantCount - notReadyProfilesCount;
    return {
      text: (
        <>
          {!!playersClicked
            ? "A new team name was proposed. Click on “I agree” to continue!"
            : "Everyone must agree on the team name to continue!"}{" "}
          {playersClicked > 0 && (
            <span className="accent">
              {playersClicked} player{playersClicked > 1 && "s"} clicked.
            </span>
          )}
        </>
      ),
      buttonText: "I agree",
      disabledButton: !currentInputValue?.length,
      type: FooterType.Notice,
      isLoading: false,
    };
  }, [
    isTransitioning,
    isReady,
    currentActiveParticipantCount,
    notReadyProfilesCount,
    currentInputValue?.length,
    transition,
  ]);

  const inputElementBorderStyle = useMemo(() => {
    const playersClicked =
      currentActiveParticipantCount - notReadyProfilesCount;

    if (isTransitioning) {
      return "2px solid var(--Colors-Functional-Info-600, #22B5D1)";
    }

    if (!isReady && playersClicked) {
      return "2px dashed var(--Colors-Neutral-gray-700, #444340)";
    }

    return `2px ${playersClicked ? "dashed" : "solid"} ${
      playersClicked
        ? "var(--Colors-Functional-Info-600, #22B5D1)"
        : "var(--Colors-Text-text-palest, #BEBEB3)"
    }`;
  }, [
    currentActiveParticipantCount,
    notReadyProfilesCount,
    isTransitioning,
    isReady,
  ]);

  const onChangeHandler = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      const yDocText = yDoc.getText();
      if (!yDocText) return;
      const differences = diffChars(inputElementPrevValue.current, value);
      inputElementPrevValue.current = value;
      const additions = differences.filter((d) => !!d.added);
      const deletions = differences.filter((d) => !!d.removed);

      for (const a of additions) {
        const arrayIndex = differences.indexOf(a);
        const textIndex = differences
          .slice(0, arrayIndex)
          .reduce((acc, curr) => acc + (curr?.count || 0), 0);
        yDocText.insert(textIndex, a.value);
      }
      for (const d of deletions) {
        const arrayIndex = differences.indexOf(d);
        const textIndex = differences
          .slice(0, arrayIndex)
          .reduce((acc, curr) => acc + (curr?.count || 0), 0);
        yDocText.delete(textIndex, d.count!);
      }

      setCurrentInputValue(value);
    },
    [yDoc]
  );

  const onFocusHandler = useCallback(() => {
    setIsInputFocused(true);
  }, []);

  const onBlurHandler = useCallback(() => {
    setIsInputFocused(false);
  }, []);

  useEffect(() => {
    if (!teamNameValue || !yDoc || !inputElementRef.current) return;
    let teamNameData: ActivityComplexValue | null = null;
    try {
      teamNameData = JSON.parse(teamNameValue);
    } catch (e) {
      console.error("Cannot parse teamNameValue to extract data", e);
    }
    if (teamNameData && "value" in teamNameData) {
      const update = deserializeUint8Array(teamNameData.value);
      if (update) applyUpdate(yDoc, update);
    }
    const text = yDoc?.getText()?.toString() || "";

    inputElementRef.current.value = text;
    inputElementPrevValue.current = text;
    setCurrentInputValue(text);
  }, [teamNameValue, yDoc]);

  useEffect(() => {
    const yDocText = yDoc.getText();
    if (!yDocText) return;

    const updateHandler = (event: YTextEvent) => {
      if (!event.target.doc) return;
      const update = encodeStateAsUpdate(event.target.doc);
      const uInt8ArrayString = serializeUint8Array(update);
      const activityComplexValue: ActivityComplexValue = {
        value: uInt8ArrayString,
        type: ActivityComplexValueType.yjs,
      };
      debouncedChangeTeamNameHandler(JSON.stringify(activityComplexValue));
    };

    yDocText.observe(updateHandler);

    return () => {
      yDocText.unobserve(updateHandler);
    };
  }, [debouncedChangeTeamNameHandler, yDoc]);

  return (
    <Form.Root onSubmit={(e) => e.preventDefault()}>
      <div className={styles.container}>
        <div className={cn(styles.teamNameContainer, "main-container")}>
          <h1>Choose your team name</h1>
          <p className="text secondary">
            <i className="icon fa fa-circle-info" /> This is a synchronized
            field where everyone can type
          </p>
          <div className={styles.formContainer}>
            <Form.Field
              name="teamName"
              className={cn(
                styles.nameInput,
                "input-big",
                isInputFocused && "focused",
                isTransitioning && "blue"
              )}
            >
              <Form.Control
                ref={inputElementRef}
                style={{ border: inputElementBorderStyle }}
                placeholder="Name this team"
                disabled={isTransitioning}
                onInput={onChangeHandler}
                onFocus={onFocusHandler}
                onBlur={onBlurHandler}
                maxLength={30}
                autoFocus={true}
              />
            </Form.Field>
          </div>
        </div>

        {isParticipating && (
          <ActionFooter
            buttonText={actionFooterData.buttonText}
            type={actionFooterData.type}
            disabledButton={actionFooterData.disabledButton}
            buttonClickHandler={setActivityReadyHandler}
            isLoading={actionFooterData.isLoading}
          >
            {actionFooterData.text}
          </ActionFooter>
        )}
      </div>
    </Form.Root>
  );
});
