import {
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import withTitle from "../../hocs/withTitle";

import ViewResults from "./ViewResults/ViewResults";
import WorkshopActivity from "./Activity";
import TeamName from "./TeamName";
import Waiting from "./Waiting";
import Emotion from "./Emotion";
import Rating from "./Rating";

import { WorkshopDetailProps } from "./WorkshopDetails";
import { StandardSessionActivity } from "../../apollo-graphql/types/enums/standard-session-activity";
import { SessionStateValue } from "../../apollo-graphql/types/session-state";
import { InvitationStatus } from "../../types/enums/invitation-status";
import { Activity } from "../../apollo-graphql/types/activity";
import { ActivityPart } from "../../types/enums/activity-part";
import { Workspace } from "../../apollo-graphql/types/workspace";
import { Workshop } from "../../apollo-graphql/types/workshop";
import { Feedback } from "../../apollo-graphql/types/feedback";
import { Slot } from "../../apollo-graphql/types/slot";
import { ConnectionStrength } from "../../types/enums/connection-strength";

function WorkshopInstance(
  props: PropsWithChildren<{
    isSessionCompleted: boolean;
    workshop: Workshop;
    feedbacks: Feedback[];
    connectionStrength: ConnectionStrength | null;
    isFetchingFeedbacks: boolean;
    nextWorkshop: Workshop | null;
    isFetchingNextWorkshop: boolean;
    workspace: Workspace;
    slot: Slot;
    profileId: string;
    transition: number;
    isSettingValue: boolean;
    isReadyToStart: boolean;
    isParticipating: boolean;
    isConnected: boolean;
    sessionState: SessionStateValue;
    currentActivity: Activity | undefined;
    currentActivityId: StandardSessionActivity | string | null;
    currentActivityPart: ActivityPart;
    toggleParticipationHandler: () => void;
    setReadyToStartHandler: () => void;
    setActivityValueHandler: (args: {
      activityId: string;
      value: string;
    }) => void;
    setActivityReadyHandler: (args: { activityId: string }) => void;
    teamName: string;
    reconnectTimeouts: { profileId: string }[];
    millisecondsToStart: number | null;
    requestNextWorkshop: (id: string) => void;
    nextWorkshopRequested: boolean;
    workshopCoverageData: {
      coverage: number;
      workspaceProfilesCount: number;
    } | null;
  }>
) {
  const {
    workshop,
    nextWorkshop,
    isFetchingNextWorkshop,
    feedbacks,
    isFetchingFeedbacks,
    connectionStrength,
    workspace,
    slot,
    profileId,
    transition,
    isSettingValue,
    sessionState,
    isReadyToStart,
    isParticipating,
    isConnected,
    isSessionCompleted,
    currentActivity,
    currentActivityId,
    currentActivityPart,
    toggleParticipationHandler,
    setReadyToStartHandler,
    setActivityValueHandler,
    setActivityReadyHandler,
    teamName,
    reconnectTimeouts,
    millisecondsToStart,
    requestNextWorkshop,
    nextWorkshopRequested,
    workshopCoverageData,
  } = props;

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const lostConnectionIdRef = useRef<NodeJS.Timeout | null>(null);

  const isTransitioning = useMemo(() => transition > 0, [transition]);

  const isUnknownConnectionStrength = useMemo(
    () => connectionStrength === ConnectionStrength.Unknown,
    [connectionStrength]
  );

  const isWaitingStage = useMemo(
    () => currentActivityId === StandardSessionActivity.Waiting,
    [currentActivityId]
  );

  const isStartEmotionStage = useMemo(
    () => currentActivityId === StandardSessionActivity.StartEmotion,
    [currentActivityId]
  );

  const isTeamNameStage = useMemo(
    () => currentActivityId === StandardSessionActivity.TeamName,
    [currentActivityId]
  );

  const isEndEmotionStage = useMemo(
    () => currentActivityId === StandardSessionActivity.EndEmotion,
    [currentActivityId]
  );

  const isRatingStage = useMemo(
    () => currentActivityId === StandardSessionActivity.Rating,
    [currentActivityId]
  );

  const isViewResultsStage = useMemo(
    () => currentActivityId === StandardSessionActivity.ViewResults,
    [currentActivityId]
  );

  const activityResult = useMemo(
    () => sessionState.context.activityResult,
    [sessionState.context.activityResult]
  );

  const activityResultForCurrentProfile = useMemo(
    () =>
      activityResult
        .find((a) => a.key === currentActivityId)
        ?.value?.find((a) => a.key === currentActivityPart)
        ?.value.find((a) => a.profileId === profileId) || null,
    [activityResult, currentActivityId, currentActivityPart, profileId]
  );

  const currentActivityResults = useMemo(
    () =>
      activityResult
        .find((a) => a.key === currentActivityId)
        ?.value?.filter((a) => a.key === currentActivityPart) || [],
    [activityResult, currentActivityId, currentActivityPart]
  );

  const currentActivityResultsForOtherProfiles = useMemo(
    () =>
      currentActivityResults.filter((a) =>
        a.value.find((v) => v.profileId !== profileId)
      ) || [],
    [currentActivityResults, profileId]
  );

  const currentActivityGroupResults = useMemo(() => {
    return (
      activityResult
        .find((a) => a.key === currentActivityId)
        ?.value?.filter((a) => a.key === "group") || []
    );
  }, [activityResult, currentActivityId]);

  const currentActiveParticipants = useMemo(() => {
    const currentActiveProfiles =
      sessionState.context.currentActiveProfiles.map(
        ({ profileId }) => profileId
      );

    if (!reconnectTimeouts.length) return currentActiveProfiles;

    return currentActiveProfiles.filter((id) =>
      reconnectTimeouts.find((t) => t.profileId === id)
    );
  }, [sessionState.context.currentActiveProfiles, reconnectTimeouts]);

  const currentActiveParticipantCount = useMemo(
    () => currentActiveParticipants.length,
    [currentActiveParticipants.length]
  );

  const notReadyProfilesCount = useMemo(
    () =>
      sessionState.value === StandardSessionActivity.Waiting
        ? sessionState.context.currentActiveProfiles.length -
          sessionState.context.readyActiveProfiles.length
        : sessionState.context.currentActiveProfiles.filter(({ profileId }) => {
            const match = currentActivityResults[0]?.value?.find(
              (v) => v.profileId === profileId
            );
            return match === undefined || match.ready === false;
          })?.length,
    [
      currentActivityResults,
      sessionState.context.currentActiveProfiles,
      sessionState.context.readyActiveProfiles.length,
      sessionState.value,
    ]
  );

  const isActivityReady = activityResultForCurrentProfile?.ready || false;
  const activityValue = activityResultForCurrentProfile?.value || null;

  const internalSetActivityValueHandler: (args: {
    activityId: string;
    value: string;
  }) => void = useCallback(
    (args) => {
      if (isTransitioning) {
        return;
      }
      setActivityValueHandler(args);
    },
    [isTransitioning, setActivityValueHandler]
  );

  const setEmotionHandler = useCallback(
    (emotionValue: string) => {
      if (isTransitioning) {
        return;
      }
      internalSetActivityValueHandler({
        activityId: isStartEmotionStage
          ? StandardSessionActivity.StartEmotion
          : StandardSessionActivity.EndEmotion,
        value: emotionValue,
      });
    },
    [isStartEmotionStage, isTransitioning, internalSetActivityValueHandler]
  );

  const setEmotionReadyHandler = useCallback(() => {
    setActivityReadyHandler({
      activityId: isStartEmotionStage
        ? StandardSessionActivity.StartEmotion
        : StandardSessionActivity.EndEmotion,
    });
  }, [isStartEmotionStage, setActivityReadyHandler]);

  const setRatingReadyHandler = useCallback(() => {
    setActivityReadyHandler({
      activityId: StandardSessionActivity.Rating,
    });
  }, [setActivityReadyHandler]);

  const setTeamNameHandler = useCallback(
    (value: string) => {
      internalSetActivityValueHandler({
        activityId: StandardSessionActivity.TeamName,
        value,
      });
    },
    [internalSetActivityValueHandler]
  );

  const setRatingHandler = useCallback(
    (value: string) => {
      internalSetActivityValueHandler({
        activityId: StandardSessionActivity.Rating,
        value,
      });
    },
    [internalSetActivityValueHandler]
  );

  const setTeamNameReadyHandler = useCallback(() => {
    setActivityReadyHandler({
      activityId: StandardSessionActivity.TeamName,
    });
  }, [setActivityReadyHandler]);

  const workshopData: WorkshopDetailProps["workshop"] = useMemo(
    () => ({
      topic: workshop.topic,
      headline: workshop.headline,
      goals: workshop.goals
        .slice()
        .sort((a, b) => a.sequence_number - b.sequence_number)
        .map((g) => g.text),
      duration: workshop.duration,
    }),
    [workshop.duration, workshop.goals, workshop.headline, workshop.topic]
  );

  const authorData: WorkshopDetailProps["author"] = useMemo(
    () => ({
      id: workshop.author.id,
      name: workshop.author.name,
      headline: workshop.author.headline,
    }),
    [workshop.author.headline, workshop.author.id, workshop.author.name]
  );

  const workshopActivities = useMemo(
    () => workshop.activities,
    [workshop.activities]
  );

  const invitedParticipantCount: number = useMemo(
    () =>
      slot.invitations.filter(
        ({ status }) => status !== InvitationStatus.AUTO_GENERATED
      ).length,
    [slot.invitations]
  );

  const Connecting = (
    <>
      {(!isConnected || isUnknownConnectionStrength) && !isSessionCompleted && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1,
            background: "rgb(247 247 243 / 50%)",
          }}
        >
          <h2
            style={{
              background: "var(--Colors-Text-text-primary, #444340)",
              padding: "10px",
              borderRadius: "10px",
              color: "white",
            }}
          >
            Connecting...
          </h2>
        </div>
      )}
    </>
  );

  useEffect(() => {
    // Show the Connection overlay is case of connection lost
    // After a few seconds we should redirect the user to /lost-connection page
    if (!isConnected && !isSessionCompleted && !lostConnectionIdRef.current) {
      const newPath = `/session/connection-lost/${pathname.replace(
        "/session/instance/",
        ""
      )}`;
      lostConnectionIdRef.current = setTimeout(() => navigate(newPath), 10000);
    }

    // We must interrupt the redirect if the connection is established
    if (isConnected && lostConnectionIdRef.current) {
      clearTimeout(lostConnectionIdRef.current);
      lostConnectionIdRef.current = null;
    }
  }, [isConnected, isSessionCompleted, navigate, pathname]);

  if (isWaitingStage) {
    return (
      <>
        {Connecting}
        <Waiting
          slot={slot}
          author={authorData}
          transition={transition}
          workshop={workshopData}
          isReady={isReadyToStart}
          isParticipating={isParticipating}
          notReadyProfilesCount={notReadyProfilesCount}
          invitedParticipantCount={invitedParticipantCount}
          currentActiveParticipantCount={currentActiveParticipantCount}
          minimumWorkshopParticipants={
            sessionState.context.minimumWorkshopParticipants
          }
          toggleParticipationHandler={toggleParticipationHandler}
          setActivityReadyToStartHandler={setReadyToStartHandler}
          millisecondsToStart={millisecondsToStart}
        />
      </>
    );
  }
  if (isStartEmotionStage || isEndEmotionStage) {
    return (
      <>
        {Connecting}
        <Emotion
          workshopHeadline={workshopData.headline}
          isParticipating={isParticipating}
          isReady={isActivityReady}
          currentEmotion={activityValue}
          isEndEmotionStage={isEndEmotionStage}
          notReadyProfilesCount={notReadyProfilesCount}
          currentActiveParticipantCount={currentActiveParticipantCount}
          setEmotionHandler={setEmotionHandler}
          transition={transition}
          setActivityReadyHandler={setEmotionReadyHandler}
        />
      </>
    );
  }
  if (isTeamNameStage) {
    return (
      <>
        {Connecting}
        <TeamName
          isParticipating={isParticipating}
          isReady={isActivityReady}
          transition={transition}
          notReadyProfilesCount={notReadyProfilesCount}
          currentActiveParticipantCount={currentActiveParticipantCount}
          teamNameValue={activityValue || ""}
          changeTeamNameHandler={setTeamNameHandler}
          setActivityReadyHandler={setTeamNameReadyHandler}
        />
      </>
    );
  }
  if (isRatingStage) {
    return (
      <>
        {Connecting}
        <Rating
          activityValue={activityValue}
          feedbacks={feedbacks}
          isFetchingFeedbacks={isFetchingFeedbacks}
          isReady={isActivityReady}
          transition={transition}
          notReadyProfilesCount={notReadyProfilesCount}
          currentActiveParticipantCount={currentActiveParticipantCount}
          setRatingHandler={setRatingHandler}
          setActivityReadyHandler={setRatingReadyHandler}
        />
      </>
    );
  }
  if (isViewResultsStage) {
    return (
      <>
        {Connecting}
        <ViewResults
          sessionState={sessionState}
          activityResult={activityResult}
          profileId={profileId}
          workshop={workshop}
          nextWorkshop={nextWorkshop}
          isFetchingNextWorkshop={isFetchingNextWorkshop}
          workspace={workspace}
          requestNextWorkshop={requestNextWorkshop}
          nextWorkshopRequested={nextWorkshopRequested}
          workshopCoverageData={workshopCoverageData}
        />
      </>
    );
  }

  return (
    <>
      {Connecting}
      <WorkshopActivity
        profileId={profileId}
        isReady={isActivityReady}
        workshopAuthor={workshop.author}
        transition={transition}
        isSettingValue={isSettingValue}
        activity={currentActivity!}
        sessionState={sessionState}
        currentActivityId={currentActivityId!}
        currentActivityPart={currentActivityPart}
        notReadyProfilesCount={notReadyProfilesCount}
        currentActiveParticipants={currentActiveParticipants}
        currentActiveParticipantCount={currentActiveParticipantCount}
        currentActivityResultsForOtherProfiles={
          currentActivityResultsForOtherProfiles
        }
        currentActivityGroupResults={currentActivityGroupResults}
        workshopActivities={workshopActivities}
        setActivityValueHandler={internalSetActivityValueHandler}
        setActivityReadyHandler={setActivityReadyHandler}
        teamName={teamName}
      />
    </>
  );
}

export default memo(withTitle(WorkshopInstance));
