import { PropsWithChildren, memo } from "react";
import Loader from "../Loader/Loader";
import { ButtonSize, ButtonVariant } from "../../../types/buttons";

import cn from "classnames";

export default memo(function Button3D(
  props: PropsWithChildren<{
    className?: string;
    disabled?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    size?: ButtonSize;
    variant?: ButtonVariant;
    type?: "button" | "reset" | "submit";
    isLoading?: boolean;
  }>
) {
  const {
    className,
    disabled,
    size,
    variant,
    onClick,
    children,
    type = "button",
    isLoading = false,
  } = props;

  return (
    <button
      className={cn("btn", "dbl", className, size, variant || "primary")}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      <div className={cn("front", isLoading && "loading")}>
        {isLoading && <Loader className="loading-container" />}
        {children}
      </div>
    </button>
  );
});
