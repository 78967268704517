import { PropsWithChildren, memo, useMemo, useState } from "react";
import { WorkshopAuthor } from "../../../../../../apollo-graphql/types/workshop";
import withSortable, {
  IWithSortableProps,
} from "../../../../../../hocs/withSortable";

import cn from "classnames";
import styles from "./MomentCard.module.css";

export interface IMoment {
  id: string;
  profileId: string;
  text: string;
}

interface MomentCardProps extends IWithSortableProps {
  index: number;
  isTransitioning: boolean;
  currentProfileId: string;
  workshopAuthor: WorkshopAuthor;
  item: IMoment;
}

const MomentCard = (props: PropsWithChildren<MomentCardProps>) => {
  const {
    index,
    item,
    isTransitioning,
    currentProfileId,
    workshopAuthor,
    isDraggable,
    listeners,
    attributes,
    setNodeRef,
    style,
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const titleText = useMemo(() => {
    const initialText = `Aha moment ${index + 1}`;
    return isDraggable
      ? `${workshopAuthor.name}’s ${initialText}`
      : initialText;
  }, [index, isDraggable, workshopAuthor.name]);

  return (
    <div
      ref={setNodeRef}
      style={style}
      key={item.id}
      className={cn(styles.moment, "review", isTransitioning && "disabled")}
    >
      <div
        className={cn(
          styles.momentTopLine,
          !isDraggable ? "not-draggable" : "draggable"
        )}
        {...listeners}
        {...attributes}
      >
        <p className={styles.momentTopLineText}>{titleText}</p>
        {item.profileId === currentProfileId && (
          <i className="fa icon fa-user" />
        )}
      </div>
      <div
        onClick={() => {
          setIsExpanded((prev) => !prev);
        }}
        className={cn(
          styles.momentExplanationToggle,
          isExpanded ? "expanded" : ""
        )}
      >
        <i
          className={cn(
            "icon fa fa-chevron-down",
            styles.momentExplanationToggleIcon
          )}
        ></i>
      </div>
      <div
        className={cn(
          styles.momentExplanation,
          isExpanded ? "expanded" : "",
          "text",
          "small"
        )}
        dangerouslySetInnerHTML={{ __html: item.text }}
      />
    </div>
  );
};

export default memo(withSortable(MomentCard));
