import { useParams, Navigate } from "react-router-dom";

const WorkshopRedirect = () => {
  const params = useParams();
  const { slotId, group } = params;

  return <Navigate to={`/session/instance/${slotId}/${group}`} />;
};

export default WorkshopRedirect;
