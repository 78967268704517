import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";
import { UpdateProfileResult } from "../types/results";
import { ProfileUpdate } from "../types/profile";

const mutation = gql`
  mutation Mutation(
    $id: String!
    $name: String
    $email: String
    $jobTitle: String
    $permissions: ProfileWorkspaceAccess
    $status: ProfileWorkspaceStatus
    $currentPassword: String
    $newPassword: String
  ) {
    updateProfile(
      id: $id
      name: $name
      email: $email
      jobTitle: $jobTitle
      permissions: $permissions
      status: $status
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      id
      name
    }
  }
`;

export function updateProfile(
  client: AppApolloClient,
  variables: ProfileUpdate
) {
  return client
    .mutate<UpdateProfileResult>({
      mutation,
      variables,
      fetchPolicy: "no-cache",
    })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.updateProfile;
    });
}
