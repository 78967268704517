import { PropsWithChildren, useMemo } from "react";

import { FooterType } from "../../types/enums/activity-footer";
import { ActionFooterType } from "../../types/action-footer";
import Button3D from "../Shared/Button3D/Button3D";

import cn from "classnames";
import styles from "./ActionFooter.module.css";

const iconTypeMap = {
  [FooterType.Notice]: "fa-exclamation",
  [FooterType.Ready]: "fa-check",
  [FooterType.Waiting]: "fa-hourglass",
  [FooterType.Error]: "fa-x",
};

export interface ActionFooterProps extends Omit<ActionFooterType, "text"> {
  buttonClickHandler: () => void;
}
export default function ActionFooter(
  props: PropsWithChildren<ActionFooterProps>
) {
  const {
    type,
    children,
    buttonText,
    disabledButton,
    buttonClickHandler,
    isLoading,
  } = props;

  const iconContent = useMemo(
    () => (
      <div className={cn("big-icon", type)}>
        <i className={cn("icon", styles.icon, "fa", iconTypeMap[type])} />
      </div>
    ),
    [type]
  );

  return (
    <div
      className={cn(styles.container, type, "main-container", "row", "footer")}
    >
      <div className={styles.actionsInfo}>
        {iconContent}
        <h2>{children}</h2>
      </div>
      {buttonText && (
        <Button3D
          size="large"
          variant={type === FooterType.Waiting ? "primary" : "success"}
          onClick={buttonClickHandler}
          disabled={disabledButton}
          className={styles.actionButton}
          isLoading={isLoading}
        >
          {buttonText}
        </Button3D>
      )}
    </div>
  );
}
