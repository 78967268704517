import { PropsWithChildren, useContext, useMemo } from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
  useMatches,
  useSearchParams,
} from "react-router-dom";

import Header from "./components/Header/Header";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Session from "./pages/Session";
import Logout from "./pages/Logout";
import Disconnected from "./pages/Disconnected";
import ResetPassword from "./pages/ResetPassword";
import ChangePassword from "./pages/ChangePassword";
import WorkshopRedirect from "./components/Workshop/WorkshopRedirect/WorkshopRedirect";

import { GlobalContext } from "./contexts/Global";
import { AuthState } from "./+xstate/machines/auth";
import { SessionContextProvider } from "./contexts/Session";
import { JitsiContextProvider } from "./contexts/Jitsi";

import "./Router.module.css";

const authRequired = ["dashboard", "session", "logout"];
const noAuthRequired = ["login"];

const Root = () => {
  const {
    auth: { state, matches },
  } = useContext(GlobalContext);
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const redirectUrl = useMemo(
    () => searchParams.get("redirectUrl") || "/",
    [searchParams]
  );
  const loginUrl = useMemo(() => {
    return location.pathname &&
      !location.pathname.includes("logout") &&
      location.pathname !== "/"
      ? `/login?redirectUrl=${location.pathname}`
      : `/login`;
  }, [location.pathname]);

  const [, main] = useMatches();
  const isValidating = state === AuthState.Validating;
  const isLoggedIn = matches(AuthState.Authenticated);

  if (isValidating) return null;

  const redirect =
    isLoggedIn && noAuthRequired.includes(main.id) ? (
      <Navigate to={redirectUrl} />
    ) : !isLoggedIn && authRequired.includes(main.id) ? (
      <Navigate to={loginUrl} />
    ) : null;

  const routerContainerClassName = [
    "RouterContainer",
    isLoggedIn ? "" : "LoggedOut",
  ]
    .filter(Boolean)
    .join(" ");

  return redirect ? (
    redirect
  ) : (
    <div className={routerContainerClassName}>
      <div>
        <Header />
      </div>
      <Outlet />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    id: "root",
    children: [
      {
        path: "/",
        element: <Dashboard pageTitle="Dashboard - AhaPlay" />,
        id: "dashboard",
        children: [
          {
            id: "dashboard-main",
            path: "",
            index: true,
          },
          {
            id: "dashboard-schedule",
            path: "schedule",
            index: true,
          },
          {
            id: "dashboard-team-members",
            path: "team-members",
            children: [
              {
                id: "dashboard-team-members-edit",
                path: "/team-members/edit/:id",
              },
            ],
          },
          {
            id: "dashboard-schedule-upcoming",
            path: "schedule/upcoming",
            index: true,
          },
          {
            id: "dashboard-schedule-history",
            path: "schedule/history",
            index: true,
          },
          {
            id: "dashboard-workshops",
            path: "workshops",
            index: true,
          },
          {
            id: "dashboard-workshop-dialog",
            path: "workshops/schedule/:workshopId",
            index: true,
          },
        ],
      },
      {
        id: "session",
        path: "/session",
        element: (
          <SessionContextProvider>
            <JitsiContextProvider>
              <Session />
            </JitsiContextProvider>
          </SessionContextProvider>
        ),
        children: [
          {
            id: "session-slot",
            path: "slot/:slotId",
            index: true,
          },
          {
            id: "session-instance",
            path: "instance/:sessionKey",
            index: true,
          },
          {
            id: "session-instance-group",
            path: "instance/:sessionKey/:group",
            index: true,
          },
          {
            id: "reschedule-redirect",
            path: "waiting-room/reschedule-redirect/:slotId/:newSlotId",
            index: true,
          },
          {
            id: "waiting-room",
            path: "waiting-room/:slotId",
            index: true,
          },
          {
            id: "ongoing-sessions",
            path: "ongoing-sessions/:slotId",
            index: true,
          },
          {
            id: "thank-you",
            path: "thank-you/:slotId/:group",
            index: true,
          },
          {
            id: "connection-lost",
            path: "connection-lost/:slotId/:group",
            index: true,
          },
        ],
      },
      {
        path: "workshop-redirect/:slotId/:group",
        element: <WorkshopRedirect />,
      },
      {
        path: "/disconnected",
        element: <Disconnected />,
      },
      {
        path: "/login",
        element: <Login pageTitle="Login - AhaPlay" />,
        id: "login",
      },
      {
        path: "/logout",
        element: <Logout />,
        id: "logout",
      },
      {
        path: "/reset-password",
        element: <ResetPassword pageTitle="Reset Password - AhaPlay" />,
        id: "reset-password",
      },
      {
        path: "/change-password",
        element: <ChangePassword pageTitle="Change Password - AhaPlay" />,
        id: "change-password",
      },
    ],
  },
]);

export default function Router(props: PropsWithChildren) {
  return <RouterProvider router={router} />;
}
