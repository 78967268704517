import { PropsWithChildren, memo, useCallback, useMemo, useState } from "react";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { OutputDevice } from "../../../types/output-device";

import styles from "./DeviceSelect.module.css";
import cn from "classnames";

const deviceIconMap = {
  [OutputDevice.AUDIO]: "fa-microphone",
  [OutputDevice.VIDEO]: "fa-video",
};

const arrowIconMap = {
  up: <ChevronUpIcon />,
  down: <ChevronDownIcon />,
};

const DeviceSelect = (
  props: PropsWithChildren<{
    deviceId: string | null | undefined;
    deviceName: OutputDevice;
    values: { value: string; key: string; label: string }[];
    disabled: boolean;
    onClickHandler: (newDeviceId: string) => void;
    initialArrowDirection: "up" | "down";
  }>
) => {
  const {
    deviceName,
    values,
    deviceId,
    disabled,
    onClickHandler,
    initialArrowDirection,
  } = props;
  const [isDropdownVisible, setDropdownVisibility] = useState<boolean>(false);

  const deviceIcon = useMemo(
    () => <i className={cn("fa", deviceIconMap[deviceName])} />,
    [deviceName]
  );

  const arrowIcon = useMemo(() => {
    const direction = isDropdownVisible
      ? initialArrowDirection === "up"
        ? "down"
        : "up"
      : initialArrowDirection;
    return arrowIconMap[direction];
  }, [initialArrowDirection, isDropdownVisible]);

  const onToggleHandler = useCallback(
    () => setDropdownVisibility((prev) => !prev),
    []
  );

  return (
    <DropdownMenu.Root onOpenChange={onToggleHandler}>
      <DropdownMenu.Trigger asChild>
        <button
          className={styles.chevronIconButton}
          aria-label="aria-label"
          disabled={disabled}
        >
          {arrowIcon}
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          aria-disabled={disabled}
          className={styles.DropdownMenuContent}
          sideOffset={5}
        >
          <DropdownMenu.RadioGroup value={deviceId || ""}>
            <DropdownMenu.Label className={styles.DropdownMenuGroupLabel}>
              {deviceName}
            </DropdownMenu.Label>
            {values.map(({ key, label }) => {
              return (
                <DropdownMenu.RadioItem
                  key={key}
                  className={styles.DropdownMenuRadioItem}
                  value={key}
                  onClick={() => onClickHandler(key)}
                >
                  <DropdownMenu.Item>{deviceIcon}</DropdownMenu.Item>

                  <DropdownMenu.Label className={styles.DropdownMenuLabel}>
                    {label}
                  </DropdownMenu.Label>
                  <DropdownMenu.ItemIndicator
                    className={styles.DropdownMenuItemIndicator}
                  >
                    <i className="fa fa-check" />
                  </DropdownMenu.ItemIndicator>
                </DropdownMenu.RadioItem>
              );
            })}
          </DropdownMenu.RadioGroup>
          <DropdownMenu.Arrow className={styles.DropdownMenuArrow} />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default memo(DeviceSelect);
