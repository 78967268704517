import styles from "./VideoPreview.module.css";
import cn from "classnames";

export function AudioDisabledTooltip() {
  return (
    <div className={styles.tooltip}>
      <p className="text medium bold">
        <i
          className={cn(
            "fa-solid fa-triangle-exclamation",
            styles.tooltipWarning
          )}
        />
        Microphone error
      </p>
      <span className="text small">
        You need to enable your microphone in order to join the workshop.
      </span>
    </div>
  );
}
