import {
  ChangeEvent,
  PropsWithChildren,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Cross2Icon } from "@radix-ui/react-icons";

import * as Dialog from "@radix-ui/react-dialog";
import * as Form from "@radix-ui/react-form";

import styles from "./ChallengeAuthorModal.module.css";
import cn from "classnames";
import LoadingButton from "../../Shared/LoadingButton/LoadingButton";
import Alert, { AlertType } from "../../Shared/Alert/Alert";

const ChallengeAuthorModal = ({
  closeDialogHandler,
  submitDialogHandler,
}: PropsWithChildren<{
  closeDialogHandler: () => void;
  submitDialogHandler: (comment: string) => Promise<any>;
}>) => {
  const [comment, setComment] = useState<string>("");
  const [status, setStatus] = useState<string>("idle");

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setComment(event.target.value);
    },
    []
  );

  const onSubmitHandler = useCallback(() => {
    if (!comment || comment.length === 0 || status === "loading") {
      return;
    }
    setStatus("loading");
    submitDialogHandler(comment)
      .then((res) => {
        setStatus("success");
        setTimeout(() => {
          closeDialogHandler();
          setStatus("idle");
        }, 2000);
      })
      .catch((err) => {
        setStatus("error");
        setTimeout(() => {
          closeDialogHandler();
          setStatus("idle");
        }, 2000);
      });
  }, [comment, submitDialogHandler, closeDialogHandler, status]);

  const content = useMemo(
    () => (
      <>
        <Form.Root>
          <Form.Field name="comment" className={styles.textAreaContainer}>
            <Form.Label className="text">Add your objection here</Form.Label>
            <Form.Control asChild>
              <textarea
                className={cn("Textarea", "text")}
                required
                placeholder="Your comment"
                onInput={onChangeHandler}
              />
            </Form.Control>
          </Form.Field>
        </Form.Root>
        {status === "error" && <p>Error message! Please try again later.</p>}
      </>
    ),
    [onChangeHandler, status]
  );

  if (status === "success") {
    return (
      <Alert
        type={AlertType.Success}
        heading="Author Challenged"
        description="Your objection was successfully sent."
        onClose={closeDialogHandler}
      />
    );
  }

  if (status === "error") {
    return (
      <Alert
        type={AlertType.Error}
        description="There was an unexpected error while submiting your objection. Please try again."
        onClose={closeDialogHandler}
      />
    );
  }

  return (
    <Dialog.Root open={true} onOpenChange={closeDialogHandler}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className={cn(styles.dialogContent, "DialogContent")}>
          <Dialog.Title className={cn(styles.title, "DialogTitle")}>
            Challenge the author
          </Dialog.Title>
          <div className={styles.content}>{content}</div>
          <div className={styles.dialogFooter}>
            <button
              className={cn(
                "btn medium cancel outline",
                comment.length === 0 || status === "loading" ? "disabled" : ""
              )}
              onClick={closeDialogHandler}
              disabled={status === "loading"}
            >
              Cancel
            </button>
            <LoadingButton
              className="submit"
              size="medium"
              variant="primary"
              onClick={onSubmitHandler}
              isLoading={status === "loading"}
              disabled={comment.length === 0 || status === "loading"}
            >
              Submit
            </LoadingButton>
          </div>
          <Dialog.Close asChild>
            <button
              className={cn(styles.dialogCloseButton, "IconButton")}
              aria-label="Close"
              disabled={status === "loading"}
            >
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default memo(ChallengeAuthorModal);
