import { gql } from "@apollo/client";
import { AppApolloClient } from "../../contexts/Apollo";

const mutation = gql`
  mutation Mutation($sessionId: String!, $uuid: String!) {
    join(sessionId: $sessionId, uuid: $uuid) {
      context {
        activityResult {
          key
          value {
            key
            value {
              profileId
              ready
              value
              disconnectTimestamp
              workshopTimeout
              activityTimeout
              activityPartTimeout
            }
          }
        }
        reconnectTimeouts {
          profileId
        }
        currentActiveProfiles {
          profileId
          uuid
        }
        startTimestamp
        lastUpdatedTimestamp
        lastActivityTimestamp
        lastActivityPartTimestamp
        readyActiveProfiles
        minimumWorkshopParticipants
        maximumWorkshopParticipants
      }
      value
      sessionKey
      sessionId
    }
  }
`;

export function join(
  client: AppApolloClient,
  variables: { sessionId: string; uuid: string }
) {
  return client
    .mutate<any>({ mutation, variables, fetchPolicy: "no-cache" })
    .then((result) => {
      if (result.errors) return Promise.reject(result.errors[0]);
      return result.data!.join;
    });
}
