import { ActivityPart } from "../types/enums/activity-part";
import { ActivityType } from "../types/enums/activity-type";

const defaultFooterTextFactory = (cnt: number) => (
  <>
    Click “Continue” when ready!{" "}
    {cnt > 0 && <span className="accent">{cnt} player{cnt > 1 && 's'} clicked.</span>}
  </>
);
export const activityTypeFooterTextFactoryMap = {
  [ActivityType.Theory]: defaultFooterTextFactory,
  [ActivityType.Assignment]: defaultFooterTextFactory,
  [ActivityType.Conceptualization]: defaultFooterTextFactory,
  [ActivityType.Benchmark]: defaultFooterTextFactory,
  [ActivityType.Survey]: defaultFooterTextFactory,
  [ActivityType.Action]: (cnt: number) => (
    <>
      Choose your individual answer before joining the discussion.{" "}
      {cnt > 0 && <span className="accent">{cnt} player{cnt > 1 && 's'} clicked.</span>}
    </>
  ),
  [ActivityType.Question]: (cnt: number) => (
    <>
      Click “Go to discussion” to confirm!{" "}
      {cnt > 0 && <span className="accent">{cnt} player{cnt > 1 && 's'} clicked.</span>}
    </>
  ),
  [ActivityType.Moment]: (cnt: number) => (
    <>
      Click “Go to discussion” to confirm!{" "}
      {cnt > 0 && <span className="accent">{cnt} player{cnt > 1 && 's'} clicked.</span>}
    </>
  ),
};

export const getFooterText = (
  activityType: ActivityType,
  currentActivityPart: ActivityPart,
  cnt: number
) => {
  if (
    activityType === ActivityType.Question &&
    currentActivityPart === ActivityPart.Group
  ) {
    return (
      <>
        You have to <span className="accent">match your opinions</span> to
        continue.
      </>
    );
  }
  if (
    activityType === ActivityType.Question &&
    currentActivityPart === ActivityPart.Review
  ) {
    return (
      <>
        Your individual opinions match! Click{" "}
        <span className="accent">“Continue”</span> to confirm them as a team
        opinion!
      </>
    );
  }
  if (activityType === ActivityType.Action &&
    currentActivityPart === ActivityPart.Group
  ) {
    return (
      <>
        Your opinions match! Click{" "}
        <span className="accent">“Continue”</span> to confirm them as a team
        opinion! {cnt > 0 && <span className="accent">{cnt} player{cnt > 1 && 's'} clicked!</span>}
      </>
    );
  }
  return activityTypeFooterTextFactoryMap[activityType](cnt);
};